import { connect } from 'react-redux'
import Perfil from './UsuarioPerfil'
import { updatePerfil, load2Update } from '../../../../redux/modules/usuario'



const mdtp = { updatePerfil, load2Update };
const mstp = state => state.usuario

export default connect(mstp, mdtp)(Perfil)
