import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './acciones.css';
import Swal from 'sweetalert2';

class Acciones extends Component {
    constructor(props) {
        super(props);
        this.state = { redirect: false, url: '' };
        this.redirect = this.redirect.bind(this);
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
    }
    redirect(url) {
        return () => {
            this.setState({ url });
            this.setState({ redirect: true });
        };
    }
    eliminar(id) {
        return () => {
            Swal({
                title: '¿Desea eliminar este elemento?',
                text: 'No puedes deshacer esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    }
    editar(id) {
        return () => {
            this.props.editar(id);
        }
    }

    render() {
        const { id, ver, editar, eliminar, adicional, obj, row, ver_icono} = this.props;
        if (this.state.redirect) {
            return (<Redirect to={`${this.state.url}/${id}`} />);
        }
        let offset = 0;
        if (ver !== undefined) {
            offset += 1;
        }
        if (editar !== undefined) {
            offset += 1;
        }
        if (eliminar !== undefined) {
            offset += 1;
        }
        if (ver_icono !== undefined) {
            offset += 1;
        }
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {(adicional !== undefined) && adicional(id)}
                    {(ver_icono !== undefined) && (
                         <Link to={`${ver_icono}/${id}/`} ><img className="action-img" title="Ver" src={require("../../../../../assets/img/icons/ver.png")} alt="Ver" /></Link>
                    )}
                    {(ver !== undefined) && (
                        <Link to={`${ver}/${obj?row[obj].pk:id}/`}  className="btn btn-info letra-pequenia mr-1" >Ver Acta</Link>
                    )}

                    {(editar !== undefined) && ((typeof editar) === "string") && (
                        <Link to={`${editar}/${id}/`} ><img className="action-img" title="Editar" src={require("../../../../../assets/img/icons/edit.png")} alt="Edit" /></Link>
                    )}
                    {(editar !== undefined) && ((typeof editar) !== "string") && (
                        <img onClick={this.editar(id)} className="action-img" title="Editar" src={require("../../../../../assets/img/icons/edit.png")} alt="Edit" />
                    )}
                    {(eliminar !== undefined) && (
                        <img onClick={this.eliminar(id)} className="action-img" title="Eliminar" src={require("../../../../../assets/img/icons/delete.png")} alt="Delete" />
                    )}
                </div>
            </div>
        );
    }
}
Acciones.propTypes = {
};

export function activeFormatter(acciones) {
    return (cell, row) => {
        return (<Acciones id={cell} {...acciones} row={row} />)
    };
}
//