 
 import React, { Component } from 'react';


class Titulo extends Component  {
    render() {
       const { titulo, noDivisor } = this.props;
        return(
        	<div>
	         	<h5 className="m-3 text-primary subtitulo font-weight-bold">
	                <strong>{ titulo }</strong>
	            </h5>
	           {
	            	!noDivisor && 
	            	<div className="py-3 background-secondary mb-4">
	            	</div>
	            }
	        </div>
        )
    }
}

export default Titulo;
