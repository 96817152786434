import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2';
import moment from 'moment'; 
import {push} from 'react-router-redux';
import _ from "lodash";

const url = 'asignaciones/'

export const onSubmit=() => (dispatch, getState)=>{
  const store = getState();
  console.log(store.asignacion.cerrar);
  console.log('store.asignacion.cerrar');
  if(store.asignacion.cerrar)
   {
     Swal({
    title: '¿Desea cerrar esta acta?',
    text: 'Si lo hace no podrá volver a modificar sus asignaciones. No puedes deshacer esta acción.',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Sí, cerrar',
    cancelButtonText: 'No, cancelar',
    reverseButtons: true
  }).then((result) => {
      if (result.value) {
          Swal({
            title: '¿Está seguro que desea cerrar esta asignación?',
            text: 'Si lo hace no podrá volver a modificar sus asignaciones. No puedes deshacer esta acción',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, cerrar',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
          }).then((result) => {
              if (result.value) {
                   dispatch(create(true))
              }
          });

      }
  });
    dispatch(setCerrar(false));
   }
  else
  {

    dispatch(create(false))
  }
}


export const setCerrarAsignacion=(estado) => (dispatch, getState) => {
    dispatch(setCerrar(estado))
}





export const create = (cerrar) => (dispatch, getState) => {
  dispatch(loading())
  const formData = _.cloneDeep(getState().form.asignacion.values);
  const store = getState();
  console.log(formData, "FORM ASIGNACION")
  let asignacion = normalizarObjeto(formData, store.asignacion.idCiclo, store.asignacion.inscripcion, cerrar);
  api.post(url,asignacion)
    .then(response => {
      if(!cerrar)
        dispatch(goBack())
      else
         dispatch(push(`/asignacion/inscripcion`));
    })
    .catch(e => {
      console.error(e)
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.detail)
        mensaje = e.detail;
      if(e.length)
        mensaje = e[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}



const normalizarObjeto=(value, ciclos_asignacion, inscripcion, cerrar=false)=>{
  let valores = []
  const ciclo = ciclos_asignacion;
  console.log(value)
  value.ciclos.forEach( (element, index)=> {
      if(element.ciclo && element.cursos && element.cursos.length){
         element.cursos.forEach((curso, index)=> {
            let asignacion = {
              ciclo: ciclo, 
              acta: curso,
            };
            valores.push(asignacion);
         });
      }
  });

  const data = {
    inscripcion:  inscripcion.pk,
    cerrar: cerrar,
    cohorte:  inscripcion.cohorte,
    ciclo: ciclo,
    data_asignaciones: valores
  }
  return data;
}



export const load2Update = id => async (dispatch, getState) => {
  dispatch(loading())
  dispatch(setUpdateData({}))
  try {
    const asignacion = await api.get(`${url}${id}`)
    const storeAsignacion = getState().asignacion;
    let temp = _.cloneDeep(asignacion)
    temp = normalizarGet(temp, storeAsignacion.ciclos_asignacion )
    dispatch(setUpdateData(temp))
    dispatch(initializeForm('asignacion', temp))
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido obtener, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

const normalizarGet =(data, ciclos_opcion)=>{
  let ciclos = new Array(ciclos_opcion.length);
  /*Siempre se deben mostar todas las opciones de ciclo en el form,
    aunque no hayan sido seleccionadas*/
  ciclos.fill({})
   data.forEach( (element, index)=> {
      //Solamente puede haber una sección seleccionada, por lo tanto siempre se 
      //leerá el índice 
      let ciclo = element.secciones[0];
      let cursos =[];
      let cursos_ciclo =[];

      element.secciones[0].cursos.forEach((curso, index)=>{
        cursos.push(curso.acta.pk)
      });
      //Obteniendo cursos de la lista ode ciclos_opcion
      let i = _.findIndex(ciclos_opcion, e=>{ return e.pk == element.pk})
      if(i>-1){
        let temp = _.find(ciclos_opcion[i].secciones, e=>{ return e.seccion == element.secciones[0].seccion})
        if(temp){
          ciclo.cursos  = temp.cursos;
        }
        //añadiendo elementos en la posición adecuada
        ciclo.pk = element.pk;
        let item = {ciclo: ciclo, cursos: cursos}
        ciclos[i]=item;
      }
   })
   return({ciclos: ciclos})

}
export const destroy = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const remove = await api.erase(`asignaciones/${id}`)
    dispatch(find())
  } catch(e) {
    let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}



export const findAsignacion = (page = 1) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  try {
    const response = await  api.get(url, page)
    //dispatch(setDataAsignacion(response))
    dispatch(setPage(page))

  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}
export const findAsignacionPasada = () => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  try {
    const response = await  api.get(url)
    dispatch(setDataAsignacion(response))
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

export const getCiclos = (inscripcion, ciclo) => async (dispatch, getState) => {
  dispatch(loading())
  dispatch(setCiclosAsignacion([]))
  dispatch(setIdCiclo(ciclo))
  const store = getState();
  try {
    const response = await  api.get(`${url}cursos/${inscripcion}/${ciclo}`)
    dispatch(setCiclosAsignacion(response))
  } catch(e) {
     console.error(e)
     console.log(e)
     let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.body && e.body.detail)
        mensaje = e.detail;
      if(e.body && e.body.length)
        mensaje = e.body[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

export const getInscripcion = (id) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  try {
    const response = await  api.get('inscripciones/'+id)
    dispatch(setInscripcion(response))

  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}



export const findInscripcion = (page = 1) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  try {
    const response = await  api.get('inscripciones', page)
    let datos_inscripcion=_.cloneDeep(response);
    datos_inscripcion=  normalizarInscripciones(datos_inscripcion)
    dispatch(setDataInscripcion(datos_inscripcion))
    dispatch(setPage(page))

  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

 
const normalizarInscripciones=(inscripcion)=>{
  //Filtrar todas las inscripciones que no tiene fechas abiertas
  let nuevo = inscripcion.filter((e)=>e.ciclo != 0);
  return nuevo;
}

export const findCursosAsignados = (encabezado) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  try {
    const response = await  api.get(`asignaciones/asignados/${encabezado}`)
    dispatch(setDataCursosAsignados(response))
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

export const {  loading, 
                setDataAsignacion, 
                setDataInscripcion, 
                setPage, 
                setUpdateData, 
                setInscripcion, 
                setCiclosAsignacion, 
                setDataCursosAsignados, 
                setIdCiclo,
                setCerrar
} = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'UPLOADING': (uploading = true) => ({ uploading }),
  'SET_DATA_ASIGNACION': (data_asignacion) => ({ data_asignacion }),
  'SET_DATA_INSCRIPCION': (data_inscripcion) => ({ data_inscripcion }),
  'SET_PAGE': (page) => ({ page }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
  'SET_INSCRIPCION': (inscripcion) => ({ inscripcion }),
  'SET_CICLOS_ASIGNACION': (ciclos_asignacion) => ({ ciclos_asignacion }),
  'SET_DATA_CURSOS_ASIGNADOS': (data_cursos_asignados) => ({ data_cursos_asignados }),
  'SET_DATA_CURSOS_ASIGNADOS': (data_cursos_asignados) => ({ data_cursos_asignados }),
  'SET_ID_CICLO': (idCiclo) => ({ idCiclo }),
  'SET_CERRAR': (cerrar) => ({ cerrar }),
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setDataAsignacion]: (state, { payload: { data_asignacion }}) => ({ ...state, data_asignacion }),
  [setDataInscripcion]: (state, { payload: { data_inscripcion }}) => ({ ...state, data_inscripcion }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setInscripcion]: (state, { payload: { inscripcion }}) => ({ ...state, inscripcion }),
  [setCiclosAsignacion]: (state, { payload: { ciclos_asignacion }}) => ({ ...state, ciclos_asignacion }),
  [setDataCursosAsignados]: (state, { payload: { data_cursos_asignados }}) => ({ ...state, data_cursos_asignados }),
  [setIdCiclo]: (state, { payload: { idCiclo }}) => ({ ...state, idCiclo }),
  [setCerrar]: (state, { payload: { cerrar }}) => ({ ...state, cerrar }),
}

export const initialState = {
  data_asignacion: [],
  inscripcion: {},
  data_inscripcion: [],
  page: 1,
  loading: false,
  ciclos_asignacion:[],
  updateData: {},
  data_cursos_asignados:[],
  //Guardar el id del ciclo a guardar o actualizar
  idCiclo: 0,
  cerrar: false
}

export default handleActions(reducers, initialState)

