 import React, { Component } from 'react';


class Titulo extends Component  {
    render() {
       const { 	titulo, 
       			boton1, 
       			boton2, 
       			boton3,
       			funcBoton1,
       			funcBoton2,
       			funcBoton3,
       			color, 
       		 } = this.props;
        return(
        	<div>
        		<div className="row mx-0">
	        		<div className="col px-0">
			         	<h5 className="mx-lg-3 my-3 text-primary subtitulo font-weight-bold">
			                <strong> { titulo }</strong>
			            </h5>
			        </div>
			        { (boton1 || boton2 || boton3) &&
			        	<div className="col-lg-8 col-md-12 text-md-center  pr-lg-5 text-lg-right">
			        		{ boton1 &&
			        			<button className={`btn mx-2 my-3 ${color?color:'btn-info'}`}
			        					onClick={ funcBoton1 }>
			        					{ boton1 }
			        			</button>
			        		}

			        		{ boton2 &&
			        			<button className="btn mx-2 my-3 btn-info"
			        					onClick={ funcBoton2 }>
			        					{ boton2 }
			        			</button>
			        		}

			        		{ boton3 &&
			        			<button className="btn mx-2 my-3 btn-info"
			        					onClick={ funcBoton3 }>
			        					{ boton3 }
			        			</button>
			        		}

			        	</div>

			        }
		        </div>
	            <div className="py-3 background-secondary mb-4">
	            </div>
	        </div>
        )
    }
}

export default Titulo;
