import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderField } from '../Utils/renderField'
import { validate, validators } from 'validate-redux-form'

const LoginForm = props => {
    const { handleSubmit, pristine, reset, submitting } = props
    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}
        >
            <div className="form-group has-feedback">
                <label htmlFor="username">Usuario</label>
                <Field name="username" component={renderField} type="text" className="form-control" />

            </div>
            <div className="form-group has-feedback">
                <label htmlFor="password">Contraseña</label>
                <Field name="password" component={renderField} type="password" className="form-control" />
            </div>
            <div className="clearfix" />
            <div className="row text-center d-flex justify-content-center mt-3">
            <button type="submit" className="btn btn-primary btn-lg m-1 align-self-center">Iniciar sesión</button>
            </div>
        </form>
    )
}

export default reduxForm({


    form: 'login', // a unique identifier for this form
    validate: data => {
        return validate(data, {
            'username': validators.exists()('Este Campo es obligatorio'),
            'password': validators.exists()('Este Campo es obligatorio'),
        })
    }
})(LoginForm)