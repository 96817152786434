import { connect } from 'react-redux'
import CursoDocenteGrid from './CursoDocenteGrid'
import { 	findImpartidos, 
			findHistorial, 
			filtroCoordinacionCarerraDocente,
			filtroPensumDocente,
			filtroCicloDocente,
			filtroCohorteDocente
		 } from '../../../../redux/modules/curso_docente'

const ms2p = (state) => {
  return {
    ...state.curso_docente,
  };
};


const mdtp = { findImpartidos, 
			findHistorial, 
			filtroCoordinacionCarerraDocente,
			filtroPensumDocente,
			filtroCicloDocente,
			filtroCohorteDocente 
		}

export default connect(ms2p, mdtp)(CursoDocenteGrid)
