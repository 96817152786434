import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import { Layout } from '../layout';
import LoadMask from 'Utils/LoadMask';

function CarreraFormat(cell, row) {
  if(cell && cell.label){
    return cell.label;
  }

  return cell;
}

function CicloFormat(cell, row) {
  if(cell && cell.nombre){
    return cell.nombre;
  }
  return cell;
}

function BotonFormat(cell, row) {
  if(cell && row.accion){
    if(row.accion == 1){
       return ( 
        <Link to={ `/asignacion/crear/${ cell }/${row.ciclo.pk}` } className="btn btn-primary">
            Asignar Cursos
       </Link>
       )
   }
   else{
        return (
            <Link to={ `/asignacion/actualizar/${ cell }/${row.ciclo.pk}` } className="btn btn-primary">
                Editar Asignación
            </Link>
        )
   }
  }
}
function BotonVer(cell, row){
  if(row.inscripcion && row.pk){
     return (
        <Link to={ `/asignacion/ver/${row.pk}/${row.inscripcion}` } className="btn btn-primary">
             Ver Cursos
        </Link>
    )
  }
  else{
    return ''
  }
}


export default class InscripcionGrid extends React.Component {
    static propTypes = {
        data_inscripcion: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        findInscripcion: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.findInscripcion();
        this.props.findAsignacionPasada();

    }

    render() {
        const { loading, page, data_inscripcion, me, data_asignacion} = this.props;
        const { findInscripcion, findAsignacionPasada} = this.props;

        return (
            <div className="row d-flex justify-content-center">
                
                <Layout titulo={'ASIGNACIONES'} 
                        noDivisor={ false } 
                        scroll={ true }
                        carnet={me.username} 
                        nombre={me.first_name}>
                  <div className="scroll-table-container col-md-10 col-sm-12 mt-3 px-0">
                   <div className="d-flex justify-content-center">
                      { (data_inscripcion&& data_inscripcion.length>0) && 
                        <div>
                          <h6>
                            <span  className="mb-1 text-primary subtitulo font-weight-bold">ASIGNACIONES ACTUALES</span>
                          </h6>
                            <Table  onPageChange={findInscripcion} 
                                    data={ { results: data_inscripcion } } 
                                    loading={loading} 
                                    page={page} 
                                    noPagination={ true }
                                    noDataText={'No está inscrito'}>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        className="titulo-dias text-info"
                                        dataFormat={ CarreraFormat }
                                        dataField="carrera" dataSort>Carrera</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        className="titulo-dias text-info"
                                        dataField="cohorte" dataSort>Cohorte</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={ CicloFormat }
                                        className="titulo-dias text-info"
                                        dataField="ciclo" dataSort>Ciclo</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        className="titulo-dias text-info"
                                        dataField="pk" isKey dataAlign="center"
                                        dataFormat={BotonFormat}
                                    />
                            </Table>
                        </div>
                        }
                      
                      </div>
                      <div className=" mt-5 d-flex justify-content-center">
                        <div>
                          <h6>
                            <span  className="mb-1 text-primary subtitulo font-weight-bold">ASIGNACIONES ANTERIORES</span>
                          </h6>

                          <Table  onPageChange={findAsignacionPasada} 
                                  data={ { results: data_asignacion } } 
                                  loading={loading} 
                                  page={page} 
                                  noPagination={ true }
                                  noDataText={'No está inscrito'}>
                                  <TableHeaderColumn
                                      tdStyle={BreakLine}
                                      thStyle={BreakLine}
                                      className="titulo-dias text-info"
                                      dataFormat={ CarreraFormat }
                                      dataField="carrera" dataSort>Carrera</TableHeaderColumn>
                                  <TableHeaderColumn
                                      tdStyle={BreakLine}
                                      thStyle={BreakLine}
                                      className="titulo-dias text-info"
                                      dataField="cohorte" dataSort>cohorte</TableHeaderColumn>
                                   <TableHeaderColumn
                                      tdStyle={BreakLine}
                                      thStyle={BreakLine}
                                      className="titulo-dias text-info"
                                      dataField="ciclo" dataSort>Ciclo</TableHeaderColumn>
                                  <TableHeaderColumn
                                      tdStyle={BreakLine}
                                      thStyle={BreakLine}
                                      className="titulo-dias text-info"
                                      dataField="index" isKey dataAlign="center"
                                      dataFormat={BotonVer}
                                  />
                            </Table>
                        </div>

                      </div>
                    </div>
                </Layout>

            </div>
        )
    }
}

