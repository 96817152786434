import React, {Component, PropTypes} from 'react';
import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import _ from "lodash";
import { dateFormatter, RenderDateTime } from './renderReadField'

class MultiCheckboxField extends Component {
    constructor() {
        super();

        this.getCurrentValues = this.getCurrentValues.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getFecha = this.getFecha.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    getCurrentValues() {
        const {field} = this.props;
        const {value, initialValue} = field;

        let previousValues = [];

        if (!isUndefined(value) && value !== '') {
            previousValues = value;
        }
        else if (!isUndefined(initialValue) && initialValue !== '') {
            previousValues = initialValue;
        }

        const currentValues = isArray(previousValues) ? [...previousValues] : [previousValues];

        return currentValues;
    }


    handleChange(event, pk) {
        const {field} = this.props;
        const {onChange} = field;
        const values = this.getCurrentValues();

        if (event.target.checked) {
            if(values.indexOf(pk)==-1)
                values.push(pk);
        }
        else {
            values.splice(values.indexOf(pk), 1);
        }

        return onChange(values);
    }

    handleSelectAll(event){
        const {field} = this.props;
        const {onChange} = field;
        const values = this.getCurrentValues();
         if (event.target.checked) {
            this.props.options.forEach( (element, index)=> {
                console.log(element)
                if(values.indexOf(element.pk)==-1)
                    values.push(element.pk); 
            });
        }
        else {
            if(values.length)
                values.splice(0);
        }
        
        onChange(values);
    }

    getFecha(coordinacion, tipo){
        let valor = _.find(this.props.data, {coordinacion: coordinacion});
        if(valor){
            const fecha =  tipo?valor.inicio:valor.fin;
            console.log(fecha, tipo )
            return dateFormatter(fecha);
        }
        else
            return ''
    }

    render() {
        const {label, options, field, labelKey, data, tieneFechas} = this.props;
        const {onBlur} = field;
        const values = this.getCurrentValues();

        return (
            <tbody className="tabla-fechas">
                
                <tr>
                   <td className="py-1" colSpan={2}>
                        {label &&
                            <label>
                                <input
                                    {...field}
                                    type="checkbox"
                                    onChange={event => this.handleSelectAll(event)}
                                    onBlur={() => onBlur(values)}
                                    checked={ values.length  == options.length }
                                    value={ label }
                                    className="mr-3"
                                />
                                {label}
                            </label>
                        }
                    </td>
                    <td className="py-1"></td>
                    <td className="py-1"></td>
                    { tieneFechas &&
                        <td className="py-1"></td>
                    }

                </tr>

                    {options.map(option => {
                        const isChecked = values.indexOf(option.pk) > -1;

                        return (
                            <tr key={option.pk} >
                                <td className="checkbox-item pl-4 py-1" colSpan={2}>
                                    <label>
                                        <input
                                            {...field}
                                            type="checkbox"
                                            onChange={event => this.handleChange(event, option.pk)}
                                            onBlur={() => onBlur(values)}
                                            checked={isChecked}
                                            value={option.pk}
                                            className="mr-3"
                                        />

                                        {labelKey && option[labelKey] ?option[labelKey].label:option.label}
                                    </label>
                                </td>
                                
                                    <td className="check-box-detail  text-center py-1">
                                 
                                        { !tieneFechas &&
                                            <span>{this.getFecha(option.pk, true)} </span>
                                        }

                                        { tieneFechas &&
                                            <span>{ RenderDateTime({value: option.inicio, className:''}) } </span>
                                        }
                                        
                                    </td>
                                    <td className="check-box-detail  text-center py-1">
                                        
                                       { !tieneFechas &&
                                        <span>
                                            {this.getFecha(option.pk, false)}
                                        </span>
                                        }
                                         { tieneFechas &&
                                            <span>{ RenderDateTime({value: option.fin, className:''}) } </span>
                                        }
                                        
                                    </td>
                                      { tieneFechas &&
                                         <td className="check-box-detail  text-center py-1">
                                        
                                              <span>{ RenderDateTime({value: option.fecha_acta, className:''}) } </span>
                                        
                                        </td>
                                      }

                                
                            </tr>
                        );
                    })}
            </tbody>
        );
    }
}


export default MultiCheckboxField;



