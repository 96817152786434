import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import ToolbarUsuario from '../../Utils/Toolbar/Toolbar';
      


export default class CarrerasGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.find()
    }

    render() {
        const { data, loading, page, buscador_carrera } = this.props;

        const { destroy, find, search } = this.props;

        return (

            <div className="row  d-flex justify-content-center">
               <div className="col-sm-12 px-0 pt-2">
                    <div className="grid-container">
                       <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                            <ToolbarUsuario
                                buscar={search}
                                titulo={"carreras"}
                                boton = {"Agregar Carrera"}
                                buscador={buscador_carrera}/>

                        </div>
                        <br />
                        <Table onPageChange={find} data={data} loading={loading} page={page}>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="codigo" dataSort>Código</TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                dataField="nombre" dataSort>Carrera</TableHeaderColumn>
                             <TableHeaderColumn
                                thStyle={BreakLine}
                                dataField="nivel_display" dataSort>Nivel</TableHeaderColumn>
                             <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="alias">Alias de Área Común</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="pk" isKey dataAlign="center"
                                 dataFormat={activeFormatter({ editar: '/carreras/actualizar', eliminar: destroy })}
                            />
                        </Table>

                    </div>

                </div>

            </div>
        )
    }
}
