import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { Link } from 'react-router-dom';
import { icons } from "icons";
import {push} from 'react-router-redux';
import './Sidebar.css';
const NavHeader= (props)=>{

  return(
    <div className={`nav-header ${props.expanded? 'nav-header-mostar': 'nav-header-oculta'}`}>
      <a href="#/">
        <img className="img-responsive" src={require('../../../../../assets/img/logo_cusam.png')} alt="Logo" />
        <span className="cusam-logo">CUSAM</span>
      </a>
    </div>
  )

}

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { 
        verMenu: false,
        selectAlumnos: false,
        selectCatedraticos: false,
        selectExtension: false,
        selectAdmin: false,
        selectHome: false,
        expanded: false
      }
  }
  componentWillMount() {
    this.toggleMenu = this.toggleMenu.bind(this);
    this.logOut = this.logOut.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.setState({ verMenu: false });
    }
  }




  onSelect(selected){
    const to = '#'+selected
    if(this.props.url !== selected){
     window.location.assign(to);
    }
  }



  toggleMenu() {
    return (e) => {
      e.preventDefault();
      this.setState({ verMenu: !this.state.verMenu });
    };
  }
  logOut(event) {
    this.props.logOut();
  }

  render() {

    const {expanded} = this.props;
    const {
        ver_usuarios,
        ver_cursos,
        ver_docente,
        ver_carreras,
        ver_extensiones,
        ver_pensums,
        ver_ciclos,
        ver_horarios,
        url,
        ver_vistas_alumno ,
        ver_vistas_docente,
        me
      } = this.props;

    return (

<SideNav className="side-desk"
    onSelect={this.onSelect}
    onToggle={ (e)=>{
            this.setState({ expanded: e });
            expanded(e)
          }
       }
    >
    <SideNav.Toggle  />
    <NavHeader expanded={this.state.expanded} />

    <SideNav.Nav defaultSelected="home">
        <div style={{marginTop: '3em'}}></div>
        
        { /******************************************RUTAS DE REGISTRO ALUMNOS*****************************************/
          ver_vistas_alumno &&
            <NavItem className={`texto-general ${url.includes("/asignacion") ? "activo" : ""}`} eventKey="/asignacion/inscripcion">
              <NavIcon>
                <Link   to='/asignacion/inscripcion'>
                  <img   className="icon-menu img-inactivo" src={icons.asignaciones1}  alt="Asignaciones" />
                  <img   className="icon-menu img-activo" src={icons.asignaciones2}  alt="Asignaciones" />    
                </Link>
              </NavIcon>
              <NavText>
                  <Link className="texto-general" to='/asignacion/inscripcion'>
                   Asignaciones
                  </Link>
              </NavText>
            </NavItem>
        }

        { ver_vistas_alumno &&
          <NavItem className={`texto-general ${url.includes("/notas") ? "activo" : ""}`} eventKey="/notas">
            <NavIcon>
              <Link   to='/notas'>
                <img   className="icon-menu img-inactivo" src={icons.alumnos1}  alt="Notas" />
                <img   className="icon-menu img-activo" src={icons.alumnos2}  alt="Notas" />    
              </Link>
            </NavIcon>
            <NavText>
                <Link className="texto-general" to='/asignacion/inscripcion'>
                 Notas
                </Link>
            </NavText>
          </NavItem>
        }
        { /******************************************RUTAS DE CATEDRÁTICOS ********************************************/
          ver_vistas_docente &&
          <NavItem className={`texto-general ${url.includes("/curso_docente") ? "activo" : ""}`} eventKey="/curso_docente">
              <NavIcon>
                <Link   to='/curso_docente'>
                  <img   className="icon-menu img-inactivo" src={icons.cursoscatedraticos1}  alt="Cursos" />
                  <img   className="icon-menu img-activo" src={icons.cursoscatedraticos2}  alt="Cursos" />    
                </Link>
              </NavIcon>
              <NavText>
                  <Link className="texto-general" to='/asignacion/inscripcion'>
                   Cursos
                  </Link>
              </NavText>
          </NavItem>
        }

        { /******************************************RUTAS DE REGISTRO ACADÉMICO*****************************************/
          (me.is_superuser || me.rol == 0) &&
          <NavItem eventKey="admin" className={`expandible ${url.includes("/carreras") || url.includes("/pensums") || url.includes("/cursos") || url.includes("/ciclos") || url.includes("/extensiones") || url.includes("/bitacora") || url.includes("/configuraciones") || url.includes("/usuarios")  ? "nav-text-activo" : ""}`} subnavClassName ="submenu">
              <NavIcon>
                  <div  id="admin" className={` ${url.includes("/carreras") || url.includes("/pensums") || url.includes("/cursos") || url.includes("/ciclos") || url.includes("/extensiones") || url.includes("/usuarios")  || url.includes("/bitacora") || url.includes("/configuraciones") ? "activo" : ""}`}>
                        <img   className="icon-menu img-inactivo" src={icons.admin1}  alt="Admin" />
                        <img   className="icon-menu img-activo" src={icons.admin2}  alt="Admin" />
                  </div>
                   
              </NavIcon>
              <NavText>
                  <span className={`font-weight-bold pl-2 ${!this.state.expanded ? 'text-claro': 'texto-general'}`}>
                    Control Académico
                  </span>
              </NavText>
              { ver_carreras &&
                <NavItem eventKey="/carreras">
                    <NavText>
                        <Link  to='/carreras/'>
                          Carreras
                        </Link>
                    </NavText>
                </NavItem>
              }
              { ver_cursos &&
                <NavItem eventKey="/cursos">
                    <NavText>
                       <Link className="texto-general" to='/cursos/'>
                          Cursos
                        </Link>
                    </NavText>
                </NavItem>
              }
              { ver_pensums &&
                <NavItem eventKey="/pensums">
                    <NavText>
                       <Link className="texto-general" to='/pensums/'>
                          Pensums
                        </Link>
                    </NavText>
                </NavItem>
              }
              
              { ver_extensiones &&
                <NavItem eventKey="/extensiones">
                    <NavText>
                       <Link className="texto-general" to='/extensiones/'>
                         Extensiones
                        </Link>
                    </NavText>
                </NavItem>
              }
              { ver_ciclos &&
                <NavItem eventKey="/ciclos">
                  <NavText>
                     <Link className="texto-general" to='/ciclos/'>
                        Ciclos
                      </Link>
                  </NavText>
                </NavItem>
            }
            { ver_usuarios &&
                <NavItem eventKey="/usuarios">
                  <NavText>
                     <Link className="texto-general letra-pequenia" to='/usuarios/' style={{fontSize:'0.9em !impotant'}}>
                        Usuarios Control Académico
                      </Link>
                  </NavText>
                </NavItem>
            }


           
            { ver_ciclos &&
                <NavItem eventKey="/configuraciones">
                  <NavText>
                     <Link className="texto-general letra-pequenia" to='/configuraciones/'>
                        Configuraciones Universidad
                      </Link>
                  </NavText>
                </NavItem>
            }
             { ver_ciclos &&
                <NavItem eventKey="/bitacora">
                  <NavText>
                     <Link className="texto-general" to='/bitacora/' style={{fontSize:'0.9em !impotant'}}>
                        Bitácora
                      </Link>
                  </NavText>
                </NavItem>
            }


          </NavItem>
        }
        { ver_ciclos &&
          <NavItem id="Alumnos" className={`texto-general ${url.includes("/alumnos") ? "activo" : ""}`} eventKey="/alumnos/">
              <NavIcon>
                <Link   to='/alumnos/'>
                  <img   className="icon-menu img-inactivo" src={icons.alumnos1}  alt="Alumnos" />
                  <img   className="icon-menu img-activo" src={icons.alumnos2}  alt="Alumnos" />    
                </Link>
              </NavIcon>
              <NavText>
                  <Link className="texto-general" to='/alumnos/'>
                   Alumnos
                  </Link>
              </NavText>
          </NavItem>
       }
       { ver_docente &&
        <NavItem className={`texto-general nav-item ${url.includes("/docentes") ? "activo" : ""}`} eventKey="/docentes">
            <NavIcon>
              <Link   to='/docentes/'>
                <img   className="icon-menu img-inactivo" src={icons.catedraticos1}  alt="Catedráticos" />
                <img   className="icon-menu img-activo" src={icons.catedraticos2}  alt="Catedráticos" />    
              </Link>
            </NavIcon>
            <NavText>
                <Link className="texto-general" to='/docentes/'>
                 Catedráticos
                </Link>
            </NavText>
        </NavItem>
      }
      { ver_horarios &&
        <NavItem className={`texto-general ${url.includes("/actas") ? "activo" : ""}`} eventKey="/actas">
            <NavIcon>
              <Link   to='/actas/'>
                <img   className="icon-menu img-inactivo" src={icons.actas1}  alt="Actas" />
                <img   className="icon-menu img-activo" src={icons.actas2}  alt="Actas" />    
              </Link>
            </NavIcon>
            <NavText>
                <Link className="texto-general" to='/actas/'>
                 Actas
                </Link>
            </NavText>
        </NavItem>
      }
      { ver_horarios &&
        <NavItem className={`texto-general ${url.includes("/horarios") ? "activo" : ""}`} eventKey="/horarios">
            <NavIcon>
              <Link   to='/horarios/'>
                <img   className="icon-menu img-inactivo" src={icons.horarios1}  alt="Horarios" />
                <img   className="icon-menu img-activo" src={icons.horarios2}  alt="Horarios" />    
              </Link>
            </NavIcon>
            <NavText>
                <Link className="texto-general" to='/docentes/'>
                 Horarios
                </Link>
            </NavText>
        </NavItem>
      }
      { ver_horarios &&
        <NavItem className={`texto-general ${url.includes("/fecha_asignacion") ? "activo" : ""}`} eventKey="/fecha_asignacion">
            <NavIcon>
              <Link   to='/fecha_asignacion/'>
                <img   className="icon-menu img-inactivo" src={icons.asignaciones1}  alt="Asignaciones" />
                <img   className="icon-menu img-activo" src={icons.asignaciones2}  alt="Asignaciones" />    
              </Link>
            </NavIcon>
            <NavText>
                <Link className="texto-general" to='/fecha_asignacion/'>
                 Asignaciones
                </Link>
            </NavText>
        </NavItem>
      }
      { ver_horarios &&
        <NavItem className={`texto-general ${url.includes("/certificaciones") ? "activo" : ""}`} eventKey="/certificaciones">
            <NavIcon>
              <Link   to='/certificaciones/'>
                <img   className="icon-menu img-inactivo" src={icons.certificaciones1}  alt="Certificaciones" />
                <img   className="icon-menu img-activo" src={icons.certificaciones2}  alt="Certificaciones" />    
              </Link>
            </NavIcon>
            <NavText>
                <Link className="texto-general" to='/certificaciones/'>
                 Certificaciones
                </Link>
            </NavText>
        </NavItem>
      }
    
    </SideNav.Nav>
</SideNav>
    );
  }
}


export default Sidebar;
