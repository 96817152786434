import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm, FieldArray, formValueSelector, change } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { connect } from 'react-redux'
import { renderField, renderSelectField, renderSwitch, renderSearchSelect, renderNoAsyncSelectField} from 'Utils//renderField/renderField'
import { api } from "../../../../utility/api";
import _ from "lodash";
import { required, esBorrable } from '../../../../utility/validation';
import Titulo from "../../Utils/Titulo/TituloBigForm";

let carrera_seleccionada = 0

//FROM DE CLICLO
let renderCiclos = ({  borrables, handleCursoChange, handeleCursoDelete, prerequisitos_list, fields, cursos_list, meta: { error, submitFailed } }) =>{
    return (
        <div className="row col-12 pr-0 mr-0">
            <div className="col-12 pr-0 mr-0">
               <div className="array-table-pensum">
                {
                    fields.map((ciclo, index) => {
                        return (
                         <div key={ index }>
                            <div className="py-3 background-secondary mb-4 mt-5">
                            </div>
                            <br/>
                            <div className="row d-fex justify-content-center mb-2">
                                <div className="col-lg-10 col-sm-11 d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center array-form">
                                    <label htmlFor="ciclo" className="mr-lg-5">Nombre de ciclo</label>
                                    <Field
                                        name={`${ciclo}.nombre`}
                                        component={renderField}
                                        type="text"
                                        key={ index }
                                        validate={required}
                                        className="array-form"
                                        label="Ciclo"/>
                                </div>
                                <div className="col-lg-1 col-sm-1">
                                    <img   onClick={() =>{
                                                const ciclo = _.cloneDeep(fields.get(index));
                                                const detalles = ciclo.detalle;
                                                const leyenda = ciclo && ciclo.nombre? ciclo.nombre:''
                                                let borrable = true;
                                                if(ciclo && detalles &&detalles.length)
                                                {
                                                    for (let detalle of detalles){
                                                        if(!esBorrable(detalle, borrables, leyenda)){
                                                            borrable = false;
                                                            break;
                                                        }
                                                    }
                                                }
                                        if(borrable)
                                        {
                                            if(detalles)
                                            {
                                                let cursos = detalles.map(e=>{return e.curso})
                                                handeleCursoDelete(cursos);
                                            }
                                            fields.remove(index)
                                        }
                                    }
                                    } 
                                             className="action-img" 
                                             title="Eliminar" 
                                             src={require("../../../../../assets/img/icons/delete.png")} alt="Delete" />
                                </div>
                             </div> 
                            <table className="table table-sm titulo-dias table-hover">
                                <thead>
                                <tr className="text-primary font-weight-bold pl-3 mt-2 mr- py-2 titulo-dias">
                                    <th style={{width: "42%"}} >CURSO</th>
                                    <th style={{width: "50%"}} >PRE REQUISITO</th>
                                    <th style={{width: "8%"}} />
                                </tr>
                                </thead>
                            </table>

                            <div>
                                <FieldArray name={`${ciclo}.detalle`} 
                                            component={renderCursos} 
                                            borrables={borrables}
                                            handleCursoChange={ handleCursoChange } 
                                            prerequisitos_list={ prerequisitos_list || [] }
                                            cursos_list ={ cursos_list || [] }
                                            key_array={ index }/>
                            </div>
                        </div>
                        )
                        }
                    )
                    
                }

                </div>
                {submitFailed &&
                error &&
                <div className="invalid-feedback-array">
                        {error}
                    </div>}
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center mt-5">
                    <button type="button" className="btn btn-info ml-0 my-sm-auto flex-nowrap flex-sm-wrap mr-4" onClick={() => fields.push({})}>
                        Agregar Ciclo
                    </button>
                </div>

            </div>
        </div>
    )
} 

//FROM DE CURSOS Y PRE REQUISISTOS

class renderCursos extends Component {
    constructor(props) {
        super(props);
        this.state = {cursos: []};
    }


    render(){
        const { fields, meta, key_array, cursos_list, handleCursoChange, prerequisitos_list, borrables } = this.props;
        const { error, submitFailed } = meta;
        return (
            <div >
                <div>
                    {
                        fields.map((curso, index) => {
                            let curso_sel = fields.get(index)?fields.get(index).curso:0;
                            let cursos = cursos_list;
                            return (
                            
                            <div className="row array-item" key={ index }>
                                <div className="col-5">
                                    <Field name={`${curso}.curso`}
                                            valueKey="value"
                                            labelKey="label"
                                            key = { index }
                                            component={renderNoAsyncSelectField}
                                            label="Curso"
                                            onChange = {(e)=>{
                                                let seleccionado = e.value
                                                if(curso_sel != seleccionado){
                                                    this.props.handleCursoChange(parseInt(curso_sel?curso_sel.value:0), seleccionado);
                                                }
                                            }}
                                            
                                            validate={required}
                                            options={cursos}/> 
                                </div>
                                <div className="col-6">
                                     <Field
                                        name={`${curso}.prerequisitos`}
                                        valueKey="value"
                                        labelKey="label"
                                        key={index}
                                        component={renderNoAsyncSelectField}
                                        label="Curso"
                                        isMulti={ true }
                                        options={
                                            prerequisitos_list.filter(
                                                e=>{
                                                    return curso_sel && curso_sel.value?e.value != curso_sel.value:true
                                                })
                                        }/> 
                                </div>
                                <div className="text-center col-1">
                                    <img   onClick={() => {
                                            //AÑADIR NUEVAMENTE EL CURSOS SELECCIONADO
                                            const detalles =fields.get(index);
                                                const leyenda =detalles.curso?detalles.curso.label:''
                                                if(esBorrable(detalles, borrables, leyenda))
                                                {   if(detalles)
                                                    {
                                                        let curso = detalles.curso
                                                        this.props.handleCursoChange(parseInt(curso?curso.value:0), null);
                                                    }
                                                    fields.remove(index)
                                                }  
                                           }}
                                         className="action-img" 
                                         title="Eliminar" 
                                         src={require("../../../../../assets/img/icons/delete.png")} alt="Delete" />
                                </div>

                            </div>


                            )}
                        )
                    }
                    
                  
                </div>
                {submitFailed &&
                        error &&
                        <div className="invalid-feedback-array">
                                {error}
                            </div>}

               <div className="row mt-3">
                    <div className="col-12">
                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center mt-3">
                                            
                            <button type="button" className="btn btn-info ml-0  flex-nowrap flex-sm-wrap" onClick={() => fields.push({})}>
                                            Agregar Curso
                            </button>
                     </div>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}

//FORM PRINCINPAL DE PENSUMS
class PensumForm extends Component {
    constructor(props) {
        super(props);
        this.state = {cursos_list: [], prerequisitos_list: []};
    }
    componentWillMount() {
        this.getCursos = this.getCursos.bind(this);
        this.getCarreras = this.getCarreras.bind(this);
        this.getPrerequisitos = this.getPrerequisitos.bind(this);
        this.handleCursoChange = this.handleCursoChange.bind(this);
        this.handeleCursoDelete = this.handeleCursoDelete.bind(this);

    }
    componentDidMount() {
        if (!this.props.editar) {
            carrera_seleccionada = 0;
            //prerequisitos_list = [];
        }
        if (this.props.pensum) {
            this.getPrerequisitos(this.props.pensum);
           
        }
    }
    componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.data && this.props.data.carrera  !== prevProps.data.carrera) {
       carrera_seleccionada = this.props.data.carrera?this.props.data.carrera.value:0;
            if(carrera_seleccionada !=0){
            this.getCursos("", true)
        }
      }
    }

    //PARA POBLAR EL SELECT DE CARRERAS
    getCarreras(search=''){
        return api.get(`carreras/select`, {value:search})
        .then(data=>{
            if(data){
                return data;
            }
            else
                return []
        })
        .catch(e=>{
            return []
        })
    };
    handeleCursoDelete(anteriores){
        let temp_prerequisitos = _.cloneDeep(this.state.prerequisitos_list);
        let temp_cursos = _.cloneDeep(this.state.cursos_list);
        //REMOVER LOS PREREQUISITOS Y AÑADIR CURSOS
        anteriores.forEach( (value, index)=> {
            if(value){
             _.remove(temp_prerequisitos, (currentObject)=> {
                    return currentObject.value ===  value.value;
            });
            temp_cursos.push(value)
            }
        });
        temp_cursos = _.orderBy(temp_cursos, ['label'],['asc']);
        temp_prerequisitos = _.orderBy(temp_prerequisitos, ['label'],['asc']);
        this.setState({ prerequisitos_list: temp_prerequisitos})
        this.setState({ cursos_list: temp_cursos})
    }
    handleCursoChange(anterior, seleccionado){
        //REMOVER EL PREREQUISITO ANTERIOR
        let temp =  _.cloneDeep(this.state.prerequisitos_list);
        let temp_cursos = _.cloneDeep(this.state.cursos_list);
        //ELIMINAR DE LA LISTA DE PREREQUSITOS Y AÑADIR A CURSOS LA SELECCIÓN ANTERIOR
        if(anterior)
        {        
            let requisito_anterior = _.remove(temp, (currentObject)=> {
                return currentObject.value ===  anterior;
            });
            temp_cursos.push(requisito_anterior[0]);
        }
        if(seleccionado){
            //Añadir el nuevo prerequisito y eliminar de lista de cursos
            if(!_.find(temp, {value: seleccionado}))
            {
                let value = _.find(temp_cursos, {value: seleccionado})
                if(value){
                    
                    //AGREGAR EL VALOR A LOS PRE REQUISITOS
                    temp.push(value)
                    // REMOVER EL ITEM DEL ARRAY TEMPORAL DE CURSOS
                     _.remove(temp_cursos, (currentObject)=> {
                              return currentObject.value ===  value.value;
                    });
                   
                }
            }
        }
         //AGREGAR EL NUEVO ARRAY DE CURSOS A STATE
            temp_cursos = _.orderBy(temp_cursos, ['label'],['asc']);
            temp = _.orderBy(temp, ['label'],['asc']);
           this.setState({cursos_list: temp_cursos})
           this.setState({prerequisitos_list: temp})
    }

    //PARA POBLAR EL SELECT DE CURSOS
    getPrerequisitos(pensum){
        return api.get(`cursos/prerequisitos/${pensum}/`)
        .then(data=>{
            if(data){
               this.setState({
                          prerequisitos_list: data
                        })
            return data
            }
            else
                return  []
        })
        .catch(e=>{
            return []
        })
    };

//PARA POBLAR EL SELECT DE CURSOS
    getCursos(search, update=false){
        return api.get(`cursos/select/${carrera_seleccionada}/`, {value:search})
        .then(data=>{
            if(data){
                data.forEach((curso, i)=>{
                    if(!_.find(this.state.cursos_list, {value: curso.value}))
                        this.setState({
                          cursos_list: [...this.state.cursos_list, curso]
                        })
                    }
                );
                if(update){
                     this.props.data.pensum_curso.forEach((item, index)=>{
                        item.detalle.forEach((linea, index)=>{
                            this.handleCursoChange(null, linea.curso.value);
                        });
                    });
                }
            }
            else
                return  []
        })
        .catch(e=>{
            return []
        })
    }




    render() {
        const { handleSubmit, pensum, data, editar, borrables } = this.props
        
        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container">
                    <div className="grid-titulo padding-15">
                        

                        <div className="padding-15 p-sm-0 py-sm-1">
                            <Titulo  editar={editar} titulo='PENSUM'/>
                            <div className="row mt-3">
                                 
                                <div className="form-group col-sm-12 col-md-4 ">
                                    <label htmlFor="codigo">Código</label>
                                    <Field name="codigo" component={renderField} type="text" className="form-control" />
                                </div>
                                <div className="form-group col-sm-12 col-md-4">
                                    <label htmlFor="carrera">Carrera</label>
                                    <Field
                                        name='carrera'
                                        valueKey="value"
                                        labelKey="label"
                                        component={renderSearchSelect}
                                         onChange = {(e)=>{
                                                if(e.value !=carrera_seleccionada){
                                                    carrera_seleccionada = e.value;
                                                    this.setState({
                                                      cursos_list: []
                                                    });
                                                     this.setState({
                                                      prerequisitos_list: []
                                                    })
                                                    this.getCursos(" ")
                                                }
                                        }}
                                        label="Carrera"
                                        defaultOptions={ true }
                                        loadOptions={this.getCarreras}/>       
                               </div>
                                <div className=" form-group col-sm-12 col-md-4  d-flex align-items-end">
                                            <div className="col-12  d-flex flex-row">
                                                
                                                <div className="">
                                                    <Field 
                                                        name="vigente" 
                                                        component={renderSwitch} 
                                                        type="text" 
                                                        className="form-control p-0 no-border" />
                                                </div>
                                                <div className="pl-5">
                                                    <label htmlFor="vigente">PENSUM ACTIVO</label>
                                                </div>
                                            </div>
                                </div>
                               <FieldArray  name="pensum_curso"  
                                            component={renderCiclos}
                                            borrables={ borrables } 
                                            cursos_list={ this.state.cursos_list } 
                                            handleCursoChange={this.handleCursoChange}
                                            handeleCursoDelete={this.handeleCursoDelete}
                                            prerequisitos_list={ this.state.prerequisitos_list }/>
                                   
                            </div>
                         


                             <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center mt-3">
                                        <Link className="btn btn-secondary m-1" to="/pensums/">Cancelar</Link>
                                        <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                    </div>
                                </div>
                            </div>
               
                        </div>
                    </div>
                </div>
            </form>
      )
    }

}

PensumForm = reduxForm({
    form: 'pensum',
    validate: values => {

        const errors = {};
        if (!values.codigo) {
            errors.codigo = 'Campo Requerido';
        }
        if (!values.carrera) {
            errors.carrera = 'Campo Requerido';
        }
        const pensum_cursoArrayErrors = []
         if (!values.pensum_curso || !values.pensum_curso.length) {
            errors.pensum_curso = { _error: 'Debe agregar al menos un ciclo' }
          } 
        else 
        {
            //console.log(values)
            values.pensum_curso.forEach( function(element, index) {
                //console.log(element)
                const pensum_cursoErrors ={}
                if(!element || !element.nombre){
                    //console.log("No tengo nombre")
                    pensum_cursoErrors.nombre = 'Campo Requerido';
                }
                if (!element.detalle || !element.detalle.length) {
                    pensum_cursoErrors.detalle = { _error: 'Debe agregar al menos un curso' }
                } 
                else{
                        const detalleArrayErrors = []
                        element.detalle.forEach((item, itemIndex) => {
                            //console.log(item)
                          if (!item || !item.length) {
                            detalleArrayErrors[itemIndex] = 'Campo Requerido'
                          }
                          if (!item.curso) {
                            const detalleErrors = {}
                            detalleErrors.curso = 'Campo Requerido'
                            detalleArrayErrors[itemIndex] = detalleErrors
                          }
                        });
                         if (detalleArrayErrors.length) {
                          pensum_cursoErrors.detalle = detalleArrayErrors
                         
                        }
                 }
                 if(pensum_cursoErrors){
                     pensum_cursoArrayErrors[index] = pensum_cursoErrors
                 }
            });
             if (pensum_cursoArrayErrors.length) {
              errors.pensum_curso = pensum_cursoArrayErrors
            }
        }


        return errors;
    }
})(PensumForm)

export default PensumForm
