import { connect } from 'react-redux'
import Update from './UpdatePensum'
import { update, load2Update, seleccionarCarrera , findBorrablesPensum} from '../../../../redux/modules/pensum'



const mdtp = { update, load2Update, seleccionarCarrera, findBorrablesPensum };
const mstp = state => state.pensum


export default connect(mstp, mdtp)(Update)
