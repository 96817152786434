import { connect } from 'react-redux'
import HorarioGrid from './HorarioGrid'
import { 	find, 
			destroy, 
			search, 
			filtroCoordinacionCarerra, 
			filtroCoordinacionExtension, 
			filtroPensum,
			filtroCiclo,
			filtroCohorte 
	} from '../../../../redux/modules/horario'

const mstp = state => state.horario

const mdtp = { find, destroy, search, filtroCoordinacionCarerra, filtroCoordinacionExtension, filtroPensum, filtroCiclo, filtroCohorte }

export default connect(mstp, mdtp)(HorarioGrid)
