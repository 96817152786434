import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './toolbar.css';
import Select from 'react-select';
import Filter from './FilterFechaAsignacion';

class Toolbar extends Component  {
    render() {
       const { titulo, boton, ruta, filtroCiclo, filtroCohorte, fecha_ciclo, fecha_cohorte } = this.props;
        return(
            <div className="col-12 row pb-2 pt-5 pl-3">
                <div className="col-md-4 titulo">
                    <h6 className="text-secondary  text-uppercase  text-center text-md-left font-italic">FECHAS INICIO Y FIN </h6>
                    <h1 className="ml-0 text-uppercase text-center text-md-left"><strong>{this.props.titulo}</strong></h1>
                </div>
                
                <div className="col-lg-5 col-md-5 search mb-1 text-left">
                    <Filter filtroCiclo={ filtroCiclo }
                            fecha_ciclo={fecha_ciclo}
                            fecha_cohorte={fecha_cohorte}
                            filtroCohorte={ filtroCohorte }/>
                </div>

                <div className="col-md-3 col-lg-3  mb-sm-3 mb-md-0 text-center d-flex  flex-column ">
    
                    <div className="mt-auto">
                        <Link   className="btn btn-primary ml-0 ml-sm-auto flex-nowrap flex-sm-wrap" 
                            to={`/${ ruta?ruta:titulo }/crear/${fecha_ciclo.pk}/${fecha_cohorte.label}`}> { boton }
                        </Link>
                    </div>
                    <div className="invalid-feedback-array">
                        <span>¡Seleccione filtros para Editar!</span>
                    </div>

                </div>
            </div>

        )
    }
}

export default Toolbar;
