import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Titulo from '../Utils/Titulo/TituloGrid';


export  const Layout = (props)=> {
    const { titulo, noDivisor, carnet, nombre, carrera, cohorte, scroll, boton} = props;

    return (
        <div className="col-sm-12 px-0 pt-2">
                <div className="grid-container">
                    <Titulo titulo={ titulo  } noDivisor={ noDivisor }/>
                    <br />
                </div>
                
                <div className={`mb-5 mt-3 ${scroll? 'table-container': ''}`}>
                    <div className="row  d-flex justify-content-center mx-0">
                        <div className="col-md-10 col-sm-12">
                            <div className="row col-12">
                                <div className="col-lg-3  font-weight-bold">
                                   <span className="mr-3">Carnet:</span> 
                                   <span className="text-info subtitulo font-weight-bold">{carnet}</span>
                                </div>

                                <div className="col mb-1  font-weight-bold">
                                    <span className="mr-3">Alumno:</span>
                                    <span className="text-info subtitulo font-weight-bold">{nombre}</span>
                                </div>
                                { cohorte &&
                                    <div className="col-lg-3  font-weight-bold">
                                        <span className="mr-3">Cohorte:</span> 
                                        <span className="text-info subtitulo font-weight-bold">{cohorte}</span>
                                    </div>
                                }
                            </div>
                            <div className="row col-12 mt-3">
                               <div className="col">
                                    <h5>
                                        <strong className="mt-3 pl-0 text-info subtitulo font-weight-bold ">
                                            { carrera }
                                        </strong>
                                    </h5>
                                </div>
                                {
                                    boton &&
                                    <div className="col-md-3 col-sm-12 text-center">
                                        <button type="submit" className="btn btn-primary" onClick={props.funcBoton}>
                                            CERRAR ASIGNACIÓN
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="row  mx-0  d-flex justify-content-center">
                        {props.children} 
                    </div>
                </div>

        </div>
    )
}