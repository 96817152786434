import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField } from 'Utils//renderField/renderField'
import { nivelesOptions } from '../../../utility/constants';
import Titulo from "../../Utils/Titulo/Titulo";
const Form = props => {
    const { handleSubmit, editar } = props
    return (

        <div  className="col-12 px-0">
            <Titulo  editar={editar} titulo='CARRERA'/>
            <form onSubmit={handleSubmit} className="row d-flex justify-content-center mt-lg-5 ">
                <div className="form-group grid-container col-lg-9 col-sm-12">
                    <div className="grid-titulo padding-15">
                        <div className="padding-15 p-sm-0 py-sm-1">
                            <div className="row">
                                
                                    <div className="form-group col-sm-12 col-md-6">
                                        <label htmlFor="codigo">Código</label>
                                        <Field name="codigo" component={renderField} type="text" className="form-control" />
                                    </div>
                                    
                                    <div className="form-group col-sm-12 col-md-6">
                                        <label htmlFor="nombre">Nombre</label>
                                        <Field name="nombre" component={renderField} type="text" className="form-control" />
                                    </div>
                            </div>
                             <div className="row">

                                    <div className="form-group col-sm-12 col-md-6">
                                        <label htmlFor="email">Nivel Académico</label>
                                        <Field 
                                            name="nivel" 
                                            component={renderSelectField} 
                                            type="text" 
                                            className="form-control p-0 no-border" 
                                            labelKey="label" valueKey="value" options={nivelesOptions}/>
                                    </div>
                                  
                                    <div className="form-group col-sm-12 col-md-6">
                                        <label htmlFor="email">Alias de Área Común</label>
                                        <Field name="alias" component={renderField} type="text" className="form-control" />
                                    </div>
                            </div>
                                    
                       

                            <div className="row mt-5">
                                <div className="col-12 mt-lg-5 ">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Link className="btn btn-secondary m-1" to="/carreras/">Cancelar</Link>
                                        <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
  )
}



const CarreraForm = reduxForm({
    form: 'carrera',
    validate: data => {
        return validate(data, {
            'codigo': validators.exists()('Campo Requerido'),
            'nombre': validators.exists()('Campo Requerido'),
            'nivel': validators.exists()('Campo Requerido')
        })
    }
})(Form)

export default CarreraForm
