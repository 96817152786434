import {createActions, handleActions} from 'redux-actions';
import {goBack} from 'react-router-redux';
import {initialize as initializeForm} from 'redux-form';
import { api } from '../../utility/api';
import Swal from 'sweetalert2'; 
import _ from "lodash";


//ACTIONS
export const create = () => (dispatch, getState)=>{
	let formData =  _.cloneDeep(getState().form.extension.values);
	formData.coordinaciones = noramlizarObjeto(formData.coordinaciones)
	dispatch(loading());
	api.post('extensiones', formData)
		.then(response => {
			dispatch(goBack())
	
		})
		.catch(e => {
			let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
			if(e.detail)
				mensaje = e.detail;
			if(e.length )
				mensaje= e[0];

			Swal(
                'ERROR',
                mensaje,
                'error'
	        );
	        console.log('error', e)
		})
		.finally(() => {
			dispatch(loading(false));
		});
}

export const update = () => (dispatch, getState)=>{
	let  formData = _.cloneDeep(getState().form.extension.values);
	formData.coordinaciones = noramlizarObjeto(formData.coordinaciones)
	dispatch(loading());
	api.put(`extensiones/${formData.pk}`, formData)
		.then(response => {
			dispatch(goBack())
	
		})
		.catch(e => {
			let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
			if(e.detail)
				mensaje = e.detail;
			if(e.length )
				mensaje= e[0];
			Swal(
                'ERROR',
                mensaje,
                'error'
	        );
	        console.log('error', e)
		})
		.finally(() => {
			dispatch(loading(false));
		});
}

const noramlizarObjeto= (coordinacion)=>{
	coordinacion.forEach((item, index)=> {
		if(item.carrera.value)
			item.carrera = item.carrera.value;
		if(item.docente.value)
			item.docente = item.docente.value;
	});
	console.log(coordinacion)
	return coordinacion;

}

export const load2Update = id => async (dispatch, getState) =>{
	dispatch(loading())
	try {
		const extension = await api.get(`extensiones/${id}`);
		dispatch(setUpdateData(extension));
		dispatch(initializeForm('extension',extension));
	} catch(e) {
		// statements
		Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido obtener, por favor vuelva a intentar.',
                'error'
        );
		console.log(e);
	} finally {
		dispatch(loading(false));
	}
}//



export const destroy = id => async (dispatch, getState) =>{
	dispatch(loading())
	try {
		const remove = await api.erase(`extensiones/${id}`);
		dispatch(find());
	} catch(e) {
		// statements
		let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
     	if(e.detail)
        	mensaje = e.detail;

	    Swal(
            'ERROR',
            mensaje,
            'error'
	    );
		console.error(e);
	} finally {
		dispatch(loading(false));
	}
}//


export const search = (search) => (dispatch) => {
    let buscador = search.replace(" ", "");
    if (Number(buscador) !== Number(buscador)){
      dispatch(setBuscadorExtension(search));
    } 
    else {
      buscador = `${buscador.substring(0,4)} ${buscador.substring(4,9)} ${buscador.substring(9)}`;
      buscador = buscador.trim();
      dispatch(setBuscadorExtension(buscador));
    }
    dispatch(find(1));
}

export const find = (page=1) => async (dispatch, getState) => {
	dispatch(loading())
	const store = getState();
  	const search = store.extension.buscador_extension;
 	let params = {page, search};
	try {
		const response = await api.get('extensiones', params);
		dispatch(setData(response));
		dispatch(setPage(page));	
	} catch(e) {
		 Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
		console.log(e);
	} finally {
		dispatch(loading(false))
	}
}//


/*ENCUENTRA TODAS LAS INSTANCIAS DE DETALLE ACTAS QUE PUEDEN SER BORRADAS*/
export const findBorrablesCoordinacion = (extension) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  try {
    const response = await  api.get(`coordinaciones/borrables/${extension}/`)
    dispatch(setBorrablesCoordinacion(response))
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}


export const { loading, setData, setPage,setUpdateData, setBuscadorExtension, setBorrablesCoordinacion } = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA': (data) => ({ data }),
  'SET_PAGE': (page) => ({ page }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
  'SET_BUSCADOR_EXTENSION':(buscador_extension)=>({ buscador_extension}),
  'SET_BORRABLES_COORDINACION':(borrables_coordinacion)=>({ borrables_coordinacion}),
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setData]: (state, { payload: { data }}) => ({ ...state, data }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setBuscadorExtension]: (state, { payload: { buscador_extension }}) => ({ ...state, buscador_extension }),
  [setBorrablesCoordinacion]: (state, { payload: { borrables_coordinacion }}) => ({ ...state, borrables_coordinacion }),
  

}

export const initialState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  page: 1,
  buscador_extension: '',
  loading: false,
  updateData: {},
  borrables_coordinacion:[]
}

export default handleActions(reducers, initialState)

