import React from 'react';
import { UsuarioUpdateForm as Form } from '../Create/UsuarioForm';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Update extends React.Component {

  static propTypes = {
    updatePerfil: PropTypes.func.isRequired,
    load2Update: PropTypes.func.isRequired

  }

  componentWillMount(){
    this.props.load2Update()
  }

  render () {
    const { updatePerfil, loading } = this.props

    return (
      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
          <div className="col-12 px-0">
            <Form onSubmit={ updatePerfil } editar={ true } perfil={ true }/>
          </div>
        </div>
      </LoadMask>
    )
  }
}
