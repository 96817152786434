import React from 'react'
import { PrintContainer, actions as printActions } from "Utils/Print";
import EncabezadoDocs from "Utils/EncabezadoDocs/EncabezadoDocs";
import './ListadoAsistencia.css';

const Listado = ({ data_acta, data_inscritos, numeracion }) =>{

	return(
    <div className="">
      <EncabezadoDocs  titulo={ `LISTADO OFICIAL DE ESTUDIANTES ASIGNADOS` }
                        subtitulo={ `
                                      ${data_acta?data_acta.cohorte:''}-
                                      ${data_acta?data_acta.codigo_ciclo:''}-
                                      ${data_acta?data_acta.codigo_curso:''}-
                                      ${data_acta?data_acta.seccion:''}` 
                                  }/>
      <table className="encabezado ">
        <tbody>
            <tr style={{width: "100%"}}>
                <td style={{width: "22%"}}> EXTENSIÓN O PLAN: </td>
                <td className="text-left" style={{width: "45%"}}>{data_acta?data_acta.extension:''}</td>
                <td style={{width: "20%"}}>COHORTE: </td>
                <td className="text-center" style={{width: "20%"}}>{data_acta?data_acta.cohorte:''} </td>
            </tr>
            <tr style={{width: "100%"}}>
                <td style={{width: "22%"}}> CURSO: </td>
                <td className="text-left" style={{width: "45%"}}> {data_acta?data_acta.curso:''}</td>
                <td style={{width: "20%"}}> SECCIÓN:</td>
                <td className="text-center" style={{width: "20%"}}> {data_acta?data_acta.seccion:''}</td>                 
            </tr>
            <tr style={{width: "100%"}}>
                <td style={{width: "22%"}}> FECHA: </td>
                <td className="text-left pr-2" style={{width: "45%"}}></td>
                <td style={{width: "20%"}}> HORA:</td>
                <td className="text-center" style={{width: "20%"}}> </td>                 
            </tr>
        </tbody>
     </table>
       <div>
        <table className=""style={{width: "100%"}}>
          <thead style={{width: "100%"}}>
            <tr style={{width: "100%"}}>
              <th style={{width: "10%", border: "solid 0.5px black"}}>NO.</th>
              <th style={{width: "20%", border: "solid 0.5px black"}}>CARNET</th>
              <th style={{width: "40%", border: "solid 0.5px black"}}>NOMBRE DEL ESTUDIANTE</th>
              <th style={{width: "30%", border: "solid 0.5px black"}}>FIRMA</th>
            </tr>
          </thead>
          <tbody style={{width: "100%"}}>
            {
              data_inscritos.map((alumno, index)=>{
              	return(
                  <tr style={{width: "100% "}} key={index} >
                    <td style={{width: "10%", border: "solid 0.5px black" }}>{ index+numeracion }</td>
                    <td style={{width: "20%", border: "solid 0.5px black" }}>{ alumno.username }</td>
                    <td style={{width: "40%", border: "solid 0.5px black" }}>{ alumno.first_name}</td>
                    <td style={{width: "30%", border: "solid 0.5px black"}}> </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <br/>
        <div className="break-after"> 
            <span>NO. DE REGISTRO DE PERSONAL: {data_acta?data_acta.codigo_docente:''}</span>
            <br className="my-0"/> 
            <span>CATEDRÁTICO: {data_acta?data_acta.docente:''}</span>
            <br />
            <span> OBSERVACIONES:</span>
            <table  style={{width: "100% "}} className="break-after">
              <tbody style={{width: "100% "}}>
                <tr style={{width: "100%", height: "12pt", borderBottom: "0.5pt solid black"}}>
                  <td style={{width: "100% "}}> </td>
                </tr>
                <tr style={{width: "100%", height: "12pt", borderBottom: "0.5pt solid black"}}>
                  <td style={{width: "100% "}}> </td>
                </tr>
              </tbody>
            </table>
        </div>
       </div>
    </div>
	)
}



const getNumeroHojas= (array, n)=>{
  return Math.ceil(array.length/n);
}

const crearListado=( data_acta, data_inscritos)=>{
  const n = 50;
   const hojas = getNumeroHojas(data_inscritos, n);
   const impresiones = [];
    for (let i = 0; i<hojas ; i++) {
           impresiones.push(
              <Listado  
                  data_acta={data_acta}
                  key={ i }
                  numeracion={ (n*i)+1 }
                  data_inscritos={data_inscritos.slice((n*i),(i+1)*n)} />
            )  

      }

    return impresiones;   

}

const ListadoAsistencia = ({ data_acta, data_inscritos }) =>{
   
    return(
     <PrintContainer name='asistencia' className="print-only" >
         
      {
        crearListado(data_acta, data_inscritos)
      }
      </PrintContainer>
    )


}

export default ListadoAsistencia;