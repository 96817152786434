import React from 'react'
import Form from './CicloForm'
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Create extends React.Component {
  render () {
    const { create, loading } = this.props

    return (
    <LoadMask loading={loading} dark blur>
	      <div className="row d-flex justify-content-center">
	          <Form onSubmit={create} />
	      </div>
	</LoadMask>
    )
  }
}
