import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2'; 

// Actions
export const create = () => (dispatch, getState) => {
  const formData = getState().form.docente.values
  
  dispatch(loading())
  api.post('docentes/',formData)
    .then(response => {
      dispatch(goBack())
       
    })
    .catch(e => {

      let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}

export const update = () => async (dispatch, getState) => {
  const formData = getState().form.docente.values

  dispatch(loading())
  
  try {
    const docente = await api.put(`docentes/${formData.pk}`, formData)
    dispatch(goBack())
    
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, verifique los datos y vuelva a intentar.',
                'error'
      );
    console.log('error', e)
  } finally {
    dispatch(loading(false))
  }
}

export const destroy = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const remove = await api.erase(`docentes/${id}`)
    dispatch(find())
  } catch(e) {
      let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
  } finally {
    dispatch(loading(false))
  }
}

export const search = (search) => (dispatch) => {
    let buscador = search.replace(" ", "");
    if (Number(buscador) !== Number(buscador)){
      dispatch(setBuscadorDocente(search));
    } 
    else {
      buscador = `${buscador.substring(0,4)} ${buscador.substring(4,9)} ${buscador.substring(9)}`;
      buscador = buscador.trim();
      dispatch(setBuscadorDocente(buscador));
    }
    dispatch(find(1));
}

export const find = (page = 1) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const search = store.docente.buscador_docente;
  let params = {page, search};

  try {
    const response = await  api.get('docentes', params)
    dispatch(setData(response))
    dispatch(setPage(page))

  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

export const load2Update = id => async (dispatch, getState) => {
  dispatch(loading())
  
  
  try {
    let docente = await api.get(`docentes/${id}`);
    dispatch(setUpdateData(docente))
    dispatch(initializeForm('docente', docente))
  } catch(e) {
    console.log("Este error")
     Swal(
                'ERROR',
                'Ha ocurrido un error, verifique los datos y vuelva a intentar.',
                'error'
      );
  } finally {
    dispatch(loading(false))
  }
}

export const { loading, setData, setPage,setUpdateData, setBuscadorDocente } = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA': (data) => ({ data }),
  'SET_PAGE': (page) => ({ page }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
  'SET_BUSCADOR_DOCENTE':(buscador_docente)=>({ buscador_docente}),
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setData]: (state, { payload: { data }}) => ({ ...state, data }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setBuscadorDocente]: (state, { payload: { buscador_docente }}) => ({ ...state, buscador_docente })
}

export const initialState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  page: 1,
  buscador_docente: '',
  loading: false,
  updateData: {}
}


export default handleActions(reducers, initialState)


