import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2'; 
import _ from "lodash";
import moment from 'moment'; 

const url = 'actas/';


//CREAR UN ACTA NORMAL
export const create = () => (dispatch, getState) => {
  dispatch(loading())
  const formData = getState().form.acta.values;
  const store = getState().acta;
  let acta =  _.cloneDeep(formData);
  acta = noramlizarObjeto(acta, store);
  api.post(url,acta)
    .then(response => {
      dispatch(goBack())
      //dispatch(setCarreraSeleccionada(0))
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e && e.detail)
        mensaje = e.detail;
      if(e && e.length)
        mensaje = e[0]

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)

    })
    .finally(() => {
      dispatch(loading(false))
    })
}

//ACTUALIZAR ACTA NORMAL
export const update = () => (dispatch, getState) => {
  const formData = getState().form.acta.values
  let acta =  _.cloneDeep(formData);
  const store = getState().acta;
  acta = noramlizarObjeto(acta, store);
  dispatch(loading())
  api.put(`${url}${formData.pk}`,acta)
    .then(response => {
      dispatch(goBack())
       
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e && e.detail)
        mensaje = e.detail;
      if(e && e.length)
        mensaje = e[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}


//CREAR ACTA ESPECIAL
export const createEspecial = () => (dispatch, getState) => {
  dispatch(loading())
  const formData = getState().form.acta_especial.values;
  const store = getState().acta;
  let acta =  _.cloneDeep(formData);
  acta = noramlizarObjetoEspecial(acta, store);
  api.post('actas_especiales/',acta)
    .then(response => {
      dispatch(goBack())
      //dispatch(setCarreraSeleccionada(0))
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e && e.detail)
        mensaje = e.detail;
      if(e && e.length)
        mensaje = e[0]

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)

    })
    .finally(() => {
      dispatch(loading(false))
    })
}

//ACTUALIZAR ACTA ESPECIAL
export const updateEspecial = () => (dispatch, getState) => {
  const formData = getState().form.acta_especial.values
  let acta =  _.cloneDeep(formData);
  const store = getState().acta;
  acta = noramlizarObjetoEspecial(acta, store);
  dispatch(loading())
  api.put(`actas_especiales/${formData.pk}`,acta)
    .then(response => {
      dispatch(goBack())
       
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e && e.detail)
        mensaje = e.detail;
      if(e && e.length)
        mensaje = e[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}




//ACTUALIZAR FECHAS DE ACTAS
export const updateFechas = () => (dispatch, getState) => {
  const formData = getState().form.acta.values
  let acta =  _.cloneDeep(formData);
  acta = normalizarSetFechas(acta);
  dispatch(loading())
  api.put(`${url}/fechas`,acta)
    .then(response => {
      dispatch(goBack())
       
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e && e.detail)
        mensaje = e.detail;
      if(e && e.length)
        mensaje = e[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}

export const load2Update = (ciclo, coordinacion, pensum, cohorte) => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const acta = await api.get(`${url}/list_acta/${ciclo}/${coordinacion}/${pensum}/${cohorte}`)
    dispatch(setUpdateData(acta))
    dispatch(initializeForm('acta', acta))
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}


export const load2UpdateEspecial = (pk) => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const acta = await api.get(`/actas_especiales/${pk}/`)
    dispatch(setUpdateDataEspecial(acta))
    dispatch(initializeForm('acta_especial', acta))
  } catch(e) {
    console.log(e)
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

export const destroy = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const remove = await api.erase(`${url}${id}`)
    dispatch(findActa())
  } catch(e) {
     let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}
export const searchActa = (search) => (dispatch) => {
    let buscador = search.replace(" ", "");
    if (Number(buscador) !== Number(buscador)){
      dispatch(setBuscadorActa(search));
    } 
    else {
      buscador = `${buscador.substring(0,4)} ${buscador.substring(4,9)} ${buscador.substring(9)}`;
      buscador = buscador.trim();
      dispatch(setBuscadorActa(buscador));
    }
    dispatch(findActa(1));
}
export const findActa = (page = 1) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const search = store.acta.buscador_acta;
  const coordinacion = store.acta.coordinacion.pk;
  const coordinacion__extension = store.acta.coordinacion__extension.value;
  const pensum = store.acta.pensum.pk;
  const ciclo = store.acta.ciclo.pk;
  const cohorte = store.acta.cohorte.value;
  let params = {page, search, coordinacion, coordinacion__extension, pensum, ciclo, cohorte};
  try {
    const response = await  api.get(url, params)
    dispatch(setDataActa(response))
    dispatch(setPage(page))

  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

export const findEspecial = (page = 1) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const search = store.acta.buscador_acta;
  const coordinacion = store.acta.coordinacion.pk;
  const coordinacion__extension = store.acta.coordinacion__extension.value;
  const pensum = store.acta.pensum.pk;
  const ciclo = store.acta.ciclo.pk;
  const cohorte = store.acta.cohorte.value;
  let params = {page, search, coordinacion, coordinacion__extension, pensum, ciclo, cohorte};
  try {
    const response = await  api.get('actas_especiales/', params)
    dispatch(setDataEspecial(response))
    dispatch(setPage(page))

  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}


export const findNotasActa = (id) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  try {
    const response = await  api.get(`notas/acta/${id}/`)
    dispatch(setNotas(response))
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

/*ENCUENTRA TODAS LAS INSTANCIAS DE DETALLE ACTAS QUE PUEDEN SER BORRADAS*/
export const findBorrables = (ciclo, coordinacion, pensum, cohorte) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  try {
    const response = await  api.get(`${url}detalles_borrables//${ciclo}/${coordinacion}/${pensum}/${cohorte}/`)
    dispatch(setBorrables(response))
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

export const getPieActa = (id) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  try {
    const response = await  api.get(`notas/pie/${id}/`)
    dispatch(setPieActa(response))
  } catch(e) {
    console.error(e)
    let mensaje= 'Ha ocurrido un error, por favor vuelva a intentar.'
    if(e.mensaje)
      mensaje = e.mensaje
     Swal(
                'Error',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}
export const filtroCoordinacionCarerraActa = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCoordinacionActa(filtro));
    dispatch(findActa());
    dispatch(findEspecial());
}
export const filtroCoordinacionExtensionActa = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCoordinacionExtensionActa(filtro));
    dispatch(findActa());
    dispatch(findEspecial());
}

export const filtroPensumActa = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroPensumActa(filtro));
    dispatch(findActa());
    dispatch(findEspecial());
}
export const filtroCicloActa = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCicloActa(filtro));
    dispatch(findActa());
    dispatch(findEspecial());
}
export const filtroCohorteActa = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCohorteActa(filtro));
    dispatch(findActa());
    dispatch(findEspecial());
}

export const todosFiltros = (coordinacion, extension, pensum, ciclo, cohorte) => async (dispatch, getStore) =>{
  const store = getStore();
  dispatch(loading())
  try {
    const valor = await api.get(`actas/filtros/${coordinacion}/${extension}/${pensum}/${ciclo}`);
    dispatch(setFiltroCoordinacionActa(valor.coordinacion));
    dispatch(setFiltroCoordinacionExtensionActa(valor.extension));
    dispatch(setFiltroPensumActa(valor.pensum));
    dispatch(setFiltroCicloActa(valor.ciclo));
    dispatch(setFiltroCohorteActa({value:cohorte, label: cohorte}));
    dispatch(findActa());
    dispatch(findEspecial());
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  } 
}


const noramlizarObjeto= (data, store)=>{ 
  const actas = []
  data.detalleCiclo.forEach((detalle, index)=> {
    //Parte de detalleCiclo
    let ciclo_pensum = detalle.ciclo_pensum.nombre;
    detalle.detalleSeccion.forEach((seccion, index)=> {
      //RECORRIENDO CADA SECCIÒN DE UN CICLO
      let nombre_seccion = seccion.seccion;
      seccion.detalleActas.forEach( (linea, index)=> {
            //Obtiene un arreglo con todos los "DETALLEActa "
            let tempLine =linea;
            tempLine.curso = tempLine.curso.pk;
            tempLine.docente = tempLine.docente.value;
            if(tempLine.fin)
              tempLine.fin= moment(getFechaForm(tempLine.fin, false));
            if(tempLine.inicio)
              tempLine.inicio= getFechaForm(tempLine.inicio);
            return tempLine
        });

        //creando el acta
        let acta = {
        coordinacion: store.coordinacion.pk, 
        pensum: store.pensum.pk,
        ciclo: store.ciclo.pk,
        cohorte: store.cohorte.value,
        pk: seccion.pk,
        ciclo_pensum: ciclo_pensum,
        seccion:  nombre_seccion,
        detalleActas: seccion.detalleActas
      };
      actas.push(acta)
    });

  });

  console.log(actas)
  return actas;
}

//PARA ACTAS ESPECIALES
const noramlizarObjetoEspecial= (data, store)=>{ 
   //AÑADIENDO LOS CAMPOS DE LOS FILTROS
   data.coordinacion = store.coordinacion.pk;
   data.pensum = store.pensum.pk;
   data.ciclo = store.ciclo.pk;
   data.cohorte = store.cohorte.value;
   data.extension = store.coordinacion__extension.value;
   data.ciclo_pensum = data.ciclo_pensum.nombre;
   //En detalle Actas
   data.detalleActas.curso = data.detalleActas.curso.pk;
   data.detalleActas.docente = data.detalleActas.docente.value;
   if( data.detalleActas.inicio )
     data.detalleActas.inicio =  getFechaForm(data.detalleActas.inicio);
   if( data.detalleActas.fin )
     data.detalleActas.fin = moment(getFechaForm(data.detalleActas.fin, false));
   if( data.detalleActas.fecha_acta )
    data.detalleActas.fecha_acta = getFechaForm(data.detalleActas.fecha_acta);
   //ALUMNO 
   data.alumnos.forEach((element, index)=> data.alumnos[index] = element.pk);
  console.log(data)
  return data;
}
const normalizarSetFechas=(value)=>{
  let valores = []
  value.secciones.forEach( (element, index)=> {
      if(element.seccion){
         element.seccion.forEach((detalle, index)=> {
            let fecha = {
              fin: moment(getFechaForm(value.fin, false)), 
              inicio:  getFechaForm(value.inicio), 
              fecha_acta:  getFechaForm(value.fecha_acta), 
              pk: detalle
            };
            valores.push(fecha);
         });
      }
  });
  return valores;
}


const getFechaForm=(fecha, inicio=true)=>{
  let date = new Date(fecha);
  if(inicio)
    return  new Date(date.getFullYear(), date.getMonth(), date.getDate(),0,0,0);
  else
    return  new Date(date.getFullYear(), date.getMonth(), date.getDate(),23,59,59);
}
export const { 
                loading, 
                setDataActa, 
                setDataEspecial, 
                setPage,
                setUpdateData, 
                setBuscadorActa, 
                setCarreraSeleccionada,
                setNotas,
                setPieActa,
                setFiltroCoordinacionActa,
                setFiltroCoordinacionExtensionActa,
                setFiltroPensumActa,
                setFiltroCicloActa,
                setFiltroCohorteActa,
                setUpdateDataEspecial,
                setBorrables
} = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA_ACTA': (data_acta) => ({ data_acta }),
  'SET_DATA_ESPECIAL': (data_especial) => ({ data_especial }),
  'SET_PAGE': (page) => ({ page }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
  'SET_UPDATE_DATA_ESPECIAL': (updateDataEspecial) => ({ updateDataEspecial }),
  'SET_BUSCADOR_ACTA':(buscador_acta)=>({ buscador_acta}),
  'SET_NOTAS':(notas)=>({ notas}),
  'SET_PIE_ACTA':(pie_acta)=>({ pie_acta}),
  'SET_FILTRO_COORDINACION_EXTENSION_ACTA':(coordinacion__extension)=>({ coordinacion__extension}),
  'SET_FILTRO_COORDINACION_ACTA':(coordinacion)=>({ coordinacion}),
  'SET_FILTRO_PENSUM_ACTA':(pensum)=>({ pensum }),
  'SET_FILTRO_CICLO_ACTA':(ciclo)=>({ ciclo }),
  'SET_FILTRO_COHORTE_ACTA':(cohorte)=>({ cohorte }),
  'SET_BORRABLES':(borrables)=>({ borrables }),


})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setDataActa]: (state, { payload: { data_acta }}) => ({ ...state, data_acta }),
  [setDataEspecial]: (state, { payload: { data_especial }}) => ({ ...state, data_especial }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setUpdateDataEspecial]: (state, { payload: { updateDataEspecial }}) => ({ ...state, updateDataEspecial }),
  [setBuscadorActa]: (state, { payload: { buscador_acta }}) => ({ ...state, buscador_acta }),
  [setNotas]: (state, { payload: { notas }}) => ({ ...state, notas }),
  [setPieActa]: (state, { payload: { pie_acta }}) => ({ ...state, pie_acta }),
  [setFiltroCoordinacionActa]: (state, { payload: { coordinacion}}) => ({ ...state, coordinacion }),
  [setFiltroCoordinacionExtensionActa]: (state, { payload: { coordinacion__extension }}) => ({ ...state, coordinacion__extension }),
  [setFiltroPensumActa]: (state, { payload: { pensum }}) => ({ ...state, pensum }),
  [setFiltroCicloActa]: (state, { payload: { ciclo }}) => ({ ...state, ciclo }),
  [setFiltroCohorteActa]: (state, { payload: { cohorte }}) => ({ ...state, cohorte }),
  [setBorrables]: (state, { payload: { borrables }}) => ({ ...state, borrables }),

}

export const initialState = {
  data_acta:[],
  data_especial:[],
  page: 1,
  buscador_acta: '',
  loading: false,
  updateData: {},
  updateDataEspecial: {},
  coordinacion__extension: {value: '', label:''},
  coordinacion: {pk: '', label:''},
  pensum: {pk: '', codigo:''},
  ciclo: {pk: '', nombre:''},
  cohorte: {value: '', label:''},
  notas:[],
  borrables:[],
  pie_acta: {
              conteo: { aprobado: 0, reprobado:0, ausente:0}, 
              docente: '', 
              co_carrera: '',
               academico:'' }

}


export default handleActions(reducers, initialState)



