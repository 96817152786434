import React from 'react';
import { AsyncCreatable, Async } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from "react-switch";
import Select, { components } from 'react-select';
import _ from "lodash";
import MultiCheckboxField from './MultiCheckboxField';
import MultiCheckboxCursosField from './MultiCheckboxCursosField';
import TimePicker from 'react-time-picker';


export const renderField = ({ input, label, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input {...input} placeholder={label} type={type}
                disabled={disabled?'disabled':''}
                className={classNames('form-control', { 'is-invalid': invalid })} />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};


export const renderFieldChek = ({ input, label, value, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input {...input} placeholder={label} type={type}
                disabled={disabled?'disabled':''}
                className={classNames('form-check-input', { 'is-invalid': invalid })} />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};


export const renderTextArea = ({ input, label, rows, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea {...input} placeholder={label} style={{ resize: "none" }} rows={rows ? rows : 3}
                className={classNames('form-control', { 'is-invalid': invalid })} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};
export const renderSearchSelect = ({ input, clearable, disabled, loadOptions, valueKey, labelKey, defaultOptions, meta: { touched, error } }) => {
    const invalid = touched && error;
    
    return (
        <div>
            <AsyncSelect clearable={clearable} disabled={disabled} value={input.value} className={classNames('form-control p-0 select-reset', { 'is-invalid': invalid })}
                   classNamePrefix="react-select"
                   onChange={(e) => { 
                    input.onChange(e ? e : null); 
                    }}
                     
                     theme={(theme) => {
                        return {
                      ...theme,
                      colors: {
                      ...theme.colors,
                        'neutral0': "#FFFFFF",
                        text: '#415362',
                        primary25: '#1687a7bd',
                        primary: '#1687a7',
                      },
                    }
                    }}
                    classNamePrefix="react-select"
                    cache={false} placeholder="Escriba para buscar"
                    defaultOptions={ true }
                    searchPromptText="Escriba para buscar" 
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}
                    loadOptions={loadOptions} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderSearchCreateSelect = ({ input, disabled, loadOptions, valueKey, labelKey, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <AsyncCreatable disabled={disabled} value={input.value} className={classNames('form-control', { 'is-invalid': invalid })}
                onChange={(e) => { input.onChange(e[valueKey]); }}
                defaultOptions
                searchPromptText="Escriba para buscar" valueKey={valueKey} labelKey={labelKey}
                loadOptions={loadOptions} promptTextCreator={(label) => { return `Crear opción ${label}` }} />
            {invalid && <div className="invalid-feedback">
                {error}
                {input.value}
            </div>}
        </div>
    )
};

export const renderSelectField = ({ input, disabled, options,prerequisitos_list, key=0, valueKey='value', labelKey='label', meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <select {...input} 
                disabled={disabled} 
                key={ key }
                className={classNames('form-control', { 'is-invalid': invalid })}>
                <option  value="" className="select-text-default" style={{color: '#918080'}}>
                    Seleccione

                </option>);
                {options.map((opcion, index) => {
                    return (<option
                        key={typeof (opcion) === "string"+ key? opcion : String(opcion[valueKey])+String(key)}
                        value={typeof (opcion) === "string" ? opcion : opcion[valueKey]}>
                        {typeof (opcion) === "string" ? opcion : opcion[labelKey]}

                    </option>);
                })}
            </select>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderNumber = ({ input, label, type, decimalScale, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale ? decimalScale : 0} fixedDecimalScale={true}
                value={input.value} thousandSeparator={true} prefix={''}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};
export const renderNoAsyncSelectField = ({ input, prerequisitos_list,  clearable, disabled, options, valueKey,isMulti, labelKey, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <Select clearable={clearable} disabled={disabled} value={input.value} className={classNames('form-control p-0 select-reset', { 'is-invalid': invalid })}
                   isMulti={ isMulti }
                   onChange={(e) => { 
                        input.onChange(e ? e : null); 
                    }}
                     theme={(theme) => {
                        return {
                      ...theme,
                      colors: {
                      ...theme.colors,
                        'neutral0': "#FFFFFF",
                        text: '#415362',
                        primary25: '#1687a7bd',
                        primary: '#1687a7',
                      },
                    }
                    }}
                    classNamePrefix="react-select"
                   cache={false} placeholder="Escriba para buscar"
                   key={valueKey}
                   defaultOptions
                   options={ options }
                   defaultOptions
                   searchPromptText="Escriba para buscar" 
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}/>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
}; 
export const renderCurrency = ({ input, label, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
          <div className={classNames({ 'is-invalid': invalid })}>
            <NumberFormat className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={2} fixedDecimalScale={true}
                value={input.value} thousandSeparator={true} prefix={'Q '}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderSwitch = ({ input, label, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <Switch
                onColor="#dd0a35"
                offColor="#014955"
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
                id="normal-switch"
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};
""
export const renderFileUpload = ({className, placeholder, input, label, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                className={classNames('form-control', { 'is-invalid': invalid })}
                placeholder={placeholder}
                type="file"
                accept=".csv" 
                onChange={(e, file) => {
                file = file || e.target.files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                    input.onChange(reader.result);
                };
                reader.readAsDataURL(file);
                console.log("En el input")
            }}/>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};



export const renderTimeField = ({ required, name, className, input, label, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <TimePicker 
                maxDetail="minute" 
                locale="en-US" 
                disableClock={ true }  
                clearIcon={ null } 
                name={ name }
                value={ input.value }
                onChange={(value) => {
                    input.onChange(value);
                }}/>
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};

export  const MultiCheckbox = ({ data, options, tieneFechas=false, labelKey,input, label, value, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <table className="table table-hover table-bordered">
                
            <MultiCheckboxField
                label={label}
                options={options}
                labelKey={ labelKey }
                data={ data }
                tieneFechas={ tieneFechas }
                field={input}/>
        </table>
    )
};

export  const MultiCheckboxCursos = ({ data, options, tieneFechas=false, labelKey,input, label, value, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <table className="table table-hover table-bordered mb-0">
            <thead className="titulo-dias">
                <tr>
                  <th scope="col" colSpan={2} className="py-1">CURSOS</th>
                </tr>
              </thead>
            <MultiCheckboxCursosField
                label={label}
                options={options}
                labelKey={ labelKey }
                data={ data }
                field={input}/>
        </table>
    )
};
 


export const RenderField = {
    renderField,
    renderFieldChek,
    renderTextArea,
    renderSearchSelect,
    renderSearchCreateSelect,
    renderSelectField,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderNoAsyncSelectField
};


/*temp1.filter(e=>{return e.value != 3})*/