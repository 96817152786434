import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField } from 'Utils//renderField/renderField'
import { email, minLength, match, phone } from '../../../../utility/validation';
import Titulo from "../../Utils/Titulo/Titulo";
import renderDatePicker from 'Utils/renderField/renderDatePicker'
       
          




const minLength6 = minLength(6)
class Form extends React.Component{

   
    constructor(props) {
      super(props);

      this.state = {
          cambiarPwd: false
      };

      this.onClick = this.onClick.bind(this)
  }

  
  onClick(e) {
    console.log(this.state.cambiarPwd)
      this.setState({ cambiarPwd: !this.state.cambiarPwd })
  }

  render(){
     const { handleSubmit, editar, perfil } = this.props
     const matchPwd = match('password')
      return (
         <div className="h-100">
            <Titulo  editar={editar || perfil} titulo={`${perfil?'PERFIL':'USUARIO CONTROL ACADÉMICO'}`}/>
            <form onSubmit={handleSubmit} className=" row d-flex justify-content-center mt-lg-5">
                <div className="form-group grid-container col-lg-9 col-sm-12">
                    <div className="grid-titulo padding-15">

                        <div className="padding-15 p-sm-0 py-sm-1">
                            <div className="row">
                                
                                <div className="form-group col-sm-12 col-md-6">
                                    <label htmlFor="first_name">Nombre</label>
                                    <Field name="first_name" component={renderField} type="text" className="form-control" />
                                </div>
                                <div className="form-group col-sm-12 col-md-6">
                                    <label htmlFor="last_name">Apellido</label>
                                    <Field name="last_name" component={renderField} type="text" className="form-control" />
                                </div>
                                   
                            </div>
                            <div className="row">

                             
                                <div className="form-group col-sm-12 col-md-6">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <Field  name="telefono" 
                                            component={renderField}
                                            validate={phone}
                                            type="text" 
                                            className="form-control" />
                                </div>
                                <div className="form-group col-sm-12 col-md-6">
                                    <label htmlFor="email">Correo</label>
                                    <Field  name="email" 
                                            component={renderField} 
                                            type="email" 
                                            className="form-control" 
                                            validate={email}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-12 col-md-6">
                                    <label htmlFor="username">Usuario</label>
                                    <Field name="username" component={renderField} type="text" className="form-control" />
                                </div>
                                
                                <div className="form-group col-sm-12 col-md-6">
                                    <label htmlFor="direccion">Dirección</label>
                                    <Field  name="direccion" 
                                            component={renderField} 
                                            type="direccion" 
                                            className="form-control"/>
                                </div>
                            </div>
                            {(perfil && this.state.cambiarPwd) &&  
                                <div className="row">
                                    <div className="form-group col-sm-12 col-md-6">
                                        <label htmlFor="password">Contraseña</label>
                                        <Field  name="password" 
                                                component={renderField}
                                                type="password" 
                                                disabled ={!this.state.cambiarPwd}
                                                className="form-control" />
                                    </div>
                                    <div className="form-group col-sm-12 col-md-6">
                                         <label htmlFor="password">Confirmar</label>
                                        <Field  name="confirmar" 
                                                component={renderField}
                                                disabled ={!this.state.cambiarPwd}
                                                type="password" 
                                                placeholder="Confirm password" 
                                                className="form-control"/>
                                    </div>
                                </div>
                            }
                           { (!perfil && (!editar || this.state.cambiarPwd )) && <div className="row">
                                                       <div className="form-group col-sm-12 col-md-6">
                                                           <label htmlFor="password">Contraseña</label>
                                                           <Field  name="password" 
                                                                   component={renderField} 
                                                                   type="text" 
                                                                   validate={minLength6}
                                                                   className="form-control" />
                                                       </div>
                                                   </div>
                            }
                            { editar && 
                                <div className="row">
                                     
                                    <div className="form-group col-sm-12 col-md-6 d-flex">
                                        
                                         <button type="button" 
                                                className="btn btn-info m-1 my-auto"
                                                onClick={this.onClick}>
                                            Cambiar Contraseña
                                        </button>
                                    </div>
                                </div>
                            }
                           
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Link className="btn btn-secondary m-1" to="/usuarios/">Cancelar</Link>
                                        <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
  }



}

export const UsuarioUpdateForm = reduxForm({
    form: 'usuario',
    validate: values => {

        const errors = {}
        if (!values.first_name) {
            errors.firstName = 'Campo Requerido'
        } 
        if (!values.last_name){
            errors.lastName = 'Campo Requerido'
        }
        if (!values.telefono){
            errors.telefono = 'Campo Requerido'
        }
        if (!values.direccion){
            errors.direccion = 'Campo Requerido'
        }
        if(!values.email) {
            errors.email = 'Campo Requerido'
        } 
        if(values.password && values.password.length<6){
            errors.password = 'Demasiado Corto (el mínimo es 6 caracteres)';
        }

        if (values.confirmar && values.confirmar != values.password) {
            errors.confirmar = 'Debe ser igual a la contraseña'
        }
        return errors    
}})(Form)




const UsuarioForm = reduxForm({
    form: 'usuario',
    validate: data => {

        return validate(data, {
            'first_name': validators.exists()('Campo Requerido'),
            'last_name': validators.exists()('Campo Requerido'),
            'username': validators.exists()('Campo Requerido'),
            'email': validators.exists()('Campo Requerido'),
            'password': validators.exists()('Campo Requerido'),
            'telefono': validators.exists()('Campo Requerido'),
            'direccion': validators.exists()('Campo Requerido'),
        })
    }
})(Form)

export default UsuarioForm
