import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField, renderSwitch, renderSearchSelect} from 'Utils//renderField/renderField'
import { nivelesOptions } from '../../../utility/constants';
import { api } from "../../../../utility/api";
import Titulo from "../../Utils/Titulo/Titulo";


//PARA POBLAR EL SELECT DE CARRERAS
const getCarreras = (search='') => {


    return api.get(`carreras/select`, {value: search})
    .then(data=>{
        if(data){
            return  data;
        }
        else
            return  []
    })
    .catch(e=>{
        return  []
    })
};

const Form = props => {
    const { handleSubmit, editar } = props
    return (

        <div className="h-100">
            <Titulo editar={ editar } titulo=' CURSOS'/>
          
            <form onSubmit={handleSubmit} className="row d-flex justify-content-center mt-lg-5 ">
                <div className="form-group grid-container col-lg-9 col-sm-12">
                    <div className="grid-titulo padding-15">
                  
                        
                        <div className="padding-15 p-sm-0 py-sm-1">
                            <div className="row">
                                    <div className="form-group col-lg-6 col-sm-12 ">
                                        <label htmlFor="codigo">Código</label>
                                        <Field name="codigo" component={renderField} type="text" className="form-control" />
                                    </div>
                                    <div className="form-group col-lg-6 col-sm-12 ">
                                        <label htmlFor="nombre">Curso</label>
                                        <Field name="nombre" component={renderField} type="text" className="form-control" />
                                    </div>
                                    
                                   
                            </div>

                            <div className="row">
                                <div className="form-group col-lg-6 col-sm-12">
                                    <label htmlFor="carrera">Carrera</label>
                                    <Field
                                        name='carrera'
                                        valueKey="value"
                                        labelKey="label"
                                        component={renderSearchSelect}
                                        label="Titulo"
                                        loadOptions={getCarreras}/>
                                            
                               </div>
                               <div className="form-group col-lg-6 col-sm-12 d-flex align-items-end">
                                        <div className="col-12  d-flex flex-row">
                                            <div className="pr-5">
                                                <label htmlFor="email">Área Común</label>
                                            </div>
                                            <div className="">
                                                <Field 
                                                    name="area_comun" 
                                                    component={renderSwitch} 
                                                    type="text" 
                                                    className="form-control p-0 no-border" />
                                            </div>
                                        </div>
                                 </div>
                           </div>


                            <div className="row mt-5">
                                <div className="col-12 mt-lg-5 ">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Link className="btn btn-secondary m-1" to="/cursos/">Cancelar</Link>
                                        <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
  )
}



const CursoForm = reduxForm({
    form: 'curso',
    validate: data => {

        return validate(data, {
            'codigo': validators.exists()('Campo Requerido'),
            'nombre': validators.exists()('Campo Requerido'),
            'carrera': validators.exists()('Campo Requerido')
        })
    }
})(Form)

export default CursoForm
