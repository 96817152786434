import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm, FieldArray, formValueSelector, change, FormSection} from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { connect } from 'react-redux'
import { renderField, renderSelectField, renderSwitch, renderSearchSelect, renderNoAsyncSelectField, renderTimeField } from 'Utils//renderField/renderField'
import { api } from "../../../../utility/api";
import _ from "lodash";
import { required, esBorrable} from '../../../../utility/validation';
import renderDatePicker from 'Utils//renderField/renderDatePicker'

//PARA POBLAR EL SELECT DE DOCENTES
const getDocentes = (search='') => {


    return api.get(`docentes/select`, {value: search})
    .then(data=>{
        if(data){
            return data;
        }
        else
            return [];
    })
    .catch(e=>{
        return [];
    })
};

 const  renderDetalle= ({ fields, titulo, cursos_list, meta: { error, submitFailed }, key_array, borrables }) => (
    <div className={`dont-break ${!fields || !fields.length || error?'dont-print':''}`}>
            <div>
                <table className="table table-sm table-hover mb-0 ">
                    <tbody >
                        {
                            fields.map((detalle, index) => (
                                <tr key={ index }>
                                    <td style={{width: "26%"}}>
                                        <Field
                                            name={`${detalle}.curso`}
                                            valueKey="pk"
                                            labelKey="label"
                                            component={renderNoAsyncSelectField}
                                            label="Curso"
                                            defaultOptions={ true }
                                            options={ cursos_list } /> 
                                    </td>
                                    <td style={{width: "21%"}}>
                                        <Field
                                            name={`${detalle}.docente`}
                                            valueKey="value"
                                            labelKey="label"
                                            component={renderSearchSelect}
                                            label="Catedrático"
                                            placeholder="Catedrático"
                                            defaultOptions={ true }
                                            loadOptions={ getDocentes }/>
                                    </td>
                                    <td style={{width: "16%"}}>
                                        <Field
                                                    name={`${detalle}.inicio`}
                                                    className=" p-0"
                                                    component={renderDatePicker}
                                                    placeholder="10-19-2018"
                                                    numberOfMonths={1}/>
                                    </td>
                                    <td style={{width: "16%"}}>
                                         <Field
                                                    name={`${detalle}.fin`}
                                                    className=" p-0"
                                                    component={renderDatePicker}
                                                    placeholder="10-19-2018"
                                                    numberOfMonths={1}/>

                                    </td>
                                    <td style={{width: "16%"}}>
                                         <Field
                                                    name={`${detalle}.fecha_acta`}
                                                    className=" p-0"
                                                    component={renderDatePicker}
                                                    placeholder="10-19-2018"
                                                    numberOfMonths={1}/>

                                    </td>
                                    <td className="text-center" style={{width: "5%", minWidth: "25px"}}>
                                        <img   onClick={() => {
                                                    const detalle =fields.get(index);
                                                    const leyenda =detalle.curso?detalle.curso.label:''
                                                    if(esBorrable(detalle, borrables, leyenda))
                                                        fields.remove(index)

                                                    
                                                }}
                                             className="action-img dont-print" 
                                             title="Eliminar" 
                                             src={require("../../../../../assets/img/icons/delete.png")} alt="Delete" />
                                    </td>

                                </tr>
                                )
                            )
                        }
                      
                    </tbody>
                </table>
            </div>      
         <div>
            {submitFailed &&
                    error &&
                    <div className="invalid-feedback-array">
                            {error}
                        </div>}
        </div>

          <div className="row mx-0 button-form-array ">
                <div className="col-12">
                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center my-2">
                                        
                        <button type="button" className="btn btn-info ml-0  flex-nowrap flex-sm-wrap mr-md-5" onClick={() => fields.push({})}>
                                        Agregar Curso
                        </button>
                 </div>
                </div>
            </div>
    </div>
       
   
)


export default renderDetalle;
