import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Side from './Sidebar/Sidebar'
import { slide as Slide } from 'react-burger-menu';
import Movil from './movil'


class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {verMenu: false};
  }

  componentWillMount() {
    this.toggleMenu = this.toggleMenu.bind(this);
  }

    toggleMenu() {
    return (e) => {
      e.preventDefault();
      this.setState({ verMenu: !this.state.verMenu });
    };
  }
  render(){
    const {
      ver_usuarios,
      ver_cursos,
      ver_docente,
      ver_carreras,
      ver_extensiones,
      ver_pensums,
      ver_ciclos,
      ver_horarios,
      url,
      ver_vistas_alumno ,
      ver_vistas_docente,
      me
    } = this.props;
    const { OnExpanded} = this.props;
    return (

<div>
        <Side expanded={ OnExpanded } {...this.props}/>
        <Slide className="div-only-mobile" isOpen={this.state.verMenu}>
           <Movil {...this.props} className="sidebar" toggleMenu={this.toggleMenu}/>
        </Slide>
        </div>
      
    );
  }
}

export default Menu;

