import { connect } from 'react-redux'
import ActasGrid from './ActasGrid'
import { 	findActa, 
			destroy, 
			searchActa,
			filtroCoordinacionExtensionActa, 
			filtroCoordinacionCarerraActa,
			filtroPensumActa,
			filtroCicloActa,
			filtroCohorteActa, 
			findEspecial

		} from '../../../../redux/modules/acta'



const mstp = (state) => {
  return {
    ...state.acta,
  };
}

const mdtp = { 	findActa, 
				destroy, 
				searchActa, 
				filtroCoordinacionExtensionActa, 
				filtroCoordinacionCarerraActa,
				filtroPensumActa,
				filtroCicloActa,
				filtroCohorteActa,
				findEspecial
			}

export default connect(mstp, mdtp)(ActasGrid)
