import React from 'react'
import Form from './PensumForm'
 import LoadMask from "Utils/LoadMask/LoadMask";
export default class Create extends React.Component {
  render () {
    const { create, loading, borrables_pensum  } = this.props

    return (

      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 col-sm-12">
            <Form onSubmit={create} borrables={borrables_pensum}/>
          </div>
        </div>
      </LoadMask>
    )
  }
}
