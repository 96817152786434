import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import Toolbar from '../../Utils/Toolbar/ToolbarAlumno';
import moment from 'moment'

function SexoFormat(cell, row) {
  let htlm = `<span >Femenino</span>`
  if(cell){
    htlm = `<span>Masculino</span>`
  }

  return htlm;
}

function dateFormatter(cell) {
    if (!cell) {
          return "";
    }
    try
    {

        let date = new Date(cell)
        return date.toLocaleDateString()
    }
    catch(e){
        return cell;
    }
    
}

export default class AlumnosGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.find()
    }

    render() {
        const { data, loading, page, buscador_alumno, uploading } = this.props;

        const { destroy, find, search, upload} = this.props;

        return (

            <div className="row  d-flex justify-content-center">
               <div className="col-sm-12 px-0 pt-2">
                    <div className="grid-container">
                       <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                            <Toolbar
                                buscar={search}
                                titulo={"alumnos"}
                                boton = {"Agregar Alumnos"}
                                upload={ upload }
                                buscador={buscador_alumno}/>
                        </div>
                        <br />
                        <Table onPageChange={find} data={data} loading={loading} page={page} uploading={uploading}>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="username" dataSort>Carnet</TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                dataField="first_name" dataSort>Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                dataField="CUI" dataSort>CUI</TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                dataFormat={ SexoFormat }
                                dataField="sexo" dataSort>Sexo</TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                dataFormat={ dateFormatter }
                                dataField="nacimiento" dataSort>Nacimiento</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="pk" isKey dataAlign="center"
                                 dataFormat={activeFormatter({ editar: '/alumnos/actualizar', eliminar: destroy })}
                            />
                        </Table>

                    </div>

                </div>

            </div>
        )
    }
}
