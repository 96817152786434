import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import { Layout } from '../layout';
import LoadMask from 'Utils/LoadMask';
import { PrintContainer, actions as printActions } from "Utils/Print";
import EncabezadoDocs from "Utils/EncabezadoDocs/EncabezadoDocs";


function indexN(cell, row, enumObject, index) {
    return (<div>{index+1}</div>) 
}

export default class InscripcionGrid extends React.Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        findCursosAsignados: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.findCursosAsignados(this.props.match.params.encabezado);
        this.props.getInscripcion(this.props.match.params.inscripcion)
    }

    render() {
        const { loading, page, data_cursos_asignados, data_inscripcion, me, data_asignacion, inscripcion} = this.props;
        const { findInscripcion, findCursosAsignados } = this.props;

        return (
            <div className="row  d-flex justify-content-center">
                
                <Layout titulo={'CURSOS ASIGNADOS'} 
                        noDivisor={ false } 
                        carnet={me.username} 
                        nombre={me.first_name}
                        carrera= {inscripcion && inscripcion.carrera?inscripcion.carrera.label:''}>
                      <div className="col-md-10 col-sm-12 mt-5">
                          <div  className="mb-3 d-flex justify-content-between">
                            <span>
                              <strong className="mx-md-5 mb-3 text-primary subtitulo font-weight-bold">
                              {data_cursos_asignados && data_cursos_asignados.length?data_cursos_asignados[0].ciclo:''}
                              </strong>
                            </span>
                             <button  className="btn btn-primary font-italic" 
                                      onClick={(e) => {
                                              e.preventDefault();
                                              printActions.print('constancia')
                                      }}
                                      >
                                IMPRIMIR 
                                <i className="pl-3 fa fa-print fa-lg" aria-hidden="true"></i>
                              </button>
                          </div>
                            <PrintContainer name='constancia'>
                              <div className="margenes-impresion">
                                <EncabezadoDocs  titulo=" CONSTANCIA DE ASIGNACIÓN DE CURSOS"
                                                 subtitulo={ data_cursos_asignados && data_cursos_asignados.length?data_cursos_asignados[0].ciclo:'' }/>
                                  <div className="print-only"> 
                                          <span>NO. CARNET: { me.username }</span>
                                          <br className="my-0"/> 
                                          <span>NOMBRE DEL ESTUDIANTE: { me.first_name }</span>
                                          <br className="my-0"/> 
                                          <span>CARRERA: {inscripcion && inscripcion.carrera?inscripcion.carrera.label:''}</span>
                                          <br className="my-0"/> 
                                          <span>CÓDIGO PÉNSUM: {inscripcion && inscripcion.pensum?inscripcion.pensum:''}</span>
                                          <br/>

                                  </div>
                                <Table  onPageChange={findCursosAsignados} 
                                  data={ { results: data_cursos_asignados } } 
                                  loading={loading} 
                                  page={page} 
                                  noPagination={ true }>
                                <TableHeaderColumn
                                              tdStyle={BreakLine}
                                              thStyle={BreakLine}
                                              className="titulo-dias text-info td-impresion col-size"
                                              columnClassName=" td-impresion col-size"
                                              dataField="pk" isKey dataAlign="center"
                                              dataFormat={indexN}>NO.</TableHeaderColumn>
   
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    className="titulo-dias text-info td-impresion col-size"
                                    columnClassName=" td-impresion col-size"
                                    dataAlign="center"
                                    dataField="codigo" dataSort>Código</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    className="titulo-dias text-info col-large td-impresion "
                                    columnClassName="col-large td-impresion"
                                    dataField="acta" dataSort>Curso</TableHeaderColumn>
                                 <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    className="titulo-dias text-info dont-print"
                                    columnClassName="dont-print"
                                    dataField="docente" dataSort>Catedrático</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    className="titulo-dias text-info dont-print"
                                    columnClassName="dont-print"
                                    dataField="ciclo" dataSort>Ciclo</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    className="titulo-dias text-info td-impresion col-size"
                                    columnClassName=" td-impresion col-size"
                                    dataAlign="center"
                                    dataField="seccion" dataSort>Sección</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    className="titulo-dias text-info dont-print"
                                    columnClassName="dont-print"
                                    dataAlign="center"
                                    dataField="extension" dataSort>EXTENSIÓN</TableHeaderColumn>
                                
                                </Table>
                                <br/>
                                <div className="mt-5 print-only">
                                  <span>
                                    FIRMA DEL ESTUDIANTE: _________________________________________________________________________________________________.
                                  </span>
                                  <br className="my-5"/>
                                  <span>
                                    OBSERVACIONES: FIRMO CONSIDERANDO ESTO COMO UNA DECLARACIÓN JURADA
                                     DE LO QUE ME ASIGNÉ EN EL SISTEMA.
                                  </span>
                                </div>
                              </div>
                              
                            </PrintContainer>
                      </div>
                </Layout>

            </div>
        )
    }
}

