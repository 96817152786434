import React from 'react'
import Form from './UsuarioForm'
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Create extends React.Component {
  render () {

    const { create, loading } = this.props

    return (
      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
          <div className="col-12 px-0">
            <Form onSubmit={create} editar={false} perfil={ false } />
          </div>
        </div>
      </LoadMask>
    )
  }
}

