import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import Search from '../../Utils/Toolbar/SearchCertificaciones';
import Titulo from '../../Utils/Titulo/TituloBotones';
import {RenderDateTime} from '../../Utils/renderField/renderTableField';
import Modal from 'react-responsive-modal';
import EquivalenciasForm from '../Equivalencias/EquivalenciasForm'

import { PrintContainer, actions as printActions } from "Utils/Print";
import CertificacionContraloria from "Utils/Impresiones/CertificacionContraloria";




function indexN(cell, row, enumObject, index) {
    return (<div>{index+1}</div>) 
}

function Color(cell){
    return {color: '#1687a7'}
}

function NotaFormat(cell, row) {
  //return cell.nombre
  if(cell)
    return parseInt(cell)
  else
    return 'EQV'
}


function FormatDate(cell, row) {
  return  RenderDateTime({fecha: cell})
}


export default class CertificacionesVer extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    componentWillMount() {
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    componentDidMount(){
        this.props.getAlumnoById(this.props.match.params.alumno)
        this.props.getPensumById(this.props.match.params.pensum)
        this.props.getDataCertificacion(this.props.match.params.alumno, this.props.match.params.pensum)
        this.props.getCoordinador();
        this.props.getFirmas();

    }

    //Para mostrar el modal
    openModal(){
         this.props.setToggleModal(true);
    }
    closeModal(){
         this.props.setToggleModal(false);
    }

    render() {
        const { data, 
                loading, 
                page, 
                getAlumno,  
                buscador_certificacion, 
                dataAlumno, 
                dataPensum, 
                getDataCertificacion,
                coordinador_academico,
                configuracion } = this.props;

        return (

            <div className="row  d-flex justify-content-center">
               <div className="col-sm-12 px-0 pt-2">
                    {/*********************MODAL********************/}
                    {   this.props.toggleModal && (
                        <Modal open={this.props.toggleModal} onClose={this.closeModal} >
                            <div  style={{ Width: '100%' }}>
                                <div className="modal-header">
                                    <div className="panel-body">
                                      
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <EquivalenciasForm closeModal={this.closeModal} 
                                                       pensum={this.props.match.params.pensum}
                                                       onSubmit={ this.props.create }/>
                                </div>
                            </div>
                        </Modal>
                    )}



                    <div className="grid-container">
                                <Titulo titulo={'CERTIFICACIONES'}
                                        boton1={'Agregar Equivalencia'}
                                        funcBoton1={this.openModal}
                                        color={'btn-primary'}
                                        boton2={'Imprimir Certificación'}
                                        funcBoton2={(e) => {
                                              e.preventDefault();
                                              printActions.print('CERTIFICACIONES')
                                        }}
                                        noDivisor={true}/>
                                 <div className="row px-5 mx-0 d-flex justify-content-center my-5">
                                    <div className="col-md-6 col-sm-12">
                                       <h5>
                                            <strong className="mt-3 pl-0 text-info subtitulo font-weight-bold ">
                                                { dataPensum.display_carrera }
                                            </strong>
                                        </h5>
                                    </div>
                                    <div className="col-lg-3  font-weight-bold">
                                       <span className="mr-3">Carnet:</span> 
                                       <span className="text-info subtitulo font-weight-bold">{ dataAlumno.username }</span>
                                    </div>
                                     <div className="col-lg-3  font-weight-bold">
                                       <span className="mr-3">Alumno:</span> 
                                       <span className="text-info subtitulo font-weight-bold">{ dataAlumno.first_name }</span>
                                    </div>

                                </div>

                        <div className="row px-5 mx-0 d-flex justify-content-center my-5">
                            <div className="col-md-11 col-sm-12">
                                <div className="row mx-0 col-12">
                                    <Table  onPageChange={getDataCertificacion} 
                                           data={ { results: data } } 
                                           loading={loading} 
                                           page={page} 
                                           noPagination={ true }>
                                        <TableHeaderColumn
                                          tdStyle={BreakLine}
                                          thStyle={BreakLine}
                                          className="titulo-dias text-info td-impresion"
                                          columnClassName=" td-impresion col-size"
                                          dataField="pk" isKey dataAlign="center"
                                          dataFormat={indexN}>NO.</TableHeaderColumn>

                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            className="titulo-dias text-info td-impresion col-size"
                                            columnClassName=" td-impresion col-size pr-2"
                                            dataField="codigo" dataSort>Código</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            className="titulo-dias text-info col-large td-impresion "
                                            columnClassName="col-large td-impresion"
                                            dataField="curso" dataSort>Curso</TableHeaderColumn>
                                         <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            className="titulo-dias text-info text-uppercase"
                                            columnClassName="text-uppercase"
                                            tdStyle={Color}
                                            dataFormat={NotaFormat}
                                            dataField="nota" dataSort>Nota</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            columnClassName="text-uppercase"
                                            className="titulo-dias text-info"
                                            dataFormat={FormatDate}
                                            dataField="fecha_acta" dataSort>Fecha</TableHeaderColumn>
                                    </Table>
                                    <CertificacionContraloria alumno={dataAlumno}
                                                              datos={data}
                                                              coordinador_academico={coordinador_academico}
                                                              configuracion={configuracion}
                                                              carrera={dataPensum.display_carrera}/>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
        )
    }
}
