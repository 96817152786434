import React from 'react'
import Form from './RestablecerForm'
import { Layout } from '../Login/layout';

export default class Create extends React.Component {
  
  componentWillMount(){
    this.props.getTokenCorreo( this.props.match.params.token)
  }

  render () {

    const { restablecer } = this.props

    return (
        <Layout >
          <Form onSubmit={restablecer} />
        </Layout>
  
    )
  }
}

