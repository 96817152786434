import React from 'react'
import { PrintContainer, actions as printActions } from "Utils/Print";
import EncabezadoDocs from "Utils/EncabezadoDocs/EncabezadoDocs";
import moment from 'moment'; 
import { NOTA } from '../../../utility/constants'; 
import { RenderDateTime } from "Utils/renderField/renderReadField";
import './ListadoAsistencia.css';


const EncabezadoHoja = ({ data_encabezado, totalPaginas, pagina}) =>{
  return(
    <div className="encabezado-hoja">
      <EncabezadoDocs  titulo={ `FORMULARIO COMPROBANTE DE INGRESO DE ACTA` }
                        subtitulo={ `
                                      ${data_encabezado?data_encabezado.cohorte:''}-
                                      ${data_encabezado?data_encabezado.codigo_ciclo:''}-
                                      ${data_encabezado?data_encabezado.codigo_curso:''}-
                                      ${data_encabezado?data_encabezado.seccion:''}` 
                                  }/>
      <table className="encabezado text-uppercase">
        <tbody>
            <tr style={{width: "100%"}}>
                <td style={{width: "17%"}}> EXTENSIÓN </td>
                <td className="text-left" style={{width: "50%"}}>{data_encabezado?data_encabezado.extension:''}</td>
                <td style={{width: "20%"}}>COHORTE </td>
                <td className="text-center" style={{width: "20%"}}>{data_encabezado?data_encabezado.cohorte:''} </td>
            </tr>
            <tr style={{width: "100%"}}>
                <td style={{width: "17%"}}> CURSO </td>
                <td className="text-left" style={{width: "50%"}}> {data_encabezado?data_encabezado.curso:''}</td>
                <td style={{width: "20%"}}> SECCIÓN</td>
                <td className="text-center" style={{width: "20%"}}> {data_encabezado?data_encabezado.seccion:''}</td>                 
            </tr>
             <tr style={{width: "100%"}}>
                <td style={{width: "17%"}}> EXAMEN </td>
                <td className="text-left" style={{width: "50%"}}> 
                  {data_encabezado && data_encabezado.nombre_especial?data_encabezado.nombre_especial:'EXAMEN FINAL'}
                </td>
                <td style={{width: "20%"}}> ZONA MÍNIMA</td>
                <td className="text-center" style={{width: "20%"}}>31 </td>                 
            </tr>
            <tr style={{width: "100%"}}>
                <td style={{width: "17%"}}> SEMESTRE</td>
                <td className="text-left pr-2" style={{width: "50%"}}>{data_encabezado?data_encabezado.ciclo:''}</td>
                <td style={{width: "20%"}}> FECHA</td>
                <td className="text-center" style={{width: "20%"}}> 
                   { 
                        data_encabezado.fecha_acta?RenderDateTime({value:data_encabezado.fecha_acta}):'' 
                    }
                </td>                 
            </tr>
        </tbody>
     </table>
     <div  className="text-right pie-pagina" 
            style={{ right:"0cm"}}>
            PÁGINA: {`${ pagina } DE ${ totalPaginas}` } 
      </div>
      <div  className="text-left pie-pagina" 
          style={{left:"0cm"}}>
        FECHA DE IMPRESIÓN: { moment().format('DD/MM/YY') } 
      </div>
    </div>
  )
}

const Hoja = ({ notas, data_encabezado,  numeracion, pagina, totalPaginas, ultimaHoja}) =>{

	return(
    <div className="">
      <EncabezadoHoja data_encabezado={data_encabezado} 
                      totalPaginas={ ultimaHoja?totalPaginas+1: totalPaginas}
                      pagina={pagina} />
     
      <div style={{marginTop: '5mm', width: "100%"}}>

        <table className="tabla-print-only"style={{width: "100%"}} 
               className={` ${pagina != totalPaginas?'break-after':''}`}>
          <thead style={{width: "100%"}}>
            <tr style={{width: "100%"}}>
              <th style={{width: "15%", border: "solid 0.5px black"}}>CARNET</th>
              <th style={{width: "45%", border: "solid 0.5px black"}}>NOMBRE DEL ESTUDIANTE</th>
              <th style={{width: "10%", border: "solid 0.5px black"}}>NOTA</th>
              <th style={{width: "15%", border: "solid 0.5px black"}}>PERFIL</th>
            </tr>
          </thead>
          <tbody style={{width: "100%"}}>
            {
              notas.map((nota, index)=>{
                return (
                  <tr style={{width: "100% "}} key={index} >
                    <td style={{width: "15%", border: "solid 0.5px black" }}>{ nota.carnet }</td>
                    <td style={{width: "45%", border: "solid 0.5px black" }}>{ nota.alumno}</td>
                    <td className="text-right"
                        style={{width: "10%", border: "solid 0.5px black" }}>
                        { parseInt(nota.nota)}
                    </td>
                    <td style={{width: "15%", border: "solid 0.5px black"}}>{ NOTA[nota.estado_nota] } </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
	)
}



const getNumeroHojas= (array, n)=>{
  return Math.ceil(array.length/n);
}



/*
notas, pie_acta, data_encabezado,  numeracion, pagina, totalPaginas
*/
const crearListado=( notas, data_encabezado, n, hojas, ultimaHoja)=>{
   
   const impresiones = [];
   
    for (let i = 0; i<hojas ; i++) {
           impresiones.push(
              <Hoja  
                  key={ i }
                  numeracion={ (n*i)+1 }
                  totalPaginas={ hojas }
                  pagina={i+1}
                  ultimaHoja={ ultimaHoja }
                  data_encabezado={ data_encabezado }
                  notas={notas.slice((n*i),(i+1)*n)} />
            )  

      }

    return impresiones;   

}

const ActaComprobante = ({ notas, data_encabezado}) =>{
    const n = 50;
    const hojas = getNumeroHojas(notas, n);
    const resto = notas.length%n;

    return(
     <PrintContainer name='COMPROBANTE'>
      <div className="margen-1 print-only">
        {
          crearListado(notas, data_encabezado, n, hojas, (resto>42 || resto == 0))
        }
       
        <div className=""> 
            <div  style={{marginTop: '5mm'}}   
                  className={`${ (resto == 0)|| (resto>45 && resto<=49)?'break-after':''}`}>
              <span>NO. DE REGISTRO DE PERSONAL: {data_encabezado?data_encabezado.codigo_docente:''}</span>
              <br className="my-0"/> 
              <span>CATEDRÁTICO: {data_encabezado?data_encabezado.docente:''}</span>
            </div>
            { ((resto == 0)|| (resto>45 && resto<=49)) && 
              <div>
                <EncabezadoHoja data_encabezado={data_encabezado}
                                totalPaginas={hojas+1}
                                pagina={hojas+1} />
                
              </div>
            }
            <div  style={{marginTop: '10mm', marginBottom: '10mm'}}
                  className={`${ (resto>42 && resto<=45)?'break-after':''}`}>
              FIRMA DEL CATEDRÁTICO: 
            </div>
            { ((resto>42 && resto<=45)) && 
              <div>
                <EncabezadoHoja data_encabezado={data_encabezado}
                                totalPaginas={hojas+1}
                                pagina={hojas+1} />
                  
              </div>
            }
            <div>
              <br /> <br />
              <span> OBSERVACIONES:</span>
              <div>LAS ACTAS FINALES SE ELABORAR UNICAMENTE DE ACUERDO A ESTE FORMULARIO.</div>
              <div>ESTE FORMULARIO NO CONSTITUYE REEMPLAZO DEL ACTA OFICIAL DEL CURSO.</div>
              <div className="h-100"></div>
            </div>
        </div>
      </div>
      </PrintContainer>
    )


}

export default ActaComprobante;


