import React from 'react'
import PropTypes from 'prop-types'
import Form from '../Create/HorarioForm'
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Update extends React.Component {
  static propTypes = {
    update: PropTypes.func.isRequired,
    load2Update: PropTypes.func.isRequired
  }

  componentWillMount() {
    this.props.load2Update(this.props.match.params.id)
  }

  render() {
    const { update, 
            updateData,
            filtroCoordinacionForm,
            filtroPensumForm,
            filtroCicloForm,
            filtroCohorteForm,
            resetFiltoForm,
            secciones,
            loading
          } = this.props;

    return (
      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 col-sm-12">
            <Form onSubmit={update} 
                  editar={ true } 
                  data ={updateData}
                  secciones={ secciones } 
                  filtroCoordinacionForm={ filtroCoordinacionForm }
                  filtroPensumForm={ filtroPensumForm } 
                  filtroCicloForm={ filtroCicloForm  } 
                  filtroCohorteForm={ filtroCohorteForm } 
                  resetFiltoForm={ resetFiltoForm } 
                  secciones={ secciones }/>
          </div>
        </div>
      </LoadMask>
    )
  }
}
