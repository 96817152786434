 

 import React, { Component } from 'react';


class Titulo extends Component  {
    render() {
       const { titulo, editar } = this.props;
        return(
        	<div>
	        	<h4 className="mb-3 text-primary subtitulo font-weight-bold mt-4">
	                <strong>{editar?'EDITAR': 'INGRESAR'} { titulo } </strong>
	            </h4>
	        </div>
        )
    }
}

export default Titulo;
