import React from 'react'
import Form from './CursoForm'
      import LoadMask from "Utils/LoadMask/LoadMask";
export default class Create extends React.Component {
  render () {
    const { create, loading  } = this.props

    return (

    <LoadMask loading={loading} dark blur>
      <div className="row d-flex justify-content-center h-100">
        <div className="col-12 px-0">
          <Form onSubmit={create} />
        </div>
      </div>
    </LoadMask>
    )
  }
}
