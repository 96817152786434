import { connect } from 'react-redux'
import Update from './HorarioUpdate'
import { 	update, 
			load2Update,
			filtroCoordinacionForm,
			filtroPensumForm,
			filtroCicloForm,
			filtroCohorteForm,
			resetFiltoForm
		 } from '../../../../redux/modules/horario'



const mdtp = { 	update, 
				load2Update,
				filtroCoordinacionForm,
				filtroPensumForm,
				filtroCicloForm,
				filtroCohorteForm,
				resetFiltoForm
			};
const mstp = state => state.horario;

export default connect(mstp, mdtp)(Update)
