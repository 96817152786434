import React, { Component } from "react";
import Loader from "react-loader-spinner";
import PropTypes from "prop-types";
import classnames from "classnames";
require("./LoadMask.css");

class LoadMask extends Component {
    static propTypes = {
        radius: PropTypes.bool,
        loading: PropTypes.bool.isRequired,
        dark: PropTypes.bool,
        blur: PropTypes.bool,
        light: PropTypes.bool
    };

    static defaultProps = {
        radius: false,
        dark: false,
        blur: false,
        light: false
    };

    render() {
        const { children, radius, dark, light, blur, loading, uploading} = this.props;

        return (
            <div  className={classnames('load-mask', { 'full-size': loading || uploading })} >
                {loading  && (
                    <div
                        className={classnames("loader-container d-flex flex-column", {
                            radius,
                            dark,
                            light
                        })}
                    >
                        <Loader
                            type="Rings"
                            color="#dd0a35"
                            height="100"
                            width="100"
                        />
                        <p className="font-weight-bold font-italic">CARGANDO...</p>
                    </div>
                )}
                {uploading  && (
                    <div
                        className={classnames("loader-container d-flex flex-column", {
                            radius,
                            dark,
                            light
                        })}
                    >
                        <div className="progress">
                              <div className="progress-bar progress-bar-striped progress-bar-animated striped bg-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width: "100%"}}></div>
                        </div>
                        <p className="font-weight-bold font-italic pt-3">IMPORTANDO LISTADO...</p>
                        <p>Por favor no salga de esta ventana</p>
                    </div>
                )}
                <div
                    className={classnames("load-mask-content", {
                        loading,
                        blur
                    })}
                >
                    {children}
                </div>
            </div>
        );
    }
}

export default LoadMask;
