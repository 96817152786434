import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './toolbar.css';
import Select from 'react-select';
import Search from './Search';

class Toolbar extends Component  {
    render() {
       const { titulo, buscar, buscador, boton, ruta } = this.props;
        return(
            <div className="col-12 row pb-2 pt-5 pl-3">
                <div className="col-md-5 titulo">
                    <h1 className="ml-0 text-uppercase text-center text-md-left"><strong>{this.props.titulo}</strong></h1>
                </div>
                <div className="col-md-3 col-lg-3 text-center mb-3">
                    <Link   className="btn btn-primary ml-0 ml-sm-auto flex-nowrap flex-sm-wrap" 
                            to={`/${ ruta?ruta:titulo }/crear`}> { boton }
                    </Link>
                </div>
                <div className="col-lg-4 col-md-4 text-right search mb-1">
                    {(buscar !== undefined) && (
                    <Search buscar={buscar} buscador={buscador}/>
                    )}
                </div>
            </div>

        )
    }
}

export default Toolbar;
