 

 import React, { Component } from 'react';


class Titulo extends Component  {
    render() {
       const { 	titulo, editar } = this.props;
        return(
        	<div>
        		<div>
        			<h5 className="mx-lg-3 my-3 text-primary subtitulo font-weight-bold">
			            <strong>{editar?'EDITAR': 'INGRESAR'} { titulo }</strong>
			        </h5>
		        </div>
	            <div className="py-3 background-secondary mb-4">
	            </div>
	        </div>
        )
    }
}

export default Titulo;
