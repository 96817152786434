import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField, renderSwitch} from 'Utils//renderField/renderField'
import { tituloOptions } from '../../../utility/constants';
import { email, phone, minLength } from '../../../../utility/validation';
import Titulo from "../../Utils/Titulo/TituloGrid";
import { PrintContainer, actions as printActions } from "Utils/Print";
import ReactTable from "react-table";
import classNames from 'classnames';
import "react-table/react-table.css";
import _ from "lodash";
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import ActaComprobante from "Utils/Impresiones/ActaComprobante";

function TotalColorFormat(cell){
    return {color: cell>=70?'#1687a7':'#dd0a35', fontWeight: 'bold'}
}

function EstadoColorFormat(cell){
   if(cell==1)
     return {color: '#1687a7', fontWeight: 'bold'}
  else if(cell==2)
     return {color: '#dd0a35', fontWeight: 'bold'}
  else
     return {color: '#014955', fontWeight: 'bold'}

}

function EstadoFormat(cell, row) {
  //return cell.nombre
  if(cell==1)
    return 'APROBADO'
  else if(cell==2)
    return 'REPROBADO'
  else
    return 'AUSENTE'
}

class CursoDocenteVer extends React.Component{
    constructor(props) {
      super(props);
      this.state = {data: [], columns:  [], hasNameError: "", validate: true}
  }

    componentWillMount() {
    this.props.getActa(this.props.match.params.id)
    this.props.getInscritos(this.props.match.params.id)
    this.props.findTareasVer(this.props.match.params.id)
    this.props.findNotas(this.props.match.params.id)

  }

  //INICIALIZAR COLUMNAS Y FILA PARA FORM
  componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
    if (this.props.data_tareas_ver !== prevProps.data_tareas_ver) {
      let columnas =[];
      let datos = _.cloneDeep(this.props.data_tareas_ver);

      datos.forEach((data, index)=>{
          data.id = data.accessor;
          data.accessor = String(data.id);
          data.headerClassName = "header-table-notas"
          data.className = "text-right"
          columnas.push(data);
      });
      this.setState({columns: columnas})
    } 
    if (this.props.data_notas !== prevProps.data_notas ) {
      this.setState({data: this.props.data_notas})
    }
  
  }


  componentDidMount(){
  }


      render(){
       

        const { create, editar, data_acta_encabezado, data_inscritos, loading, data_comprobante, data_notas } = this.props
        return (
            <div  className="col-12 px-0">
                <Titulo  titulo='NOTAS '  noDivisor={ true }/>
                <div className="row mx-0 ">
                  <div className="col-12 text-left">
                    <h6 className="subtitulo text-info font-weight-bold">
                       { data_acta_encabezado?data_acta_encabezado.carrera:'' }
                    </h6>
                  </div>
                </div>
                <div className="row mx-0 ">
                  <div className="col-md-5  col-sm-12 text-sm-center text-md-left">
                    <h5 className="subtitulo text-info font-weight-bold">
                     { data_acta_encabezado?data_acta_encabezado.curso:'' }
                    </h5>
                  </div>
                  <div className="col-md-4  col-sm-6 text-center subtitulo">
                    <div>
                        <span className="mr-2">
                            Ciclo:
                        </span>
                        <span className="mr-3  text-info font-weight-bold ">
                            { data_acta_encabezado?data_acta_encabezado.ciclo:'' }
                        </span>
                        <span className="mr-2">
                            Sección:
                        </span>
                        <span className="text-info font-weight-bold">
                            { data_acta_encabezado?data_acta_encabezado.seccion:'' }
                        </span>
                    </div>
                  </div>
                  <div className="col-md-3 pt-sm-3 pt-md-0 col-sm-6 text-sm-center text-md-right pr-lg-5 subtitulo">
                    <div className="">
                      <button className="btn btn-info px-1 ml-auto"
                        style={{paddingLeft:'0.5em !important', paddingRight: '0.5em !important'}}
                        onClick={(e) => {
                              e.preventDefault();
                              printActions.print('COMPROBANTE')
                        }}>
                               Imprimir Comprobante Acta
                      </button>
                    </div>
                  </div>
                  
                </div>
                <div className="py-3 background-secondary my-4"></div>

                <div className="row d-flex justify-content-center ">
                    <div className="form-group grid-container col-lg-11 col-sm-12">
                        <div className="grid-titulo padding-15">
                            <div className="padding-15 p-sm-0 py-sm-1">
                                <div className="row d-flex">
                                </div>
                              <Table  onPageChange={this.props.findNotas} 
                                data={ { results: this.state.data } } 
                                loading={loading} 
                                page={1} 
                                noPagination={ true }
                                noDataText={'No tiene alumnos asignados'}>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                     dataAlign="center"
                                    className="titulo-dias text-info"
                                    dataAlign="center"
                                    dataField="carnet" dataSort>CARNET</TableHeaderColumn>
                                <TableHeaderColumn
                                    thStyle={BreakLine}
                                    dataAlign="center"
                                    className="titulo-dias text-info"
                                    dataField="alumno" dataSort>ALUMNO</TableHeaderColumn>
                               
                                { 

                                  this.state.columns.map((columna, index)=>{
                                    return( 
                                      <TableHeaderColumn
                                      isKey
                                      thStyle={BreakLine}
                                      dataAlign="right"
                                      className="titulo-dias text-info"
                                      dataField={columna.accessor} 
                                      dataSort> {columna.Header}</TableHeaderColumn>
                                    )
                                  })
                                }
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="center"
                                    className="titulo-dias text-info font-weight-bold"
                                    tdStyle={ TotalColorFormat }
                                    dataField="nota" dataSort>TOTAL</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={ EstadoFormat }
                                    tdStyle={ EstadoColorFormat }
                                    dataAlign="center"
                                    className="titulo-dias text-info"
                                    dataField="estado_nota" dataSort>ESTADO</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={{width:'0px'}}
                                    thStyle={{width:'0px'}}
                                    dataField="pk" isKey dataAlign="center"/>
                        </Table>

                        <ActaComprobante notas={ data_notas }
                                             data_encabezado={ data_acta_encabezado }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      )
    }

}



export default CursoDocenteVer

                

