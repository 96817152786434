import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import Toolbar from '../../Utils/Toolbar/ToolbarActa';
import FilterHorario from '../../Utils/Toolbar/FilterHorario';
import ExpandComponent from './expandComponent';
import EspecialGrid from './EspecialesGrid';
import LoadMask from 'Utils/LoadMask'


export default class ActasGrid extends React.Component {
    static propTypes = {
        data_acta: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        findActa: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.getRow = this.getRow.bind(this);
        this.EstadoFormat = this.EstadoFormat.bind(this);
        this.props.findActa();
        this.props.findEspecial();
    }


    EstadoFormat(cell, row) {
      let estado="";
      if(!cell){
        estado+="Por Catedrático";
      }
      if(row.cerrado_fecha)
        estado+=" Por Fecha"
      if(!row.cerrado_fecha && cell)
        estado+="No"

      return estado;
    }

    getRow(row){
        return (
                <ExpandComponent key={row.pk} row={ row } EstadoFormat={this.EstadoFormat}/>
            )
    }
    render() {
        const { loading, page, data_acta, buscador_acta, data_especial } = this.props;
        const { destroy, findActa, searchActa, findEspecial } = this.props;
        const { 
                filtroCoordinacionExtensionActa, 
                filtroCoordinacionCarerraActa,
                filtroPensumActa,
                filtroCicloActa,
                filtroCohorteActa,
                coordinacion__extension,
                coordinacion,
                pensum,
                ciclo,
                cohorte,
            } = this.props;

        return (
            <div className="row  d-flex justify-content-center">
               <div className="col-sm-12 px-0 pt-2">
                    <div className="grid-container">
                       <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                           <Toolbar
                                buscar={searchActa}
                                titulo={"actas"}
                                boton = {"Agregar Acta"}
                                buscador={buscador_acta}
                                filtroCoordinacionExtension={ filtroCoordinacionExtensionActa }
                                filtroCoordinacionCarerra={ filtroCoordinacionCarerraActa }
                                filtroPensum={ filtroPensumActa }
                                filtroCiclo={ filtroCicloActa }
                                filtroCohorte={ filtroCohorteActa }
                                coordinacion__extension={ this.props.coordinacion__extension}
                                coordinacion={ this.props.coordinacion}
                                pensum={ this.props.pensum}
                                ciclo={ this.props.ciclo}
                                cohorte={ this.props.cohorte}/>
                            </div>
                        <br />


                      {/* GRID DE ACTAS ESPECIALES*/}
                      <EspecialGrid   data_especial={data_especial}
                                      findEspecial={findEspecial}
                                      loading={loading}
                                      coordinacion__extension={ this.props.coordinacion__extension}
                                      coordinacion={ this.props.coordinacion}
                                      pensum={ this.props.pensum}
                                      ciclo={ this.props.ciclo}
                                      cohorte={ this.props.cohorte} />

                        {/*EL PRIMER NIVEL DE LA TABLA SIEMBRE TENDRÁ SOLAMENTE UNA FILA*/}
                        <LoadMask loading={loading} dark blur>
                          <div className="react-bs-table-container">
                            <div className="react-bs-table react-bs-table-bordered">
                                 <div className="react-bs-container-body">
                                  <div className="react-bs-container-header table-header-wrapper" style={{overflow: 'initial'}}>
                                    <table className="table table-hover table-bordered">
                                        <colgroup><col/><col/><col/><col/><col/></colgroup>
                                        <thead>
                                            <tr>
                                                <th  className="p-3">EXTENSIÓN </th>
                                                <th  className="p-3">CARRERA </th>
                                                <th  className="p-3">CICLO</th>
                                                <th  className="p-3">COHORTE</th>
                                                <th  className="p-3"/>
                                            </tr>
                                        </thead>
                                    </table>
                                  </div>
                                 <div>
                                 { data_acta &&!data_acta.length ?
                                    <table key={'NODATA'} style={{width: "100%"}} className="table table-hover table-bordered">
                                      <tbody>
                                        <tr>
                                          <td colSpan="5" className="react-bs-table-no-data">No hay datos. Seleccione filtros para ver resultados
                                          </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                  :
                                  <table className="table table-hover table-bordered">
                                    <tbody>  
                                        <tr key={'DATA'}>
                                            <td >{this.props.coordinacion__extension.label}</td>
                                            <td style={BreakLine}>{this.props.coordinacion.label} </td>
                                            <td >{this.props.ciclo.nombre}</td>
                                            <td >{this.props.pensum.codigo} </td>
                                            <td >
                                               <Link className="btn btn-primary" to={ `/actas/actualizar/${this.props.cohorte.value}/${this.props.ciclo.pk}/${this.props.pensum.pk}/${this.props.coordinacion__extension.value}/${this.props.coordinacion.pk}`}>Editar</Link>

                                            </td>
                                        </tr>
                                        <tr width="100%" key={'EXPANSION'}>
                                            <td colSpan="5" className="pt-0">
                                                {
                                                     data_acta.map((row, index)=>{
                                                        return (this.getRow(row))
                                                     })
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                  </table>
                                 }
                                </div>
                             </div>
                           
                            </div>
                          </div>
                        </LoadMask>
                    </div>

                </div>

            </div>
        )
    }
}



