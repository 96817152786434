import React, { Component } from 'react';

class Privado extends Component {
  render() {
    return (
      <div className="imagen-inicio">
        <div className="d-flex flex-column justify-content-around h-100 pb-3">
          <div className="p-2 bd-highlight pt-5 text-center">
            <h3 className="font-weight-bold font-italic">BIENVENIDO</h3>
          </div>
          <div className="p-2 bd-highlight text-center img-cont">
             <img className="inicio-logo img-responsive" src={require('../../../../assets/img/logo_cusam.png')} alt="Logo" />
          </div>
          <div className="p-2 bd-highlight text-center img-cont2">
            <img className="inicio-logo img-responsive" src={require('../../../../assets/img/back_bienvenida2.png')} alt="Bienvenida" />
          </div>
        </div>
      </div>
    );
  }
}

export default Privado;