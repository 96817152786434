import React from 'react';
import Form from '../Create/ExtensionForm';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Update extends React.Component {

  static propTypes = {
    update: PropTypes.func.isRequired,
    load2Update: PropTypes.func.isRequired

  }

  componentWillMount(){
    this.props.load2Update(this.props.match.params.id)
    this.props.findBorrablesCoordinacion(this.props.match.params.id)
  }

  render () {
    const { update, borrables_coordinacion, loading  } = this.props

    return (
      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
          <div className="col-12 px-0">
            <Form onSubmit={update} editar={ true } borrables={ borrables_coordinacion }/>
          </div>
        </div>
      </LoadMask>
    )
  }
}
