import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm, FieldArray, formValueSelector, change, FormSection} from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { connect } from 'react-redux'
import { renderField, renderSelectField, renderSwitch, renderSearchSelect, renderNoAsyncSelectField, renderTimeField } from 'Utils//renderField/renderField'
import { api } from "../../../../utility/api";
import _ from "lodash";
import { required } from '../../../../utility/validation';
import EncabezadoDocs from "Utils/EncabezadoDocs/EncabezadoDocs";
import Titulo from "../../Utils/Titulo/TituloBigForm";
import { PrintContainer, actions as printActions } from "Utils/Print";

class renderDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
                secciones:[],
        };
    }
    render(){
        const { fields, titulo, cursos_list, key_array }= this.props
        const { error, submitFailed } = this.props.meta;
        return  (
           
            <div className={`dont-break ${!fields || !fields.length || error?'dont-print':''}`}>
                <div className="table-responsive-sm">
                    <h5 className="mt-5 mb-0 text-primary subtitulo font-weight-bold pl-3 mt-2 mr- py-2 titulo-dias">
                        <strong>{ titulo }  </strong>
                    </h5>
                    <div >
                        <table className="table table-sm table-hover table-detalle">
                            <thead className="text-info">
                                <tr>
                                    <th style={{width: "16%"}}>HORA INICIO</th>
                                    <th style={{width: "16%"}}>HORA FINAL</th>
                                    <th style={{width: "63%"}}>CURSO Y CATEDRÁTICO</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    fields.map((dia, index) => 
                                    {
                                    return (
                                        <tr key={ index }>
                                            <td style={{width: "16%"}}>
                                                <Field
                                                    name={`${dia}.hora_inicio`}
                                                    component={renderTimeField}
                                                    required={ true }/>
                                            </td>
                                            <td style={{width: "16%"}}>
                                                <Field
                                                    name={`${dia}.hora_fin`}
                                                    component={renderTimeField}
                                                    required={ true }/>

                                            </td>
                                            <td style={{width: "63%"}}>
                                                <Field
                                                    name={`${dia}.curso`}
                                                    valueKey="value"
                                                    labelKey="label"
                                                    component={renderNoAsyncSelectField}
                                                    label="Curso"
                                                    defaultOptions={ true }
                                                    options={ cursos_list } /> 
                                            </td>
                                            <td className="text-center" style={{width: "5%"}}>
                                                <img   onClick={() => fields.remove(index)}
                                                     className="action-img dont-print" 
                                                     title="Eliminar" 
                                                     src={require("../../../../../assets/img/icons/delete.png")} alt="Delete" />
                                            </td>

                                        </tr>
                                    )
                                
                                })
                                }
                              
                            </tbody>
                        </table>
                    </div>      
                </div>
                 <div>
                    {submitFailed &&
                            error &&
                            <td className="invalid-feedback-array">
                                    {error}
                                </td>}
                </div>

                  <div className="row mt-3">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center mt-3">
                                                
                                <button type="button" className="btn btn-info ml-0  flex-nowrap flex-sm-wrap" onClick={() => fields.push({})}>
                                                Agregar Curso
                                </button>
                         </div>
                        </div>
                    </div>
            </div>
               
           
        )
    }
}

export default renderDetalle;