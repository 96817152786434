//IMAGENES EN NEGRO
const admin1 = require('../../assets/img/sidebar/admin1.png')
const alumnos1 = require('../../assets/img/sidebar/alumnos1.png')
const catedraticos1 = require('../../assets/img/sidebar/catedraticos1.png')
const extenciones1 = require('../../assets/img/sidebar/extenciones1.png')
const usuariosacademicos1 = require('../../assets/img/sidebar/usuariosacademicos1.png')
const asignaciones1 = require('../../assets/img/sidebar/asignaciones1.png')
const horarios1 = require('../../assets/img/sidebar/horarios1.png')
const actas1 = require('../../assets/img/sidebar/actas1.png')
const cursoscatedraticos1 = require('../../assets/img/sidebar/cursoscatedraticos1.png')
const certificaciones1 = require('../../assets/img/sidebar/certificaciones1.png')

//IMAGES EN BLANCO
const admin2 = require('../../assets/img/sidebar/admin2.png')
const alumnos2 = require('../../assets/img/sidebar/alumnos2.png')
const catedraticos2 = require('../../assets/img/sidebar/catedraticos2.png')
const extenciones2 = require('../../assets/img/sidebar/extenciones2.png')
const usuariosacademicos2 = require('../../assets/img/sidebar/usuariosacademicos2.png')
const asignaciones2 = require('../../assets/img/sidebar/asignaciones2.png')
const horarios2 = require('../../assets/img/sidebar/horarios2.png')
const actas2 = require('../../assets/img/sidebar/actas2.png')
const cursoscatedraticos2 = require('../../assets/img/sidebar/cursoscatedraticos2.png')
const certificaciones2 = require('../../assets/img/sidebar/certificaciones2.png')


export const icons = {
    admin1,
    alumnos1,
    catedraticos1,
    extenciones1,
    usuariosacademicos1,
    asignaciones1,
    horarios1,
    admin2,
    alumnos2,
    catedraticos2,
    extenciones2,
    usuariosacademicos2,
    asignaciones2,
    horarios2,
    actas1,
    actas2,
    cursoscatedraticos1,
    cursoscatedraticos2,
    certificaciones1,
    certificaciones2,
}