import React from 'react'
import Form from './ActasFechaForm'

export default class Create extends React.Component {


  componentWillMount() {
    let ciclo = this.props.match.params.ciclo; 
    let pensum = this.props.match.params.pensum; 
    let extension = this.props.match.params.extension; 
    let coordinacion = this.props.match.params.coordinacion; 
    let cohorte = this.props.match.params.cohorte;
    this.props.todosFiltros(coordinacion, extension, pensum, ciclo, cohorte);
  }
  render () {
    const { updateFechas, data_acta  } = this.props

    return (
      <div className="row d-flex justify-content-center">
        <div className="col-md-12 col-sm-12">
          <Form onSubmit={updateFechas} data={data_acta?data_acta:[]} />
        </div>
      </div>
    )
  }
}
