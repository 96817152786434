import React from 'react'
import Form from './HorarioForm'
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Create extends React.Component {
  render () {
    const { create,
            filtroCoordinacionForm,
            filtroPensumForm,
            filtroCicloForm,
            filtroCohorteForm,
            resetFiltoForm,
            getSecciones,
            secciones ,
            loading
          } = this.props

    return (
      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 col-sm-12">
            <Form onSubmit={create} secciones={ secciones } 
                  filtroCoordinacionForm={ filtroCoordinacionForm }
                  filtroPensumForm={ filtroPensumForm } 
                  filtroCicloForm={ filtroCicloForm  } 
                  filtroCohorteForm={ filtroCohorteForm } 
                  resetFiltoForm={ resetFiltoForm } 
                  secciones={ secciones } />
          </div>
        </div>
      </LoadMask>
    )
  }
}
