import { connect } from 'react-redux'
import ActaVer from './ActaVer'
import { findNotasActa, getPieActa} from '../../../../redux/modules/acta'

import { getInscritos, getActa, reabrir} from '../../../../redux/modules/curso_docente'

const mstp = (state) => {
  return {
    ...state.acta,
    data_encabezado: state.curso_docente.data_acta_encabezado,
    data_inscritos: state.curso_docente.data_inscritos,
  };
}

const mdtp = { 	findNotasActa, 
				getInscritos, 
				getActa,
				getPieActa,
				reabrir
			}

export default connect(mstp, mdtp)(ActaVer)
