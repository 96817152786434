import React from 'react'
import Form from './CursoDocenteForm'
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Create extends React.Component {
  
  componentWillMount() {
    this.props.getActa(this.props.match.params.id)
    this.props.getInscritos(this.props.match.params.id)
    this.props.findTareas(this.props.match.params.id)
    this.props.findNotas(this.props.match.params.id)

  }

  render () {
    const { create, 
            data_acta_encabezado, 
            data_inscritos, 
            data_tareas, 
            data_notas, 
            setToggleModal, 
            toggleModal,
            createTarea,
            nueva_tarea,
            cerrar,
            me,
            loading,
            resetArreglos,
          } = this.props

    return (
      <LoadMask loading={loading} dark blur>
          <div className="row d-flex justify-content-center">
            
              <Form create={create} 
                    me={ me }
                    resetArreglos={resetArreglos}
                    loading={loading}
                    editar={ false } 
                    data_acta={ data_acta_encabezado } 
                    data_inscritos={ data_inscritos }
                    data_tareas={ data_tareas }
                    data_notas={ data_notas }
                    setToggleModal={ setToggleModal }
                    createTarea={ createTarea }
                    toggleModal={ toggleModal }
                    nueva_tarea={ nueva_tarea }
                    cerrar={ cerrar }
                    id={this.props.match.params.id}/>

          </div>
        </LoadMask>
    )
  }
}
