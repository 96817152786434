import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { api } from "../../../../utility/api";
import _ from "lodash";
import { RenderSearchSelect, RenderNoAsyncSelectField } from './FilterFields'


class CursoDocenteFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
                cursos_list: [], 
                pensum_sel: 0, 
                pensums:[], 
                carrera_seleccionada: 0, 
                carrera_list:[],
                extension_sel: 0,
                cohorte_options:[]
        };
    }
    componentWillMount() {
        this.getCiclos = this.getCiclos.bind(this);
        this.getCoordinacion = this.getCoordinacion.bind(this);
        this.handleCarreraChange = this.handleCarreraChange.bind(this);
        this.handlePensumChange = this.handlePensumChange.bind(this);
        this.handleCicloChange = this.handleCicloChange.bind(this);
        this.handleCohorte = this.handleCohorte.bind(this);


    }
    componentDidMount() {
        const year = (new Date()).getFullYear() -5;
        const years = []
        for (let i = 0; i < 10; i++) { 
            years.push({value: year+i, label: year+i });
        }
         this.setState({
                  cohorte_options: years
        })

    }

//PARA POBLAR EL SELECT DE CURSOS
    getCiclos(search=''){
        return api.get(`ciclos/`, {search:search})
        .then(data=>{
            if(data && data.results){
                return data.results;
            }
            else
                return  []
        })
        .catch(e=>{
            return []
        })
    }


    getCoordinacion(search=''){
        return api.get(`carreras/selectpensum/`,{value:search})
        .then(data=>{
            if(data){
                this.setState({
                  carrera_list: []
                })
                //console.log(data, "temp")
                this.setState({
                      carrera_list: [...data]
                    })
                return data;
            }
            else
                this.setState({
                  carrera_list: []
                })
                return [];
        })
        .catch(e=>{
           this.setState({
                  carrera_list: []
                })
            return [];
        })
    };

    handleCarreraChange(e){
        if(e == '')
            this.props.filtroCoordinacionCarerra({value: '', label:''});
        else if(e.pk !=this.state.carrera_seleccionada){
            this.setState({ carrera_seleccionada: e.pk });
            this.setState({ pensums: [] });
            let temp = []
            e.pensums.forEach((pensum, i)=>{
            if(!_.find(this.state.pensums, {pk: pensum.pk}))
                temp.push(pensum)
            });
            //console.log(temp, "temp")
            this.setState({
                  pensums: [...temp]
            })
            this.props.filtroCoordinacionCarerra(e)
            
        }
    }

    handlePensumChange(e){
        //console.log(e, "HANDLE")
        if(e == '')
            this.props.filtroPensum({id:'', codigo:''});
        else if(e.id !=this.state.pensum_sel){
            this.setState({ pensum_sel: e.id });
            this.props.filtroPensum(e);
       }
    }
    handleCicloChange(e){
        if(e != '')
            this.props.filtroCiclo(e);
        else
            this.props.filtroCiclo({pk:'', nombre:''});
    }

    handleCohorte(e){
         if(e != '')
            this.props.filtroCohorte(e);
        else
            this.props.filtroCohorte({value:'', label:''});
    }


    render() {
      
        
        return (
            <div className="col-12 row pb-2 pl-3 mt-2 ">
                <div  className="col row">
                    <div className="form-group col-lg-6 col-sm-12 col-xl-5">
                       <label>Coordinación</label>
                       <RenderSearchSelect
                            name='coordinacion'
                            valueKey="value"
                            labelKey="label"
                            onChange = { this.handleCarreraChange }
                            label="Pensums"
                            defaultOptions={ true }
                            valor={this.props.coordinacion}
                            loadOptions={this.getCoordinacion}/>             
                   </div>
                   <div className="form-group col-lg-6 col-sm-12 col-xl-2">
                        <label>Pénsum</label>
                        <RenderNoAsyncSelectField
                            name='pensum'
                            valueKey="id"
                            labelKey="codigo"
                            onChange = { this.handlePensumChange }
                            label="Pensums"
                            valor={this.props.pensum}
                            defaultOptions={ true }
                            options={this.state.pensums}/>       
                   </div>
                   <div className="form-group col-sm-12 col-lg-6 col-xl-3">
                        <label>Ciclo</label>
                        <RenderSearchSelect
                            name='ciclo'
                            valueKey="pk"
                            labelKey="nombre"
                            label="Ciclo"
                            valor={this.props.ciclo}
                            defaultOptions={ true }
                            onChange={this.handleCicloChange}
                            loadOptions={this.getCiclos}/>       
                   </div> 
                   <div className=" form-group col-lg-6 col-sm-12 col-xl-2">
                        <label>Cohorte</label>
                        <RenderNoAsyncSelectField
                            name='cohorte'
                            valueKey="value"
                            labelKey="label"
                            onChange = { this.handleCohorte }
                            label="Cohorte"
                            valor={this.props.cohorte}
                            defaultOptions={ true }
                            options={this.state.cohorte_options}/>       
                        

                    </div>
                </div>
            </div>

      )
    }

}


export default CursoDocenteFilter;