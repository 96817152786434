import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import Login from './common/components/Login/LoginContainer';
import Restablecer from './common/components/Restablecer/RestablecerContainer';
import Home from './common/components/Home/Home';

import Privado from './common/components/Privado/Privado';
import ProtectedRoute from './ProtectedRoute'

import '../assets/fonts/fonts.css';



import { Grid as CAGrid, Create as CACreate, Update as CAUpdate} from './common/components/Carrera';
import { Grid as DOGrid, Create as DOCreate, Update as DOUpdate } from './common/components/Docente';
import { Grid as EXGrid, Create as EXCreate, Update as ExUpdate} from './common/components/Extension';
import { Grid as CURGrid, Create as CURCreate, Update as CURUpdate} from './common/components/Curso';
import { Grid as USGrid, Create as USCreate, Update as  USUpdate, Perfil as USPerfil} from './common/components/Usuario';
import { Grid as PEGrid, Create as PECreate, Update as  PEUpdate } from './common/components/Pensum';
import { Grid as CIGrid, Create as CICreate, Update as  CIUpdate} from './common/components/Ciclo';
import { Grid as ALGrid,  Create as ALCreate, Update as  ALUpdate, VerErrores} from './common/components/Alumno';
import { Grid as HOGrid, Create as HOCreate, Update as  HOUpdate } from './common/components/Horario';
import { Grid as FAGrid, Create as FACreate, Update as  FAUpdate} from './common/components/FechaAsignacion';
import { Grid as ACGrid, Create as ACCreate, Update as  ACUpdat, Ver as ACVer, SetFecha} from './common/components/Actas';
import { EspecialesCreate, EspecialesUpdate } from './common/components/Actas';
import { Grid as ASGrid, InscripcionGrid as INSGrid, Create as ASCreate, Update as ASUpdate} from './common/components/Asignacion';
import { Grid as CDGrid,  Create as CDCreate, Ver as CDVer } from './common/components/CursoDocente';
import { Grid as CEGrid, Ver as CEVer } from './common/components/Certificaciones';
import { Grid as NOTASGrid } from './common/components/Notas';
import { Create as CONFCreate } from './common/components/Configuracion';
import { Grid as BICreate, Ver as BIVer } from './common/components/Bitacora';




require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <ProtectedRoute path="/page" component={Privado} />
                <Route path="/restablecer_contrasena/:token" component={Restablecer} />



                <ProtectedRoute path="/carreras/crear" component={CACreate} />
                <ProtectedRoute path="/carreras/actualizar/:id" component={CAUpdate} />
                <ProtectedRoute path="/carreras/" component={CAGrid} />
               

                <ProtectedRoute path="/docentes/crear" component={DOCreate} />
                <ProtectedRoute path="/docentes/actualizar/:id" component={DOUpdate} />
                <ProtectedRoute path="/docentes/" component={DOGrid} />
                

                <ProtectedRoute path="/extensiones/crear" component={EXCreate} />
                <ProtectedRoute path="/extensiones/actualizar/:id" component={ExUpdate} />
                <ProtectedRoute path="/extensiones/" component={EXGrid} />
                

                <ProtectedRoute path="/cursos/crear" component={CURCreate} />
                <ProtectedRoute path="/cursos/actualizar/:id" component={CURUpdate} />
                <ProtectedRoute path="/cursos/" component={CURGrid} />
                

                <ProtectedRoute path="/usuarios/crear" component={USCreate} />
                <ProtectedRoute path="/usuarios/actualizar/:id" component={USUpdate} />
                <ProtectedRoute path="/usuarios/perfil" component={USPerfil} />
                <ProtectedRoute path="/usuarios/" component={USGrid} />
                

                <ProtectedRoute path="/pensums/crear" component={PECreate} />
                <ProtectedRoute path="/pensums/actualizar/:id" component={PEUpdate} />
                <ProtectedRoute path="/pensums/" component={PEGrid} />
                


                <ProtectedRoute path="/ciclos/crear" component={CICreate} />
                <ProtectedRoute path="/ciclos/actualizar/:id" component={CIUpdate} />
                <ProtectedRoute path="/ciclos/" component={CIGrid} />


                <ProtectedRoute path="/alumnos/crear" component={ALCreate} />
                <ProtectedRoute path="/alumnos/errores" component={VerErrores} />
                <ProtectedRoute path="/alumnos/actualizar/:id" component={ALUpdate} />
                <ProtectedRoute path="/alumnos/" component={ALGrid} />
                
                <ProtectedRoute path="/horarios/crear" component={HOCreate} />
                <ProtectedRoute path="/horarios/actualizar/:id" component={HOUpdate} />
                <ProtectedRoute path="/horarios/" component={HOGrid} />


                <ProtectedRoute path="/fecha_asignacion/crear/:ciclo/:cohorte" component={FACreate} />
                <ProtectedRoute path="/fecha_asignacion/actualizar/:id" component={FAUpdate} />
                <ProtectedRoute path="/fecha_asignacion/" component={FAGrid} />


                
                <ProtectedRoute path="/actas/crear/:cohorte/:ciclo/:pensum/:extension/:coordinacion" component={ACCreate} />
                <ProtectedRoute path="/actas/actualizar/:cohorte/:ciclo/:pensum/:extension/:coordinacion" component={ACUpdat} /> 
                <ProtectedRoute path="/actas/especiales/crear/:cohorte/:ciclo/:pensum/:extension/:coordinacion" component={EspecialesCreate} />
                <ProtectedRoute path="/actas/especiales/actualizar/:cohorte/:ciclo/:pensum/:extension/:coordinacion/:pk" component={EspecialesUpdate} />
                <ProtectedRoute path="/actas/ver/:id" component={ACVer} />
                <ProtectedRoute path="/actas/setfecha/:cohorte/:ciclo/:pensum/:extension/:coordinacion" component={SetFecha} />
                <ProtectedRoute path="/actas/" component={ACGrid} />
    
                <ProtectedRoute path="/asignacion/ver/:encabezado/:inscripcion" component={ASGrid} />
                <ProtectedRoute path="/asignacion/crear/:inscripcion/:ciclo/" component={ASCreate} />
                <ProtectedRoute path="/asignacion/actualizar/:inscripcion/:ciclo/" component={ASUpdate} />
                <ProtectedRoute path="/asignacion/inscripcion" component={INSGrid} />


                <ProtectedRoute path="/curso_docente/crear/:id" component={CDCreate} />
                <ProtectedRoute path="/curso_docente/ver/:id" component={CDVer} />
                <ProtectedRoute path="/curso_docente" component={CDGrid} />


                <ProtectedRoute path="/certificaciones/ver/:pensum/:alumno" component={CEVer} />
                <ProtectedRoute path="/certificaciones" component={CEGrid} />

                <ProtectedRoute path="/configuraciones" component={CONFCreate} />

                <ProtectedRoute path="/notas" component={NOTASGrid} />



                <ProtectedRoute path="/bitacora/ver/:id" component={BIVer} />
                <ProtectedRoute path="/bitacora" component={BICreate} />
                

                <ProtectedRoute path="/" component={Privado} />

                <Route path="*" component={Home} />
            </Switch>
        </div>
    </div>
);
