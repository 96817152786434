import { connect } from 'react-redux'
import Update from './CursoUpdate'
import { update, load2Update } from '../../../../redux/modules/curso'



const mdtp = { update, load2Update };
const mstp = state => state.curso;


export default connect(mstp, mdtp)(Update)
