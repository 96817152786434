
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2'; 
import _ from "lodash";

import {push} from 'react-router-redux';

const url = 'detalle_acta/'

export const create = (filas, columnas, id, cerrar=false) => (dispatch, getState) => {
  dispatch(loading())
  let rows = _.cloneDeep(filas);
  let cols = _.cloneDeep(columnas);
  //console.log(id)
  let notas = normalizarObjecto(rows, cols, id);
  //console.log(notas)
  api.post('notas/',notas)
    .then(response => {
      dispatch(goBack())
      if(cerrar){
       dispatch(cerrarConfirmado(id))
      }
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.detail)
        mensaje = e.detail;
      if(e.length)
        mensaje = e[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}


const normalizarObjecto = (filas, columnas, acta)=>{
    const nombre_tareas = columnas.slice(2, columnas.length-2); 
    const asignaciones = [] 
    filas.forEach((fila, index)=>{
     let asignacion = {pk: fila.asignacion, estado_nota: fila.estado_nota, nota: fila.total, notas:[]}
     let notas = []
     for(let columna of nombre_tareas){
          if(fila[columna.accessor] != null){
            let nota= {asignacion: fila.asignacion, punteo: fila[columna.accessor], tarea:columna.accessor  }
            if(fila[columna.accessor].pk)
              nota.pk = pk;
            notas.push(nota)
          }
      };//FIN CICLO FOR
      asignacion.notas = notas;
      if(fila.justificacion)
              asignacion.justificacion = fila.justificacion;

      asignaciones.push(asignacion);
    });
    return {acta: acta, asignaciones: asignaciones};

}


export const createTarea = () => (dispatch, getState) => {
  const store =  getState().curso_docente;
  let formData = getState().form.curso_docente.values;
  formData.acta = store.data_acta_encabezado.pk
  Swal({
         title: 'Cargando',
         text: "Espere un momento.",
         allowOutsideClick: false,
         onOpen: () => {
           Swal.showLoading();
         }
       });
  dispatch(loading())
  api.post('tareas/',formData)
    .then(response => {
      dispatch(setNuevaTarea(response))
      Swal.close();
      dispatch(toggleModal(false))

    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.detail)
        mensaje = e.detail;
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}

export const update = () => (dispatch, getState) => {
  let formData = getState().form.ciclo.values;
  dispatch(loading())
  api.put(`${url}${formData.pk}`,formData)
    .then(response => {
      dispatch(goBack())
       
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.detail)
        mensaje = e.detail;
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}


export const reabrir = (pk) => (dispatch, getState) => {
   Swal({
            title: '¿Está seguro que desea reabrir esta acta?',
            text: 'Si reabres esta acta, el catedrático podrá modificar las notas.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, reabrir',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
          }).then((result) => {
              if (result.value) {
                dispatch(loading())
                api.put(`${url}/reabrir/${pk}`)
                  .then(response => {
                    dispatch(getActa(pk))
                  })
                  .catch(e => {
                    let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
                    if(e && e.detail)
                      mensaje = e.detail;
                    if(e && e.length)
                    mensaje = e[0];
                  Swal(
                            'ERROR',
                            mensaje,
                            'error'
                    );
                })
                .finally(() => {
                  dispatch(loading(false))
                })
              }
              
            });
 
}

export const getActa = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const acta = await api.get(`${url}${id}`)
    dispatch(setDataActaEncabezado(acta))
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}


//IMPRESIÓN LISTADO DE ASISTENCIA EN VISTA DE REGISTRO
export const getActaRegistro = (id, printActions) => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const acta = await api.get(`${url}${id}`)
    dispatch(setDataActaEncabezado(acta))
    dispatch(getInscritosRegistro(id, printActions))
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
     dispatch(loading(false))
  } 
}

export const getInscritos = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const inscritos = await api.get(`alumnos/inscritos/${id}`)
    dispatch(setDataInscritos(inscritos))
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

export const getInscritosRegistro = (id, printActions) => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const inscritos = await api.get(`alumnos/inscritos/${id}`)
    dispatch(setDataInscritos(inscritos))
    dispatch(loading(false))
    printActions.print('asistencia')
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
     dispatch(loading(false))
  } finally {
    dispatch(loading(false))
  }
}



//CONFIRMACIÓN CERARR ACTA
export const cerrar = (filas, columnas, id) => async (dispatch, getState) => {
  Swal({
    title: '¿Desea cerrar esta acta?',
    text: 'Si cierra esta acta no podrá volver a modificar notas.',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Sí, cerrar',
    cancelButtonText: 'No, cancelar',
    reverseButtons: true
  }).then((result) => {
      if (result.value) {
          Swal({
            title: '¿Está seguro que desea cerrar esta acta?',
            text: 'Si lo hace no podrá volver a modificar notas. No puedes deshacer esta acción',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, cerrar',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
          }).then((result) => {
              if (result.value) {
                   dispatch(create(filas, columnas, id, true))
                  //
              }
          });

      }
  });
  
}

export const cerrarConfirmado = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const cerrar = await api.erase(`${url}cerrar/${id}`)
    dispatch(push(`/curso_docente/ver/${id}/`));
    let mensaje = "Acta cerrada"
    if(cerrar.mensaje)
      mensaje = cerrar.mensaje;
    Swal(
                'Acta Cerrada',
                mensaje,
                'success'
        );

   
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
     //dispatch(loading(false))
  } finally {
    dispatch(loading(false))
  }
  
}




//CURSOS IMPARTIDOS ACTUALMENTE
export const findImpartidos = () => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  //let params = {page, search};
  try {
    const response = await  api.get(url+'impartidos/')
    

    dispatch(setDataImpartidos(response))

  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}
//CURSOS IMPARTIDOS EN CLICLOS PASADOS

export const findHistorial = () => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const acta__coordinacion__carrera = store.curso_docente.coordinacion__carrera_docente.value;
  const acta__pensum = store.curso_docente.pensum_docente.id;
  const acta__ciclo = store.curso_docente.ciclo_docente.pk;
  const acta__cohorte = store.curso_docente.cohorte_docente.value;
  let params = {acta__coordinacion__carrera, acta__pensum, acta__ciclo, acta__cohorte};
  try {
    const response = await  api.get(url+'historial/', params)
    dispatch(setDataHistorial(response))

  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

//FILTROS

export const filtroCoordinacionCarerraDocente = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCoordinacionCarreraDocente(filtro));
    dispatch(findHistorial());
}


export const filtroPensumDocente = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroPensumDocente(filtro));
    dispatch(findHistorial());
}
export const filtroCicloDocente = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    //console.log(filtro)
    dispatch(setFiltroCicloDocente(filtro));
    dispatch(findHistorial());
}
export const filtroCohorteDocente = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCohorteDocente(filtro));
    dispatch(findHistorial());
}

//DATOS PARA TABLA DE NOTAS
export const findTareas = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const tareas = await api.get(`tareas/acta/${id}`)
    const columnas = transformarTareas(tareas)
    dispatch(setDataTareas(columnas))
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

const transformarTareas= (tareas)=>{

  const columnas =[
          { Header: "Carnet", accessor: "carnet", headerClassName:"header-table-notas d-flex align-items-center"},
          { Header: "Alumno", accessor: "alumno", minWidth: 200, headerClassName:"header-table-notas d-flex align-items-center"},
      ];

  const columnas_tareas = columnas.concat(tareas)
  return columnas_tareas;
}


//DATOS PARA TABLA DE NOTAS
export const findTareasVer = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const tareas = await api.get(`tareas/acta/${id}`)
    dispatch(setDataTareasVer(tareas))
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

export const findNotas = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const response = await api.get(`notas/acta/${id}`)
    let notas = _.cloneDeep(response);
    notas= transformarNotas(notas)
    //console.log(notas, "Dispatch de notas")
    dispatch(setDataNotas(notas))
    dispatch(setDataComprobante(response))
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}



export const transformarNotas= (asignaciones)=>{
    asignaciones.forEach((asignacion, index)=>{
      asignacion.detalleNotas.forEach((nota, index)=>{
         asignacion[nota.tarea] = nota.punteo
         asignacion.pk= nota.pk
      })
      //
    });
    return asignaciones;


}

export const setToggleModal = (estado) => (dispatch, getState) => {
    dispatch(toggleModal(estado))
}

export const resetArreglos = ()=> (dispatch, getState) => {
   dispatch(setDataNotas([]))
   dispatch(setDataTareas([]))
}


export const {  loading, 
                setDataHistorial, 
                setDataImpartidos, 
                setDataActaEncabezado, 
                setDataInscritos, 
                setDataNotas, 
                setDataComprobante, 
                setDataTareas, 
                setDataTareasVer, 
                setPage,
                setUpdateData, 
                setFiltroCoordinacionCarreraDocente,
                setFiltroPensumDocente,
                setFiltroCicloDocente,
                setFiltroCohorteDocente,
                toggleModal,  
                setNuevaTarea  
} = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA_HISTORIAL': (data_historial) => ({ data_historial }),
  'SET_DATA_IMPARTIDOS': (data_impartidos) => ({ data_impartidos }),
  'SET_DATA_ACTA_ENCABEZADO': (data_acta_encabezado) => ({ data_acta_encabezado }),
  'SET_DATA_INSCRITOS': (data_inscritos) => ({ data_inscritos }),
  'SET_DATA_NOTAS': (data_notas) => ({ data_notas }),
  'SET_DATA_COMPROBANTE': (data_comprobante) => ({ data_comprobante }),
  'SET_DATA_TAREAS': (data_tareas) => ({ data_tareas }),
  'SET_DATA_TAREAS_VER': (data_tareas_ver) => ({ data_tareas_ver }),
  'SET_PAGE': (page) => ({ page }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
  'SET_FILTRO_COORDINACION_CARRERA_DOCENTE':(coordinacion__carrera_docente)=>({ coordinacion__carrera_docente}),
  'SET_FILTRO_PENSUM_DOCENTE':(pensum_docente)=>({ pensum_docente }),
  'SET_FILTRO_CICLO_DOCENTE':(ciclo_docente)=>({ ciclo_docente }),
  'SET_FILTRO_COHORTE_DOCENTE':(cohorte_docente)=>({ cohorte_docente }),
  'TOGGLE_MODAL':(toggleModal)=>({ toggleModal }),
  'SET_NUEVA_TAREA':(nueva_tarea)=>({ nueva_tarea }),

})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setDataHistorial]: (state, { payload: { data_historial }}) => ({ ...state, data_historial }),
  [setDataImpartidos]: (state, { payload: { data_impartidos }}) => ({ ...state, data_impartidos }),
  [setDataActaEncabezado]: (state, { payload: { data_acta_encabezado }}) => ({ ...state, data_acta_encabezado }),
  [setDataInscritos]: (state, { payload: { data_inscritos }}) => ({ ...state, data_inscritos }),
  [setDataNotas]: (state, { payload: { data_notas }}) => ({ ...state, data_notas }),
  [setDataComprobante]: (state, { payload: { data_comprobante }}) => ({ ...state, data_comprobante }),
  [setDataTareas]: (state, { payload: { data_tareas }}) => ({ ...state, data_tareas }),
  [setDataTareasVer]: (state, { payload: { data_tareas_ver }}) => ({ ...state, data_tareas_ver }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setFiltroCoordinacionCarreraDocente]: (state, { payload: { coordinacion__carrera_docente }}) => ({ ...state, coordinacion__carrera_docente }),
  [setFiltroPensumDocente]: (state, { payload: { pensum_docente }}) => ({ ...state, pensum_docente }),
  [setFiltroCicloDocente]: (state, { payload: { ciclo_docente }}) => ({ ...state, ciclo_docente }),
  [setFiltroCohorteDocente]: (state, { payload: { cohorte_docente }}) => ({ ...state, cohorte_docente }),
  [toggleModal]: (state, { payload: { toggleModal }}) => ({ ...state, toggleModal }),
  [setNuevaTarea]: (state, { payload: { nueva_tarea }}) => ({ ...state, nueva_tarea }),

}

export const initialState = {
  data_impartidos: [],
  data_historial: [],
  data_acta_encabezado:{},
  data_inscritos:[],
  data_tareas:[],
  data_tareas_ver:[],
  data_notas:[],
  data_comprobante:[],
  page: 1,
  loading: false,
  updateData: {},
  //VARIABLES PARA LOS FILTROS
  coordinacion__carrera_docente: {value: '', label:''},
  pensum_docente: {id: '', codigo:''},
  ciclo_docente: {pk: '', nombre:''},
  cohorte_docente: {value: '', label:''},
  //MOSTRAR UNA VENTANAN MODAL
  toggleModal: false,
  nueva_tarea: {},
}

export default handleActions(reducers, initialState)

