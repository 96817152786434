import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm, FieldArray, formValueSelector, change, FormSection} from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { connect } from 'react-redux'
import { renderField, renderSelectField, renderSwitch, renderSearchSelect, renderNoAsyncSelectField, renderTimeField } from 'Utils//renderField/renderField'
import { api } from "../../../../utility/api";
import _ from "lodash";
import { required } from '../../../../utility/validation';
import EncabezadoDocs from "Utils/EncabezadoDocs/EncabezadoDocs";
import Titulo from "../../Utils/Titulo/TituloBigForm";
import { PrintContainer, actions as printActions } from "Utils/Print";
import renderSecciones from './renderSecciones';

//FORM PRINCINPAL DE PENSUMS
class HorarioForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
                secciones:[],
                cursos_list: [], 
                pensum_sel: 0, 
                pensums:[], 
                carrera_seleccionada: 0, 
                carrera_list:[],
                extension_sel: 0,
                datos_encabezado: {extension:'', carrera: '', pensum:'', ciclo:'', cohorte:''}
        };
    }
    componentWillMount() {
        this.getCiclos = this.getCiclos.bind(this);
        this.getExtensiones = this.getExtensiones.bind(this);
        this.getCursos = this.getCursos.bind(this);
        this.getCoordinacion = this.getCoordinacion.bind(this);
        this.handleExtensionChange = this.handleExtensionChange.bind(this);
        this.handleCarreraChange = this.handleCarreraChange.bind(this);
        this.handlePensumChange = this.handlePensumChange.bind(this);
        this.handleCicloChange = this.handleCicloChange.bind(this);
        this.handleCohorteChange = this.handleCohorteChange.bind(this);


    }
    componentDidMount() {
        if (!this.props.editar) {
            this.setState({ carrera_seleccionada: 0, pensum_sel: 0, extension_sel: 0 })
        }

    }

    componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.editar && this.props.data !== prevProps.data) {
        const horario = this.props.data
        this.setState({ carrera_seleccionada: horario.coordinacion.pk, pensum_sel: horario.pensum.pk, extension_sel: horario.extension.pk });
        //POBLAR SELECT DE  PENSUMS
        this.handleExtensionChange(horario.extension);
        //POBLAR SELECT DE  PENSUMS
        this.handleCarreraChange(horario.coordinacion);
        //PARA ASIGNAR EL CICLO SELECCIONADO AL ENCABEZADO
        this.handleCicloChange(horario.ciclo);
        //POBLAR EL SELECT DE CURSOS
        this.handlePensumChange(horario.pensum);
        this.props.filtroCohorteForm(horario.cohorte);
        let datos = this.state.datos_encabezado;
        datos.cohorte = horario.cohorte;
        this.setState({ datos_encabezado:datos});
      }
      //OBTENER LAS SECCIONES DE LAS ACTAS CORRESPONDIENTES
      if(this.props.secciones !== prevProps.secciones){
        this.setState({ secciones:this.props.secciones});
      }
    }

    componentWillUnmount(){
        this.props.resetFiltoForm()
    }

    //PARA POBLAR EL SELECT DE CARRERAS
    getExtensiones(search=''){
        return api.get(`extensiones/select/`, {value:search})
        .then(data=>{
            if(data){
                return data;
            }
            else
                return []
        })
        .catch(e=>{
            return []
        })
    };
     //PARA POBLAR EL SELECT DE CARRERAS


//PARA POBLAR EL SELECT DE CURSOS
    getCiclos(search=''){
        return api.get(`ciclos/`, {search:search})
        .then(data=>{
            if(data && data.results){
                return data.results;
            }
            else
                return  []
        })
        .catch(e=>{
            return []
        })
    }


     //PARA POBLAR EL SELECT DE CURSOS
    getCursos(pensum){
        return api.get(`cursos/prerequisitos/${pensum}/`)
        .then(data=>{
            if(data){
                this.setState({
                  cursos_list: []
                })
                //console.log(data, "temp")
                this.setState({
                      cursos_list: [...data]
                    })
                return data
            }
            else
                this.setState({
                  cursos_list: []
                })
        })
        .catch(e=>{
           this.setState({
                  cursos_list: []
                })
        })
    };

    getCoordinacion(extension){
        return api.get(`coordinaciones/select/${extension}/`)
        .then(data=>{
            if(data){
                this.setState({
                  carrera_list: []
                })
                //console.log(data, "temp")
                this.setState({
                      carrera_list: [...data]
                    })
            }
            else
                this.setState({
                  carrera_list: []
                })
        })
        .catch(e=>{
           this.setState({
                  carrera_list: []
                })
        })
    };

    handleExtensionChange(e){
       if(e.value !=this.state.extension_sel){
            this.setState({ extension_sel: e.value });
            this.setState({ carrera_list: [] });
            this.getCoordinacion(e.value)
            let datos = this.state.datos_encabezado;
            datos.extension = e.label;
            this.setState({ datos_encabezado:datos});
        }
        
    }

    handleCarreraChange(e){
       if(e.pk !=this.state.carrera_seleccionada){
            this.setState({ carrera_seleccionada: e.pk });
            this.setState({ pensums: [] });
            let temp = []
            if(e && e.pensums){
               e.pensums.forEach((pensum, i)=>{
                if(!_.find(this.state.pensums, {pk: pensum.pk}))
                    temp.push(pensum)
                }); 
            }
            
            this.setState({
                  pensums: [...temp]
                })
            let datos = this.state.datos_encabezado;
            datos.carrera = e.label;
            this.setState({ datos_encabezado:datos});
            this.props.filtroCoordinacionForm(e.pk)
        }
    }

    handlePensumChange(e){
       if(e.pk !=this.state.pensum_sel){
            this.setState({ pensum_sel: e.pk });
            this.getCursos(e.pk)
            /*prerequisitos_list.splice(0,prerequisitos_list.length)
            this.getCursos(" ")*/
            let datos = this.state.datos_encabezado;
            datos.pensum = e.codigo;
            this.setState({ datos_encabezado:datos});
            this.props.filtroPensumForm(e.pk)
        }
    }
    handleCicloChange(e){
        let datos = this.state.datos_encabezado;
        datos.ciclo = e.nombre;
        //console.log(e)
        this.setState({ datos_encabezado:datos});
        this.props.filtroCicloForm(e.pk);
    }

      handleChange(moment){
        this.setState({
          moment
        });
      }
    handleCohorteChange(e){
        let datos = this.state.datos_encabezado;
        datos.cohorte = e.currentTarget.value;
        this.setState({ datos_encabezado:datos});
        this.props.filtroCohorteForm(datos.cohorte);
    }
 
          
            

    render() {
        const { handleSubmit, pensum, data, editar } = this.props;


        return (
            <form onSubmit={handleSubmit} noValidate>
                <div className="form-group grid-container">
                    <div className="grid-titulo padding-15">
                        <div className="padding-15 p-sm-0 py-sm-1">
                            <Titulo  editar={editar} titulo='HORARIO'/>
                            <div className="row mt-3">
                                 
                                <div className="form-group col-lg-6 col-sm-12 col-xl-3">
                                    <label htmlFor="carrera">Extensión</label>
                                    <Field
                                        name='extension'
                                        valueKey="value"
                                        labelKey="label"
                                        component={renderSearchSelect}
                                        onChange = { this.handleExtensionChange }
                                        label="Carrera"
                                        defaultOptions={ true }
                                        loadOptions={this.getExtensiones}/>       
                               </div>
                                <div className="form-group col-lg-6 col-sm-12 col-xl-3">
                                    <label htmlFor="carrera">Carrera</label>
                                   <Field
                                        name='coordinacion'
                                        valueKey="pk"
                                        labelKey="label"
                                        onChange = { this.handleCarreraChange }
                                        component={renderNoAsyncSelectField}
                                        label="Pensums"
                                        defaultOptions={ true }
                                        options={this.state.carrera_list}/>             
                               </div>
                               <div className="form-group col-lg-6 col-sm-12 col-xl-2">
                                    <label htmlFor="carrera">Pensum</label>
                                    <Field
                                        name='pensum'
                                        valueKey="pk"
                                        labelKey="codigo"
                                        onChange = { this.handlePensumChange }
                                        component={renderNoAsyncSelectField}
                                        label="Pensums"
                                        defaultOptions={ true }
                                        options={this.state.pensums}/>       
                               </div>
                               <div className="form-group col-sm-12 col-lg-6 col-xl-2">
                                    <label htmlFor="carrera">Ciclo</label>
                                    <Field
                                        name='ciclo'
                                        valueKey="pk"
                                        labelKey="nombre"
                                        component={renderSearchSelect}
                                        label="Ciclo"
                                        defaultOptions={ true }
                                        onChange={this.handleCicloChange}
                                        loadOptions={this.getCiclos}/>       
                               </div> 
                               <div className=" form-group col-lg-6 col-sm-12 col-xl-2">

                                   <label htmlFor="cohorte">Cohorte</label>
                                    <Field 
                                        name="cohorte" 
                                        component={renderField}
                                        onChange={this.handleCohorteChange}
                                        type="number" 
                                        className="form-control p-0 no-border" />

                                </div>
                            </div>
                            <div className="row">
                                <FieldArray name="secciones"
                                            editar={ editar }  
                                            data={ data }  
                                            component={renderSecciones} 
                                            cursos_list={ this.state.cursos_list } 
                                            encabezado={ this.state.datos_encabezado } 
                                            secciones={ this.state.secciones }/>
                            </div>


                             <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center mt-3">
                                        <Link className="btn btn-secondary m-1" to="/horarios/">Cancelar</Link>
                                        <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                    </div>
                                </div>
                            </div>
               
                        </div>
                    </div>
                </div>
            </form>
      )
    }

}

HorarioForm = reduxForm({
    form: 'horario',
     validate: values => {
        const errors = {};
        const SEMANA = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']
        if (!values.coordinacion) {
            errors.coordinacion = 'Campo Requerido';
        }
        if (!values.extension) {
            errors.extension = 'Campo Requerido';
        }
        if (!values.ciclo) {
            errors.ciclo = 'Campo Requerido';
        }
        if (!values.cohorte) {
            errors.cohorte = 'Campo Requerido';
        }
        if (!values.pensum) {
            errors.pensum = 'Campo Requerido';
        }
        const seccionesArrayErrors = []
         if (!values.secciones || !values.secciones.length) {
            errors.secciones = { _error: 'Debe agregar al menos una seccion' }
          } 
        else 
        {
            
            values.secciones.forEach( (element, index)=> {
                //console.log(element)
                let seccionesErrors ={}
                let vacio = false;
                //validar campos de cada día de la semana
                //De existir ese día, debe cumplir con los campos requeridos
                SEMANA.forEach( (DIA, index) =>{
                    if (element[DIA] && element[DIA].length) {
                        let DIA_ArrayErrors=[];
                        element[DIA].forEach( function(dia, index) {
                            let  DIAErrors = {}
                            if(!dia.curso)
                                DIAErrors.curso = "Campo Requerido";
                            if(!dia.hora_fin)
                                DIAErrors.hora_fin = "Campo Requerido";
                            else
                            {
                                let parts = dia.hora_fin.split(":")
                                if(parseInt(parts[1])>59)
                                    DIAErrors.hora_fin = "Una hora solo tiene 60 minutos";

                            }
                            if(!dia.hora_inicio)
                                DIAErrors.hora_inicio = "Campo Requerido";
                            else
                            {
                                let parts = dia.hora_inicio.split(":")
                                if(parseInt(parts[1])>59)
                                    DIAErrors.hora_inicio = "Una hora solo tiene 60 minutos";
                            }
                            DIA_ArrayErrors.push(DIAErrors);
                        });
                        seccionesErrors[DIA] = DIA_ArrayErrors;
                    }

                });
            
                 if(seccionesErrors){
                     seccionesArrayErrors[index] = seccionesErrors
                 }
            });
             if (seccionesArrayErrors.length) {
              errors.secciones = seccionesArrayErrors
            }
            let vacio=false;
            for(let value of values.secciones ){
                if (_.isEmpty(value))  {
                    errors.secciones._error=  'Todas las secciones deben tener al menos un curso.' 
                    break;
                }
                else{
                    let sin_dias = true;
                    for(let DIA of SEMANA ){
                        if (value[DIA] && value[DIA].length) {
                            sin_dias=false;
                            break
                        }
                    }
                    if(sin_dias)
                    {
                        errors.secciones._error=  'Todas las secciones deben tener al menos un curso.' 
                        break;
                    }
                }
            }
        }


        return errors;
    }
})(HorarioForm)

export default HorarioForm
