import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm, FieldArray, formValueSelector, change, FormSection} from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { connect } from 'react-redux'
import { renderField, renderSelectField, renderSwitch, renderSearchSelect, renderNoAsyncSelectField, renderTimeField } from 'Utils//renderField/renderField'
import { api } from "../../../../utility/api";
import _ from "lodash";
import { required } from '../../../../utility/validation';
import EncabezadoDocs from "Utils/EncabezadoDocs/EncabezadoDocs";
import Titulo from "../../Utils/Titulo/TituloBigForm";
import { PrintContainer, actions as printActions } from "Utils/Print";
import renderDetalle from './renderDetalle';

let prerequisitos_list = []


/**/

class renderSecciones extends Component {
  constructor(props) {
        super(props);
        this.state = {
                secciones:[],
                primera_ves: true,
        };
  }

   componentDidMount(){
    
   }
   componentWillUnmount(){
        
    }


  componentDidUpdate(prevProps) {
      //OBTENER LAS SECCIONES DE LAS ACTAS CORRESPONDIENTES
      if(this.props.secciones !== prevProps.secciones){
        //Eliminar El array anterior contenido en prev Provs
        if(!this.props.editar || !this.state.primera_ves)
        {
            if(prevProps.secciones.length)
                this.props.fields.removeAll()
                //AGREGAR LOS NUEVOS ELEMENTOS DEL ARRAY
            this.props.secciones.forEach((seccion, index)=> {
                this.props.fields.push({})
                //console.log("Los props actuales", index)
                //console.log(seccion);
            });
        }
        if(this.state.primera_ves && this.props.secciones.length){
            this.setState({primera_ves: false})
        }
        this.setState({ secciones:this.props.secciones});
        //console.log(this.props.fields.getAll())

      }
  }
  render()
  {
        const {  fields, encabezado, cursos_list, meta } = this.props;
        const { error, submitFailed } = this.props.meta;
        return (
            <div className="row col-12 pr-0 mr-0">
                <div className="col-12 pr-0 mr-0">
                    
                   <div >
                    {
                        fields.map((seccion, index) => {
                            return (
                             <div key={ index }>
                                 <div className="py-3 background-secondary mb-4 mt-5">
                                 </div>
                                <br/>
                                 <PrintContainer name={'horario'+String(index)}>
                                    <EncabezadoDocs titulo="HORARIO"/>
                                    <table className="encabezado print-only">
                                        <tbody>
                                            <tr style={{width: "100%"}}>
                                                <td style={{width: "12%"}}> CARRERA </td>
                                                <td className="text-left" style={{width: "65%"}}>{ encabezado.carrera}</td>
                                                <td style={{width: "10%"}}>CICLO</td>
                                                <td className="text-center" style={{width: "20%"}}>{encabezado.ciclo} </td>
                                            </tr>
                                            <tr style={{width: "100%"}}>
                                                <td style={{width: "12%"}}> EXTENSIÓN </td>
                                                <td className="text-left" style={{width: "65%"}}>{ encabezado.extension }</td>
                                                <td style={{width: "10%"}}> COHORTE</td>
                                                <td className="text-center" style={{width: "20%"}}> {encabezado.cohorte}</td>                 
                                            </tr>
                                        </tbody>
                                     </table>
                                    <div className="row d-fex justify-content-center mb-2">
                                        
                                        <div className="col d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center array-form">
                                            <h5>
                                                <strong className="mr-lg-5 label-titulo"> 
                                                    { this.state.secciones[index]?this.state.secciones[index].ciclo_pensum +" /  Sección "+this.state.secciones[index].seccion: '' }
                                                </strong>
                                            </h5>
                                        </div>
                                        <div className="col-lg-3 col-sm-1 dont-print text-center text-md-right">
                                            
                                            <button className="btn btn-primary font-italic btn-sin-padding " 
                                                    
                                                    onClick={(e) => {
                                                          e.preventDefault();
                                                          printActions.print('horario'+String(index))
                                                     }}>
                                                IMPRIMIR HORARIO
                                            <i className="pl-3 fa fa-print fa-lg" aria-hidden="true"></i>
                                          </button>

                                        </div>
                                         
            
                                     </div> 

                                    <FieldArray name={`${seccion}.lunes`}
                                                component={renderDetalle}
                                                titulo="LUNES" 
                                                cursos_list={ this.state.secciones[index]?this.state.secciones[index].detalleActas : [] }
                                                key_array={ index }/>
                                    <FieldArray name={`${seccion}.martes`}
                                                component={renderDetalle}
                                                titulo="MARTES"  
                                                cursos_list={ this.state.secciones[index]?this.state.secciones[index].detalleActas : [] }
                                                key_array={ index }/>
                                    <FieldArray name={`${seccion}.miercoles`}
                                                component={renderDetalle}
                                                titulo="MIERCOLES"  
                                                cursos_list={ this.state.secciones[index]?this.state.secciones[index].detalleActas : [] }
                                                key_array={ index }/>
                                    <FieldArray name={`${seccion}.jueves`}
                                                component={renderDetalle}
                                                titulo="JUEVES"  
                                                cursos_list={ this.state.secciones[index]?this.state.secciones[index].detalleActas : [] }
                                                key_array={ index }/>
                                    <FieldArray name={`${seccion}.viernes`}
                                                component={renderDetalle}
                                                titulo="VIERNES"  
                                                cursos_list={ this.state.secciones[index]?this.state.secciones[index].detalleActas : [] }
                                                key_array={ index }/>
                                    <FieldArray name={`${seccion}.sabado`}
                                                component={renderDetalle}
                                                titulo="SÁBADO"  
                                                cursos_list={ this.state.secciones[index]?this.state.secciones[index].detalleActas : [] }
                                                key_array={ index }/>
                                </PrintContainer>
                               <br/>

                            </div>
                            )
                            }
                        )
                        
                    }

                    </div>
                
                    {submitFailed &&
                    error &&
                    <div className="invalid-feedback-array">
                            {error}
                        </div>}

                </div>
                
            </div>
        )
  }
} 

export default renderSecciones;