import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import Toolbar from '../../Utils/Toolbar/Toolbar';



function VigenteFormat(cell, row) {
  let htlm = `<span >Pensum Inactivo</span>`
  if(cell){
    htlm = `<img src=${require("../../../../../assets/img/icons/pensum.png")} alt="Delete" /></img>Pensum Activo`
  }

  return htlm;
}

function VigenteColor (cell){
    return {color: '#014955', fontWeight: 'bold', textAlign: 'right'}
}


export default class ExtensionesGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.find()
    }

    render() {
        const { data, loading, page, buscador_pensum } = this.props;

        const { destroy, find, search } = this.props;

        return (

            <div className="row  d-flex justify-content-center">
               <div className="col-sm-12 px-0 pt-2">
                    <div className="grid-container">
                       <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                           <Toolbar
                                buscar={search}
                                titulo={"pensums"}
                                boton = {"Agregar Pensums"}
                                buscador={buscador_pensum}/>
                        </div>
                        <br />
                        <Table onPageChange={find} data={data} loading={loading} page={page}>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="codigo" dataSort>Código</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="display_carrera" dataSort>Carrera</TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                tdStyle={VigenteColor}
                                dataFormat={ VigenteFormat }
                                dataField="vigente" dataSort></TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="pk" isKey dataAlign="center"
                                 dataFormat={activeFormatter({ editar: '/pensums/actualizar', eliminar: destroy })}
                            />
                        </Table>

                    </div>

                </div>

            </div>
        )
    }
}
