import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm, FieldArray, formValueSelector, change, FormSection} from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { connect } from 'react-redux'
import { renderField, renderSelectField, renderSwitch, renderSearchSelect, renderNoAsyncSelectField, renderTimeField } from 'Utils//renderField/renderField'
import { api } from "../../../../utility/api";
import _ from "lodash";
import { required } from '../../../../utility/validation';
import renderDatePicker from 'Utils//renderField/renderDatePicker'
import Titulo from "../../Utils/Titulo/TituloBigForm";
import renderDetalle from "./renderDetalle";
import renderCicloPensum from "./renderCicloPensum";


//FORM PRINCINPAL DE PENSUMS
class ActaForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
                cursos_list: [], 
                pensum_sel: 0, 
                ciclopensum:[],          
        };
    }
    componentWillMount() {
        this.getCicloPensum = this.getCicloPensum.bind(this);
    }
    componentDidMount() {
        this.getCicloPensum();
    }

    componentDidUpdate(prevProps) {
    }


//PARA POBLAR EL SELECT DE CICLO PENSUM
    getCicloPensum(search){
        return api.get(`cursos/ciclopensum/${this.props.idPensum}/`)
        .then(data=>{
            if(data){
                this.setState({ciclopensum: data})
            }
            else
                this.setState({ciclopensum: []})
        })
        .catch(e=>{
            this.setState({ciclopensum: []})
        })
    }
    
    render() {
        const { handleSubmit, data, editar } = this.props
        const { coordinacion__extension, coordinacion, pensum, cohorte, borrables} = this.props
        
        return (
            <form onSubmit={handleSubmit} noValidate>
                <div className="form-group grid-container">
                    <div className="grid-titulo padding-15">
                        <div className="padding-15 p-sm-0 py-sm-1">
                            <Titulo  editar={editar} titulo='ACTA'/>
                            <div className=" subtitulo col-12 pl-5 mx-0">
                               <div className="subtitulo text-info"> 
                                    <h5><strong>{coordinacion.label}</strong></h5>
                               </div>
                               <div >
                                    <span>EXTENSIÓN {coordinacion__extension.label}</span>
                               </div>
                               <div >
                                     <span>PENSUM {pensum.codigo}</span>
                               </div>
                               <div >
                                     <span> {cohorte.label}</span>
                               </div>
                            </div>

                            <div className="row col-12 px-0 mx-0">
                               <div className="col-12 px-0">
                                    <FieldArray name="detalleCiclo"
                                                borrables={borrables}
                                                component={renderCicloPensum}
                                                options={ this.state.ciclopensum }/>
                                </div>
                            </div>


                             <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center mt-3">
                                        <Link className="btn btn-secondary m-1" to="/actas/">Cancelar</Link>
                                        <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                    </div>
                                </div>
                            </div>
               
                        </div>
                    </div>
                </div>
            </form>
      )
    }

}

ActaForm = reduxForm({
    form: 'acta',
     validate: values => {
        const errors = {};

        if(!values.detalleCiclo || !values.detalleCiclo.length)
        {
            errors.detalleCiclo ={ _error: 'Debe agregar al menos un ciclo' }
        }
        else{

           //VALIDAR CADA LÍNEA DE DETALLE
            const detalleCicloArrayErrors = []
            values.detalleCiclo.forEach((linea, index)=>{
                let  DetalleCicloErrors = {}
                if(!linea.ciclo_pensum)
                    DetalleCicloErrors.ciclo_pensum = "Campo Requerido";
                //VALIDANDO SI HAY SECCIONES
                if(!linea.detalleSeccion || !linea.detalleSeccion.length)
                {
                   DetalleCicloErrors.detalleSeccion ={ _error: 'Debe agregar al menos una sección' }
                }
                else{
                    //************VALIDAR DATA DE SECCIONES
                    const detalleSeccionArrayErrors = [];
                    linea.detalleSeccion.forEach((seccion, i)=>{
                        let  DetalleSeccionErrors = {}
                        if(!seccion.seccion)
                            DetalleSeccionErrors.seccion = "Campo Requerido";
                        //VALIDANDO SI LA SECCIÓN TIENE CURSOS
                        if(!seccion.detalleActas || !seccion.detalleActas.length)
                        {
                           DetalleSeccionErrors.detalleActas ={ _error: 'Debe agregar al menos un curso' }
                        }
                        else
                        {
                            //------------VALIDAR DATA CURSOS
                            const detalleActasArrayErrors = [];
                            seccion.detalleActas.forEach((element, j)=> {
                                let  DetalleaActaErrors = {}
                                if(!element.curso)
                                    DetalleaActaErrors.curso = "Campo Requerido";
                                if(!element.docente)
                                    DetalleaActaErrors.docente = "Campo Requerido";
                                //Agregando los errores al array de errores de secciòn
                                if(DetalleaActaErrors){
                                     detalleActasArrayErrors[j] = DetalleaActaErrors;
                                }

                            }); //fin del ciclo de Detalle Acta
                            //AGREGANDO ERRORES DE DETALLE SECCION A ERRORS
                            if (detalleActasArrayErrors.length) {
                              DetalleSeccionErrors.detalleActas = detalleActasArrayErrors
                            }
                            //-------------FIN VALIDAR DATA CURSOS
                        }
                        //Agregando los errores al array de errores de secciòn
                        if(DetalleSeccionErrors){
                             detalleSeccionArrayErrors[i] = DetalleSeccionErrors;
                        }

                    }); // fin del ciclo de secciones
                    //AGREGANDO ERRORES DE DETALLE SECCION A ERRORS
                    if (detalleSeccionArrayErrors.length) {
                      DetalleCicloErrors.detalleSeccion = detalleSeccionArrayErrors
                    }
                    //************FIN VALIDAR DATA DE SECCIONES
                }

                //Agregando los errores al array
                 if(DetalleCicloErrors){
                     detalleCicloArrayErrors[index] = DetalleCicloErrors;
                 }
            });
            //AGREGANDO ERRORES DE DETALLE CICLO A ERRORS
            if (detalleCicloArrayErrors.length) {
              errors.detalleCiclo = detalleCicloArrayErrors
            }

        }
        return errors;
    }
})(ActaForm)

export default ActaForm
