import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2'; 



const url = 'configuraciones/'

export const create = () => (dispatch, getState) => {
  const formData = getState().form.configuracion.values
  dispatch(loading())
  api.post(url,formData)
    .then(response => {
      dispatch(goBack())

    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.detail)
        mensaje = e.detail;
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}

export const load2Update = () => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const configuracion = await api.get(`${url}${1}`)
    dispatch(setUpdateData(configuracion))
    dispatch(initializeForm('configuracion', configuracion))
  } catch(e) {
    console.log(e)
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

export const { loading, setPage,setUpdateData } = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA': (data) => ({ data }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
}

export const initialState = {
  loading: false,
  updateData: {}
}

export default handleActions(reducers, initialState)

