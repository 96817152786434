import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu } from '../Menu';
import { Link } from 'react-router-dom';
import DropdownMenu from 'react-dd-menu';
import './navbar.css';
import './burger-sidebar.css';
import './dd-menu.css';

class Navbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { 
     verMenu: false, 
     opened: false
     };
  }
  componentWillMount() {
    this.toggleMenu = this.toggleMenu.bind(this);
    this.logOut = this.logOut.bind(this);
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.setState({ verMenu: false });
    }
  }
  close() {
      this.setState({ opened: !this.state.opened });
  };

  toggleMenu() {
    return (e) => {
      e.preventDefault();
      this.setState({ verMenu: !this.state.verMenu });
    };
  }
  logOut(event) {
    this.props.logOut();
  }
  toggle() {
      this.setState({ opened: !this.state.opened });
  };

  render() {
    const {me, OnExpanded } = this.props;
    const username = me?`${me.first_name?me.first_name: me.username} ${me.first_name?me.last_name:''}`:'Perfil' //user ? user.username : 'Perfil';
    const menuOptions = {
     isOpen: this.state.opened,
     close: this.close,
     closeOnInsideClick: false,
     toggle: <li><a onClick={this.toggle}> {username}<em className="px-2 fa fa-user" /><em className="fa fa-caret-down" /></a></li>,
     align: 'right',
   };
    return (
      <div>
        <header className="topnavbar-wrapper">
          <nav className="navbar topnavbar">
            <div className="nav-wrapper">
              <ul className="nav navbar-nav">
                <li>
                  <a href="#/" style={{ padding: 0 }}>
                    <img className="img-responsive" src={require('../../../../assets/img/logo_cusam.png')} alt="Logo" />
                     <span className="cusam-logo pl-3">CUSAM</span>
                  </a>
                </li>
                <li><a title="Lock screen" onClick={this.props.cerrarSesion}><em className="icon-lock" /></a></li>
              </ul>
            </div>
            <div>
                <ul className="nav navbar-nav profile-drop-down">
                    <DropdownMenu {...menuOptions}>
                     {( me.is_superuser || me.rol == 0) &&
                       <li>
                          <Link className="menu-item my-0 py-2 text-left"  to='/usuarios/perfil/'>
                            <em className="fa fa-user" />
                            Perfil
                          </Link>
                        </li>
                      }
                      <li><a href="/#/login" onClick={ this.logOut } className="menu-item my-0 py-2 text-left"><em className="fa fa-sign-out" />Cerrar Sesión</a></li>

                    </DropdownMenu>
                </ul>
            </div>
          </nav>
        </header>

        <Menu   OnExpanded={ OnExpanded } />




      </div>
    );
  }
}
Navbar.propTypes = {
};

export default Navbar;
