import React from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment'; 

export const RenderNumber = ({value, decimalScale, className}) => {
    return (
        <NumberFormat className={className}
                      decimalScale={decimalScale ? decimalScale : 0} fixedDecimalScale={true}
                      value={value} thousandSeparator={true} prefix={''} displayType={"text"}
        />
    )
};

export const RenderCurrency = ({value, className}) => {
    return (
        <NumberFormat className={className}
                      decimalScale={2} fixedDecimalScale={true}
                      value={value} thousandSeparator={true} prefix={'Q '} displayType={"text"}
        />
    )
};

export const RenderString = ({value, className}) => {
  const valor = value.toString();
        if(valor != ""){
          return valor;
        }
        else
        {
          return "*************"
        }
};

export const RenderDateTime = ({value, className, time=false}) => {
    if (value) {
        const fecha = new Date(value);
        return (
            <span className={className}>{fecha.toLocaleDateString()} {time?fecha.toLocaleTimeString():''} </span>
        );
    }
    return (<span className={className}>{value}</span>);
};


export const  dateFormatter = (cell)  => {
   if (!cell) {
         return "";
   }

   try
   {
       let date = moment(cell).format('DD/MM/YYYY')
       return date
   }
   catch(e){
       return cell;
   }

}

export const ReadFields = {
    renderCurrency : RenderCurrency,
    renderNumber: RenderNumber,
};
