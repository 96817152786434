import React, { Component }   from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm, FieldArray } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField, renderNoAsyncSelectField, MultiCheckboxCursos} from 'Utils//renderField/renderField'
import Titulo from "../../Utils/Titulo/Titulo";
import _ from "lodash";
import { Layout } from '../layout';

class ItemArray extends Component {
    constructor(props) {
        super(props);
        this.state = {cursos:[]};
    }
    componentWillMount() {
     
    }

    componentDidMount() {
        const field = this.props.field;
        //Obteniendo el array de opciones de cursos
        if(field != null && !_.isEmpty(field) && field.ciclo && field.ciclo.cursos.length){
            this.setState({cursos:field.ciclo.cursos})
        }
        else
            this.setState({cursos:[]})
    }
    render() {
        const {ciclos, index, ciclo, options} = this.props; 
        return (
             <div key={ index }>
                <div className="py-3 background-secondary mt-3">
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-10">
                        <div className="row py-2 col-12 borde mx-1">
                            <div className="col-md-5 col-lg-4  col-sm-12 text-primary subtitulo font-weight-bold pl-md-5">
                                {ciclos[index]?ciclos[index].nombre:''}
                            </div>
                            <div className="col-lg-3 col-sm-12 col-md-4  d-flex align-items-center">
                                <label className="pr-2">Sección</label>
                                <div className="w-100">
                                    <Field name={`${ciclo}.ciclo`}
                                        valueKey="seccion"
                                        labelKey="seccion"
                                        key = { index }
                                        component={renderNoAsyncSelectField}
                                        label="Sección"
                                        onChange = {(e)=>{
                                            if(e.seccion){ 
                                                   this.setState({ cursos: e.cursos})  
                                            }
                                        }}
                                        options={options}/> 
                                </div>
                            </div>
                        </div>
                        <div className="row col-12 px-md-5 borde mx-1">
                         <Field 
                                        name={`${ciclo}.cursos`}
                                        component={ MultiCheckboxCursos } 
                                        options={  this.state.cursos }
                                        labelKey="curso"
                                        className="form-control p-0 no-border" />
                        </div>
                    </div>
                </div>
                
                

            </div>
        )
    }
}


class RenderCiclos extends Component {
    constructor(props) {
        super(props);
    }



    componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
       if (this.props.ciclos !== prevProps.ciclos && this.props.ciclos.length) {
        this.props.ciclos.forEach((pensum, i)=>{
            if(!this.props.editar)
                this.props.fields.push({})
        });
       if(this.props.editar){
            //Se llama a la data después de haber obtenido los ciclos
            this.props.callLoad2Update()
        }
      }
    }

    render() {
        const { meta: { error, submitFailed } } = this.props
        const {  ciclos, fields } = this.props;
        
        return (

        <div className="col-12 px-0">
                {
                    fields.map((ciclo, index) => {
                        return (
                             <ItemArray ciclo={ciclo}
                                        ciclos={ciclos}
                                        index={index}
                                        key={index}
                                        options={ciclos[index]?ciclos[index].secciones:[]}
                                        field={fields? fields.get(index): null}/>
                            )
                        }
                    )
                    
                }
                {submitFailed &&
                error &&
                <div className="invalid-feedback-array">
                        {error}
                </div>}

                </div>
      )
    }
}


const Form = props => {
    const { handleSubmit, editar, inscripcion, ciclos, callLoad2Update, data, me, cer} = props
    return (
         <form onSubmit={handleSubmit} className="row d-flex justify-content-center col-12">
         <Layout titulo={'CURSOS'} 
                noDivisor={ true } 
                carnet={me.username} 
                nombre={me.first_name}
                carrera= {inscripcion && inscripcion.carrera?inscripcion.carrera.label:''}
                funcBoton={(e)=>{ 
                    props.setCerrarAsignacion(true)
                }}
                boton={true}
                cohorte={ inscripcion.cohorte } >
             <div  className="col-12 px-0">
                <div className="d-flex justify-content-center mt-2 ">
                    <div className="form-group grid-container col-12">
                        <div className="grid-titulo padding-15">
                        
                            <div className="padding-15 p-sm-0 py-sm-1">
                                <div className="row col-12 px-0 mx-0">
                                    
                                     <FieldArray name='ciclos'
                                        component={ RenderCiclos} 
                                        callLoad2Update={callLoad2Update}
                                        editar={editar}
                                        ciclos={ciclos}
                                        data={ data }/>
                                </div>
                                <div className="row col-12 px-0 mx-0">
                                    <div className="col-12 mt-lg-5 px-0">
                                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <Link className="btn btn-secondary m-1" to="/asignacion/inscripcion/">Cancelar</Link>
                                            <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
        </form>
  )
}



const AsignacionForm = reduxForm({
    form: 'asignacion'
})(Form)

export default AsignacionForm
