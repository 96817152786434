import React from 'react'
import { PrintContainer, actions as printActions } from "Utils/Print";
import { RenderDateTime } from "Utils/renderField/renderReadField";
import moment from 'moment'; 
import writtenNumber from  'written-number'
import numeros from './numeros'


const EncabezadoHoja=({ data_encabezado,  pagina, totalPaginas})=>{

  return(
    <div>
      <div  className="text-right" 
            style={{marginTop: '27mm', paddingRight:"25mm", width: "210mm", fontSize: '6pt'}}>
          PÁGINA: {`${pagina} DE ${totalPaginas}` } 
      </div>
      <div    className="linea letra-grande" 
              style={{marginTop: '11.5mm', paddingLeft:"33mm", width: "210mm"}}>
         <table>
          <tbody>
              <tr>
                  <td>{data_encabezado.carrera}</td>
              </tr>
              <tr>
                  <td style={{width: "158mm"}}>
                      {
                          data_encabezado.curso?data_encabezado.curso.substr(data_encabezado.codigo_curso.length):''
                       }
                  </td>
                  <td>
                      {data_encabezado.codigo_curso}
                  </td>
              </tr>
              <tr>
                  <td style={{width: "158mm"}}>
                      {data_encabezado.ciclo} {data_encabezado.cohorte}
                  </td>
                  <td>
                      {data_encabezado.seccion} 
                  </td>
              </tr>
              <tr>
                  <td style={{width: "158mm"}}>
                    {data_encabezado && data_encabezado.nombre_especial?data_encabezado.nombre_especial:'EXAMEN FINAL'}
                  </td>
                  <td>31</td>
              </tr>
          </tbody>
         </table>
         <table>
          <tbody>
              <tr>
                  <td style={{width: "111mm"}}> 
                      {data_encabezado.extension} SAN MARCOS 
                  </td>
                   <td style={{width: "47mm"}}>
                      { data_encabezado.codigo_extension }
                  </td>
                  <td>
                      { 
                        data_encabezado.fecha_acta?RenderDateTime({value:data_encabezado.fecha_acta}):'' 
                      }
                  </td>
              </tr>
          </tbody>
         </table>
      </div>
    </div>
  )
}



const Hoja = ({ notas, pie_acta, data_encabezado,  numeracion, pagina, totalPaginas, ultimaHoja }) =>{

	return(
    <div>
      <EncabezadoHoja data_encabezado={ data_encabezado }
                      pagina={ pagina }
                      totalPaginas={ ultimaHoja?totalPaginas+1: totalPaginas }/>
      <div className={` linea interlineado   
                        ${pagina != totalPaginas?'break-after':''}`}
 
              style={{marginTop: '13.5mm', paddingLeft:"10mm", width: "215mm"}}>
         <table className="w-100">
              <tbody>
                  {
                      notas.map((nota, index)=>{
                         return(
                              <tr key={index}>
                                  <td  className="text-right" style={{ width: '05mm'}}>
                                      {index+numeracion}
                                  </td>
                                  <td  className="text-right" style={{ width: '21mm'}}>
                                      {nota.carnet}
                                  </td>
                                  <td  className="text-left" style={{ width: '95mm', paddingLeft: '6mm'}}>
                                      {nota.alumno}
                                  </td>
                                  <td  className="text-right" style={{ width: '13mm'}}>
                                      { nota != 0 && nota.estado_nota != 3?parseInt(nota.nota): '--' }
                                  </td>
                                  <td  className="text-center" style={{ width: '40mm', paddingLeft: '6mm'}}>
                                     { 
                                       nota != 0 && nota.estado_nota!=3? writtenNumber(
                                                                            parseInt(nota.nota), 
                                                                            { lang: numeros}
                                                                            ):'AUSENTE' 
                                      }
                                  </td>
                              </tr>
                           )
                      })
                  }
              </tbody>
          </table>
      </div>
    </div>
	)
}



const getNumeroHojas= (array, n_por_hoja)=>{

  return Math.ceil(array.length/n_por_hoja);
}



/*
notas, pie_acta, data_encabezado,  numeracion, pagina, totalPaginas
*/
const crearListado=( notas, data_encabezado, n, hojas, ultimaHoja)=>{
   
   const impresiones = [];
    for (let i = 0; i<hojas ; i++) {
           impresiones.push(
              <Hoja
                  key={ i }
                  numeracion={ (n*i)+1 }
                  totalPaginas={ hojas }
                  pagina={i+1}
                  ultimaHoja={ ultimaHoja }
                  data_encabezado={ data_encabezado }
                  notas={notas.slice((n*i),(i+1)*n)} />
            )  

      }

    return impresiones;   

}

const ActaContraloria = ({ notas, pie_acta, data_encabezado}) =>{
    const n = 38;
    const hojas = getNumeroHojas(notas, n);
    const resto = notas.length%n;

    return(
     <PrintContainer name='ACTA'>
      <div className="hoja-oficio print-only">    
        {
          crearListado(notas, data_encabezado, n, hojas, (resto>29 || resto == 0))
        }
        
       
        <div className="">
          <div className="linea interlineado" 
                style={{paddingLeft:"10mm", width: "215mm"}}>
           
            <div  className={`${ (resto == 0)|| (resto>35 && resto<=37)?'break-after':''}`}
                  style={{paddingLeft:"20mm", width: "95", fontWeight: 'normal'}}>
            ----------------------------ÚLTIMA LÍNEA-----------------------------
            </div>
          </div>
          { ((resto == 0)|| (resto>35 && resto<=37)) &&
              <div>
                <EncabezadoHoja data_encabezado={ data_encabezado }
                            pagina={ hojas+1 }
                            totalPaginas={ hojas+1}/>
                <div style={{marginTop: '13.5mm'}}></div>
              </div>
            }
          <div  style={{marginTop: '8mm', paddingLeft:"15mm", width: "215mm"}}>
            <table  style={{width: "200mm"}}
                     className={`${(resto>29  && resto<=35)?'break-after':''}`}>
                <tbody>
                    <tr className="letra-mediana">
                        <td>RESUMEN:</td>
                    </tr>
                    <tr className="letra-mediana">
                        <td>
                            APROBADOS: { pie_acta.conteo.aprobado }
                        </td>
                        <td>
                            REPROBADOS: { pie_acta.conteo.reprobado }
                        </td>
                         <td>
                            AUSENTES: { pie_acta.conteo.ausente }
                        </td>
                        <td>
                          TOTAL: { notas.length }
                        </td>
                    </tr>
                    
                </tbody>
              </table>
            </div>

              { (resto>29  && resto<=35) &&
                <div>
                  <EncabezadoHoja data_encabezado={ data_encabezado }
                              pagina={ hojas+1 }
                              totalPaginas={ hojas+1}/>
                  <div style={{marginTop: '13.5mm'}}></div>
                </div>
              }
            <div  style={{ paddingLeft:"15mm", width: "215mm"}}>
              <table className="w-100 break-after">
                <tbody>
                  <tr style={{paddingTop:"15mm"}}>
                        <td className="letra-firmas text-center" style={{width: "62mm", paddingTop:"32mm"}}>
                            { pie_acta.docente }
                        </td>
                        <td className="letra-firmas text-center" style={{width: "62mm", paddingTop:"32mm"}}>
                            { pie_acta.co_carrera }
                        </td>
                        <td className="letra-firmas text-center" style={{width: "62mm", paddingTop:"32mm"}}>
                           { pie_acta.academico }
                        </td>
                    </tr>
                    <tr>
                        <td className="letra-firmas text-center" style={{width: "62mm"}}>
                           Docente
                        </td>
                        <td className="letra-firmas text-center" style={{width: "62mm"}}>
                            Coordinador de Carrera
                        </td>
                        <td className="letra-firmas text-center" style={{width: "62mm"}}>
                           Coordinador Académico
                        </td>
                    </tr>
                </tbody>
              </table>
              <div className="h-100">
              </div>
          </div>
        </div>
      </div>

      </PrintContainer>
    )


}

export default ActaContraloria;