import { connect } from 'react-redux'
import AsignacionGrid from './AsignacionGrid'
import { findCursosAsignados, getInscripcion } from '../../../../redux/modules/asignacion'

const ms2p = (state) => {
  return {
    ...state.login,
    ...state.asignacion,
  };
};


const mdtp = { findCursosAsignados, getInscripcion }

export default connect(ms2p, mdtp)(AsignacionGrid)
