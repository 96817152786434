import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2'; 
import {push} from 'react-router-redux';
const url = 'usuarios/'
// Actions
export const create = () => (dispatch, getState) => {
  const formData = getState().form.usuario.values
  
  dispatch(loading())
  api.post(url,formData)
    .then(response => {
      dispatch(goBack())
       
    })
    .catch(e => {

      let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e && e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}


export const restablecer = () => (dispatch, getState) => {
  let formData = getState().form.restablecer.values
  formData.token = getState().usuario.token_correo;
  console.log(formData, "form reestablecer")
  const direccion =  url + "restablecer_contrasena/"
  dispatch(loading())
  api.post(direccion,formData)
    .then(response => {
      //dispatch(goBack())
      Swal({
              title: '¡Contraseña restablecida!',
              text:'Se ha cambiado la contraseña con éxito, ahora puedes iniciar sesión.',
              type:'success',
              confirmButtonText: 'Ok'
            }
        ).then((result) => {
            dispatch(push('/login'));
        });
       
    })
    .catch(e => {

      let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e && e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}


export const update = () => async (dispatch, getState) => {
  const formData = getState().form.usuario.values

  dispatch(loading())
  
  try {
    const usuario = await api.put(`${url}${formData.pk}`, formData)
    dispatch(goBack())
    
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, verifique los datos y vuelva a intentar.',
                'error'
      );
    console.log('error', e)
  } finally {
    dispatch(loading(false))
  }
}

export const updatePerfil = () => async (dispatch, getState) => {
  const formData = getState().form.usuario.values

  dispatch(loading())
  
  try {
    const usuario = await api.put(url+'pefil/', formData)
    dispatch(goBack())
    
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, verifique los datos y vuelva a intentar.',
                'error'
      );
    console.log('error', e)
  } finally {
    dispatch(loading(false))
  }
}


export const getTokenCorreo = (token)=> (dispatch, getState) =>{
  dispatch(setTokenCorreo(token))
}

export const destroy = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const remove = await api.erase(`${url}${id}`)
    dispatch(find())
  } catch(e) {
     let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
  } finally {
    dispatch(loading(false))
  }
}
export const search = (search) => (dispatch) => {
    let buscador = search.replace(" ", "");
    if (Number(buscador) !== Number(buscador)){
      dispatch(setBuscadorUsuario(search));
    } 
    else {
      buscador = `${buscador.substring(0,4)} ${buscador.substring(4,9)} ${buscador.substring(9)}`;
      buscador = buscador.trim();
      dispatch(setBuscadorUsuario(buscador));
    }
    dispatch(find(1));
}

export const find = (page = 1) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const search = store.usuario.buscador_usuario;
  let params = {page, search};

  try {
    const response = await  api.get('usuarios', params)
    dispatch(setData(response))
    dispatch(setPage(page))

  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

export const load2Update = (id = null) => async (dispatch, getState) => {
  dispatch(loading())
   let direccion =  url+'me/';
   if(id)
    direccion = `${url}${id}/`  
  try {
    let usuario = await api.get(direccion)
    dispatch(setUpdateData(usuario))
    dispatch(initializeForm('usuario', usuario))
  } catch(e) {
    console.log("Este error")
     Swal(
                'ERROR',
                'Ha ocurrido un error, verifique los datos y vuelva a intentar.',
                'error'
      );
  } finally {
    dispatch(loading(false))
  }
}

export const { loading, setData, setPage,setUpdateData, setBuscadorUsuario, setTokenCorreo } = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA': (data) => ({ data }),
  'SET_PAGE': (page) => ({ page }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
  'SET_BUSCADOR_USUARIO':(buscador_usuario)=>({ buscador_usuario}),
  'SET_TOKEN_CORREO':(token_correo)=>({ token_correo}),
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setData]: (state, { payload: { data }}) => ({ ...state, data }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setBuscadorUsuario]: (state, { payload: { buscador_usuario }}) => ({ ...state, buscador_usuario }),
  [setTokenCorreo]: (state, { payload: { token_correo }}) => ({ ...state, token_correo })
}

export const initialState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  page: 1,
  buscador_usuario: '',
  loading: false,
  token_correo: false,
  updateData: {}
}

export default handleActions(reducers, initialState)


