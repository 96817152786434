import { connect } from 'react-redux'
import Update from './UsuarioUpdate'
import { update, load2Update } from '../../../../redux/modules/usuario'



const mdtp = { update, load2Update };
const mstp = state => state.usuario

export default connect(mstp, mdtp)(Update)
