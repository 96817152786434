import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import Toolbar from '../../Utils/Toolbar/Toolbar';


export default class UsuarioGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.find()
    }

    render() {
        const { data, loading, page, buscador_usuario } = this.props;

        const { destroy, find, search } = this.props;

        return (

            <div className="row  d-flex justify-content-center">
               <div className="col-sm-12 px-0 pt-2">
                    <div className="grid-container">
                       <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                            <Toolbar
                                buscar={search}
                                titulo={"usuarios Control Académico"}
                                ruta={"usuarios"}
                                boton = {"Agregar Usuario"}
                                buscador={buscador_usuario}/>
                            
                        </div>
                        <br />
                        <Table onPageChange={find} data={data} loading={loading} page={page}>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="first_name" dataSort>Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                dataField="last_name" dataSort>Apellido</TableHeaderColumn>       
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                dataField="username" dataSort>Usuario</TableHeaderColumn> 
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                dataField="email" dataSort>Correo</TableHeaderColumn>  
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                dataField="telefono" dataSort>Teléfono</TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                dataField="direccion" dataSort>Dirección</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="pk" isKey dataAlign="center"
                                 dataFormat={activeFormatter({ editar: '/usuarios/actualizar', eliminar: destroy })}/> 
                            
                        </Table>

                    </div>

                </div>

            </div>
        )
    }
}


/*<TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="pk" isKey dataAlign="center"
                                 dataFormat={activeFormatter({ eliminar: destroy })}/>*/