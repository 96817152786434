import { createActions, handleActions } from 'redux-actions'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2'; 


const url = 'asignaciones/notas/'



export const findNotas = () => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const carrera = store.notas.carrera.value;
  try {
    const response = await  api.get(`${url}${carrera}/`)
    dispatch(setDataNotas(response))
  } catch(e) {
    console.log(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}


export const filtroCarreraNotas= (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCarreraNotas(filtro));
    dispatch(findNotas());
}

export const { loading, setDataNotas, setPage, setFiltroCarreraNotas,  } = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA_NOTAS': (data) => ({ data }),
  'SET_PAGE': (page) => ({ page }),
  'SET_FILTRO_CARRERA_NOTAS':(carrera)=>({ carrera }),
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setDataNotas]: (state, { payload: { data }}) => ({ ...state, data }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setFiltroCarreraNotas]: (state, { payload: { carrera }}) => ({ ...state, carrera }),
}

export const initialState = {
  data: [],
  page: 1,
  loading: false,
  carrera: {value: '', label:''},
}

export default handleActions(reducers, initialState)