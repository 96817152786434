import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import { RenderDateTime } from 'Utils/renderField/renderTableField'


function cursoFormat(cell) {
	if(cell.curso)
		return cell.curso.label;
	else
		return '';
}

function inicioFormat(cell) {
	if(cell.inicio)
		return RenderDateTime({fecha: cell.inicio, className: ""});
	else
		return '---------';
}

function finalFormat(cell) {
	if(cell.fin)
		return RenderDateTime({fecha: cell.fin, className: ""});
	else
		return '---------';
}
function actaFormat(cell) {
	if(cell.fecha_acta)
		return RenderDateTime({fecha: cell.fecha_acta, className: ""});
	else
		return '---------';
}

 function EstadoFormat(cell, row) {
      let estado="";
      if(!cell.abierto){
        estado+="Por Catedrático";
      }
      if(cell.cerrado_fecha)
        estado+=" Por Fecha"
      if(!cell.cerrado_fecha && cell.abierto)
        estado+="No"

      return estado;
}

const especialGrid = (props) => (
    <div className="px-lg-5 mx-lg-5">
    	<div className="border-light">
		    <div className="d-flex flex-row justify-content-between p-3 border-gray">
		    	<h6 className="my-auto">
		            <span  className="text-primary subtitulo font-weight-bold">ACTAS ESPECIALES</span>
		        </h6>

		        <Link   className="btn btn-info letra-pequenia ml-md-3 ml-sm-0 flex-nowrap flex-sm-wrap" 
                        to={`/actas/especiales/crear/${props.cohorte.value}/${props.ciclo.pk}/${props.pensum.pk}/${props.coordinacion__extension.value}/${props.coordinacion.pk}`}> 
                    Agregar Acta Especial
                </Link>
		    </div>
	    	<div className="react-bs-table-container mb-5">
	        <div className="react-bs-table react-bs-table-bordered">
	             <div className="react-bs-container-body">
	              <div className="react-bs-container-header table-header-wrapper" style={{overflow: 'initial'}}>
	                <table className="table table-hover table-bordered">
	                    <colgroup><col/><col/><col/><col/></colgroup>
	                    <thead>
	                        <tr>
	                            <th  className="p-3">EXTENSIÓN </th>
	                            <th  className="p-3">CARRERA </th>
	                            <th  className="p-3">CICLO</th>
	                            <th  className="p-3">COHORTE</th>
	                           
	                        </tr>
	                    </thead>
	                </table>
	              </div>
	             <div>
	             { props.data_especial &&!props.data_especial.length ?
	                <table key={'NODATA'} style={{width: "100%"}} className="table table-hover table-bordered">
	                  <tbody>
	                    <tr>
	                      <td colSpan="4" className="react-bs-table-no-data">No hay datos. Seleccione filtros para ver resultados
	                      </td>
	                    </tr>
	                    </tbody>
	                </table>
	              :
	              <table className="table table-hover table-bordered">
	                <tbody>  
	                    <tr key={'DATA'}>
	                        <td >{props.coordinacion__extension.label}</td>
	                        <td style={BreakLine}>{props.coordinacion.label} </td>
	                        <td >{props.ciclo.nombre}</td>
	                        <td >{props.pensum.codigo} </td>
	                    </tr>
	                    <tr width="100%" key={'EXPANSION'}>
	                        <td colSpan="4" className=" px-1 pb-1 pt-0">
	                            {
	                                   	<Table  onPageChange={props.findEspeciales} 
			                                  data={ { results:  props.data_especial } } 
			                                  loading={props.loading} 
			                                  page={1} 
			                                  noPagination={ true }>
			                                <TableHeaderColumn
			                                    tdStyle={BreakLine}
			                                    thStyle={BreakLine}
			                                    className="titulo-dias text-info"
			                                    dataAlign="center"
			                                    dataFormat={ cursoFormat }
			                                    dataField="detalleActas" dataSort>CURSO</TableHeaderColumn>
			                                <TableHeaderColumn
			                                    tdStyle={BreakLine}
			                                    thStyle={BreakLine}
			                                    dataFormat={ inicioFormat }
			                                    className="titulo-dias text-info "
			                                    dataField="detalleActas" dataSort>FECHA INICIO</TableHeaderColumn>
			                                 <TableHeaderColumn
			                                    tdStyle={BreakLine}
			                                    thStyle={BreakLine}
			                                    dataFormat={ finalFormat }
			                                    className="titulo-dias text-info"
			                                    dataField="detalleActas" dataSort>FECHA FIN</TableHeaderColumn>
			                                <TableHeaderColumn
			                                    tdStyle={BreakLine}
			                                    thStyle={BreakLine}
			                                    dataFormat={ actaFormat }
			                                    className="titulo-dias text-info"
			                                    dataField="detalleActas" dataSort>FECHA ACTA</TableHeaderColumn>
			                                <TableHeaderColumn
								                thStyle={BreakLine}
								                tdStyle={BreakLine}
								                dataFormat={EstadoFormat}
								                className="titulo-dias text-info"
								                dataField="detalleActas" dataSort>
								                Cerrado</TableHeaderColumn>
			                                <TableHeaderColumn
			                                    tdStyle={BreakLine}
			                                    thStyle={BreakLine}
			                                    className="titulo-dias text-info"
			                                    dataAlign="center"
			                                    isKey
			                                    dataFormat={activeFormatter({ editar: `/actas/especiales/actualizar/${props.cohorte.value}/${props.ciclo.pk}/${props.pensum.pk}/${props.coordinacion__extension.value}/${props.coordinacion.pk}`, ver: '/actas/ver', obj: 'detalleActas'})}
			                                    dataField="pk" dataSort/>
			                                </Table>           
	                            }
	                        </td>
	                    </tr>
	                </tbody>
	              </table>
	             }
	            </div>
	         </div>
       		</div>
        </div>
      </div>
    </div>
);


export default especialGrid;
