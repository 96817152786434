import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import Filter from 'Utils/Toolbar/FilterCursoDocente'
import Titulo from '../../Utils/Titulo/TituloGrid';


function ColorFormat(cell){
    return {color: '#dd0a35', textAlign: 'center'}
}


export default class CursoDocenteGrid extends React.Component {
    static propTypes = {
        data_impartidos: PropTypes.array.isRequired,
        data_historial: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        findImpartidos: PropTypes.func.isRequired,
        findHistorial: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.findImpartidos();
        this.props.findHistorial();

    }

    render() {
        const { page, data_impartidos, data_historial} = this.props;
        const { findImpartidos, findHistorial, loading} = this.props;

        return (
            
            <div className="row  my-4">
                   <div className="grid-container">
                        <Titulo titulo={ 'CURSOS'  } noDivisor={ true }/>
                        <br />
                   </div>
                   <div className="col-12 d-flex justify-content-center menos-margin-3">
                    
                    { (data_impartidos&& data_impartidos.length>0) && 
                       <div className="mb-5">
                           <div className="col-12 mb-3">
                                <span className="text-secondary">
                                    ACTUALES
                                </span>
                           </div>
                           <div className="col-12 px-md-5 px-sm-0">
                                <Table  onPageChange={findImpartidos} 
                                        data={ { results: data_impartidos } } 
                                        loading={loading} 
                                        page={page} 
                                        noPagination={ true }
                                        noDataText={'No tiene cursos asignados'}>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataAlign="center"
                                            tdStyle={ ColorFormat }
                                            dataField="cohorte" dataSort>Cohorte</TableHeaderColumn>
                                        <TableHeaderColumn
                                            thStyle={BreakLine}
                                            dataField="carrera" dataSort>Carrera</TableHeaderColumn>
                                        <TableHeaderColumn
                                            thStyle={BreakLine}
                                            dataField="curso" dataSort>Curso</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataAlign="center"
                                            dataField="ciclo" dataSort>Ciclo</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataAlign="center"
                                            dataField="pensum" dataSort>Pensum</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataAlign="center"
                                            tdStyle={ ColorFormat }
                                            dataField="seccion" dataSort>Sección</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataField="pk" isKey dataAlign="center"
                                           dataFormat={activeFormatter({ editar: '/curso_docente/crear'})}
                                        />
                                </Table>
                            </div>
                        </div>
                      }
                    
                    </div>
                    <div className="col-12">
                            <h6 className="p-2 titulo-docentes">
                              <span  className="mb-1 text-primary subtitulo font-weight-bold">HISTORIAL DE CURSOS ASIGNADOS</span>
                            </h6>
                    </div>
                    <div className=" mt-1 col-12 d-flex justify-content-center">
                         
                    <div className="col-12 px-md-5 px-sm-0">
                       <Filter filtroCoordinacionCarerra={ this.props.filtroCoordinacionCarerraDocente }
                                filtroPensum={ this.props.filtroPensumDocente }
                                filtroCiclo={ this.props.filtroCicloDocente }
                                filtroCohorte ={ this.props.filtroCohorteDocente }
                                noTitulo={ true }
                                coordinacion={ this.props.coordinacion__carrera_docente}
                                pensum={ this.props.pensum_docente}
                                ciclo={ this.props.ciclo_docente}
                                cohorte={ this.props.cohorte_docente}/>

                      <Table  onPageChange={findHistorial} 
                              data={ { results: data_historial } } 
                              loading={loading} 
                              page={page} 
                              noPagination={ true }>
                              <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="center"
                                    className="titulo-dias text-info"
                                    dataField="cohorte" dataSort>Cohorte</TableHeaderColumn>
                                <TableHeaderColumn
                                    thStyle={BreakLine}
                                    className="titulo-dias text-info"
                                    dataField="carrera" dataSort>Carrera</TableHeaderColumn>
                                <TableHeaderColumn
                                    thStyle={BreakLine}
                                    className="titulo-dias text-info"
                                    dataField="curso" dataSort>Curso</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="center"
                                    className="titulo-dias text-info"
                                    dataField="ciclo" dataSort>Ciclo</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="center"
                                    className="titulo-dias text-info"
                                    dataField="pensum" dataSort>Pensum</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataAlign="center"
                                    className="titulo-dias text-info"
                                    dataField="seccion" dataSort>Sección</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    className="titulo-dias text-info"
                                    dataField="pk" isKey dataAlign="center"
                                   dataFormat={activeFormatter({ editar: '/curso_docente/ver'})}
                                />
                        </Table>
                    </div>

                    </div>

            </div>
        )
    }
}

