import React, { Component } from 'react'
import { Field, reduxForm, FieldArray } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { connect } from 'react-redux'
import { renderNoAsyncSelectField } from 'Utils//renderField/renderField'
import _ from "lodash";
import { required, esBorrable } from '../../../../utility/validation';
import renderSeccion from "./renderSeccion";



class ItemArray extends Component {
    constructor(props) {
        super(props);
        this.state = {cursos:[]};
        console.log("constructor")
    }
    componentWillMount() {
     
    }

    componentDidMount() {
        const field = this.props.field;
        if(field != null && !_.isEmpty(field) && field.ciclo_pensum && field.ciclo_pensum.cursos.length)
            this.setState({cursos:field.ciclo_pensum.cursos})
        else
            this.setState({cursos:[]})
    }
    render() {
        const {index, ciclo, options, eliminar, borrables } = this.props;
        return(
            <div key={ index}>
                <div className="py-3 background-secondary mt-5">
                </div>
                <div className="mx-md-3 mx-sm-2 p-3 borde-array-form">
                    <div className="row d-fex justify-content-center mb-2 mx-0">
                        <div className="col-lg-10 col-sm-11 d-flex justify-content-center flex-row align-items-stretch align-items-sm-center array-form">
                            <label htmlFor="ciclo" className="mr-lg-5">Ciclo</label>
                            <div className="col-lg-3 col-sm">
                             <Field
                                name={`${ciclo}.ciclo_pensum`}
                                valueKey="nombre"
                                labelKey="nombre"
                                onChange = { e=>{ 
                                    if(e){
                                        this.setState({cursos: e.cursos})
                                    }
                                }}
                                component={renderNoAsyncSelectField}
                                label="Ciclo Pensum"
                                defaultOptions={ true }
                                className="array-form"
                                options={options}/> 
                            </div>           
                        </div>
                        <div className="col-lg-1 col-sm-1">
                            <img onClick={e=>{eliminar(index)}} 
                                 className="action-img" 
                                 title="Eliminar" 
                                 src={require("../../../../../assets/img/icons/delete.png")} alt="Delete" />
                        </div>
                    </div> 
                    <div>
                       <FieldArray  name={`${ciclo}.detalleSeccion`}
                                    component={renderSeccion}
                                    borrables={borrables}
                                    options={ this.state.cursos }/>
                    </div>
                </div>
               
            </div>
        )
    }
}






class renderCicloPensum extends Component {

    componentWillMount() {
        this.eliminar = this.eliminar.bind(this);
        //this.getCursos = this.getCursos.bind(this);
        //this.handlePensumChange = this.handlePensumChange.bind(this);

    }
    eliminar(index){
        const ciclo = this.props.fields.get(index);
        let borrable = true;
        const leyenda = ciclo.ciclo_pensum?ciclo.ciclo_pensum.nombre:''
        //Verificando que no tenga objetos relacionados
        if(ciclo && ciclo.detalleSeccion && ciclo.detalleSeccion.length)
        {
            for (let seccion of ciclo.detalleSeccion){
                 if(seccion && seccion.detalleActas && seccion.detalleActas.length)
                {
                  
                    for (let detalle of seccion.detalleActas){
                        if(!esBorrable(detalle, this.props.borrables, leyenda )){
                            console.log("No se va a poder borrar")
                            borrable = false;
                            break;
                        }
                    }
                }
            }
        }
        if(borrable)
            this.props.fields.remove(index)

    }

    render(){ 
        const {options, fields, meta, borrables} = this.props;
        const { error, submitFailed } = meta; 
        return(
            
            <div>
                    <div>
                        { 
                            fields.map((ciclo, index) => {
                            return (
                                 <ItemArray key={index}
                                            eliminar={this.eliminar}
                                            index={index}
                                            ciclo={ciclo}
                                            borrables={ borrables }
                                            field={fields? fields.get(index): null}
                                            options={options}/>
                            )}
                        )
                            
                    } 
                    </div>      
                 <div>
                    {submitFailed &&
                            error &&
                            <div className="invalid-feedback-array">
                                    {error}
                                </div>}
                </div>
               
                <div className="py-3 background-secondary mt-5">
                </div>
                     <div className="mx-md-3 mx-sm-2 py-3 borde-array-form">
                      <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center my-3">
                                                    
                                    <button type="button" className="btn btn-info ml-0  flex-nowrap flex-sm-wrap" onClick={() => fields.push({})}>
                                                    Agregar Ciclo
                                    </button>
                             </div>
                            </div>
                        </div>
                </div>
                    
            </div>
               
           
        )
    }
}


export default renderCicloPensum;
