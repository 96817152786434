import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField, renderSwitch} from 'Utils//renderField/renderField'
import { tituloOptions } from '../../../utility/constants';
import { email, phone, minLength } from '../../../../utility/validation';
import Titulo from "../../Utils/Titulo/TituloGrid";
import { PrintContainer, actions as printActions } from "Utils/Print";
import ListadoAsistencia from "Utils/Impresiones/ListadoAsistencia";
import { Textbox } from 'react-inputs-validation';
import ReactTable from "react-table";
import classNames from 'classnames';
import "react-table/react-table.css";
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import TareaForm from './TareaForm'
import LoadMask from 'Utils/LoadMask'

class Form extends React.Component{
    constructor(props) {
      super(props);
      this.renderEditable = this.renderEditable.bind(this);
      this.renderDropDown = this.renderDropDown.bind(this);
      this.renderEditableText = this.renderEditableText.bind(this);
      this.getTotal = this.getTotal.bind(this);
      this.state = {data: [], columns:  [], hasNameError: "", validate: false, noTareas: false, noInscritos: false}
      this.AgregarColumnas = this.AgregarColumnas.bind(this);
      this.validarData = this.validarData.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.openModal = this.openModal.bind(this);
  }

  renderDropDown(cellInfo) {
    return (

       <div>
          <select id="plan"
                  tabIndex={ cellInfo.index }
                  disabled={ this.state.data[cellInfo.index].total>0?'disabled': '' }
                  className={ `form-control font-weight-bold 
                              ${this.state.data[cellInfo.index].total>0?'disabled-select ':''}
                              ${String(this.state.data[cellInfo.index][cellInfo.column.id])=='1'?' text-secondary ':''}
                              ${String(this.state.data[cellInfo.index][cellInfo.column.id])=='2'?' text-primary ':''}
                              ${String(this.state.data[cellInfo.index][cellInfo.column.id])=='3'?' text-info ':''}`
                  }
                  ref={node => {
                    this['plan'+cellInfo.index] = node;
                    if(this['plan'+cellInfo.index]){
                      this['plan'+cellInfo.index].value = String(this.state.data[cellInfo.index][cellInfo.column.id]);
                    }
                  }}
                  onChange={(e)=>{
                     if (this['plan'+cellInfo.index]){
                        const data = [...this.state.data];
                        data[cellInfo.index][cellInfo.column.id]=Number(this['plan'+cellInfo.index].value);
                        this.setState({ data: data });
                     }

                    }} >
                <option  value="1" disabled="disabled" className="select-text-default">
                    APROBADO
                </option>
                <option  className="texto-general" value="2" >
                    REPROBADO
                </option>
                <option  className="texto-general" value="3">
                    AUSENTE
                </option>
            </select>
            
      </div>

    );
  }

  renderEditable(cellInfo) {
    return (
       <Textbox
          tabIndex={cellInfo.index}
          type="number" // Optional.[String].Default: "text". Input type [text, password, number].
          value={this.state.data[cellInfo.index][cellInfo.column.id] } // Optional.[String].Default: "".
          placeholder="Escriba una calificación" // Optional.[String].Default: "".
          classNameInput="text-right form-control input-nota"
          onChange={(e)=>{
            const data = [...this.state.data];
            data[cellInfo.index][cellInfo.column.id] = e;
            this.setState({ data });
          }} // Required.[Func].Default: () => {}. Will return the value.
          onBlur={(e) => {
             // this.props.onCommit()
             //console.log(this.state.data[cellInfo.index][cellInfo.column.id])
          }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
          validate={this.state.validate}
          validationCallback={(res) =>
            this.setState({ hasNameError: res, validate: false })}
          validationOption={{
            type: 'number',
            name: 'Name', // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
            check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
            require: true,
           //Optional.[Func].Default: none. Return the validation result
            customFunc: res => {
                  //Optional.[Func].Default: none. Custom function. Returns true or err message
                  
                  if (res == "" || res== null  ) {
                    return "La calificación no puede estar vacia";
                  }
                  else if(Number(res) == NaN){
                    return "Solamente valores numéricos"
                  }
                  else if(!Number.isInteger(Number(res))){
                    return "Solamente valores enteros"
                  }
                  else if (Number(res)<0)
                  {
                    return "La calificación mínima es 10"
                  }
                  else if(Number(res)>100){
                    return "La calificación máxima es 100"
                  }
                  return true;
            }
          }}
        />
    );
  }

   renderEditableText(cellInfo) {
    return (
       <Textbox
      // Optional.[String or Number].Default: -1.
          tabIndex={cellInfo.index}
          value={this.state.data[cellInfo.index][cellInfo.column.id]?this.state.data[cellInfo.index][cellInfo.column.id]:''} // Optional.[String].Default: "".
          placeholder="Escriba una justificacion" // Optional.[String].Default: "".
          classNameInput="form-control input-nota"
          onChange={(e)=>{
            //console.log(e)
            const data = [...this.state.data];
            data[cellInfo.index][cellInfo.column.id] = e;
          this.setState({ data });
          }} // Required.[Func].Default: () => {}. Will return the value.

          onBlur={(e) => {
             // this.props.onCommit()
            // console.log(this.state.data[cellInfo.index][cellInfo.column.id])
          }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
          
          validationOption={{
            name: 'Justificación', // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
            check: false, // Optional.[Bool].Default: true. To determin if you need to validate.
            require: false,
          }}
        />
    );
  }
  validarData(e, id=0, cerrar=false){
    e.preventDefault();
    this.setState({validate: true})
    if(this.state.hasNameError)
      return false
    else if(this.state.data.length){
      for (var i = 0; i < this.state.data.length; i++) {
          
          if(this.state.data[i].total>100){
            //console.log("Un valor mayor a 10")
            return false;
          }
          //SI LA TABLA TIENE UN TAMAÑO DE COLUMNAS MENOR A 3 ES POR QUE NO HAY TAREAS
          const minCols = this.props.me.rol==1? 5:6;
          if(this.state.columns.length<minCols){
            this.setState({noTareas: true})
            //no son suficientes columnas
            return false;
          }
            
            
           //SE SALTA LAS COLUMNAS DE ALUMNO=0, CARNET=1 Y ESTADO=LENGTH-1
           //y TOTAL= LENGTH-2 si es rol maestro y LENGTH-3 SI ES DE REGISTRO
           const n = this.props.me.rol==1? 2:3
           for (var j = 2; j < this.state.columns.length-n; j++) {
              //console.log(this.state.columns[j])
              let key = this.state.columns[j].accessor;
              if(key != 'justificacion' && (this.state.data[i][key]== null || this.state.data[i][key]== undefined || this.state.data[i][key]=== "")){
               // console.log("sin valor")
                return false
              }
           }
          
      }
    }
    else
    {
      this.setState({noInscritos: true})
      return false;
    }
    if(!cerrar)
      this.props.create(this.state.data, this.state.columns, this.props.id);
    else
       this.props.cerrar(this.state.data, this.state.columns, this.props.id);
  }
  //INICIALIZAR COLUMNAS Y FILA PARA FORM
  componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
    if (this.props.data_tareas !== prevProps.data_tareas) {
      let columnas =[];
      let datos = _.cloneDeep(this.props.data_tareas);

      datos.forEach((data, index)=>{
          if(index>1){
            data.id = data.accessor;
            data.accessor = String(data.id);
            data.Cell= this.renderEditable
            data.headerClassName = "header-table-notas rotate"
            data.className = "text-right"
        }
          columnas.push(data);
      });
      columnas.push({ 
        Header: 
        "Total", 
        id: "nota",
        headerClassName:"header-table-notas d-flex align-items-center",
        className: "text-right d-flex my-auto justify-content-end",
        accessor: d =>
         {  
          let total = this.getTotal(d)
          return(
          <div>
              <div
                className={`${total>=70?' text-secondary ':'text-primary'} font-weight-bold`}
                dangerouslySetInnerHTML={{
                  __html: total
                }}
              />
              { total > 100 &&
                <div className="total-no-valido">
                 No puede ser mayor que 100
                </div>
              }
            </div>
          )
        }
      });
      columnas.push({ Header: "Estado", accessor: "estado_nota", headerClassName:"header-table-notas d-flex align-items-center", minWidth: 200, Cell: this.renderDropDown})
      if(this.props.me.rol ==0 || this.props.me.is_superuser ){
         columnas.push({ Header: "Observaciones", accessor: "justificacion", headerClassName:"header-table-notas", minWidth: 200, Cell: this.renderEditableText})
      }
      this.setState({columns: columnas})
    }
    if (this.props.data_notas !== prevProps.data_notas ) {
      this.setState({data: this.props.data_notas})
    }
    //CUANDO SE AGREGA UNA NUEVA TAREA
     if (this.props.nueva_tarea !== prevProps.nueva_tarea ) {
      this.AgregarColumnas(this.props.nueva_tarea)
    }
    
  }


  componentWillUnmount(){
    this.props.resetArreglos();
  }


  //OBTENER LA SUMATORIA DE TODAS LAS COLUMNAS
  getTotal(d){
    let data_row = _.cloneDeep(d);
    delete data_row.pk
    delete data_row.asignacion;
    delete data_row.alumno
    delete data_row.carnet
    delete data_row.detalleNotas
    delete data_row.total
    delete data_row.estado_nota
    delete data_row.nota
    delete data_row.justificacion
    const notas =  _.values(data_row);
    let total = 0; 
    notas.forEach((nota, index)=>{
        total += Number(nota);
    })
    d.total = total;
    //CAMBIANDO EL SELECT DE ESTADO NOTA
    if(total>=70){
      d.estado_nota = "1"
    }
    else if(total>0){
      d.estado_nota = "2"

    }
    return total
  }
  //AGREGAR COLUMNAS
  AgregarColumnas(tarea){
    let key = String(tarea.pk);
    let columns_temp = _.cloneDeep(this.state.columns)
    const col ={
            Header: tarea.nombre,
            headerClassName:"header-table-notas rotate",
            accessor: key,
            className: "text-right",
            Cell: this.renderEditable
          }
    columns_temp.splice((columns_temp.length-2), 0, col)
    this.setState({columns: columns_temp, noTareas:false})
    //seteando todos los valores a 0;
    for (var i = 0; i < this.state.data.length; i++) {          
      this.state.data[i][key]=0;
    }
  }


  //Para mostrar el modal
  openModal(){
     this.props.setToggleModal(true);
  }
  closeModal(){
     this.props.setToggleModal(false);
  }


  componentDidMount(){
  }


      render(){
       

        const { create, editar, data_acta, data_inscritos, me, loading} = this.props
        return (
            <div  className="col-12 px-0">
                { this.props.toggleModal && (
                    <Modal open={this.props.toggleModal} onClose={this.closeModal} >
                        <div  style={{ Width: '100%' }}>
                            <div className="modal-header">
                                <div className="panel-body">
                                  
                                </div>
                            </div>
                            <div className="modal-body">
                                <TareaForm closeModal={this.closeModal} 
                                              onSubmit={ this.props.createTarea }/>
                            </div>
                        </div>
                    </Modal>
                )}
                <Titulo  titulo='NOTAS '  noDivisor={ true }/>
                <div className="row mx-0 ">
                  <div className="col-12 text-left">
                    <h6 className="subtitulo text-info font-weight-bold">
                       { data_acta?data_acta.carrera:'' }
                    </h6>
                  </div>
                </div>
                <div className="row mx-0 ">
                  <div className="col-md-5  col-sm-12 text-sm-center text-md-left">
                    <h5 className="subtitulo text-info font-weight-bold">
                     { data_acta?data_acta.curso:'' }
                    </h5>
                  </div>
                  { (me.rol ==0 || me.is_superuser ) &&
                    <div className="col-md-3 pt-sm-3 pt-md-0 col-sm-6 text-center  subtitulo">
                        <span className="mr-2">
                            Catedrático:
                        </span>
                        <span className="mr-3  text-info font-weight-bold ">
                            { data_acta?data_acta.docente:'' }
                        </span>
                    </div>
                  }
                  <div className="col-md-4  col-sm-6 text-center subtitulo">
                    <div>
                        <span className="mr-2">
                            Ciclo:
                        </span>
                        <span className="mr-3  text-info font-weight-bold ">
                            { data_acta?data_acta.ciclo:'' }
                        </span>
                        <span className="mr-2">
                            Sección:
                        </span>
                        <span className="text-info font-weight-bold">
                            { data_acta?data_acta.seccion:'' }
                        </span>
                    </div>
                  </div>
                  { me.rol ==1 &&
                    <div className="col-md-3 pt-sm-3 pt-md-0 col-sm-6 text-sm-center text-md-right pr-lg-5 subtitulo">
                      <div className="">
                        <button className="btn btn-info px-1 ml-auto"
                          style={{paddingLeft:'0.5em !important', paddingRight: '0.5em !important'}}
                          onClick={(e) => {
                                    e.preventDefault();
                                    printActions.print('asistencia')
                                 }}>
                                 Imprimir Hoja Asistencia
                        </button>
                      </div>
                    </div>
                  }
                </div>
                <div className="py-3 background-secondary my-4"></div>
                <div className="row mx-0 ">
                  <ListadoAsistencia data_acta={ data_acta }
                                     data_inscritos={ data_inscritos }/>
                
                </div>
                <form onSubmit={this.validarData} className="row d-flex justify-content-center ">
                    <div className="form-group grid-container col-12 px-lg-5">
                        <div className="grid-titulo padding-15">
                            <div className="padding-15 p-sm-0 py-sm-1">
                                { me.rol ==1 &&
                                  <div className="row d-flex mx-0 mb-4">
                                    <button
                                      className="btn btn-secondary  ml-auto px-2" 
                                      type="button"
                                      onClick={this.openModal}>
                                      <i className="fa fa-plus mr-1" aria-hidden="true"></i>
                                        Agregar Tarea
                                    </button>
                               
                                    <button className="ml-3 btn btn-primary"
                                              type="button"
                                              onClick={(e) => {

                                                    this.validarData(e,this.props.id, true);
                                              }}>
                                                 Cerrar Acta
                                    </button>
  
                                  </div>
                                }
                              <LoadMask loading={loading}  dark blur>
                                 <ReactTable
                                    data={this.state.data}
                                    defaultPageSize={ 500 }
                                    columns={this.state.columns}
                                    className="-striped -highlight"
                                    showPagination={ false }
                                    minRows={2}
                                    noDataText='No hay alumnos inscritos'
                                    sortable={false}
                                  />
                                </LoadMask>
                                { this.state.noTareas &&<div>
                                    <span className="total-no-valido">Debe haber al menos una tarea</span>  
                                </div>
                                }
                                { this.state.noInscritos &&<div>
                                    <span className="total-no-valido">No hay alumnos inscritos</span>  
                                </div>
                                }
                                  <div className="row d-flex">
                                  
                                    <button
                                      className="btn btn-primary mt-4 mx-auto" 
                                      type="submit"
                                      onClick={this.validarData}>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
      )
    }

}



export const CursoDocenteForm = reduxForm({
    form: 'docente',
    validate: data => {
        return validate(data, {
            'username': validators.exists()('Campo Requerido'),
        })
    }
})(Form)


export default CursoDocenteForm



