import { createAction, handleActions } from 'redux-actions';
import { api } from '../../utility/api';
import Swal from 'sweetalert2'; 
const INITIAL = 'INITIAL';
const SUBMIT = 'SUBMIT';
const NAME_ERROR = 'NAME_ERROR';
const PASS_ERROR = 'PASS_ERROR';
const LOGIN_LOADER = 'LOGIN_LOADER';
const SUBMIT_ERROR = 'SUBMIT_ERROR';
const ME = 'ME';
const RECUPERAR_ERROR = 'RECUPERAR_ERROR';
const RECUPERAR = 'RECUPERAR';
const ENVIADO = 'ENVIADO';
const CORREO = 'CORREO';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Actions
// ------------------------------------
/* Funcion para simular el login, ELIMINAR */


export const onSubmit = (data = {}) => (dispatch, getStore) => {
    let canLog = true;
    dispatch({ type: LOGIN_LOADER, loader: true });
    dispatch({ type: SUBMIT_ERROR, submitError: false });
    if (data.username === "" || data.username === undefined) {
        canLog = false;
        dispatch({ type: LOGIN_LOADER, loader: false });
    }
    if (data.password === "" || data.password === undefined) {
        canLog = false;
        dispatch({ type: LOGIN_LOADER, loader: false });
    }
    if (canLog) {

        api.post("usuarios/token/", data)
            .catch(() => {
                dispatch({ type: LOGIN_LOADER, loader: false });
                dispatch({ type: SUBMIT_ERROR, submitError: true });
                return false;
                //ToastStore.error('Verifique que ha ingresado de forma correcta su nombre de usuario y contraseña.');
            })
            .then(resp => {
                if (resp) {
                    localStorage.setItem("token", resp.token);
                    dispatch({type: ME, me: resp.me });
                    dispatch({ type: SUBMIT, autenticado: true });
                    dispatch({ type: LOGIN_LOADER, loader: false });
                }
            });
        ///fin peticiòn api
    } else {
        dispatch({ type: LOGIN_LOADER, loader: false });
        dispatch({ type: SUBMIT, autenticado: false });
    }
};


export const enviarDeNuevo = ()=> (dispatch, getState)=>{
    
    dispatch({ type: ENVIADO, enviado: false })
    dispatch({ type: CORREO, correo: '' })
    dispatch({ type: RECUPERAR_ERROR, recuperarError: '' })
    //console.log(getState())

}

export const recuperar_contrasenia = ()=>  (dispatch, getState) =>{
    dispatch({ type: LOGIN_LOADER, loader: true });
    const formData = getState().form.recuperar.values
    console.log(formData);
    const params = {email: formData.email}
    Swal({
         title: 'Cargando',
         text: "Espere un momento.",
         allowOutsideClick: false,
         onOpen: () => {
           Swal.showLoading();
         }
       });
      api.get('usuarios/recuperar_contrasena/',params)
        .then(response => {
          if(response && response.detail == 'OK'){
            dispatch({ type: ENVIADO, enviado: true })
            dispatch({ type: CORREO, correo: formData.email })
            //console.log("enviado")
            
          }
        Swal.close();
        })
        .catch(e => {
          let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
           Swal.close();
          if(e.body && e.body.detail)
            mensaje = e.body.detail;
            dispatch({ type: RECUPERAR_ERROR, recuperarError: mensaje });
            //console.log('error', e)
        })
        .finally(() => {
         dispatch({ type: LOGIN_LOADER, loader: false });
        })
}





export const getMe = () => async (dispatch, getState) => {
     

    try 
    {
        let usuario = await api.get('usuarios/me/')
        dispatch({type: ME, me:usuario });
       
    } 
    catch(e) 
    {
       console.error(e, "error")
       localStorage.removeItem("token");
    } 
    finally 
    {
       dispatch({ type: LOGIN_LOADER, loader: false });
    }

}

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
    dispatch({ type: SUBMIT, autenticado: false });
};

export const setMe = me => ({
  type: ME,
  me,
});

export const initialLoad = createAction(INITIAL);
export const hasNameError = nameError => ({
    type: NAME_ERROR,
    nameError,
});
export const hasPassError = passError => ({
    type: PASS_ERROR,
    passError,
});


export const actions = {
    initialLoad,
    hasNameError,
    hasPassError,
    onSubmit,
    logOut,
    recuperar_contrasenia,
    enviarDeNuevo,
};

export const reducers = {
    [INITIAL]: (state) => {
        const token = localStorage.getItem('token');
        if (token) {
            return {
                ...state,
                redirect: true,
            };
        }
        return {
            ...state,
            redirect: false,
        };
    },
    [SUBMIT]: (state, { autenticado }) => {
        return {
            ...state,
            autenticado,
        };
    },
    [NAME_ERROR]: (state, { nameError }) => {
        return {
            ...state,
            nameError,
        };
    },
    [PASS_ERROR]: (state, { passError }) => {
        return {
            ...state,
            passError,
        };
    },
    [LOGIN_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SUBMIT_ERROR]: (state, { submitError }) => {
        return {
            ...state,
            submitError,
        };
    },
    [ME]: (state, { me }) => {
        return {
          ...state,
          me,
        };
    },
    [RECUPERAR]: (state, { recuperar }) => {
        return {
          ...state,
          recuperar,
        };
    },
    [RECUPERAR_ERROR]: (state, { recuperarError }) => {
        return {
          ...state,
          recuperarError,
        };
    },
    [CORREO]: (state, { correo }) => {
        return {
          ...state,
          correo,
        };
    },
    [ENVIADO]: (state, { enviado }) => {
        return {
          ...state,
          enviado,
        };
    },

};

export const initialState = {
    submitError: false,
    passError: false,
    nameError: false,
    autenticado: false,
    loader: false,
    recuperar: false,
    recuperarError: null,
    enviado: false,
    correo: '', 
    me: {},
};

export default handleActions(reducers, initialState);

