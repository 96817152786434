import React, { Component } from 'react';

class Search extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const { buscar, buscador } = this.props;
    return (
        <div className="col-12">
          {(buscador !== undefined && buscar !== undefined) && (
           <div className="d-flex flex-row">
            <div className="p-2">
              <label>Carnet</label>
                <input id="buscar" type="text" name="buscar" placeholder="Buscar..."
                       ref={node => {
                         this.buscar = node;
                         if (this.buscar) {
                           this.buscar.value = buscador;
                         }
                       }}
                       onKeyPress={(event) => {
                         if (event.key === 'Enter') {
                           this.props.buscar(this.buscar.value);
                         }
                       }}
                       autoComplete="off" className="form-control"/>
            </div>
            <div className="p-2 pl-4 d-flex align-items-end">
              <button
                  className="btn btn-primary"
                  onClick={(event) => {
                      event.preventDefault();
                      this.props.buscar(this.buscar.value);
                  }}>
                    Buscar
              </button>
            </div>
          </div>
          )}
        </div>
    );
  }
}

export default Search;
