import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField } from 'Utils//renderField/renderField'
import { email } from '../../../utility/validation';





class Form extends React.Component{

   
  render(){
    console.log(this.props)
     const { handleSubmit } = this.props

      return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h5 className="mb-1 mt-2 text-primary">
                        <strong>RECUPERAR CONTRASEÑA</strong>
                    </h5>

                     <p>
                        Proporcion el sigueinte dato para comenzar:
                     </p>
                    <div className="padding-15 p-sm-0 py-sm-1 mt-4">
                        <div className="row">
                            <div className="form-group col-sm-12 col-md-12">
                                <label htmlFor="email">Correo Electrónico</label>
                                <Field  name="email" 
                                        component={renderField} 
                                        type="email" 
                                        placeholder="Ingresa tu correo"
                                        className="form-control" 
                                        validate={email}/>
                            </div>
                        </div>
                        
                       
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <button type="submit" className="btn btn-primary m-1">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
  }



}



const RecuperarForm = reduxForm({
    form: 'recuperar',
    validate: data => {

        return validate(data, {
            'email': validators.exists()('Campo Requerido')
        })
    }
})(Form)

export default RecuperarForm
