import React, { Component } from 'react'
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import DatePicker from 'react-date-picker';

export const renderField = ({ label, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input  placeholder={label} type={type}
                disabled={disabled?'disabled':''}
                className={classNames('form-control', { 'is-invalid': invalid })} />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};

export const RenderSearchSelect = ({ valor, onChange, placeholder, clearable, disabled, loadOptions, valueKey, labelKey, defaultOptions }) => {
    
    return (
        <div>
            <AsyncSelect clearable={clearable} disabled={disabled}  className='form-control p-0 select-reset'
                   classNamePrefix="react-select"
                   onChange={(e) => { 
                        onChange(e[valueKey] ? e : ''); 
                    }}
                     
                    theme={(theme) => {
                        return {
                      ...theme,
                      colors: {
                      ...theme.colors,
                        text: '#415362',
                        primary25: '#1687a7bd;',
                        primary: '#1687a7',
                      },
                    }
                    }}
                    value={valor && valor[valueKey] != ""?valor:null}
                    classNamePrefix="react-select"
                    cache={false} 
                    placeholder='Escriba para buscar'
                    defaultOptions={ true }
                    searchPromptText="Escriba para buscar" 
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}
                    loadOptions={loadOptions} />
        </div>
    )
};
export const RenderNoAsyncSelectField = ({ valor, placeholder, onChange,  clearable, disabled, options, valueKey,isMulti, labelKey}) => {
    
    return (
        <div>
            <Select clearable={clearable} disabled={disabled} 
                    className='form-control p-0 select-reset'
                    isMulti={ isMulti }
                    onChange={(e) => { 
                        onChange(e[valueKey]? e : ''); 
                    }}
                    theme={(theme) => {
                        return {
                            ...theme,
                            colors: {
                              ...theme.colors,
                                text: '#415362',
                                primary25: '#1687a7bd;',
                                primary: '#1687a7',
                                primary50: "#1687a742",
                            },
                        }
                    }}
                    value={valor && valor[valueKey] != ""?valor:null}
                    classNamePrefix="react-select"
                    cache={false} 
                    placeholder= 'Escriba para buscar'
                    key={valueKey}
                    defaultOptions
                    options={ options }
                    defaultOptions
                    searchPromptText="Escriba para buscar" 
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}/>
        </div>
    )
}; 

export class RenderDateField extends Component {
  state = {
    date: new Date(),
  }
    onChange = date => {
        this.props._onChange(new Date(date))

        this.setState({ date })
    }
    componentDidMount() {
        const fecha = new Date(this.props.valor)
        if(typeof fecha.getMonth === 'function'){
            this.setState({date: fecha})
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.valor != prevProps.valor){
            this.setState({date:  new Date(this.props.valor)})
        }
    }

 
  render() {
    return (
      <div className="filtro">
        <DatePicker
            onChange={this.onChange}
            locale='es-GT'
            value={this.state.date}/>
      </div>
    );
  }
}
