import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2'; 
import _ from "lodash";

//  CREAR EQUIVALENCIAS
export const create = () => (dispatch, getState) => {
  const store = getState().certificacion;
  const formData = getState().form.certificacion.values
  dispatch(loading());
  api.post('equivalencias/', normalizarObjeto(formData, store))
    .then(response => {
      dispatch(toggleModal(false))
      dispatch(getDataCertificacion(store.dataAlumno.pk, store.dataPensum.pk ))

    })
    .catch(e => { 
      console.log(e)
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.non_field_errors)
        mensaje = e.non_field_errors[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}

const normalizarObjeto = (data, store)=>{
    let equivalencia =  _.cloneDeep(data);
    equivalencia.curso = equivalencia.curso.value;
    equivalencia.ciclo = equivalencia.ciclo.pk;
    equivalencia.alumno = store.dataAlumno.pk;
    equivalencia.pensum = store.dataPensum.pk;
    return equivalencia;
}




//BUSCAR ALUMNO POR CARNET Y TRAER INFO PERSONAL Y DE PENSUMS
export const search = (search) => (dispatch) => {
    let buscador = search.replace(" ", "");
      dispatch(setBuscadorCertificacion(buscador));
    
    dispatch(getAlumno());
    dispatch(getDataPensums());
}

//OBTENER INFORMACIÓN DEL ALUMNO POR CARNET
export const getAlumno = () => async (dispatch, getState) => {
  dispatch(loading())
  const store =getState()
  let carnet = store.certificacion.buscador_certificacion;
  try {
    const response = await  api.get(`alumnos/carnet/${carnet}`)
    dispatch(setDataAlumno(response))
  } catch(e) {
    let mensaje = "Ha ocurrido un error, por favor vuelva a intentar"
    if(e.body && e.body.detail)
      mensaje = e.body.detail;
    if(e.body && e.body.length)
      mensaje = e.body[0];
     Swal(
                'Error',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}


//OBTENER INFORMACIÓN DEL ALUMNO POR PK
export const getAlumnoById = (pk) => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const response = await  api.get(`alumnos/${pk}`)
    dispatch(setDataAlumno(response))
  } catch(e) {
    console.log(e)
    let mensaje = "Ha ocurrido un error, por favor vuelva a intentar"
    if(e.body && e.body.detail)
      mensaje = e.body.detail;
    if(e.body && e.body.length)
      mensaje = e.body[0];
     Swal(
                'Error',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}


//OBTENER UN PENSUM POR ID
export const getPensumById = (pk) => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const response = await  api.get(`pensums/certificacion/${pk}`)
    dispatch(setDataUnPensum(response))
  } catch(e) {
    console.log(e)
    let mensaje = "Ha ocurrido un error, por favor vuelva a intentar"
    if(e.body && e.body.detail)
      mensaje = e.body.detail;
    if(e.body && e.body.length)
      mensaje = e.body[0];
     Swal(
                'Error',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

//LISTADO DE TODOS LOS PENSUMS EN LOS QUE EL ALUMNO HA ESTADO ASIGNADO
export const getDataPensums = () => async (dispatch, getState) => {
  dispatch(loading())
  const store =getState()
  let carnet = store.certificacion.buscador_certificacion;
  try {
    const response = await  api.get(`pensums/carnet/${carnet}`)
    dispatch(setDataPensums(response))
  } catch(e) {
    console.log(e)
    let mensaje = "Ha ocurrido un error, por favor vuelva a intentar"
    if(e.body && e.body.detail)
      mensaje = e.body.detail;
    if(e.body && e.body.length)
      mensaje = e.body[0];
     Swal(
                'Error',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}


//LISTADO DE CURSOS PARA LA CERTIFICACIÓN
export const getDataCertificacion = (alumno, pensum) => async (dispatch, getState) => {
  dispatch(loading())
  const store =getState()
  let carnet = store.certificacion.buscador_certificacion;
  try {
    const response = await  api.get(`equivalencias/certificacion/${alumno}/${pensum}/`)
    dispatch(setDataCertificacion(response))
  } catch(e) {
    console.log(e)
    let mensaje = "Ha ocurrido un error, por favor vuelva a intentar"
    if(e.body && e.body.detail)
      mensaje = e.body.detail;
    if(e.body && e.body.length)
      mensaje = e.body[0];
     Swal(
                'Error',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}


//OBTENCIÓN DE DATA PARA LAS FIRMAS
export const getCoordinador = () => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const response = await  api.get(`docentes/coordinador_academico/`)
    dispatch(setCoordinador(response))
  } catch(e) {
    let mensaje = "Ha ocurrido un error, por favor vuelva a intentar"
    if(e.body && e.body.detail)
      mensaje = e.body.detail;
     Swal(
                'Error',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

export const getFirmas = () => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const response = await  api.get(`configuraciones/${1}`)
    dispatch(setConfiguracion(response))
  } catch(e) {
    let mensaje = "Ha ocurrido un error, por favor vuelva a intentar"
    if(e.body && e.body.detail)
      mensaje = e.body.detail;
     Swal(
                'Error',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}


//PARA EL MODAL
export const setToggleModal = (estado) => (dispatch, getState) => {
    dispatch(toggleModal(estado))
}

export const {  loading, 
                setDataCertificacion, 
                setPage,setUpdateData, 
                setDataAlumno, 
                setBuscadorCertificacion, 
                setDataPensums,
                toggleModal,
                setDataUnPensum,
                setCoordinador,
                setConfiguracion,
} = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA_CERTIFICACION': (data) => ({ data }),
  'SET_DATA_PENSUMS': (data_pensums) => ({ data_pensums }),
  'SET_DATA_UN_PENSUM': (dataPensum) => ({ dataPensum }),
  'SET_DATA_ALUMNO': (dataAlumno) => ({ dataAlumno }),
  'SET_COORDINADOR': (coordinador_academico) => ({ coordinador_academico }),
  'SET_CONFIGURACION': (configuracion) => ({ configuracion }),
  'SET_PAGE': (page) => ({ page }),
  'SET_BUSCADOR_CERTIFICACION':(buscador_certificacion)=>({ buscador_certificacion}),
  'TOGGLE_MODAL':(toggleModal)=>({ toggleModal }),
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setDataCertificacion]: (state, { payload: { data }}) => ({ ...state, data }),
  [setDataPensums]: (state, { payload: { data_pensums }}) => ({ ...state, data_pensums }),
  [setDataUnPensum]: (state, { payload: { dataPensum }}) => ({ ...state, dataPensum }),
  [setDataAlumno]: (state, { payload: { dataAlumno }}) => ({ ...state, dataAlumno }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setBuscadorCertificacion]: (state, { payload: { buscador_certificacion }}) => ({ ...state, buscador_certificacion }),
  [setCoordinador]: (state, { payload: { coordinador_academico }}) => ({ ...state, coordinador_academico }),
  [setConfiguracion]: (state, { payload: { configuracion }}) => ({ ...state, configuracion }),
  [toggleModal]: (state, { payload: { toggleModal }}) => ({ ...state, toggleModal }),
}

export const initialState = {
  //ENCABEZADO EN GRID
  dataAlumno: {username:'', first_name: '', cui: '', pk:0},
  dataPensum: {codigo:'', display_carrera: '', pk:0},
  page: 1,
  //DATA DE LAS CERTIFICACIONES
  data:[],
  //CARRERAS Y PENSUMS DEL ALUMNO
  data_pensums:[],
  buscador_certificacion: '',
  loading: false,
  updateData: {},
  //MOSTRAR UNA VENTANAN MODAL
  toggleModal: false,
  nueva_tarea: {},
  //DATOS PARA LAS FIRMAS
  coordinador_academico:{first_name: '', last_name: '', titulo: ''},
  configuracion:{director: '', sexo_director: false, control_academico: ''},
}

export default handleActions(reducers, initialState)