import React from 'react'
import Form from '../Create/AsignacionForm'
import { Layout } from '../layout';
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Create extends React.Component {

  constructor(props) {
        super(props);
  }

  componentWillMount() {
    //
    this.props.getInscripcion(this.props.match.params.inscripcion)
    //this.props.load2Update(this.props.match.params.inscripcion)
    this.props.getCiclos(this.props.match.params.inscripcion, this.props.match.params.ciclo)
  }
  componentDidMount(){
    this.callLoad2Update = this.callLoad2Update.bind(this)
  }

  callLoad2Update(){
    this.props.load2Update(this.props.match.params.inscripcion)
  }



  render () {
    const { onSubmit, me, inscripcion, ciclos_asignacion, updateData, setCerrarAsignacion, loading} = this.props

    return (
      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
            <Form onSubmit={onSubmit} 
                  editar={ true } 
                  inscripcion={ inscripcion } 
                  ciclos={ ciclos_asignacion } 
                  me={me}
                  setCerrarAsignacion={setCerrarAsignacion}
                  callLoad2Update={ this.callLoad2Update }
                  data={updateData}/>
        </div>
      </LoadMask>
    )
  }
}
