import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/bitacora";
import BitacoraGrid from "./BitacoraGrid";


const ms2p = (state) => {
    return {
        ...state.bitacora,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(BitacoraGrid);