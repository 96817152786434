import React from 'react'
import Form from './ActasEspecialesForm'
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Create extends React.Component {

  componentDidMount(){
    let ciclo = this.props.match.params.ciclo; 
    let pensum = this.props.match.params.pensum; 
    let extension = this.props.match.params.extension; 
    let coordinacion = this.props.match.params.coordinacion; 
    let cohorte = this.props.match.params.cohorte;
    this.props.todosFiltros(coordinacion, extension, pensum, ciclo, cohorte);
  }

  render () {
    const { createEspecial, coordinacion__extension, coordinacion, pensum, ciclo, cohorte, loading} = this.props

    return (
      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
        	<div className="col-md-12 col-sm-12">
             <Form onSubmit={createEspecial}
                  coordinacion__extension={ coordinacion__extension }
                  coordinacion= {coordinacion }
                  pensum={ pensum }
                  ciclo={ ciclo }
                  idPensum={this.props.match.params.pensum}
                  cohorte={ cohorte }/>
          </div>
        </div>
      </LoadMask>
    )
  }
}

