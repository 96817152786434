import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './toolbar.css';
import Select from 'react-select';
import Search from './Search';
import classNames from 'classnames';
class Toolbar extends Component  {
    render() {
       const { titulo, buscar, buscador, boton, upload} = this.props;
        return(
            <div className="col-12 row pb-2 pt-5 pl-3">
                <div className="col-xl-3  col-lg-6 titulo">
                    <h1 className="ml-0 text-uppercase text-center text-md-left"><strong>{this.props.titulo}</strong></h1>
                </div>
                   <div className="col-lg-3 col-xl-3 text-center text-lg-right  mb-3">
                     
                     <div className="fileUpload btn btn-info ml-0 ml-sm-auto  flex-nowrap flex-sm-wrap ">
                            <span>Importar alumnos</span>
                            <input
                            className={classNames('upload')}
                            type="file"
                            accept=".csv" 
                            onChange={(e, file) => {
                            file = file || e.target.files[0];
                            upload(file)
                            e.target.value = null;

                        }}/>
                    </div>
                </div>
                <div className="col-lg-3 col-xl-3 text-center mb-3">
                    <Link   style={{paddingLeft: "1em !important", paddingRight: "1em !important"}}
                        className="btn btn-primary ml-0 ml-sm-auto px-1 flex-nowrap flex-sm-wrap" 
                            to={`/${ titulo }/crear`}> { boton }
                    
                    </Link>
                </div>
               
                <div className="col-lg-6 col-xl-3 text-right search mb-1">
                    {(buscar !== undefined) && (
                    <Search buscar={buscar} buscador={buscador}/>
                    )}
                </div>
            </div>

        )
    }
}

export default Toolbar;
