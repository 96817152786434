import React from 'react'
import PropTypes from 'prop-types'
import Form from '../Create/ActasForm'
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Update extends React.Component {
  static propTypes = {
    update: PropTypes.func.isRequired,
    load2Update: PropTypes.func.isRequired
  }

  componentWillMount() {

    let ciclo = this.props.match.params.ciclo; 
    let pensum = this.props.match.params.pensum; 
    let extension = this.props.match.params.extension; 
    let coordinacion = this.props.match.params.coordinacion; 
    let cohorte = this.props.match.params.cohorte;
    this.props.todosFiltros(coordinacion, extension, pensum, ciclo, cohorte);
    //ciclo, coordinacion, pensum, cohorte
    this.props.load2Update(ciclo, coordinacion, pensum, cohorte);
    this.props.findBorrables(ciclo, coordinacion, pensum, cohorte);
  }

  render() {
    const { update, 
            updateData, 
            coordinacion__extension, 
            coordinacion, 
            pensum, 
            ciclo, 
            cohorte,
            borrables,
            loading
          } = this.props;

    return (
      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 col-sm-12"> 
            <Form onSubmit={update} editar={true} 
                  coordinacion__extension={ coordinacion__extension }
                  coordinacion= {coordinacion }
                  pensum={ pensum }
                  ciclo={ ciclo }
                  idPensum={this.props.match.params.pensum}
                  cohorte={ cohorte }
                  borrables={ borrables }
                  data ={updateData}/>
          </div>
        </div>
      </LoadMask>

    )
  }
}


