import { connect } from 'react-redux'
import Update from './UpdateCiclo'
import { update, load2Update } from '../../../../redux/modules/ciclo'



const mdtp = { update, load2Update };
const mstp = state => state.ciclo;

export default connect(mstp, mdtp)(Update)
