import { connect } from 'react-redux'
import Update from './DocenteUpdate'
import { update, load2Update } from '../../../../redux/modules/docente'



const mdtp = { update, load2Update };
const mstp = state => state.docente;

export default connect(mstp, mdtp)(Update)
