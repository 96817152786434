import { connect } from 'react-redux'
import Create from './AsignacionCreate'
import { create, getInscripcion, getCiclos, load2Update, onSubmit, setCerrarAsignacion} from '../../../../redux/modules/asignacion'

const mdtp = { create, getInscripcion, getCiclos, load2Update , onSubmit, setCerrarAsignacion}
const ms2p = (state) => {
  return {
    ...state.login,
    ...state.asignacion,
  };
};


export default connect(ms2p, mdtp)(Create)
