import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import Search from '../../Utils/Toolbar/SearchCertificaciones';
import Titulo from '../../Utils/Titulo/TituloGrid';


export default class CertificacionesGrid extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired
    };

    componentWillMount() {

        this.BotonFormat = this.BotonFormat.bind(this);
    }
     BotonFormat(cell, row) {
      if(cell &&  this.props.dataAlumno.pk ){
           return ( 
            <Link to={ `/certificaciones/ver/${ cell }/${this.props.dataAlumno.pk}` } className="btn btn-primary">
               Ver Certificación
           </Link>
           )
       }
       else
            return ''
       
}

    render() {
        const { data, loading, page, getAlumno,  buscador_certificacion, dataAlumno, data_pensums } = this.props;

        const { destroy, search, getDataPensums} = this.props;

        return (

            <div className="row  d-flex justify-content-center">
               <div className="col-sm-12 px-0 pt-2">
                    <div className="grid-container">
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <Titulo titulo={'CERTIFICACIONES'}
                                        noDivisor={true}/>
                            </div>
                            <div className="col-md-6 mt-3">
                                <Search  buscar={search} 
                                         buscador={buscador_certificacion}/>
                            </div>
                        </div>
                        <div className="py-3 background-secondary mb-4">
                        </div>
                        <div className="row px-5 mx-0 d-flex justify-content-center my-5">
                            <div className="col-md-11 col-sm-12">
                                <div className="row mx-0 col-12">
                                    <div className="col-md-3">
                                        <span className="mr-3">Carnet:</span> 
                                        <span className="text-info subtitulo font-weight-bold">{dataAlumno.username}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <span className="mr-3">Alumno:</span> 
                                        <span className="text-info subtitulo font-weight-bold">{dataAlumno.first_name}</span>
                                    </div>
                                    <div className="col-md-3">
                                        <span className="mr-3">CUI:</span> 
                                        <span className="text-info subtitulo font-weight-bold">{dataAlumno.CUI}</span>
                                    </div>
                                </div>
                                 <div className="row mx-0 col-12 mt-5">
                                    <div className="col-12">
                                       <Table  onPageChange={getDataPensums} 
                                            data={ { results: data_pensums } } 
                                            loading={loading} 
                                            page={page} 
                                            noPagination={ true }
                                            noDataText={'No hay datos que mostrar'}>
                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                className="titulo-dias text-info"
                                                dataField="display_carrera" dataSort>Carrera</TableHeaderColumn>
                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                className="titulo-dias text-info"
                                                dataAlign="center"
                                                dataField="codigo" dataSort>Pensum</TableHeaderColumn>
                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                className="titulo-dias text-info"
                                                dataField="pk" isKey dataAlign="center"
                                                dataFormat={this.BotonFormat}/>
                                    </Table>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
        )
    }
}
