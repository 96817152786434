import React from 'react'
import Form from './AlumnoForm'
import LoadMask from "Utils/LoadMask/LoadMask";
export default class Create extends React.Component {
  render () {

    const { create, borrables_inscripcion, loading } = this.props

    return (
      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 col-sm-12">
            <Form onSubmit={create} borrables={ borrables_inscripcion } />
          </div>
        </div>
      </LoadMask>
    )
  }
}

