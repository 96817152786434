import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { api } from "../../../../utility/api";
import _ from "lodash";
import { RenderNoAsyncSelectField } from './FilterFields'


class NotasFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
                carrera_seleccionada: 0, 
                carrera_list:[],
        };
    }
    componentWillMount() {
        this.getCarrera = this.getCarrera.bind(this);
        this.handleCarreraChange = this.handleCarreraChange.bind(this);
    }
    componentDidMount() {
       this.getCarrera();
    }


    getCarrera(){
        return api.get(`carreras/alumno/`)
        .then(data=>{
            if(data){
                console.log(data, "temp")
                this.setState({
                      carrera_list: data
                    })
                return data;
            }
            else
                this.setState({
                  carrera_list: []
                })
                return [];
        })
        .catch(e=>{
           this.setState({
                  carrera_list: []
                })
            return [];
        })
    };

    handleCarreraChange(e){
        if(e == '')
            this.props.filtroCarerra({value: '', label:''});
        else if(e.value !=this.state.carrera_seleccionada){
            this.setState({ carrera_seleccionada: e.value});
            this.props.filtroCarerra(e)
        }
    }



    render() {
      
        
        return (
            <div className="col-12 row pb-2 pl-3 mt-2 mb-3 ">
                <div  className="col row pl-md-4">
                    <div className="form-group col-lg-7 col-sm-12 select-resaltado">
                       <label>Carrera:</label>
                       <RenderNoAsyncSelectField
                            name='carrera'
                            valueKey="value"
                            labelKey="label"
                            onChange = { this.handleCarreraChange }
                            label="Pensums"
                            defaultOptions={ true }
                            valor={this.props.carrera}
                            options={this.state.carrera_list}/>             
                   </div>
                </div>
            </div>

      )
    }

}


export default NotasFilter;

