import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm, FieldArray } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import renderDatePicker from 'Utils//renderField/renderDatePicker'
import { renderField, renderSelectField, renderSearchSelect} from 'Utils//renderField/renderField'
import { api } from "../../../../utility/api";
import { SEXO } from '../../../utility/constants';
import _ from "lodash";
import Titulo from "../../Utils/Titulo/TituloBigForm";

import { esBorrable} from '../../../../utility/validation';

//PARA POBLAR EL SELECT DE CARRERAS
let CARRERAS = [];
const getCarreras = (search='') => {
    return api.get(`carreras/selectpensum`,{value: search} )
    .then(data=>{
        if(data){
            CARRERAS = data;
            return data;
        }
        else
            return []
    })
    .catch(e=>{
        return []
    })
};
const getExtensiones = (search='') => {
    return api.get(`extensiones/select`,{value: search} )
    .then(data=>{
        if(data){
            return data;
        }
        else
            return []
    })
    .catch(e=>{
        return []
    })
};

//FROM PARA AÑADIR CARRERAS
const renderCarreras = ({ borrables, fields, meta: { error, submitFailed } }) => (
    <div className="row">
        <div className="col-sm-12">
             <table className="table table-sm table-hover">
                <thead>
                <tr className="array-header">
                    <th style={{width: "35%"}} >Carrera</th>
                    <th style={{width: "15%"}} >Pensum</th>
                    <th style={{width: "35%"}} >Extensión</th>
                    <th style={{width: "10%"}} >Cohorte</th>
                    <th style={{width: "5%"}} />
                </tr>
                </thead>
                    <tbody>
                
                {
                    fields.map((inscripcion, index) => {
                    
                    //PARA POBLAR EL SELECT DE PENSUMS    
                        let carreraSel = fields.get(index)?fields.get(index).carrera:{};
                        let PENSUMS = carreraSel && carreraSel.pensums?carreraSel.pensums: [];
                        return(
                            <tr key={index} className="my-2">
                                <td >
                                    <Field
                                        name={`${inscripcion}.carrera`}
                                        valueKey="value"
                                        labelKey="label"
                                        component={renderSearchSelect}
                                        label="Carrera"
                                        onChange = {(e)=>{
                                                if(!carreraSel || carreraSel.value != e.value){
                                                    carreraSel = _.find(CARRERAS, {value: e.value});
                                                      PENSUMS.splice(0,PENSUMS.length)
                                                    if(carreraSel)
                                                         carreraSel.pensums.forEach((pensum, i)=>{
                                                            if(!_.find(PENSUMS, {id: e.id}))
                                                                PENSUMS.push(pensum)
                                                            }
                                                        );
                                                }
                                            }
                                         }
                                        loadOptions={getCarreras}
                                       
                                    />
                                </td>
                                <td>
                                    <Field 
                                        name={`${inscripcion}.pensum`}
                                        component={renderSelectField} 
                                        type="text" 
                                        className="form-control p-0 no-border" 
                                        labelKey="codigo" valueKey="id" options={PENSUMS}/>
                                </td>
                                <td>
                                    <Field
                                        name={`${inscripcion}.extension`}
                                        valueKey="value"
                                        labelKey="label"
                                        component={renderSearchSelect}
                                        label="Extension"
                                        loadOptions={getExtensiones} 
                                        className="my-2"
                                       
                                    />
                                </td>
                                <td>
                                     <Field 
                                        name={`${inscripcion}.cohorte`}
                                        component={renderField}
                                        type="number" />
                                </td>
                                <td className="text-center">
                                     <img   onClick={() => {

                                                const inscripcion =fields.get(index);
                                                console.log(inscripcion)
                                                const leyenda =inscripcion.carrera?inscripcion.carrera.label:''
                                                if(esBorrable(inscripcion, borrables, leyenda))
                                                    fields.remove(index)

                                            }}
                                             className="action-img" 
                                             title="Eliminar" 
                                             src={require("../../../../../assets/img/icons/delete.png")} alt="Delete" />
                            
                                </td>
                            </tr>
                            )
                        }
                    )

                }
                 </tbody>
            </table>
               
             <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center mt-3">
                <button type="button" className="btn btn-info  my-sm-auto flex-nowrap flex-sm-wrap" onClick={() => fields.push({})}>
                    Agregar Carrera
                </button>
            </div>
        </div>
    </div>
)
//FIN FORM CARRERAS

//FORM PRINCIPAL (DE EXTENSIONES)

class Form extends React.Component{

   
    constructor(props) {
      super(props);

      this.state = {
          cambiarPwd: false
      };

      this.onClick = this.onClick.bind(this)
  }

  
  onClick(e) {
      this.setState({ cambiarPwd: !this.state.cambiarPwd })
  }
    
    render(){
        const { handleSubmit, editar, borrables } = this.props
        return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <Titulo  editar={editar} titulo='ALUMNO'/>
                     <div className="grid-titulo padding-15">
                            <div className="padding-15 p-sm-0 py-sm-1">
                                <div className="row">
                                        <div className="form-group col-sm-12 col-md-6">
                                            <label htmlFor="first_name">Nombre</label>
                                            <Field name="first_name" component={renderField} type="text" className="form-control" />
                                        </div>
                                        <div className="form-group col-sm-12 col-md-6">
                                            <label htmlFor="username">Carnet</label>
                                            <Field name="username" component={renderField} type="text" className="form-control" />
                                        </div>
                                </div>
                                <div className="row">

                                         <div className="form-group col-sm-12 col-md-6">
                                            <label htmlFor="CUI">CUI</label>
                                            <Field name="CUI" component={renderField} type="text" className="form-control" />
                                           
                                        </div>
                                      
                                        <div className="form-group col-sm-12 col-md-6">
                                            <label htmlFor="email">Sexo</label>
                                            <Field 
                                                name="sexo" 
                                                component={renderSelectField} 
                                                type="text" 
                                                className="form-control p-0 no-border" 
                                                labelKey="label" valueKey="value" options={SEXO}/>
                                        </div>
                                </div>
                                <div className="row">
                                         <div className="form-group col-sm-12 col-md-6">
                                            <label htmlFor="nacimiento">Fecha de nacimiento</label>
                                            <Field
                                                name="nacimiento"
                                                className=" p-0"
                                                component={renderDatePicker}
                                                placeholder="10-19-2018"
                                                numberOfMonths={1}
                                            />
                                        </div>
                                       <div className="col-sm-12 col-md-6 d-flex flex-row pl-0">
                                            {(!editar || this.state.cambiarPwd) &&
                                             <div className="form-group col">
                                                <label htmlFor="pin">Pin</label>
                                                <Field  name="pin" 
                                                        component={renderField}
                                                        disabled ={editar && !this.state.cambiarPwd}
                                                        type="text" 
                                                        className="form-control" />
                                            </div>}
                                            {editar &&
                                                <div className="form-group col-sm-12 col-md-6 d-flex align-items-end">
                                            
                                                     <button type="button" 
                                                            className="btn btn-info"
                                                            onClick={this.onClick}>
                                                        Cambiar Contraseña
                                                    </button>
                                                </div>
                                            }
                                        </div>  
                                </div>


                         <FieldArray name="inscrito" component={renderCarreras} borrables={borrables}/>
                                
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Link className="btn btn-secondary m-1" to="/alumnos/">Cancelar</Link>
                                    <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
        </form>
    )
    }
}



const AlumnoForm = reduxForm({
    form: 'alumno',
    validate: value => {
        const errors = {};
        if(!value.username){
              errors.username = 'Campo Requerido';
        }
        if(!value.CUI){
              errors.CUI = 'Campo Requerido';
        }
         if(!value.nacimiento){
              errors.nacimiento = 'Campo Requerido';
        }
         if(!value.sexo){
              errors.sexo = 'Campo Requerido';
        }
        if(!value.first_name){
              errors.first_name = 'Campo Requerido';
        }
        if(!value.pin){
              errors.pin = 'Campo Requerido';
        }
        const inscritoArrayErrors = [];
        if(value && value.inscrito){
            value.inscrito.forEach( function(element, index) {
                const inscritoErrors ={};
                if(!element || !element.pensum){
                    //console.log("No tengo nombre")
                    inscritoErrors.pensum = 'Campo Requerido';
                }
                if(!element || !element.extension){
                    //console.log("No tengo nombre")
                    inscritoErrors.extension = 'Campo Requerido';
                }
                if(!element || !element.carrera){
                    //console.log("No tengo nombre")
                    inscritoErrors.carrera = 'Campo Requerido';
                }
                if(!element || !element.cohorte){
                    //console.log("No tengo nombre")
                    inscritoErrors.cohorte = 'Campo Requerido';
                }
                if(inscritoErrors){
                     inscritoArrayErrors[index] = inscritoErrors
                 }

            });
            
        }
        else{

                errors.inscrito = "Campo Requerido";
        }

         if (inscritoArrayErrors.length) {
              errors.inscrito = inscritoArrayErrors
        }
        
        return errors;
    }
})(Form)


export const AlumnoUpdateForm = reduxForm({
    form: 'alumno',
    validate: value => {
        const errors = {};
        if(!value.username){
              errors.username = 'Campo Requerido';
        }
        if(!value.CUI){
              errors.CUI = 'Campo Requerido';
        }
         if(!value.nacimiento){
              errors.nacimiento = 'Campo Requerido';
        } 
        if(value.sexo == null){
              errors.sexo = 'Campo Requerido';
        }
        if(!value.first_name){
              errors.first_name = 'Campo Requerido';
        }
        const inscritoArrayErrors = [];
        if(value && value.inscrito){
            value.inscrito.forEach( function(element, index) {
                const inscritoErrors ={};
                if(!element || !element.pensum){
                    //console.log("No tengo nombre")
                    inscritoErrors.pensum = 'Campo Requerido';
                }
                if(!element || !element.extension){
                    //console.log("No tengo nombre")
                    inscritoErrors.extension = 'Campo Requerido';
                }
                if(!element || !element.carrera){
                    //console.log("No tengo nombre")
                    inscritoErrors.carrera = 'Campo Requerido';
                }
                if(inscritoErrors){
                     inscritoArrayErrors[index] = inscritoErrors
                 }

            });
            
        }
        else{

                errors.inscrito = "Campo Requerido";
        }

         if (inscritoArrayErrors.length) {
              errors.inscrito = inscritoArrayErrors
        }
        
        return errors;
    }
})(Form)
export default AlumnoForm
