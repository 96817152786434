import { connect } from 'react-redux'
import Update from './AsignacionUpdate'
import { create, getInscripcion, getCiclos, load2Update, setCerrarAsignacion, onSubmit} from '../../../../redux/modules/asignacion'

const mdtp = { create, getInscripcion, getCiclos, load2Update, setCerrarAsignacion, onSubmit }
const ms2p = (state) => {
  return {
    ...state.login,
    ...state.asignacion,
  };
};


export default connect(ms2p, mdtp)(Update)
