import React from 'react'
import Form from './FechaAsignacionForm'
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Create extends React.Component {


  componentWillMount() {
    let ciclo = this.props.match.params.ciclo; 
    let cohorte = this.props.match.params.cohorte;
    this.props.dosFiltros(ciclo, cohorte);
  }
  render () {
    const { create, data_fecha_flat, fecha_ciclo, fecha_cohorte, loading } = this.props

    return (
      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 col-sm-12">
            <Form onSubmit={create} data={data_fecha_flat}  fecha_ciclo={fecha_ciclo} fecha_cohorte={fecha_cohorte} />
          </div>
        </div>
      </LoadMask>
    )
  }
}
