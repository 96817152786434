import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RenderNumber, RenderDateTime} from "Utils/renderField/renderReadField";
import { isArray, isObject } from "utility/validation";
import moment from "moment";


class Anterior extends Component {
    static propTypes = {
        detalle: PropTypes.any.isRequired,
    };

    constructor(props) {
        super(props);
    }
    formatoLlave(llave) {
        if(!isNaN(parseInt(llave)))
            return parseInt(llave)+1;
        try {
            return llave.split("_").join(" ")
        } catch (e) {
            return llave;
        }
    }

    formatoDetalle(valor, llave){
        try {
            if (isObject(valor)) {
                return (<div key={llave} className="d-flex flex-column px-2 py-1 ml-0 ml-sm-3">
                    <b className="text-info my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    {Object.keys(valor).sort().map((llave2) => {
                        return this.formatoDetalle(valor[llave2], llave2);
                    })}
                </div>)
            }
            if (isArray(valor)) {
                return (<div key={llave} className="d-flex flex-column px-2 py-1 ml-0 ml-sm-3">
                    <b className="text-info my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    {valor.map((llave2, index) => {
                        return this.formatoDetalle(llave2, index);
                    })}
                </div>)
            }
            if (valor == null) {
                return (<div key={llave} className="d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end">
                    <b  className="text-info my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}>----------------------</div>
                </div>)
            }
            if (!isNaN(valor) && !(valor === true) && !(valor === false)) {
                return (<div key={llave} className="d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end">
                    <b className="text-info my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}><RenderNumber value={valor} /></div>
                </div>)
            }
            if (valor === true) {
                return (<div key={llave} className="d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end">
                    <b className="text-info my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}>Sí</div>
                </div>)
            }
            if (valor === false) {
                return (<div key={llave} className="d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end">
                    <b className="text-info my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}>No</div>
                </div>)
            }
            const fecha = moment(valor.toString());
            if (fecha.isValid()) {
                return (<div key={llave} className="d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end">
                    <b  className="text-info my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    <div style={{flex: "2"}}><RenderDateTime value={valor} /></div>
                </div>)
            }
            return (<div key={llave} className="d-flex flex-row px-2 py-1 ml-0 ml-sm-3 align-items-end">
                <b className="text-info my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                <div style={{flex: "2"}}>{valor.toString()}</div>
            </div>)
        } catch (e) {
            return (<div key={llave} className="d-flex flex-row px-2 py-1 ml-0 ml-sm-3">
                <b className="text-info my-auto"style={{textTransform: "uppercase", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                <div style={{flex: "2"}} />
            </div>)
        }
    }

    render() {
        const { detalle } = this.props;


        return (
            <div className="d-flex flex-1 flex-column">
                <div className="d-flex flex-row p-2 ml-0 pl-sm-5 justify-content-start background-secondary mb-4">
                    <h6 className="mx-0 my-0 subtitulo py-0 font-weight-bold text-white"><strong>Anterior</strong></h6>
                </div>
                {Object.keys(detalle).map((llave) => {
                    return this.formatoDetalle(detalle[llave], llave);
                })}
            </div>
        )
    }
}

export default Anterior;
