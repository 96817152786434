import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2';
import moment from 'moment'; 
import {push} from 'react-router-redux';
import _ from "lodash";
const FALLO = 0
const FINALIZADO = 1
const EN_PROCESO = 2
const EXCEPCION = 3

const url = 'alumnos/'

export const create = () => (dispatch, getState) => {
  dispatch(loading())
  const formData = _.cloneDeep(getState().form.alumno.values);
  if(!formData.inscrito || formData.inscrito.length == 0 ){
   Swal(
              'ERROR',
              "Debe asignar por lo menos una carrera",
              'error'
      );
   return false;
  }
  let alumno = normalizarObjeto(formData);

  api.post(url,formData)
    .then(response => {
      dispatch(goBack())

    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.detail)
        mensaje = e.detail;
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}


const normalizarObjeto= (alumno)=>{
  console.log("Sexo", alumno.sexo);
  alumno.nacimiento = typeof (alumno.nacimiento) === "string" ? alumno.nacimiento : moment(alumno.nacimiento).format("YYYY-MM-D HH:MM")
  alumno.sexo = alumno.sexo == 'true' || alumno.sexo ==  true ? true: false;
  alumno.inscrito.forEach((inscripcion, index)=> {
    if(inscripcion.carrera)
      inscripcion.carrera = inscripcion.carrera.value;
    if(inscripcion.extension)
      inscripcion.extension = inscripcion.extension.value;
      return inscripcion;
  });
  console.log(alumno)
  return alumno;
}

export const update = () => (dispatch, getState) => {
  dispatch(loading())
 const formData = _.cloneDeep(getState().form.alumno.values);
 if(!formData.inscrito || formData.inscrito.length == 0 ){
   Swal(
              'ERROR',
              "Debe asignar por lo menos una carrera",
              'error'
      );
   return false;
  }
 let alumno = normalizarObjeto(formData);

  api.put(`${url}${formData.pk}`,alumno)
    .then(response => {
      dispatch(goBack())
       
    })
    .catch(e => {
      let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e.detail)
        mensaje = e.detail;
      if(e.length )
        mensaje= e[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
          );
        console.error(e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}
export const load2Update = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const alumno = await api.get(`${url}${id}`)
    dispatch(setUpdateData(alumno))
    dispatch(initializeForm('alumno', alumno))
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido obtener, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

export const destroy = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const remove = await api.erase(`alumnos/${id}`)
    dispatch(find())
  } catch(e) {
      let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}


export const search = (search) => (dispatch) => {
    let buscador = search.replace(" ", "");
    if (Number(buscador) !== Number(buscador)){
      dispatch(setBuscadorAlumno(search));
    } 
    else {
      buscador = `${buscador.substring(0,4)} ${buscador.substring(4,9)} ${buscador.substring(9)}`;
      buscador = buscador.trim();
      dispatch(setBuscadorAlumno(buscador));
    }
    dispatch(find(1));
}

export const find = (page = 1) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const search = store.alumno.buscador_alumno;
  let params = {page, search};
  try {
    const response = await  api.get(url, params)
    dispatch(setData(response))
    dispatch(setPage(page))

  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}
export const chekEstado = () => async (dispatch, getState) => {
  try {
    const response = await  api.get(url+'estado_publicacion/')
    console.log(response)
    if(response.estado == EN_PROCESO){
       setTimeout(()=>{
          dispatch(chekEstado());}
          ,10000)
    }
    else if(response.estado == FINALIZADO){
          Swal(
                'Datos importados',
                'Datos importados con éxito',
                'success'
        );
         dispatch(uploading(false))
         dispatch(find())
    }
    else if(response.estado == EXCEPCION){
        Swal({
          title:'Datos importados',
          text:'Algunos alumnos no han podido ser importados',
          type:'info',
          showCancelButton:true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'Ver errores'
        }).then((result) => {
          if (!result.value) {
            dispatch(push('/alumnos/errores'));
          }
        });
         dispatch(uploading(false))
         dispatch(find())
    }
    else if(response.estado == FALLO){
        Swal(
                'Datos no importados',
                'Ocurrió un error al importar alumnos.',
                'error'
        );
         dispatch(uploading(false))
         dispatch(find())
    }

  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
     dispatch(uploading(false))
  } finally {
    dispatch(loading(false))
  }

}


export const verErrores = () => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const response = await  api.get(url+'errores')
    dispatch(setErroresUpload(response))
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}


export const upload = (value) => (dispatch, getState) => {
 
  let data = new FormData();
  data.append('file', value);
  data.append('name', value.name);
  dispatch(uploading(true))
  api.postAttachments(url+"importar",{},[{field: 'file', file: value, name: value.name}])
    .then(response => {    
      if(response.proceso){
        //Revisar el estado después de 10 segundos
        setTimeout(()=>{dispatch(chekEstado())}, 10000);
      }
      else{
        let mensaje = response.detalle?response.detalle:"La importación no se realizará ya que hay otra en proceso.";
        Swal(
                'ERROR',
                mensaje,
                'info'
        );
        dispatch(uploading(false));
      }
      
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e && e.detail)
        mensaje = e.detail;
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}



/*ENCUENTRA TODAS LAS INSTANCIAS DE DETALLE ACTAS QUE PUEDEN SER BORRADAS*/
export const findBorrablesInscripcion = (alumno) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  try {
    const response = await  api.get(`inscripciones/inscripciones_borrables/${alumno}/`)
    dispatch(setBorrablesInscripcion(response))
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

export const { loading, setData, setPage,setUpdateData, setBuscadorAlumno, uploading, setErroresUpload, setBorrablesInscripcion } = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'UPLOADING': (uploading = true) => ({ uploading }),
  'SET_DATA': (data) => ({ data }),
  'SET_PAGE': (page) => ({ page }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
  'SET_BUSCADOR_Alumno':(buscador_alumno)=>({ buscador_alumno}),
  'SET_ERRORES_UPLOAD':(errores)=>({ errores}),
  'SET_BORRABLES_INSCRIPCION':(borrables_inscripcion)=>({ borrables_inscripcion}),
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [uploading]: (state, { payload: { uploading }}) => ({ ...state, uploading }),
  [setData]: (state, { payload: { data }}) => ({ ...state, data }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setBuscadorAlumno]: (state, { payload: { buscador_alumno }}) => ({ ...state, buscador_alumno }),
  [setErroresUpload]: (state, { payload: { errores }}) => ({ ...state, errores }),
  [setBorrablesInscripcion]: (state, { payload: { borrables_inscripcion }}) => ({ ...state, borrables_inscripcion }),
}

export const initialState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  page: 1,
  buscador_alumno: '',
  loading: false,
  uploading: false,
  updateData: {},
  errores: {},
  borrables_inscripcion: []
}

export default handleActions(reducers, initialState)

