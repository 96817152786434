import { connect } from 'react-redux'
import Update from './FechaAsignacionUpdate'
import { update, load2Update } from '../../../../redux/modules/fecha_asignacion'



const mdtp = { update, load2Update };
const mstp = state => state.fecha_asignacion;

export default connect(mstp, mdtp)(Update)
