import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { api } from "../../../../utility/api";
import { ACCIONES_BITACORA } from "../../../utility/constants";
import _ from "lodash";
import { RenderNoAsyncSelectField, RenderSearchSelect, RenderDateField } from './FilterFields'


class BitacoraFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
                usuario_seleccionado: 0, 
                carrera_list:[],
        };
    }
    componentWillMount() {
        this.getUsuarios = this.getUsuarios.bind(this);
        this.handleUsuarioChange = this.handleUsuarioChange.bind(this);
        this.handleAccionChange = this.handleAccionChange.bind(this);
        this.handleFechaInicialChange = this.handleFechaInicialChange.bind(this);
        this.handleFechaFinalChange = this.handleFechaFinalChange.bind(this);
    }
    componentDidMount() {
    }


    getUsuarios(search=''){
        return api.get(`usuarios/todos/`, {search})
        .then(data=>{
            if(data && data.results){
                return data.results;
            }
            else
                return [];
        })
        .catch(e=>{
            return [];
        })
    };

    handleUsuarioChange(e){

        if(e == '')
            this.props.filtroUsuario({pk: '', label:''});
        else if(e.pk !=this.state.carrera_seleccionada){
            this.setState({ usuario_seleccionado: e.pk});
            this.props.filtroUsuario(e)
        }
    }

    handleFechaInicialChange(e){
            this.props.filtroFecha('Inicial' ,e);
            //console.log("OnChange", e)
    }
    handleFechaFinalChange(e){
            this.props.filtroFecha( 'Final' ,e);
    }
    handleAccionChange(e){
        if(e != '')
            this.props.filtroAccion( e);
    }



    render() {
      
        
        return (
            <div className="col-12 row pb-2 pl-3 mt-2 mb-3 mx-0">
                <div  className="col row pl-md-4 px-0">
                    <div className="form-group col-lg-3 col-sm-12  d-flex flex-column my-auto" >
                       <label>Usuario</label>
                        <RenderSearchSelect
                            name='extension'
                            valueKey="pk"
                            labelKey="label"
                            onChange = { this.handleUsuarioChange }
                            label="Carrera"
                            defaultOptions={ true }
                            valor={this.props.usuario}
                            loadOptions={this.getUsuarios}/>                    
                    </div>
                    <div className="form-group col-lg-3 col-sm-12  d-flex flex-column my-auto">
                       <label>Acción</label>
                        <RenderNoAsyncSelectField
                            name='acion'
                            valueKey="descripcion"
                            labelKey="label"
                            onChange = { this.handleAccionChange }
                            label="Acción"
                            valor={this.props.accion}
                            defaultOptions={ true }
                            options={ACCIONES_BITACORA}/>                     
                    </div>
                    <div className="form-group col-lg-3 col-sm-12 select-resaltado d-flex flex-column my-auto">
                       <label>Fecha inicio</label>
                        <RenderDateField
                            name='fecha_inicio'
                            _onChange = { this.handleFechaInicialChange }
                            valor={this.props.fecha_inicial}/>                    
                   </div>
                   <div className="form-group col-lg-3 col-sm-12 select-resaltado d-flex flex-column my-auto">
                       <label>Fecha Final</label>
                        <RenderDateField
                            name='fecha_final'
                            _onChange = { this.handleFechaFinalChange }
                            valor={this.props.fecha_final}/>                    
                   </div>
                </div>
            </div>

      )
    }

}


export default BitacoraFilter;
