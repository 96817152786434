import { connect } from 'react-redux'
import Update from './ActasEspecialesUpdate'
import { updateEspecial, load2UpdateEspecial, todosFiltros } from '../../../../redux/modules/acta'



const mdtp = { updateEspecial, load2UpdateEspecial, todosFiltros };
const mstp = state => state.acta;

export default connect(mstp, mdtp)(Update)
