//restablecer_contrasena

import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField } from 'Utils//renderField/renderField'





class Form extends React.Component{

   
  render(){
    console.log(this.props)
     const { handleSubmit } = this.props

      return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h5 className="mb-1 mt-2 text-primary">
                        <strong>CAMBIAR CONTRASEÑA</strong>
                    </h5>

                     <p>
                        Proporcion el sigueinte dato para continuar:
                     </p>
                    <div className="padding-15 p-sm-0 py-sm-1 mt-4">
                        <div className="row">
                            <div className="form-group col-sm-12 col-md-12">
                                <label htmlFor="password">Nueva Contraseña</label>
                                <Field  name="password" 
                                        component={renderField} 
                                        type="password" 
                                        placeholder="Ingresa tu contraseña"
                                        className="form-control"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-sm-12 col-md-12">
                                <label htmlFor="confirmar">Confirmar contraseña</label>
                                <Field  name="confirmar" 
                                        component={renderField} 
                                        type="password" 
                                        placeholder="Ingresa tu contraseña"
                                        className="form-control"/>
                            </div>
                        </div>
                        
                       
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <button type="submit" className="btn btn-primary m-1">Continuar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
  }



}



const RestablecerForm = reduxForm({
    form: 'restablecer',
    validate: values => {

        const errors = {}
		if(values.password && values.password.length<6){
		    errors.password = 'Demasiado Corto (el mínimo es 6 caracteres)';
		}

        if (values.confirmar && values.confirmar != values.password) {
            errors.confirmar = 'Debe ser igual a la contraseña'
        }
        return errors  
    }
})(Form)

export default RestablecerForm
