import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import Toolbar from '../../Utils/Toolbar/Toolbar';
import FilterHorario from '../../Utils/Toolbar/FilterHorario';



function PensumFormat(cell, row) {
  if(cell){
    return cell.codigo;
  }

  return cell;
}
function ExtensionFormat(cell, row) {
  if(cell && cell.extension){
    return cell.extension;
  }

  return cell;
}
function CarreraFormat(cell, row) {
  if(cell && cell.carrera && cell.carrera.label){
    return cell.carrera.label;
  }

  return cell;
}


export default class HorariosGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.find()
    }

    render() {
        const { data, loading, page, buscador_horario, coordinacion__extension, coordinacion, pensum, ciclo, cohorte } = this.props;
        const { destroy, find, search, filtroCoordinacionCarerra, filtroCoordinacionExtension,  filtroPensum, filtroCiclo, filtroCohorte} = this.props;

        return (
            <div className="row  d-flex justify-content-center">
               <div className="col-sm-12 px-0 pt-2">
                    <div className="grid-container">
                       <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                           <Toolbar
                                buscar={search}
                                titulo={"horarios"}
                                boton = {"Agregar Horario"}
                                buscador={buscador_horario}/>
                        </div>
                        <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center linea-filtros">
                            <FilterHorario
                                filtroPensum={ filtroPensum }
                                filtroCiclo={ filtroCiclo }
                                filtroCohorte={ filtroCohorte }
                                coordinacion__extension={coordinacion__extension}
                                coordinacion={ coordinacion }
                                pensum={ pensum }
                                ciclo={ ciclo }
                                cohorte={ cohorte }
                                filtroCoordinacionCarerra={ filtroCoordinacionCarerra }
                                filtroCoordinacionExtension={ filtroCoordinacionExtension }/>
                        </div>
                        <br />
                        <Table onPageChange={find} data={data} loading={loading} page={page}>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={ ExtensionFormat }
                                dataField="coordinacion" dataSort>Extensión</TableHeaderColumn>
                                <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={ CarreraFormat }
                                dataField="coordinacion" dataSort>Carrera</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={ PensumFormat }
                                dataField="pensum" dataSort>pensum</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="cohorte" dataSort>cohorte</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="ciclo" dataSort>ciclo</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="pk" isKey dataAlign="center"
                                 dataFormat={activeFormatter({ editar: '/horarios/actualizar', eliminar: destroy })}
                            />
                        </Table>

                    </div>

                </div>

            </div>
        )
    }
}


