import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2'; 


export const create = () => (dispatch, getState) => {
  const formData = getState().form.curso.values
  if(formData.carrera.value)
    formData.carrera = formData.carrera.value;
  dispatch(loading())
  api.post('cursos/',formData)
    .then(response => {
      dispatch(goBack())

    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.detail)
        mensaje = e.detail;
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}

export const update = () => (dispatch, getState) => {
  let formData = getState().form.curso.values;
  if(formData.carrera.value)
    formData.carrera = formData.carrera.value;
  dispatch(loading())
  api.put(`cursos/${formData.pk}`,formData)
    .then(response => {
      dispatch(goBack())
       
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.detail)
        mensaje = e.detail;
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}
export const load2Update = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const curso = await api.get(`cursos/${id}`)
    dispatch(setUpdateData(curso))
    dispatch(initializeForm('curso', curso))
  } catch(e) {
     let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

export const destroy = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const remove = await api.erase(`cursos/${id}`)
    dispatch(find())
  } catch(e) {
     let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
     if(e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}


export const search = (search) => (dispatch) => {
    let buscador = search.replace(" ", "");
    if (Number(buscador) !== Number(buscador)){
      dispatch(setBuscadorCurso(search));
    } 
    else {
      buscador = `${buscador.substring(0,4)} ${buscador.substring(4,9)} ${buscador.substring(9)}`;
      buscador = buscador.trim();
      dispatch(setBuscadorCurso(buscador));
    }
    dispatch(find(1));
}

export const find = (page = 1) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const search = store.curso.buscador_curso;
  let params = {page, search};
  try {
    const response = await  api.get('cursos', params)
    dispatch(setData(response))
    dispatch(setPage(page))

  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

export const { loading, setData, setPage,setUpdateData, setBuscadorCurso } = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA': (data) => ({ data }),
  'SET_PAGE': (page) => ({ page }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
  'SET_BUSCADOR_CURSO':(buscador_curso)=>({ buscador_curso}),
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setData]: (state, { payload: { data }}) => ({ ...state, data }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setBuscadorCurso]: (state, { payload: { buscador_curso }}) => ({ ...state, buscador_curso })
}

export const initialState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  page: 1,
  buscador_curso: '',
  loading: false,
  updateData: {}
}

export default handleActions(reducers, initialState)

