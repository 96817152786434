import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import Toolbar from '../../Utils/Toolbar/Toolbar';
import { BootstrapTable } from 'react-bootstrap-table';


const   isExpandableRow=()=> {
    return true;
}

export default class ExtensionesGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.find()
    }

    render() {
        const { data, loading, page, buscador_extension } = this.props;

        const { destroy, find, search } = this.props;

        return (

            <div className="row  d-flex justify-content-center">
               <div className="col-sm-12 px-0 pt-2">
                    <div className="grid-container">
                       <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                            <Toolbar
                                buscar={search}
                                titulo={"extensiones"}
                                boton = {"Agregar Extensión"}
                                buscador={buscador_extension}/>
                        </div>
                        <br />
                        <Table 
                            onPageChange={find} 
                            data={data} 
                            loading={loading} 
                            expandableRow={isExpandableRow}
                            expandComponent={expandComponent}
                            page={page}>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="codigo" dataSort>Código</TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={BreakLine}
                                dataField="nombre" dataSort>Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="pk" isKey dataAlign="center"
                                 dataFormat={activeFormatter({ editar: '/extensiones/actualizar', eliminar: destroy })}
                            />
                        </Table>

                    </div>

                </div>

            </div>
        )
    }
}

function CarreraFormat(cell, row) {
  if(cell  && cell.label){
    return cell.label;
}
}
function DocenteFormat(cell, row) {
  if(cell  && cell.label){
    return cell.label;
    }
}

const expandComponent=(row)=> {
    return (
    <div className="px-lg-5 mx-lg-5">
        <BootstrapTable
            data={ row && row.coordinaciones ? row.coordinaciones:[] }>
            <TableHeaderColumn
                tdStyle={BreakLine}
                thStyle={BreakLine}
                dataFormat={ CarreraFormat }
                dataField="carrera" dataSort>
                Carrera</TableHeaderColumn>
            <TableHeaderColumn
                thStyle={BreakLine}
                dataFormat={ DocenteFormat }
                dataField="docente" dataSort>
                Docente</TableHeaderColumn>
            <TableHeaderColumn
                tdStyle={{width: '0px'}}
                thStyle={{width: '0px'}}
                dataField="pk" isKey dataAlign="center"/>

        </BootstrapTable>
    </div>
    );
  }
