import React, { Component } from "react";
import "./EncabezadoDocs.css";


const  EncabezadoDocs = (props)=>{
    const {titulo, subtitulo} = props;
    return(
        <div className="text-center impresion encabezado-doc print-only">
            <div className="logo-encabezado">
                <img src={require("../../../../../assets/img//membrete_logo.png")}/>

            </div>
            <div>
             
                <div className="primera-linea">
                    UNIVERSIDAD DE SAN CARLOS DE GUATEMALA
                </div>
                <div className="sengunda-linea">
                    CENTRO UNIVERSITARIO DE SAN MARCOS
                </div>
                <div className="nombre-doc">
                   OFICINA DE CONTROL ACADEMICO
                </div>
                <div className="nombre-doc">
                { titulo }
                </div>
                 <div className="nombre-doc">
                    { subtitulo?subtitulo:'' }
                </div>

            </div>
         </div>
    );
}

export  default EncabezadoDocs;