import { connect } from 'react-redux'
import InscripcionGrid from './InscripcionGrid'
import { findInscripcion, findAsignacionPasada } from '../../../../redux/modules/asignacion'

const ms2p = (state) => {
  return {
    ...state.login,
    ...state.asignacion,
  };
};


const mdtp = { findInscripcion, findAsignacionPasada }

export default connect(ms2p, mdtp)(InscripcionGrid)
