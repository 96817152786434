import React, { Component } from 'react'
import { Field, reduxForm, FieldArray } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { connect } from 'react-redux'
import { renderField } from 'Utils//renderField/renderField'
import _ from "lodash";
import { required, esBorrable } from '../../../../utility/validation';
import renderDetalle from "./renderDetalle";

//PARA POBLAR EL SELECT DE DOCENTES


 const  renderSeccion= ({ fields,borrables, options, meta: { error, submitFailed } }) => (
    <div>
            <div>
                {
                    fields.map((seccion, index) => {
                    return (
                         <div key={ index }>
                            <br/>
                            <div className="row d-fex justify-content-center titulo-dias mx-0 py-1">
                                <div className="col-lg-10 col-sm-11 d-flex justify-content-center flex-row align-items-stretch align-items-sm-center array-form">
                                    <label htmlFor="ciclo" className="mr-lg-5 text-primary">Sección</label>
                                    <Field
                                        name={`${seccion}.seccion`}
                                        component={renderField}
                                        type="text"
                                        key={ index }

                                        className="array-form"
                                        label="Sección"/>          
                                </div>
                                <div className="col-lg-1 col-sm-1">
                                    <img onClick={() =>{
                                                //Verificando que no tenga objetos relacionados
                                                const seccion = fields.get(index);
                                                let borrable = true;
                                                if(seccion && seccion.detalleActas && seccion.detalleActas.length)
                                                {
                                                    for (let detalle of seccion.detalleActas){
                                                        if(!esBorrable(detalle, borrables, seccion.ciclo_pensum?seccion.ciclo_pensum + " "+seccion.seccion:'' )){
                                                            borrable = false;
                                                            break;
                                                        }
                                                    }
                                                }
                                                if(borrable)
                                                    fields.remove(index)
                                            }
                                        } 
                                         className="action-img" 
                                         title="Eliminar" 
                                         src={require("../../../../../assets/img/icons/delete.png")} alt="Delete" />
                                </div>
                            </div> 
                            <div>
                               <div className="row col-12 px-0 mx-0">
                               <div className="col-12 px-0 borde-tabla-array mb-5 table table-responsive-sm ">
                                    <table className=" table-hover my-0">
                                            <thead className="text-info">
                                                <tr>
                                                    <th style={{width: "26%"}}>CURSO</th>
                                                    <th style={{width: "21%"}}>CATEDRÁTICO</th>
                                                    <th style={{width: "16%"}}>FECHA APERTURA</th>
                                                    <th style={{width: "16%"}}>FECHA FINAL</th>
                                                    <th style={{width: "16%"}}>FECHA ACTA</th>
                                                    <th style={{minWidth: "25px"}}></th>
                                                </tr>
                                            </thead>
                                         </table>
                                        <FieldArray     name={`${seccion}.detalleActas`}
                                                        component={renderDetalle}
                                                        borrables={borrables}
                                                        cursos_list={ options }/>
                                </div>
                            </div>
                            </div>
                        </div>
                    )}
                )
                    
            } 
            </div>      
         <div>
            {submitFailed &&
                    error &&
                    <div className="invalid-feedback-array">
                            {error}
                        </div>}
        </div>

          <div className="row mt-3">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        
                        <button type="button" className="btn btn-info ml-0  flex-nowrap flex-sm-wrap" onClick={() => fields.push({})}>
                                        Agregar Sección
                        </button>
                 </div>
                </div>
            </div>
    </div>
       
   
)


export default renderSeccion;
