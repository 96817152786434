import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2'; 
import _ from "lodash";

const url = 'pensums/';

export const create = () => (dispatch, getState) => {
  const formData = getState().form.pensum.values
  let pensum =  _.cloneDeep(formData);
  pensum = noramlizarObjeto(pensum);
  dispatch(loading())
  api.post(url,pensum)
    .then(response => {
      dispatch(goBack())
      //dispatch(setCarreraSeleccionada(0))
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e && e.detail)
        mensaje = e.detail;
      if(e && e.length)
        mensaje = e[0]

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)

    })
    .finally(() => {
      dispatch(loading(false))
    })
}


export const update = () => (dispatch, getState) => {
  const formData = getState().form.pensum.values
  let pensum =  _.cloneDeep(formData);
  pensum = noramlizarObjeto(pensum);
  dispatch(loading())
  api.put(`${url}${formData.pk}`,pensum)
    .then(response => {
      dispatch(goBack())
       
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e && e.detail)
        mensaje = e.detail;
      if(e && e.length)
        mensaje = e[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}

export const load2Update = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const pensum = await api.get(`${url}${id}`)
    dispatch(setUpdateData(pensum))
    dispatch(initializeForm('pensum', pensum))
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

export const destroy = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const remove = await api.erase(`${url}${id}`)
    dispatch(find())
  } catch(e) {
     let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}
export const search = (search) => (dispatch) => {
    let buscador = search.replace(" ", "");
    if (Number(buscador) !== Number(buscador)){
      dispatch(setBuscadorPensum(search));
    } 
    else {
      buscador = `${buscador.substring(0,4)} ${buscador.substring(4,9)} ${buscador.substring(9)}`;
      buscador = buscador.trim();
      dispatch(setBuscadorPensum(buscador));
    }
    dispatch(find(1));
}
export const find = (page = 1) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const search = store.pensum.buscador_pensum;
  let params = {page, search};
  try {
    const response = await  api.get(url, params)
    dispatch(setData(response))
    dispatch(setPage(page))

  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

/*ENCUENTRA TODAS LAS INSTANCIAS DE DETALLE PENSUM_CURSO QUE PUEDEN SER BORRADAS*/
export const findBorrablesPensum = (pensum) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  try {
    const response = await  api.get(`pensumcurso/borrables/${pensum}/`)
    dispatch(setBorrablesPensum(response))
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}


const noramlizarObjeto= (pensum)=>{
  pensum.carrera = pensum.carrera.value;
  pensum.pensum_curso.forEach((item, index)=> {
    item.detalle.forEach((detalle,index)=>{
      detalle.curso = detalle.curso.value;
      let prerequisitos_list = []
      if(detalle.prerequisitos)
      {
        detalle.prerequisitos.forEach((prerequisito, index)=>{
              prerequisitos_list.push(prerequisito.value)
            });
            detalle.prerequisitos = prerequisitos_list
      }

      return detalle;
    })
    return item;
  });
  return pensum;
}

export const { loading, setData, setPage,setUpdateData, setBuscadorPensum, setCarreraSeleccionada, setBorrablesPensum} = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA': (data) => ({ data }),
  'SET_PAGE': (page) => ({ page }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
  'SET_BUSCADOR_PENSUM':(buscador_pensum)=>({ buscador_pensum}),
  'SET_CARRERA_SELECCIONADA':(carrera_seleccionada)=>({ carrera_seleccionada}),
  'SET_PREREQUISITOS_LIST':(prerequisitos_list)=>({ prerequisitos_list}),
  'SET_BORRABLES_PENSUM':(borrables_pensum)=>({ borrables_pensum}),
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setData]: (state, { payload: { data }}) => ({ ...state, data }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setBuscadorPensum]: (state, { payload: { buscador_pensum }}) => ({ ...state, buscador_pensum }),
  [setCarreraSeleccionada]: (state, { payload: { carrera_seleccionada }}) => ({ ...state, carrera_seleccionada }),
  [setBorrablesPensum]: (state, { payload: { borrables_pensum }}) => ({ ...state, borrables_pensum }),
}

export const initialState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  page: 1,
  buscador_pensum: '',
  loading: false,
  updateData: {},
  carrera_seleccionada:0,
  borrables_pensum:[],
}


export default handleActions(reducers, initialState)

