import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from 'Utils/tableOptions';
import FilterNotas from 'Utils/Toolbar/FilterNotas';
import Table from 'Utils/Grid'
import { Layout } from '../../Asignacion/layout';
import { fechalFormat } from 'Utils/renderField/renderTableField'

function TotalColorFormat(cell, row){

     if(!row.en_curso){
      if(row.estado_nota==1)
         return {color: '#1687a7', fontWeight: 'bold'}
      else if(row.estado_nota==2)
         return {color: '#dd0a35', fontWeight: 'bold'}
      else
         return {color: '#014955', fontWeight: 'bold'}
  }
  else
    return {fontWeight: 'bold'}
}

function EstadoColorFormat(cell, row){
  if(!row.en_curso){
      if(cell==1 || row.nota ==null)
         return {color: '#1687a7', fontWeight: 'bold'}
      else if(cell==2)
         return {color: '#dd0a35', fontWeight: 'bold'}
      else if(cell==3)
         return {color: '#014955', fontWeight: 'bold'}
  }
  else
    return {fontWeight: 'bold'}
}

function EstadoFormat(cell, row) {
  //return cell.nombre
  if(!row.en_curso){
    if(cell==1)
      return 'APROBADO'
    else if(cell==2)
      return 'REPROBADO'
    else if(row.nota == null)
      return 'APROBADO'
    else
      return 'AUSENTE'
  }
  else 
    return 'CURSANDO'
}

function NotaFormat(cell, row) {
    if(cell)
      return parseInt(cell);
    else
      return 'EQV';
}



export default class NotasGrid extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        findNotas: PropTypes.func.isRequired,
    };

    componentWillMount() {

    }

    render() {
        const { loading, page, data, me, pensum, carrera} = this.props;
        const { findNotas, filtroCarreraNotas} = this.props;

        return (
            <div className="row d-flex justify-content-center">
                
                <Layout titulo={'NOTAS'} 
                        noDivisor={ false } 
                        scroll={ false }
                        carnet={me.username} 
                        nombre={me.first_name}>
               
                  <div className="col-md-10 col-sm-12 mt-3 px-0">
                   <div className="d-flex justify-content-center">
                        <div>
                             <FilterNotas  carrera={ carrera } filtroCarerra={ filtroCarreraNotas }/>
                            <Table  onPageChange={findNotas} 
                                    data={ { results: data } } 
                                    loading={loading} 
                                    page={page} 
                                    noPagination={ true }
                                    noDataText={'No hay notas'}>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        className="titulo-dias text-info"
                                        dataField="ciclo_pensum" dataSort>CICLO</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        className="titulo-dias text-info"
                                        dataField="curso_codigo" dataSort>CURSO</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        className="titulo-dias text-info"
                                        dataAlign="right"
                                        tdStyle={ TotalColorFormat }   
                                        dataFormat={ NotaFormat } 
                                        dataField="nota" dataSort>NOTA</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        className="titulo-dias text-info"
                                        dataAlign="center"
                                        dataFormat={ EstadoFormat }
                                        tdStyle={ EstadoColorFormat }                                                                      
                                        dataField="estado_nota" dataSort>RESULTADO</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        className="titulo-dias text-info"
                                        dataAlign="center"
                                        dataFormat={ fechalFormat }                                                                    
                                        dataField="fecha_acta" dataSort>FECHA</TableHeaderColumn>
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        tdStyle={{width: '0px'}}
                                        thStyle={{width: '0px'}}
                                        className="titulo-dias text-info"
                                        dataField="pk" isKey dataAlign="center"/>
                            </Table>
                        </div>
                      </div>
                    
                    </div>
                </Layout>

            </div>
        )
    }
}

