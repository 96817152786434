import React, { Component }  from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm, FieldArray } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField , renderSearchSelect, renderFieldChek, MultiCheckbox} from 'Utils//renderField/renderField'
import renderDatePicker from 'Utils//renderField/renderDatePicker'
import Titulo from "../../Utils/Titulo/TituloBigForm";
import { api } from "../../../../utility/api";
import _ from "lodash";
import './ActasFechaForm.css';


class RenderSecciones  extends Component {
    constructor(props) {
        super(props);
        this.props.secciones_list.forEach((pensum, i)=>{
            this.props.fields.push({})
        });
    }



    componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.secciones_list !== prevProps.secciones_list) {
        console.log("Cambio en props")
        this.props.secciones_list.forEach((pensum, i)=>{
            this.props.fields.push({})
        });
      }
    }


    render() {
        const { meta: { error, submitFailed } } = this.props
        const {  fecha_ciclo, fecha_cohorte, fields, secciones_list, data } = this.props
        return (

        <div>
            <div>
               <div >
                {
                    fields.map((seccion, index) => {
                        return (
                             <div key={ index }>
                                    <Field 
                                        name={`${seccion}.seccion`}
                                        component={ MultiCheckbox } 
                                        options={ 
                                            secciones_list[index]?secciones_list[index].detalleActas:[]
                                        }
                                        labelKey="curso"
                                        label={`${
                                            secciones_list[index]?secciones_list[index].ciclo_pensum +' sección '+ secciones_list[index].seccion:''
                                        }`}
                                        data={ data }
                                        tieneFechas={ true }
                                        className="form-control p-0 no-border" />

                            </div>
                            )
                        }
                    )
                    
                }

                </div>
                {submitFailed &&
                error &&
                <div className="invalid-feedback-array">
                        {error}
                    </div>}
            </div>
            
        </div>
      )
    }
}

//FORM PRINCINPAL DE PENSUMS
class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            secciones_list: []
        };
    }


    componentDidMount() {
    }

    //PARA FORMAR EL ARRAY DE EXTENSIONES
    render() {
        const { handleSubmit, editar, data } = this.props
        return (
                <form onSubmit={handleSubmit}>
                    <div className="form-group grid-container">
                        <div className="grid-titulo padding-15 ">
                            <div className="padding-15 p-sm-0 py-sm-1 form-container">
                                <span className="mt-4 text-secondary  text-uppercase  text-center text-md-left font-italic">ASIGNACIÓN FECHAS INICIO Y FIN </span>
                                <h5 className="mb-3 ml-0 text-uppercase text-center text-md-left font-italic text-primary"><strong>ACTAS</strong></h5>
                                <div className="react-bs-table-container scroll-container">
                                    <div className="react-bs-table react-bs-table-bordered">
                                         <div className="react-bs-container-body">
                                          <div className="react-bs-container-header table-header-wrapper" style={{overflow: 'initial'}}>
                                            <table className="table table-hover table-bordered">
                                       
                                              <thead>
                                                <tr>
                                                  <th scope="col" colSpan={2} className="py-1">SELECCIONES</th>
                                                  <th scope="col" className="text-center py-1"> FECHA INICIO</th>
                                                  <th scope="col" className="text-center py-1"> FECHA FIN</th>
                                                  <th scope="col" className="text-center py-1"> FECHA ACTA</th>
                                                </tr>
                                              </thead>
                                            </table>
                                           </div>
                                            <FieldArray name='secciones'
                                                    component={ RenderSecciones} 
                                                    data={data}
                                                    secciones_list={ data }/>
                                           </div>
                                    </div>
                                </div>


                                <div className="row mt-5">
                                     <div className="row col-lg-8 col-sm-12 ">
                                        <div className="form-group col-sm-4 mb-0">
                                              <label htmlFor="carrera">Fecha Inicio</label>
                                              <Field
                                                name="inicio"
                                                className=" p-0"
                                                component={renderDatePicker}
                                                placeholder="10-19-2018"
                                                numberOfMonths={1}/>
                                        </div>
                                        <div className="form-group col-sm-4 mb-0">
                                            <label htmlFor="carrera">Fecha Fin</label>
                                            <Field
                                                    name="fin"
                                                    className=" p-0"
                                                    component={renderDatePicker}
                                                    placeholder="10-19-2018"
                                                    numberOfMonths={1}/>
                                        </div>
                                        <div className="form-group col-sm-4 mb-0">
                                            <label htmlFor="carrera">Fecha Acta</label>
                                            <Field
                                                    name="fecha_acta"
                                                    className=" p-0"
                                                    component={renderDatePicker}
                                                    placeholder="10-19-2018"
                                                    numberOfMonths={1}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12 d-flex align-items-end justify-content-center">
                                        <div className="col-12 d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <Link className="btn btn-secondary m-1" to="/fecha_asignacion/">Cancelar</Link>
                                            <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

      )
    }
}


const FechaAsignacionForm = reduxForm({
    form: 'acta',
    validate: data => {
        return validate(data, {
            'inicio': validators.exists()('Campo Requerido'),
            'fin': validators.exists()('Campo Requerido'),
            'fecha_acta': validators.exists()('Campo Requerido'),
        })
    }
})(Form)

export default FechaAsignacionForm
