import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getMe } from "./redux/modules/login";

// maquetado base
import Navbar from "./common/components/Navbar/NavbarContainer";

// funciona para determinar si puede acceder a la vista
function isAuthenticated() {
    

    let auth = localStorage.getItem("token")
    return auth;
}

class PrivateRouteBase extends Component {
    
    constructor(props) {
        super(props);
        this.state = { expanded: false};
        this.OnExpanded = this.OnExpanded.bind(this);

      }


    componentWillMount(props) {
            //this.props.setRuta(window.location.href);
            //OBTENER LOS DATOS DEL USUARIOS LOGUEADO
            this.props.getMe();
     }


    OnExpanded(event) {
       this.setState({expanded: event});
    }

 
    render() {
        const { component: Component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated() ? (
                        <div>
                            <Navbar OnExpanded={ this.OnExpanded } />
                            <div                    
                                 className={`d-flex justify-content-center content-wrapper ${this.state.expanded? 'side-expanded' : ''}`} >
                                
                                <div className="col-lg-11 col-md-11 col-sm-11 card my-4 card-container">
                                    <Component {...props} />
                                </div>
                            </div>
                        </div>
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: props.location }
                                }}
                            />
                        )
                }
            />
        );
    }
}


const mstp = state => ({});

const mdtp = { getMe };

const ProtectedRoute = connect(mstp, mdtp)(PrivateRouteBase);

export default ProtectedRoute;
