import { connect } from 'react-redux'
import Update from './UpdateAlumno'
import { update, load2Update, findBorrablesInscripcion } from '../../../../redux/modules/alumno'



const mdtp = { update, load2Update, findBorrablesInscripcion };
const mstp = state => state.alumno;

export default connect(mstp, mdtp)(Update)
