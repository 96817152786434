import React, {Component, PropTypes} from 'react';
import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import _ from "lodash";

class MultiCheckboxCursosField extends Component {
    constructor() {
        super();

        this.getCurrentValues = this.getCurrentValues.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            options_list: [], 
            primera_ves: true
        };
    }
    componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
        if (this.props.options  !== prevProps.options) {
            this.setState({ options_list: this.props.options})
        }
    }
    componentDidMount() {
        if (this.props.options) {
            this.setState({ options_list: this.props.options})
        }
    }


    getCurrentValues() {
        const {field} = this.props;
        const {value, initialValue} = field;

        let previousValues = [];

        if (!isUndefined(value) && value !== '') {
            previousValues = value;
        }
        else if (!isUndefined(initialValue) && initialValue !== '') {
            previousValues = initialValue;
        }

        const currentValues = isArray(previousValues) ? [...previousValues] : [previousValues];
        return currentValues;

    }


    handleChange(event, pk) {
        const {field} = this.props;
        const {onChange} = field;
        const values = this.getCurrentValues();

        if (event.target.checked) {
            if(values.indexOf(pk)==-1){
                values.push(pk);
                 _.remove(values, (e)=> {
                   let indices= _.findIndex(this.props.options, {pk: e});
                   if(indices ==-1)
                   {
                      return true;
                   }
                   else{
                       return false;
                   }
               })
            }
        }
        else {
            values.splice(values.indexOf(pk), 1);
        }

        return onChange(values);
    }



    render() {
        const { options, field, labelKey} = this.props;
        const {onBlur} = field;
        const values = this.getCurrentValues();

        return (
                <tbody className="tabla-fechas">
                        {
                            this.state.options_list.map(option => {
                            const isChecked = values.indexOf(option.pk) > -1;

                            return (
                                <tr key={option.pk}  className="table-bordered">
                                    <td className="checkbox-item px-md-5 py-1 d-flex justify-content-between" colSpan={2}>
                                        <label>
                                            {labelKey && option[labelKey] ?option[labelKey]:option.label}
                                        </label>
                                        <input
                                                {...field}
                                                type="checkbox"
                                                onChange={event => this.handleChange(event, option.pk)}
                                                onBlur={() => onBlur(values)}
                                                checked={isChecked}
                                                value={option.pk}
                                                className="mr-3"
                                            />
                                    </td>
                                    
                                </tr>
                            );
                        })}
                        {
                            (!this.state.options_list || !this.state.options_list.length) &&

                                <tr key={0} >
                                    <td className="checkbox-item px-5 py-1 text-center" colSpan={2}>
                                        Seleccione una seccion
                                    </td>
                                    
                                </tr>

                        }
                </tbody>

        );
    }
}


export default MultiCheckboxCursosField;



