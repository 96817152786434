import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm, FieldArray } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField, renderSearchSelect} from 'Utils//renderField/renderField'
import { nivelesOptions } from '../../../utility/constants';
import { api } from "../../../../utility/api";
import Titulo from "../../Utils/Titulo/TituloBigForm";
import { esBorrable } from '../../../../utility/validation';


//PARA POBLAR EL SELECT DE CARRERAS
const getCarreras = (search='') => {


    return api.get(`carreras/select`,{value: search} )
    .then(data=>{
        if(data){
            return data;
        }
        else
            return []
    })
    .catch(e=>{
        return []
    })
};

//PARA POBLAR EL SELECT DE DOCENTES
const getDocentes = (search='') => {


    return api.get(`docentes/select`, {value: search})
    .then(data=>{
        if(data){
            return data;
        }
        else
            return [];
    })
    .catch(e=>{
        return [];
    })
};

//FROM PARA AÑADIR CARRERAS
const renderCarreras = ({ borrables,fields, meta: { error, submitFailed } }) => (
    <div className="row">
        <div className="col-sm-12">
             <table className="table table-sm table-hover">
                <thead>
                <tr className="array-header">
                    <th style={{width: "45%"}} >Carrera</th>
                    <th style={{width: "45%"}} >Coordinador</th>
                    <th style={{width: "10%"}} />
                </tr>
                </thead>
            </table>
               
            <div className="">
            <table className="table table-sm table-hover">
                <tbody>
                
                {fields.map((coordinacion, index) => (
                    <tr key={index} className="my-2">
                        <td >
                            <Field
                                name={`${coordinacion}.carrera`}
                                valueKey="value"
                                labelKey="label"
                                component={renderSearchSelect}
                                label="Carrera"
                                loadOptions={getCarreras}
                               
                            />
                        </td>
                        <td>
                            <Field
                                name={`${coordinacion}.docente`}
                                valueKey="value"
                                labelKey="label"
                                component={renderSearchSelect}
                                label="Coordinador"
                                loadOptions={getDocentes} 
                                className="my-2"
                               
                            />
                        </td>
                        <td className="text-center">
                             <img   onClick={() =>{
                                        const coordinacion =fields.get(index);
                                        const leyenda =coordinacion.carrera?coordinacion.carrera.label:''
                                        if(esBorrable(coordinacion, borrables, leyenda))
                                            fields.remove(index)
                                    }}
                                     className="action-img" 
                                     title="Eliminar" 
                                     src={require("../../../../../assets/img/icons/delete.png")} alt="Delete" />
                    
                        </td>
                    </tr>
                ))}
                 </tbody>

                
               
            </table>
            </div>
             <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center mt-5">
                <button type="button" className="btn btn-info  my-sm-auto flex-nowrap flex-sm-wrap" onClick={() => fields.push({})}>
                    Agregar Carrera
                </button>
            </div>
        </div>
    </div>
)
//FIN FORM CARRERAS

//FORM PRINCIPAL (DE EXTENSIONES)
const Form = props => {
    const { handleSubmit, editar } = props
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <Titulo  editar={editar} titulo='EXTENSIÓN'/>

                    <div className="padding-15 p-sm-0 py-sm-1">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-9 col-sm-11 row">
                                <div className="form-group col-lg-6 col-sm-12 ">
                                    <label htmlFor="codigo">Código</label>
                                    <Field name="codigo" component={renderField} type="text" className="form-control" />
                                </div>

                                <div className="form-group col-lg-6 col-sm-12">
                                    <label htmlFor="nombre">Nombre</label>
                                    <Field name="nombre" component={renderField}  type="text" className="form-control" />
                                </div>
                                </div>
                            </div>
                        </div>


                         <FieldArray name="coordinaciones" component={renderCarreras} borrables={ props.borrables }/>
                                
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Link className="btn btn-secondary m-1" to="/extensiones/">Cancelar</Link>
                                    <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </form>
  )
}



const ExtensionForm = reduxForm({
    form: 'extension',
    validate: data => {
        return validate(data, {
            'codigo': validators.exists()('Campo Requerido'),
            'nombre': validators.exists()('Campo Requerido'),
        })
    }
})(Form)

export default ExtensionForm
