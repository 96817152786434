import React from 'react'
import { PrintContainer, actions as printActions } from "Utils/Print";
import { RenderDateTime } from "Utils/renderField/renderReadField";
import writtenNumber from  'written-number'
import numeros from './numeros'
import {MESES} from '../../../utility/constants'
import './ListadoAsistencia.css';



const fecha = new Date();

const EncabezadoHoja=({ alumno, carrera, coordinador_academico, configuracion})=>{

  return(
    <div  className="encabezado-hoja h-100" >  
      <div  className="linea letra-grande" 
              style={{marginTop: '37mm', paddingLeft:"13mm", width: "215mm"}}>
         <table>
          <tbody>
              <tr>
                  <td  style={{width: "126mm"}}>{alumno.first_name}</td>
                  <td style={{paddingLeft: "22mm"}}>{alumno.username}</td>
              </tr>
              <tr>
                  <td style={{width: "40mm", paddingTop: "4mm"}}>
                       {carrera}
                  </td>
              </tr>
          </tbody>
         </table>
      </div>  
       <div style={{position: 'absolute', bottom: "-282mm", paddingLeft: "12mm"}}>
          <table className="w-100 font-weight-bold letra-grande">
            <tbody>
                <tr> 
                    <td  className="text-uppercase" style={{width: "190mm"}}>
                      {
                        
                        `${writtenNumber(fecha.getDate(), { lang: numeros})} DÍAS DEL MES DE 
                          ${MESES[fecha.getMonth()]} DEL AÑO 
                         ${writtenNumber(fecha.getFullYear(), { lang: numeros})}
                        `
                        
                      }
                    </td>
                </tr>

               </tbody>
            </table>
            <table className="w-100" style={{marginTop: "15mm"}}>
              <tbody>
                <tr>
                    <td className="text-center" style={{width: "63mm"}}>
                      {configuracion?configuracion.control_academico:''}
                    </td>
                    <td className="text-center" style={{width: "63mm"}}>
                     {`${coordinador_academico.titulo} ${coordinador_academico.first_name} ${coordinador_academico.last_name}`}
                    </td>
                    <td className="text-center" style={{width: "63mm"}}>
                      {configuracion?configuracion.director:''}
                    </td>
                </tr>
                <tr>
                    <td className="text-center" style={{width: "63mm"}}>
                      CONTROL ACADÉMICO
                    </td>
                    <td className="text-center" style={{width: "63mm"}}>
                      COORDINADOR ACADÉMICO
                    </td>
                    <td className="text-center" style={{width: "63mm"}}>
                     {configuracion && !configuracion.sexo_director?'DIRECTORA CUSAM':'DIRECTOR CUSAM'}
                    </td>
                </tr>
                
            </tbody>
         </table>
      </div>
    </div>
  )
}



const Hoja = ({ datos, alumno, carrera,  numeracion, coordinador_academico, configuracion}) =>{

	return(
    <div>
      <EncabezadoHoja alumno={ alumno }
                      coordinador_academico={ coordinador_academico }
                      configuracion={ configuracion }
                      carrera={ carrera }/>
      <div className='linea '
 
              style={{marginTop: '8mm', paddingLeft:"10mm", width: "210mm"}}>
         <table className="w-100">
              <tbody>
                  {
                      datos.map((data, index)=>{
                         return(
                              <tr key={index}>
                                  <td  className="text-center" style={{ width: '12mm'}}>
                                      {index+numeracion}
                                  </td>
                                  <td  className="text-center" style={{ width: '19mm'}}>
                                      {data.codigo}
                                  </td>
                                  <td  className="text-left" style={{ width: '125mm', paddingLeft: '4mm'}}>
                                      { data.curso}
                                  </td>
                                  <td  className="text-center" style={{ width: '20mm'}}>
                                      { data.nota? parseInt(data.nota): 'EQV' }
                                  </td>
                                  <td  className="text-right" style={{ width: '32mm', paddingRight: '8mm'}}>
                                     { 

                                      data.fecha_acta?RenderDateTime({value:data.fecha_acta}):'' 
                                      }
                                  </td>
                              </tr>
                           )
                      })
                  }

                   <tr>
                      <td  className="text-right" style={{ width: '10mm', paddingRight: '3mm'}}></td>
                      <td  className="text-left" style={{ width: '17mm',  paddingLeft: '6mm'}}></td>
                      <td  className="text-left" style={{ width: '11mm', paddingLeft: '4mm'}}>
                          -----------------------------------ÚLTIMA LÍNEA-----------------------------------
                      </td>
                      <td  className="text-center" style={{ width: '18mm'}}></td>
                      <td  className="text-right" style={{ width: '29mm', paddingRight: '6mm'}}></td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>
	)
}



const getNumeroHojas= (array, n_por_hoja)=>{

  return Math.ceil(array.length/n_por_hoja);
}



const CertificacionContraloria = ({ carrera, alumno, datos, coordinador_academico, configuracion}) =>{

    return(
     <PrintContainer name='CERTIFICACIONES'>
      <div className="h-100 print-only">    
        <Hoja numeracion={ 1 }
              carrera={ carrera }
              alumno={ alumno }
              coordinador_academico={ coordinador_academico }
              configuracion={ configuracion }
              datos={datos} />
      </div>

      </PrintContainer>
    )


}

export default CertificacionContraloria;


                                      /*print-only nota != 0 && nota.estado_nota!=3? writtenNumber(
                                                                            parseInt(nota.nota), 

                                                                   { lang: numeros}
                                                                            ):'AUSENTE' */