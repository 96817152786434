import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable } from 'react-bootstrap-table';
import LoadMask from "../LoadMask";


export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    }

    static defaultProps = {
        loading: false
    }

        parseData(data) {
        // this method parses the data and extracts:
        // previous: Página Previa
        // next: La siguiente página
        // page: the current page
        // pageSize: the page size
        // rows: rows for the table
         let datos =  {}
        if(!this.props.noPagination)
            datos =  {
                total: data && data.count?data.count:0,
                page:  data && data.previous?data.previous +1:1,
                pageSize: 10,
                rows: data && data.results?data.results:[]
            }
        else
            datos ={

                  rows: data && data.results?data.results:[]
            }

        return datos;
    }

    render() {
        // state
        const {
            // state
            loading, data, page,uploading, expandableRow, expandComponent, noDataText, noPagination,
            // bind
            onPageChange, onSortChange,
            // other
            ...other
        } = this.props;
        const { rows, count } = this.parseData(data);

        // render
        const options = {
            sizePerPage: 10,
            hideSizePerPage: true,
            alwaysShowAllBtns: true,
            noDataText: loading ? 'Cargando...' : (noDataText?noDataText:'No hay datos'),
            page,
            onPageChange: onPageChange ? onPageChange : () => {},
            onSortChange: onSortChange ? onPageChange : () => {}
        };

        return (
            <LoadMask loading={loading} uploading={ uploading } dark blur>
                <BootstrapTable
                    expandableRow={ expandableRow  }
                    expandComponent={ expandComponent }
                    data={loading ? [] : rows}
                    remote 
                    pagination = { !noPagination  } 
                    hover
                    fetchInfo={{ dataTotalSize: data?data.count:0 }} options={options} {...other}>
                </BootstrapTable>
            </LoadMask>
        )
    }
}
