import _ from "lodash";
import Swal from 'sweetalert2';

const isEmpty = value => value === undefined || value === null || value === '';
function join(rules) {
  return (value, data) => rules.map(rule => rule(value, data)).filter(error => !!error)[0];
}

export function email(value) {
  // Let's not start a debate on email regex! This one is quite standard
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'La dirección de correo no es válida';
  }

  return null;
}

export function phone(value) {
  // Let's not start a debate on phone regex! This one is the best I can find, the best way to
  // do it correctly is utilizing a third party verification, but for our use case, it is
  // just overkill
  if (!isEmpty(value) && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/g.test(value)) {
    return 'El número de teléfono no es válido';
  }

  return null;
}

const required = value => value ? undefined : 'Campo requerido'


export const minLength = min => value =>
 !isEmpty(value) && value.length < min ? `Debe tener al menos ${min} caracteres` : undefined


export const maxLength = max => value =>
 !isEmpty(value) && value.length > max ? `Debe tener ${max} caracteres como máximo` : undefined


export function integer(value: string|number) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }

  return null;
}

export function oneOf(enumeration) {
  return (value) => {
    if (!enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(', ')}`;
    }

    return null;
  };
}

export const match = field=> value=> data=>{
  console.log(data); console.log(value); console.log(field)
  return null
}
  



//  validate={[required, (value, values) => maxDate(values.dateTo)(value)]}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      // concat enables both functions and arrays of functions
      const rule = join([].concat(rules[key]));
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}



export function isArray(a) {
    return (!!a) && (a.constructor === Array);
}

export function isObject(a) {
    return (!!a) && (a.constructor === Object);
}



export function esBorrable(obj, borrables, leyenda){

    if(obj.pk){ 
      if( _.find(borrables, e=>{ return e == obj.pk; })){
        return true;
      }
     else{
       const mensaje = "No se puede eliminar el elemento -"+leyenda+"- porque existen otros elementos que dependen de  este";
       Swal(
                   'ERROR',
                   mensaje,
                   'error'
         );
       return false;
     }
   }
   else{
    //Si no tiene una pk, es una creación y no hay que verificar nada
    return true;
   }
}