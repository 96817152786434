import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import { Layout } from './layout';
import RecuperarForm from './RecuperarContraForm';
import './login.css';

class Login extends PureComponent {
    static propTypes = {
        nameError: PropTypes.bool.isRequired,
        passError: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
        hasNameError: PropTypes.func.isRequired,
        hasPassError: PropTypes.func.isRequired,
        submitError: PropTypes.bool.isRequired,
        recuperar_contrasenia: PropTypes.func.isRequired,

    };
    constructor(props)
    {
        super(props);
         this.state = { activar_recuperar: false };
         this.handleClick = this.handleClick.bind(this);
         this.EnviarDeNuevo = this.EnviarDeNuevo.bind(this);
    }

    componentDidMount(props) {

    }

    EnviarDeNuevo(event) {
        this.props.enviarDeNuevo();
        this.setState(state => ({
                activar_recuperar: true
        }));
    }

    handleClick(e) {
                this.setState(state => ({
                activar_recuperar: true
            }));
            e.preventDefault();
    }

    render() {
        //console.log(this.props)
        const { onSubmit, submitError, recuperar, enviado, recuperar_contrasenia, recuperarError, correo, enviarDeNuevo } = this.props;
        let auth = localStorage.getItem('token')
        if (auth) {
            return (<Redirect to="/page" />);
        } 
        return (
                <Layout>
                                
                              
                                    { !this.state.activar_recuperar &&

                                    <div>
                                        <h1>
                                            <p className="text-center pv text-muted">CUSAM</p>
                                        </h1>
                                        { submitError && (
                                              <div className="alert alert-danger text-center">
                                                Usuario o contraseña incorrectos
                                              </div>)
                                        }
                                        <LoginForm onSubmit={onSubmit} />
                                        <div className="my-3">
                                            <p className="text-center text-muted">
                                                ¿Olvidaste tu contraseña? 
                                                <a onClick={this.handleClick} className="pl-1" href="/#/">RECUPERAR</a>
                                            </p>
                                        </div>
                                    </div>
                                }
                                
                                { this.state.activar_recuperar && !enviado &&
                                     <div>

                                        { recuperarError && (
                                              <div className="alert alert-danger text-center">
                                               { recuperarError }
                                              </div>)
                                        }
                                        <RecuperarForm onSubmit={recuperar_contrasenia} />
                                    </div>
                                }


                                { enviado &&
                                    <div>

                                        <p className="text-primary text-center font-weight-bold">
                                            ENVIADO
                                        </p>
                                        <p className="text-center font-weight-bold">
                                            { correo }
                                        </p>
                                        <p>
                                            Te enviaremos una clave de cuenta para verificar que eres el propietario
                                            de este correo electrònico, de modo que puedas iniciar sesión
                                        </p>
                                        <div className="text-center mt-5">
                                            <button  onClick={this.EnviarDeNuevo} className="btn btn-primary">
                                                Envíar de nuevo
                                            </button>
                                        </div>
                                    </div>
                                }
                                
</Layout>
        );
    }
}

export default Login;

