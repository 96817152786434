import { connect } from 'react-redux'
import NotasGrid from './NotasGrid'
import { findNotas, filtroCarreraNotas } from '../../../../redux/modules/notas'

const ms2p = (state) => {
  return {
    ...state.login,
    ...state.notas,
  };
};


const mdtp = { findNotas, filtroCarreraNotas  }

export default connect(ms2p, mdtp)(NotasGrid)
