import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2'; 
import _ from "lodash";
import { SEMANA } from '../../common/utility/constants'

/******CONSTATES****/
export const DIAS = {lunes:  1, martes:  2, miercoles:  3, jueves : 4, viernes:  5,sabado : 6};

const url = 'horarios/';
/*******************/
export const create = () => (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const formData = store.form.horario.values;
  let temp =  _.cloneDeep(formData);
  let horario = normalizarObjeto(temp, store.horario.secciones);

  api.post(url, horario)
    .then(response => {
      dispatch(goBack())

    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e && e.detail)
        mensaje = e.detail;
      if(e && e.length)
        mensaje = e[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}


const normalizarObjeto= (horario, secciones_list)=>{
  horario.coordinacion = horario.coordinacion.pk;
  horario.pensum = horario.pensum.pk;
  horario.ciclo = horario.ciclo.pk;
  /****formando detalleHorarios***/
  let detalleHorarios=[];
  /****RECORRIENDO CADA LÍNEA DE SECCIÓN****/
  horario.secciones.forEach((seccion, index)=> {
    let nombre_seccion = secciones_list[index].seccion;
    //Verificar cada dìa de la semana
    SEMANA.forEach( function(dia, index) {
      /****por cada sección recorrer cada día de la semana si existe*/
      if(seccion[dia]){
        //Obtiene un arreglo con todos los "DETALLEHORARIO " de ese día 
        let tempLine = recorrerDias(dia, seccion, nombre_seccion); 
          //Concatenar el horario del día ej: lunes
          detalleHorarios= detalleHorarios.concat(tempLine)
      }
    });
  });//FIN FOR EACH SECCION
  //ADJUNTANDO CADA LÍNEA DE DETALLE A HORARIO, YA SIN EL OBJETO DE SECCIONES
  horario.detalleHorarios = detalleHorarios
  //console.log(horario)
  return horario;
}

const recorrerDias=(dia, seccion, nombre_seccion)=>{
  let detalle = []
   seccion[dia].forEach((item, index)=> {
      //CADA DIA PUEDE TENER SU  DETALLE QUE INCLUYE HORA, CURSO Y CATEDRÁTICO
      let tempLine = normalizarDia(item, dia, nombre_seccion);
          detalle.push(tempLine)

    }); //Fin for each seccion.detalle
   return detalle;
}

/**función para convertir cada objeto de día en una forma aceptada por el backend**/
const normalizarDia= (item, dia, seccion)=>{
  let tempLine =item;
  tempLine.curso = tempLine.curso.value;
   tempLine.dia = DIAS[dia];
   tempLine.seccion = seccion;
   return tempLine;
}

export const update = () => (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const formData = store.form.horario.values;
  let temp =  _.cloneDeep(formData);
  let horario = normalizarObjeto(temp, store.horario.secciones);
  api.put(`${url}${formData.pk}`,horario)
    .then(response => {
      dispatch(goBack())
       
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e && e.detail)
        mensaje = e.detail;
      if(e && e.length)
        mensaje = e[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}
export const load2Update = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const horario = await api.get(`${url}${id}`)
    dispatch(setUpdateData(horario))
    dispatch(initializeForm('horario', horario))
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido obtener, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

export const destroy = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const remove = await api.erase(`horarios/${id}`)
    dispatch(find())
  } catch(e) {
    let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
    if(e.detail)
      mensaje = e.detail;

    Swal(
              'ERROR',
              mensaje,
              'error'
      );
  } finally {
    dispatch(loading(false))
  }
}


export const search = (search) => (dispatch) => {
    let buscador = search.replace(" ", "");
    if (Number(buscador) !== Number(buscador)){
      dispatch(setBuscadorHorario(search));
    } 
    else {
      buscador = `${buscador.substring(0,4)} ${buscador.substring(4,9)} ${buscador.substring(9)}`;
      buscador = buscador.trim();
      dispatch(setBuscadorHorario(buscador));
    }
    dispatch(find(1));
}

export const find = (page = 1) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const search = store.horario.buscador_horario;
  const coordinacion = store.horario.coordinacion.pk;
  const coordinacion__extension = store.horario.coordinacion__extension.value;
  const pensum = store.horario.pensum.pk;
  const ciclo = store.horario.ciclo.pk;
  const cohorte = store.horario.cohorte.value;
  let params = {page, search, coordinacion, coordinacion__extension, pensum, ciclo, cohorte};
  try {
    const response = await  api.get(url, params)
    dispatch(setData(response))
    dispatch(setPage(page))

  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

export const filtroCoordinacionCarerra = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCoordinacion(filtro));
    dispatch(find());
}
export const filtroCoordinacionExtension = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCoordinacionExtension(filtro));
    dispatch(find());
}

export const filtroPensum = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroPensum(filtro));
    dispatch(find());
}
export const filtroCiclo = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCiclo(filtro));
    dispatch(find());
}
export const filtroCohorte = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCohorte(filtro));
    dispatch(find());
}

/*FILTROS PARA EL FORMULARIO*/
export const filtroCoordinacionForm = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    if(!filtro)
      dispatch(setFiltroCoordinacionForm(0));
    else
      dispatch(setFiltroCoordinacionForm(filtro));
    dispatch(getSecciones());
}
export const filtroPensumForm = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    if(!filtro)
      dispatch(setFiltroPensumForm(0));
    else
      dispatch(setFiltroPensumForm(filtro));
    dispatch(getSecciones());
}
export const filtroCicloForm = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    if(!filtro)
      dispatch(setFiltroCicloForm(0));
    else
      dispatch(setFiltroCicloForm(filtro));
    dispatch(getSecciones());
}
export const filtroCohorteForm = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    if(!filtro)
      dispatch(setFiltroCohorteForm(0));
    else
      dispatch(setFiltroCohorteForm(filtro));
    dispatch(getSecciones());
}

export const resetFiltoForm = () => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCoordinacionForm(0));
    dispatch(setFiltroPensumForm(0));
    dispatch(setFiltroCicloForm(0));
    dispatch(setFiltroCohorteForm(0));
}

export const getSecciones = () => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const search = store.horario.buscador_horario;
  const coordinacion = store.horario.coordinacion_form;
  const pensum = store.horario.pensum_form;
  const ciclo = store.horario.ciclo_form;
  const cohorte = store.horario.cohorte_form;
  let ruta = `actas/secciones/${ciclo}/${coordinacion}/${pensum}/${cohorte}`
  try {
    const response = await  api.get(ruta)
    dispatch(setSecciones(response))
  } catch(e) {
    console.error(e);
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }

}

export const {  
  loading, 
  setData, 
  setPage,setUpdateData, 
  setBuscadorHorario, 
  setFiltroCoordinacion,
  setFiltroCoordinacionExtension,
  setFiltroPensum,
  setFiltroCiclo,
  setFiltroCohorte,
  setFiltroCoordinacionForm,
  setFiltroPensumForm,
  setFiltroCicloForm,
  setFiltroCohorteForm,
  setSecciones
} = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA': (data) => ({ data }),
  'SET_PAGE': (page) => ({ page }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
  'SET_BUSCADOR_HORARIO':(buscador_horario)=>({ buscador_horario}),
  'SET_FILTRO_COORDINACION_EXTENSION':(coordinacion__extension)=>({ coordinacion__extension}),
  'SET_FILTRO_COORDINACION':(coordinacion)=>({ coordinacion}),
  'SET_FILTRO_PENSUM':(pensum)=>({ pensum }),
  'SET_FILTRO_CICLO':(ciclo)=>({ ciclo }),
  'SET_FILTRO_COHORTE':(cohorte)=>({ cohorte }),
  'SET_FILTRO_COORDINACION_FORM':(coordinacion_form)=>({ coordinacion_form}),
  'SET_FILTRO_PENSUM_FORM':(pensum_form)=>({ pensum_form }),
  'SET_FILTRO_CICLO_FORM':(ciclo_form)=>({ ciclo_form }),
  'SET_FILTRO_COHORTE_FORM':(cohorte_form)=>({ cohorte_form }),
  'SET_SECCIONES':(secciones)=>({ secciones }),
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setData]: (state, { payload: { data }}) => ({ ...state, data }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setBuscadorHorario]: (state, { payload: { buscador_horario }}) => ({ ...state, buscador_horario }),
  [setFiltroCoordinacion]: (state, { payload: { coordinacion}}) => ({ ...state, coordinacion }),
  [setFiltroCoordinacionExtension]: (state, { payload: { coordinacion__extension }}) => ({ ...state, coordinacion__extension }),
  [setFiltroPensum]: (state, { payload: { pensum }}) => ({ ...state, pensum }),
  [setFiltroCiclo]: (state, { payload: { ciclo }}) => ({ ...state, ciclo }),
  [setFiltroCohorte]: (state, { payload: { cohorte }}) => ({ ...state, cohorte }),
  [setFiltroCoordinacionForm]: (state, { payload: { coordinacion_form}}) => ({ ...state, coordinacion_form }),
  [setFiltroPensumForm]: (state, { payload: { pensum_form }}) => ({ ...state, pensum_form }),
  [setFiltroCicloForm]: (state, { payload: { ciclo_form }}) => ({ ...state, ciclo_form }),
  [setFiltroCohorteForm]: (state, { payload: { cohorte_form }}) => ({ ...state, cohorte_form }),
  [setSecciones]: (state, { payload: { secciones }}) => ({ ...state, secciones }),
}

export const initialState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  page: 1,
  buscador_horario: '',
  loading: false,
  updateData: {},
  coordinacion__extension: {value: '', label:''},
  coordinacion: {pk: '', label:''},
  pensum: {pk: '', codigo:''},
  ciclo: {pk: '', nombre:''},
  cohorte: {value: '', label:''},
  ciclo_form: 0,
  coordinacion_form: 0,
  pensum_form: 0,
  cohorte_form: 0,
  secciones: []

}

export default handleActions(reducers, initialState)
