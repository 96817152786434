export const nivelesOptions = [
    {value: 1, label: "Maestría"},
    {value: 2, label: "Doctorado"},
    {value: 3, label: "Licenciatura"},
    {value: 4, label: "Técnico"},
];


export const ROLES = {REGISTRO: 0, DOCENTE: 1, ESTUDIANTE: 2}
export const SEXO =  [{value: 'true', label: "Masculino"}, {value: 'false', label: "Femenino"}]

export const DIAS = {LUNES:  1, MARTES:  2, MIERCOLES:  3, JUEVES : 4, VIERNES:  5,SABADO : 6}
export const SEMANA = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']

export const MESES = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];


export const NOTA = {1: 'APROBADO', 2: 'REPROBADO', 3: 'AUSENTE'}



export const ACCIONES_BITACORA = [
	//ACCIONES DE ACTA
    {descripcion: "Crear actas", label: "Crear actas"},
    {descripcion: "Editar actas", label: "Editar actas"},
    {descripcion: "Crear acta especial", label: "Crear acta especial"},
    {descripcion: "Editar acta especial", label: "Editar acta especial"},
    {descripcion: "Cerrar acta", label: "Cerrar acta"},
    {descripcion: "Reabrir acta", label: "Reabrir acta"},
    {descripcion: "Asignar fecha de acta", label: "Asignar fecha de acta"},
    //ACCIONES DE NOTA
    {descripcion: "Editar notas", label: "Editar notas"},
];