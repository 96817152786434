import { connect } from 'react-redux'
import Update from './ExtensionUpdate'
import { update, load2Update, findBorrablesCoordinacion } from '../../../../redux/modules/extension'



const mdtp = { update, load2Update, findBorrablesCoordinacion };
const mstp = state => state.extension

export default connect(mstp, mdtp)(Update)
