import { connect } from 'react-redux'
import Create from './HorarioCreate'
import { 	create,
			filtroCoordinacionForm,
			filtroPensumForm,
			filtroCicloForm,
			filtroCohorteForm,
			resetFiltoForm,
			getSecciones 
		} from '../../../../redux/modules/horario'

const mdtp = { 	
				create, 
				filtroCoordinacionForm,
				filtroPensumForm,
				filtroCicloForm,
				filtroCohorteForm,
				resetFiltoForm,
				getSecciones 
			}
const mstp = state => state.horario;

export default connect(mstp, mdtp)(Create)
