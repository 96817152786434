
const request = require("superagent");

/**
 * Function to obtain the token
 * */
function getToken() {
    const token = localStorage.getItem("token");
    if (token) {
        return `${token}`;
    }
    return false;
}

/**
 * Function to make the absolute url with a query string from the relative path and params
 * @param path: string: relative route
 * @param params: dict: dictionary to pass as query params
 * @return: string: string with absolute url
 * */
export function makeUrl(path, params = {}) {
    let url = "/api";
    if (path[0] === "/") {
        url += `${path}/`;
    } else {
        url += `/${path}/`;
    }
    let hasQueue = false; // starting with no query string
    // insert an attribute to the query params for each param

    const dicKeys = Object.keys(params);
    dicKeys.forEach(row => {
        if (hasQueue) {
            url += `&${row}=${params[row]}`;
        } else {
            url += `?${row}=${params[row]}`;
            hasQueue = true;
        }
    });
    // absolute url returned
    return url;
}

/**
 * Function to handle the errors of any petition
 * @param response: response: petition's response
 * */
function errorHandler(response) {
    if (response.statusCode === 401 || response.statusCode === 403) {
        localStorage.removeItem("token");
        window.location.assign("/");
    } else {
        // console.log(response.body);
    }
}

/**
 * Function to process a POST petition
 * @param path: string: relative path of the petition
 * @param body: dict: body for the post
 * @param params: dict: params for the query string, optional
 * @return: Promise of superagent
 * */
function _post(path, body, params = {}) {
    const url = makeUrl(path, params);
    let token = getToken();
    if (getToken()) {
        return request
            .post(url)
            .send(body)
            .set("Accept", "application/json")
            .set("Content-Type", "application/json")
            .set("Authorization",'Token '+ token);
    }
    return request
        .post(url)
        .send(body)
        .set("Accept", "application/json")
        .set("Content-Type", "application/json");
}

/**
 * POST
 * @param path: string: relative path of the petition
 * @param body: dict: body for the post
 * @param params: dict: params for the query string, optional
 * @return: Promise: promise of the post
 * */
function post(path, body, params = {}) {
    return new Promise((resolve, reject) => {
        _post(path, body, params)
            .then(response => {
                if (response.body) {
                    resolve(response.body);
                }
                resolve(response);
            })
            .catch(error => {
                errorHandler(error.response);
                reject(error.response.body);
            });
    });
}

/**
 * Function to process a PATCH petition
 * @param path: string: relative path of the petition
 * @param body: dict: body for the patch
 * @param params: dict: params for the query string, optional
 * @return: Promise of superagent
 * */
function _patch(path, body, params = {}) {
    const url = makeUrl(path, params);
    let token = getToken();
    if (getToken()) {
        return request
            .patch(url)
            .send(body)
            .set("Accept", "application/json")
            .set("Content-Type", "application/json")
            .set("Authorization", "Token " + token);
    }
    return request
        .patch(url)
        .send(body)
        .set("Accept", "application/json")
        .set("Content-Type", "application/json");
}

/**
 * PATCH
 * @param path: string: relative path of the petition
 * @param body: dict: body for the patch
 * @param params: dict: params for the query string, optional
 * @return: Promise: promise of the patch
 * */
function patch(path, body, params = {}) {
    return new Promise((resolve, reject) => {
        _patch(path, body, params)
            .then(response => {
                if (response.body) {
                    resolve(response.body);
                }
                resolve(response);
            })
            .catch(error => {
                errorHandler(error.response);
                reject(error.response.body);
            });
    });
}

/**
 * Function to process a PUT petition
 * @param path: string: relative path of the petition
 * @param body: dict: body for the put
 * @param params: dict: params for the query string, optional
 * @return: Promise of superagent
 * */
function _put(path, body, params = {}) {
    const url = makeUrl(path, params);
    let token = getToken();
    if (getToken()) {
        return request
            .put(url)
            .send(body)
            .set("Accept", "application/json")
            .set("Content-Type", "application/json")
            .set("Authorization", "Token "+token);
    }
    return request
        .put(url)
        .send(body)
        .set("Accept", "application/json")
        .set("Content-Type", "application/json");
}

/**
 * PUT
 * @param path: string: relative path of the petition
 * @param body: dict: body for the put
 * @param params: dict: params for the query string, optional
 * @return: Promise: promise of the put
 * */
function put(path, body, params = {}) {
    return new Promise((resolve, reject) => {
        _put(path, body, params)
            .then(response => {
                if (response.body) {
                    resolve(response.body);
                }
                resolve(response);
            })
            .catch(error => {
                errorHandler(error.response);
                reject(error.response.body);
            });
    });
}
/**
 * Function to process a DELETE petition
 * @param path: string: relative path of the petition
 * @return: Promise of superagent
 * */
function _delete(path) {
    const url = makeUrl(path);
    let token = getToken();
    if (getToken()) {
        return request
            .delete(url)
            .set("Accept", "application/json")
            .set("Content-Type", "application/json")
            .set("Authorization", "Token "+token);
    }
    return request
        .delete(url)
        .set("Accept", "application/json")
        .set("Content-Type", "application/json");
}

/**
 * DELETE
 * @param path: string: relative path of the petition
 * @return: Promise: promise of the delete
 * */
function erase(path) {
    return new Promise((resolve, reject) => {
        _delete(path)
            .then(response => {
                if (response.body) {
                    resolve(response.body);
                }
                resolve(response);
            })
            .catch(error => {
                errorHandler(error.response);
                reject(error.response.body);
            });
    });
}

/**
 * Function to process a GET petition
 * @param path: string: relative path of the petition
 * @param params: dict: params for the query string, optional
 * @return: Promise of superagent
 * */
function _get(path, params = {}) {
    const url = makeUrl(path, params);
    let token = getToken();
    if (getToken()) {
        return request
            .get(url)
            .set("Accept", "application/json")
            .set("Content-Type", "application/json")
            .set("Authorization", "Token "+token);
    }
    return request
        .get(url)
        .set("Accept", "application/json")
        .set("Content-Type", "application/json");
}

/**
 * GET
 * @param path: string: relative path of the petition
 * @param params: dict: params for the query string, optional
 * @return: Promise: promise of the get
 * */
function get(path, params = {}) {
    return new Promise((resolve, reject) => {
        _get(path, params)
            .then(response => {
                if (response.body) {
                    resolve(response.body);
                }
                resolve(response);
            })
            .catch(error => {
                errorHandler(error.response);
                reject(error.response);
            });
    });
}

/**
 * Funcion para hacer una peticion post
 * @param path: string: path relativo de la peticion
 * @param body: dict: el body para el post
 * @param attachments: array: diccionario con estructura name, file, con el nombre y archivo que se desea enviar
 * @param params: dict: parametros para query string, opcionales
 * @return: instancia de superagent lista para ser recibida como promise
 * */
function _postMultiPart(path, body, attachments, params = {}) {
  const url = makeUrl(path, params);
  let token = getToken();
  let result;
  if (getToken()){
    result = request.post(url).set('Authorization', token);
  }else{
    result = request.post(url);
  }
  attachments.forEach((attachment) => {
    result.attach(attachment.field || attachment.name,  attachment.file, attachment.name);
  });
  const data = JSON.stringify(body);
  result.field('data', data);
  return result;
}

/**
 * Funcion para hacer una peticion post
 * @param path: string: path relativo de la peticion
 * @param body: dict: el body para el post
 * @param attachments: array: diccionario con estructura name, file, con el nombre y archivo que se desea enviar
 * @param params: dict: parametros para query string, opcionales
 * @return: Promise: promise del post
 * */
function postAttachments(path, body, attachments, params = {}) {
  return new Promise((resolve, reject) => {
    _postMultiPart(path, body, attachments, params).then((response) => {
      if (response.body){
        resolve(response.body);
      }
      resolve(response);
    }).catch((error) => {
      errorHandler(error.response);
      reject(error.response.body);
    });
  });
}

/**
 * Funcion para hacer una peticion put
 * @param path: string: path relativo de la peticion
 * @param body: dict: el body para el put
 * @param attachments: array: diccionario con estructura name, file, con el nombre y archivo que se desea enviar
 * @param params: dict: parametros para query string, opcionales
 * @return: instancia de superagent lista para ser recibida como promise
 * */
function _putMultiPart(path, body, attachments, params = {}) {
  const url = makeUrl(path, params);
  let token = getToken();
  let result;
  if (getToken()){
    result = request.put(url).set('Authorization', token);
  }else{
    result = request.put(url);
  }
  attachments.forEach((attachment) => {
    result.attach(attachment.field || attachment.name, attachment.name, attachment.file);
  });
  const data = JSON.stringify(body);
  result.field('data', data);
  return result;
}

/**
 * Funcion para hacer una peticion put
 * @param path: string: path relativo de la peticion
 * @param body: dict: el body para el put
 * @param attachments: array: diccionario con estructura name, file, con el nombre y archivo que se desea enviar
 * @param params: dict: parametros para query string, opcionales
 * @return: Promise: promise del put
 * */
function putAttachments(path, body, attachments, params = {}) {
  return new Promise((resolve, reject) => {
    _putMultiPart(path, body, attachments, params).then((response) => {
      if (response.body){
        resolve(response.body);
      }
      resolve(response);
    }).catch((error) => {
      errorHandler(error.response);
      reject(error.response.body);
    });
  });
}

export const api = {
    get,
    post,
    put,
    patch,
    erase,
    postAttachments,
    putAttachments
};
