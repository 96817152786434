import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { api } from "../../utility/api";
import Swal from 'sweetalert2'; 
import moment from 'moment'; 
import _ from 'lodash';

const url = 'fecha_asignacion/'

export const create = () => (dispatch, getState) => {
  const formData = getState().form.fecha_asignacion.values
  const store = getState();
  const ciclo = store.fecha_asignacion.fecha_ciclo;
  const cohorte = store.fecha_asignacion.fecha_cohorte;
  let fechas = normalizarData( _.cloneDeep(formData), ciclo.pk, cohorte.value)
  dispatch(loading())
  api.post(url,fechas)
    .then(response => {
      dispatch(goBack())
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.detail)
        mensaje = e.detail;
      if(e.length)
        mensaje = e[0];
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}



const normalizarData=(value, ciclo, cohorte)=>{
  let valores = []
  value.extensiones.forEach( (element, index)=> {
      if(element.extension){
         element.extension.forEach((coordinacion, index)=> {
            let fecha = {
              ciclo: ciclo, 
              fin: typeof (value.fin) === "string" ?value.fin : moment(value.fin).format("YYYY-MM-D"), 
              inicio: (value.inicio) === "string" ?value.inicio : moment(value.inicio).format("YYYY-MM-D"), 
              coordinacion: coordinacion, 
              cohorte: cohorte
            };
            valores.push(fecha);
         });
      }
  });
  return valores;
}

export const update = () => (dispatch, getState) => {
  let formData = getState().form.fecha_asignacion.values;
  dispatch(loading())
  api.put(`${url}${formData.pk}`,formData)
    .then(response => {
      dispatch(goBack())
       
    })
    .catch(e => {
      let mensaje = "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
      if(e.detail)
        mensaje = e.detail;
      Swal(
                'ERROR',
                mensaje,
                'error'
        );
        console.log('error', e)
    })
    .finally(() => {
      dispatch(loading(false))
    })
}
export const load2Update = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const fecha_asignacion = await api.get(`${url}${id}`)
    dispatch(setUpdateData(fecha_asignacion))
    dispatch(initializeForm('fecha_asignacion', fecha_asignacion))
  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

export const destroy = id => async (dispatch, getState) => {
  dispatch(loading())
  try {
    const remove = await api.erase(`fecha_asignacion/${id}`)
    dispatch(find())
  } catch(e) {
     let mensaje = 'Ha ocurrido un error, verifique los datos y vuelva a intentar.';
      if(e.detail)
        mensaje = e.detail;

      Swal(
                'ERROR',
                mensaje,
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}


export const search = (search) => (dispatch) => {
    let buscador = search.replace(" ", "");
    if (Number(buscador) !== Number(buscador)){
      dispatch(setBuscadorFecha(search));
    } 
    else {
      buscador = `${buscador.substring(0,4)} ${buscador.substring(4,9)} ${buscador.substring(9)}`;
      buscador = buscador.trim();
      dispatch(setBuscadorFecha(buscador));
    }
    dispatch(find(1));
}

export const find = (page = 1) => async (dispatch, getState) => {
  dispatch(loading())
  const store = getState();
  const ciclo = store.fecha_asignacion.fecha_ciclo.pk;
  const cohorte = store.fecha_asignacion.fecha_cohorte.value;

  let params = { ciclo, cohorte};
  try {
    const response = await  api.get(url, params)
    const datos = formatData(response);
    dispatch(setDataFecha(datos))
    dispatch(setDataFechaFlat(response))

  } catch(e) {
     Swal(
                'Error',
                'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  }
}

const formatData=(data)=>{
  let extensiones = _.values(_.groupBy(data,(e)=> { return e.extension.value}))
  let array = extensiones.map(e=>{
    e.unshift(e[0].extension)
    return e;
  })
  return array
}


export const filtroCiclo = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCicloFecha(filtro));
    dispatch(find());
}
export const filtroCohorte = (filtro) => (dispatch, getStore) =>{
    const store = getStore();
    dispatch(setFiltroCohorteFecha(filtro));
    dispatch(find());
}

export const dosFiltros = (ciclo, cohorte) => async (dispatch, getStore) =>{
  const store = getStore();
  dispatch(loading())
  try {
    const ciclo_valor = await api.get(`ciclos/${ciclo}`);
    dispatch(setFiltroCicloFecha(ciclo_valor));
    dispatch(setFiltroCohorteFecha({value:cohorte, label: cohorte}));
    dispatch(find());
  } catch(e) {
    console.error(e)
     Swal(
                'Error',
                'Ha ocurrido un error. Los datos no se han podido borrar, por favor vuelva a intentar.',
                'error'
        );
  } finally {
    dispatch(loading(false))
  } 
}

export const { loading, setDataFecha, setDataFechaFlat, setPage,setUpdateData, setBuscadorFecha, setFiltroCiclo, setFiltroCohorte, setFiltroCicloFecha, setFiltroCohorteFecha} = createActions({
  'LOADING': (loading = true) => ({ loading }),
  'SET_DATA_FECHA': (data_fecha) => ({ data_fecha }),
  'SET_DATA_FECHA_FLAT': (data_fecha_flat) => ({ data_fecha_flat }),
  'SET_PAGE': (page) => ({ page }),
  'SET_UPDATE_DATA': (updateData) => ({ updateData }),
  'SET_BUSCADOR_FECHA':(buscador_fecha)=>({ buscador_fecha}),
  'SET_FILTRO_CICLO_FECHA':(fecha_ciclo)=>({ fecha_ciclo }),
  'SET_FILTRO_COHORTE_FECHA':(fecha_cohorte)=>({ fecha_cohorte }),
 
})

// Reducers
const reducers = {
  [loading]: (state, { payload: { loading }}) => ({ ...state, loading }),
  [setDataFecha]: (state, { payload: { data_fecha }}) => ({ ...state, data_fecha }),
  [setDataFechaFlat]: (state, { payload: { data_fecha_flat }}) => ({ ...state, data_fecha_flat }),
  [setPage]: (state, { payload: { page }}) => ({ ...state, page}),
  [setUpdateData]: (state, { payload: { updateData }}) => ({ ...state, updateData }),
  [setBuscadorFecha]: (state, { payload: { buscador_fecha }}) => ({ ...state, buscador_fecha }),
  [setFiltroCicloFecha]: (state, { payload: { fecha_ciclo }}) => ({ ...state, fecha_ciclo }),
  [setFiltroCohorteFecha]: (state, { payload: { fecha_cohorte }}) => ({ ...state, fecha_cohorte }),
}

export const initialState = {
  data_fecha: [],
  data_fecha_flat: [],
  page: 1,
  buscador_fecha: '',
  loading: false,
  updateData: {},
  fecha_ciclo: {pk: '', nombre:''},
  fecha_cohorte: {value: '', label:''}
}

export default handleActions(reducers, initialState)

