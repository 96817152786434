import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import LoadMask from 'Utils/LoadMask'
import {dateFormatter} from 'Utils/renderField/renderReadField'
import Toolbar from '../../Utils/Toolbar/ToolbarFechas';
import Filter from '../../Utils/Toolbar/FilterFechaAsignacion';
import { BootstrapTable } from 'react-bootstrap-table';
/*
function PensumFormat(cell, row) {
  if(cell){
    return cell.codigo;
  }

  return cell;
}*/
function ExtensionFormat(cell, row) {
    console.log(cell)
  if(cell && cell.extension){
    return cell.extension.label;
  }

  return cell;
}
function CarreraFormat(cell, row) {
  if(cell && cell.carrera && cell.carrera.label){
    return cell.carrera.label;
  }

  return cell;
}





export default class FechasGrid extends React.Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.find()
    }

    render() {
        const { data_fecha, loading, page, buscador_fecha, fecha_ciclo, fecha_cohorte } = this.props;

        const { destroy, find, search, filtroCiclo, filtroCohorte } = this.props;

        return (
            <div className="row  d-flex justify-content-center">
               <div className="col-sm-12 px-0 pt-2">
                    <div className="grid-container">
                       <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                            <Toolbar
                                filtroCiclo={filtroCiclo}
                                filtroCohorte={filtroCohorte}
                                fecha_ciclo={fecha_ciclo}
                                fecha_cohorte={fecha_cohorte}
                                titulo={"Asignaciones"}
                                boton = {"Editar Asignaciones "}
                                ruta={'fecha_asignacion'}/>
                        </div>

                        <br />
                        <LoadMask loading={loading} dark blur>
                          <div className="react-bs-table-container">
                            <div className="react-bs-table react-bs-table-bordered">
                                
                              
                                 <div className="react-bs-container-body">
                                  <div className="react-bs-container-header table-header-wrapper" style={{overflow: 'initial'}}>
                                    <table className="table table-hover table-bordered">
                                        <colgroup><col/><col/><col/><col/><col/><col/></colgroup>
                                        <thead>
                                            <tr>
                                                <th  className="p-3">EXTENSIÓN</th>
                                                <th  className="p-3">CARRERA </th>
                                                <th  className="p-3">CICLO</th>
                                                <th  className="p-3">COHORTE</th>
                                                <th  className="p-3">FECHA INICIO</th>
                                                <th  className="p-3">FECHA FIN</th>
                                            </tr>
                                        </thead>
                                    </table>
                                  </div>
                                  { data_fecha && 
                                 <div>
                                 {!data_fecha.length && 
                                    <table key={'NODATA'} style={{width: "100%"}} className="table table-hover table-bordered">
                                      <tbody>
                                        <tr>
                                          <td colSpan="6" className="react-bs-table-no-data">No hay datos</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                 }

                                 {
                                    data_fecha.map((extension, index)=>{
                                       return (
                                            <table key={index} className="table table-hover table-bordered">
                                               <tbody>  
                                               {
                                                extension.map((fecha, index)=>{
                                                   return( 
                                                        <tr key={index}>
                                                            <td >{fecha.value?fecha.label:''}</td>
                                                            <td style={BreakLine}>
                                                              {fecha.display_coordinacion?fecha.display_coordinacion:''}
                                                            </td>
                                                            <td >{fecha.display_ciclo?fecha.display_ciclo:''}</td>
                                                            <td >{fecha.cohorte?fecha.cohorte:''}</td>
                                                            <td >{fecha.inicio?dateFormatter(fecha.inicio):''}</td>
                                                            <td >{fecha.fin?dateFormatter(fecha.fin):''}</td>
                                                        </tr>
                                                    )
                                                })
                                              }
                                            </tbody>
                                            </table>
                                        )
                                    })
                                 }
                                 </div>
                              }
                             </div>
                           
                            </div>
                          </div>
                        </LoadMask>
                    </div>

                </div>

            </div>
        )
    }
}

