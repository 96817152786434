import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './login.css';


export  const Layout = (props)=> {
        return (
            <div className="login w-100 h-100 d-flex align-items-center">

                <div className="row login-wrapper col-12">

                    <div className="p-5 col-lg-4 col-md-4 col-sm-11 offset-lg-4 offset-md-4 offset-sm-3 offset-sm-0">
                         <div className="login_logo text-center">
                            <img  title="Ver" src={require("../../../../assets/img/logo_cusam.png")} alt="CUSAM" />
                         </div>
                        <div className="panel panel-flat">
                            <div className="panel-body">
                              
                                    {props.children} 
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}