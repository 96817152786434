import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { api } from "../../../../utility/api";
import _ from "lodash";
import { RenderSearchSelect, RenderNoAsyncSelectField } from './FilterFields'


class HorarioFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
                cursos_list: [], 
                pensum_sel: 0, 
                pensums:[], 
                carrera_seleccionada: 0, 
                carrera_list:[],
                extension_sel: 0,
                cohorte_options:[]
        };
    }
    componentWillMount() {
        this.getCiclos = this.getCiclos.bind(this);
        this.getExtensiones = this.getExtensiones.bind(this);
        this.getCoordinacion = this.getCoordinacion.bind(this);
        this.handleExtensionChange = this.handleExtensionChange.bind(this);
        this.handleCarreraChange = this.handleCarreraChange.bind(this);
        this.handlePensumChange = this.handlePensumChange.bind(this);
        this.handleCicloChange = this.handleCicloChange.bind(this);
        this.handleCohorte = this.handleCohorte.bind(this);


    }
    componentDidMount() {
        const year = (new Date()).getFullYear() -5;
        const years = []
        for (let i = 0; i < 10; i++) { 
            years.push({value: year+i, label: year+i });
        }
         this.setState({
                  cohorte_options: years
        })

    }
    //PARA POBLAR EL SELECT DE CARRERAS
    getExtensiones(search=""){
        return api.get(`extensiones/select/`, {value:search})
        .then(data=>{
            if(data){
                return data;
            }
            else
                return []
        })
        .catch(e=>{
            return []
        })
    };


//PARA POBLAR EL SELECT DE CURSOS
    getCiclos(search=''){
        return api.get(`ciclos/`, {search:search})
        .then(data=>{
            if(data && data.results){
                return data.results;
            }
            else
                return  []
        })
        .catch(e=>{
            return []
        })
    }


    getCoordinacion(extension){
        return api.get(`coordinaciones/select/${extension}/`)
        .then(data=>{
            if(data){
                this.setState({
                  carrera_list: []
                })
                this.setState({
                      carrera_list: [...data]
                    })
            }
            else
                this.setState({
                  carrera_list: []
                })
        })
        .catch(e=>{
           this.setState({
                  carrera_list: []
                })
        })
    };

    handleExtensionChange(e){
        if(e == '')
            this.props.filtroCoordinacionExtension({value: '', label:''});
        else if(e.value !=this.state.extension_sel){
                this.setState({ extension_sel: e.value });
                this.setState({ carrera_list: [] });
                this.getCoordinacion(e.value)
                this.props.filtroCoordinacionExtension(e);
        }
        
    }

    handleCarreraChange(e){
        if(e == '')
            this.props.filtroCoordinacionCarerra({pk: '', label:''});
        else if(e.pk !=this.state.carrera_seleccionada){
            this.setState({ carrera_seleccionada: e.pk });
            this.setState({ pensums: [] });
            let temp = []
            e.pensums.forEach((pensum, i)=>{
            if(!_.find(this.state.pensums, {pk: pensum.pk}))
                temp.push(pensum)
            });
            this.setState({
                  pensums: [...temp]
            })
            this.props.filtroCoordinacionCarerra(e)
            
        }
    }

    handlePensumChange(e){
        if(e == '')
            this.props.filtroPensum({pk:'', codigo:''});
        else if(e.pk !=this.state.pensum_sel){
            this.setState({ pensum_sel: e.pk });
            this.props.filtroPensum(e);
       }
    }
    handleCicloChange(e){
        if(e != '')
            this.props.filtroCiclo(e);
        else
            this.props.filtroCiclo({pk:'', nombre:''});
    }

    handleCohorte(e){
         if(e != '')
            this.props.filtroCohorte(e);
        else
            this.props.filtroCohorte({value:'', label:''});
    }


    render() {
      
        
        return (
            <div className="col-12 row pb-2 mt-2 px-0 mx-0">
                
                <div  className="col row px-0 mx-0">
                    <div className="form-group col-lg-6 col-sm-12 col-xl-3">
                        <label>Extensión</label>
                        <RenderSearchSelect
                            name='extension'
                            valueKey="value"
                            labelKey="label"
                            onChange = { this.handleExtensionChange }
                            label="Carrera"
                            defaultOptions={ true }
                            valor={this.props.coordinacion__extension}
                            loadOptions={this.getExtensiones}/>       
                   </div>
                    <div className="form-group col-lg-6 col-sm-12 col-xl-3">
                        <label>Carrera</label>
                       <RenderNoAsyncSelectField
                            name='coordinacion'
                            valueKey="pk"
                            labelKey="label"
                            onChange = { this.handleCarreraChange }
                            label="Pensums"
                            defaultOptions={ true }
                            valor={this.props.coordinacion}
                            options={this.state.carrera_list}/>             
                   </div>
                   <div className="form-group col-lg-6 col-sm-12 col-xl-2">
                        <label>Pénsum</label>
                        <RenderNoAsyncSelectField
                            name='pensum'
                            valueKey="pk"
                            labelKey="codigo"
                            onChange = { this.handlePensumChange }
                            label="Pensums"
                            valor={this.props.pensum}
                            defaultOptions={ true }
                            options={this.state.pensums}/>       
                   </div>
                   <div className="form-group col-sm-12 col-lg-6 col-xl-2">
                         <label>Ciclo</label>
                        <RenderSearchSelect
                            name='ciclo'
                            valueKey="pk"
                            labelKey="nombre"
                            label="Ciclo"
                            valor={this.props.ciclo}
                            defaultOptions={ true }
                            onChange={this.handleCicloChange}
                            loadOptions={this.getCiclos}/>       
                   </div> 
                   <div className=" form-group col-lg-6 col-sm-12 col-xl-2">
                         <label>Cohorte</label>
                        <RenderNoAsyncSelectField
                            name='cohorte'
                            valueKey="value"
                            labelKey="label"
                            onChange = { this.handleCohorte }
                            label="Cohorte"
                            valor={this.props.cohorte}
                            defaultOptions={ true }
                            options={this.state.cohorte_options}/>       
                        

                    </div>
                </div>
            </div>

      )
    }

}


export default HorarioFilter;



/*{ !this.props.noTitulo &&
                    <div className="col-lg-3 col-md-2 col-sm-12 text-right d-flex align-items-center justify-content-end font-weight-bold font-italic">
                                    FILTROS
                     </div>
                 }*/