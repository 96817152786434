import React from 'react';
import Form from '../Create/PensumForm';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Update extends React.Component {

  static propTypes = {
    update: PropTypes.func.isRequired,
    load2Update: PropTypes.func.isRequired

  }

  componentWillMount(){
    this.props.load2Update(this.props.match.params.id)
    this.props.findBorrablesPensum(this.props.match.params.id)
  }

  render () {
    const { update, loading, borrables_pensum } = this.props

    return (
    <LoadMask loading={loading} dark blur>
      <div className="row d-flex justify-content-center">
        <div className="col-12">
          <Form onSubmit={update} 
                pensum={ this.props.match.params.id} 
                data={ this.props.updateData } 
                editar={ true }  
                borrables={borrables_pensum}/>
        </div>
      </div>
    </LoadMask>
    )
  }
}
