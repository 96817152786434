import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask/LoadMask";
import { ACCIONES_BITACORA } from "../../../utility/constants";
import { RenderDateTime } from "../../Utils/renderField/renderReadField";
import Titulo  from "../../Utils/Titulo/TituloGrid";
import Anterior from './Anterior';
import Actual from './Actual';


class DetalleBitacora extends Component {
    static propTypes = {
        bitacora: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        //console.log(this.props.match.params.id)
        this.props.getBitacora(this.props.match.params.id);
    }

    render() {
        const {loading, bitacora} = this.props;
        const descripcion = _.find(ACCIONES_BITACORA, {label: bitacora.accion}) ? _.find(ACCIONES_BITACORA, {label: bitacora.accion}) : {};

        return (
            <div className="row pb-5">
                <div className="col-12 px-0">
                    <div className="grid-container">
                        <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                            <Titulo  titulo="Detalle" noDivisor={ true }/>
                            <LoadMask loading={loading} light blur>
                                <div className="d-flex flex-column flex-md-row">
                                     <div className="d-flex flex-2 pl-5">
                                        <span>
                                            <strong className="text-primary subtitulo font-weight-bold pr-3">{ bitacora.accion}</strong>
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-3">
                                     <div className="d-flex flex-1 pl-5">
                                        <span>
                                            <strong className="text-info subtitulo font-weight-bold pr-3">Fecha y hora:</strong>
                                        </span>
                                        <RenderDateTime value={bitacora.creado} time={ true } />
                                    </div>
                                    <div className="d-flex flex-1">
                                        <span>
                                            <strong  className="text-info subtitulo font-weight-bold pr-3">Responsable:</strong>
                                        </span>
                                        {bitacora.usuario.first_name}
                                    </div>
                                   
                                </div>
                                <div className="d-flex flex-column flex-sm-row font-italic">
                                    {!!bitacora.detalle_anterior && (
                                        <Anterior detalle={JSON.parse(bitacora.detalle_anterior)} />
                                    )}
                                    {!!bitacora.detalle && (
                                        <Actual detalle={JSON.parse(bitacora.detalle)} anterior={JSON.parse(bitacora.detalle_anterior)} />
                                    )}
                                </div>
                            </LoadMask>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default DetalleBitacora;
