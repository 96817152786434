import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/login';
import carrera from './modules/carrera';
import docente from './modules/docente';
import extension from './modules/extension';
import curso from './modules/curso';
import usuario from './modules/usuario';
import pensum from './modules/pensum';
import ciclo from './modules/ciclo';
import alumno from './modules/alumno';
import horario from './modules/horario';
import fecha_asignacion from './modules/fecha_asignacion';
import acta from './modules/acta';
import asignacion from './modules/asignacion';
import curso_docente from './modules/curso_docente';
import certificacion from './modules/certificacion';
import notas from './modules/notas';
import configuracion from './modules/configuracion';
import bitacora from './modules/bitacora';

export default combineReducers({
    form: formReducer,
    login,
    routing,
    carrera,
    docente,
    extension,
    curso,
    usuario,
    pensum,
    ciclo,
    alumno,
    horario,
    fecha_asignacion,
    acta,
    asignacion,
    curso_docente,
    certificacion,
    notas,
    configuracion,
    bitacora

});
