import { connect } from 'react-redux'
import Create from './CursoDocenteCreate'
import { 	create, 
			getActa, 
			getInscritos, 
			findTareas, 
			findNotas, 
			setToggleModal, 
			createTarea, 
			cerrar,
			resetArreglos
		} from '../../../../redux/modules/curso_docente'

const mdtp = { 	create, 
				getActa, 
				getInscritos, 
				findTareas, 
				findNotas, 
				setToggleModal, 
				createTarea, 
				cerrar,
				resetArreglos
			}
const mstp = state => {
	
	return{
		...state.curso_docente,
		me: state.login.me
	}
}


export default connect(mstp, mdtp)(Create)

