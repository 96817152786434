import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/login';
import { ROLES } from '../../utility/constants'
import Menu from './Menu';


const ms2p = (state) => {



	const url = window.location.href;

	let ver_usuarios = false;
	let ver_cursos = false;
	let ver_docente = false;
	let ver_carreras = false;
	let ver_extensiones = false;
	let ver_pensums = false;
	let ver_ciclos = false;
	let ver_horarios = false;
	let ver_vistas_alumno = false;
	let ver_vistas_docente= false;

	const me = state.login.me;
	if(state.login.me.is_superuser)
		ver_usuarios = true;
	if(state.login.me.rol == ROLES.REGISTRO || state.login.me.is_superuser){
		ver_cursos = true;
		ver_docente = true;
		ver_carreras = true;
		ver_extensiones = true;
		ver_pensums = true;
		ver_ciclos = true;
		ver_horarios = true;
	}
	else if(state.login.me.rol == ROLES.ESTUDIANTE)
		ver_vistas_alumno = true;
	else if(state.login.me.rol == ROLES.DOCENTE)
		ver_vistas_docente = true;
	return {
		ver_usuarios,
		ver_cursos,
		ver_docente,
		ver_carreras,
		ver_extensiones,
		ver_pensums,
		ver_ciclos,
		ver_horarios,
		url,
		ver_vistas_alumno ,
		ver_vistas_docente,
		me

	}

}



const md2p = { ...actions };

export default connect(ms2p, md2p)(Menu);


