import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './toolbar.css';
import Select from 'react-select';
import Search from './Search';
import Filter from './FilterHorario';

class Toolbar extends Component  {
    render() {
       const { titulo, buscar, buscador, boton, ruta } = this.props;
        return(
            <div className="col-12 row">
                <div className="col-12 row pt-5 pl-3">
                    <div className="col-md-9 titulo">
                        <h1 className="ml-0 text-uppercase text-center text-md-left"><strong>{this.props.titulo}</strong></h1>
                    </div>
                    <div className="col-md-3 col-lg-3 text-right mb-3 invalid-feedback-array subtitulo">
                        <span>¡Seleccione filtros para editar!</span>
                    </div>
                </div>
                <div className="col-12 row pb-2 mx-0 ">
                    
                        <Filter filtroCoordinacionExtension={ this.props.filtroCoordinacionExtension }
                            filtroCoordinacionCarerra={ this.props.filtroCoordinacionCarerra }
                            filtroPensum={ this.props.filtroPensum }
                            filtroCiclo={ this.props.filtroCiclo }
                            filtroCohorte ={ this.props.filtroCohorte }
                            noTitulo={ true }
                            coordinacion__extension={ this.props.coordinacion__extension}
                            coordinacion={ this.props.coordinacion}
                            pensum={ this.props.pensum}
                            ciclo={ this.props.ciclo}
                            cohorte={ this.props.cohorte}/>
                </div>
                <div className="col-12 row mb-3  d-flex justify-content-md-end justify-content-sm-center">
                        <div className="col-lg-2 px-0 col-md-3 mb-3 col-sm-12  d-flex justify-content-md-end justify-content-center">
                            <Link   className="btn btn-info  flex-nowrap flex-sm-wrap" 
                                to={`/${ ruta?ruta:titulo }/setfecha/${this.props.cohorte.value}/${this.props.ciclo.pk}/${this.props.pensum.pk}/${this.props.coordinacion__extension.value}/${this.props.coordinacion.pk}`}> 
                                Asignar Fechas
                            </Link>
                        </div>
                        <div className="col-lg-2  pl-md-0 col-md-3 col-sm-12  mb-3 d-flex justify-content-md-end justify-content-center">
                            <Link   className="btn btn-primary ml-md-3 ml-sm-0 flex-nowrap flex-sm-wrap" 
                                    to={`/${ ruta?ruta:titulo }/crear/${this.props.cohorte.value}/${this.props.ciclo.pk}/${this.props.pensum.pk}/${this.props.coordinacion__extension.value}/${this.props.coordinacion.pk}`}> { boton }
                            </Link>
                        </div>
                </div>



            </div>

        )
    }
}

export default Toolbar;





/*

 <div className="invalid-feedback-array">
                            <span>¡Seleccione filtros para asignar!</span>
                        </div>
*/