import React from 'react'
import Form from './AsignacionForm'
import LoadMask from "Utils/LoadMask/LoadMask";

export default class Create extends React.Component {
  componentWillMount() {
    this.props.getInscripcion(this.props.match.params.inscripcion)
    this.props.getCiclos(this.props.match.params.inscripcion, this.props.match.params.ciclo)
  }

  render () {
    const { onSubmit, me, inscripcion, ciclos_asignacion, updateData, setCerrarAsignacion, loading} = this.props

    return (
      <LoadMask loading={loading} dark blur>
        <div className="row d-flex justify-content-center">
            <Form onSubmit={onSubmit} 
                  editar={ false } 
                  inscripcion={ inscripcion }
                  me={me} 
                  setCerrarAsignacion={setCerrarAsignacion}
                  ciclos={ ciclos_asignacion }/>
        </div>
      </LoadMask>
    )
  }
}
