import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField, renderNoAsyncSelectField, renderSearchSelect } from 'Utils//renderField/renderField'
import { nivelesOptions } from '../../../utility/constants';
import Titulo from "../../Utils/Titulo/Titulo";
import renderDatePicker from 'Utils//renderField/renderDatePicker'
import { api } from "../../../../utility/api";


class Form  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {cursos_list: []};
    }
    componentWillMount() {
        this.getCursos = this.getCursos.bind(this);
        this.getCiclos = this.getCiclos.bind(this);
    }
    componentDidMount(){
        this.getCursos(this.props.pensum);

    }
    getCursos(pensum){
        return api.get(`cursos/prerequisitos/${pensum}/`)
        .then(data=>{
            if(data){
               this.setState({
                          cursos_list: data
                        })
            return data
            }
            else
                return  []
        })
        .catch(e=>{
            return []
        })
    }


    //PARA POBLAR EL SELECT DE CURSOS
    getCiclos(search=''){
        return api.get(`ciclos/`, {search:search})
        .then(data=>{
            if(data && data.results){
                return data.results;
            }
            else
                return  []
        })
        .catch(e=>{
            return []
        })
    }


    render(){
        const { handleSubmit } = this.props
        return (
            <form onSubmit={handleSubmit} className="row d-flex justify-content-center mt-lg-5 ">
                    <div className="form-group grid-container col-lg-11 col-sm-12">
                        <div className="grid-titulo padding-15">
                            <div className="padding-15 p-sm-0 py-sm-1">
                                <div className="row">
                                    <div className="form-group col-sm-12 col-md-12">
                                        <label htmlFor="nombre">Agregar curso con equivalencia</label>
                                        <Field name="curso"
                                            valueKey="value"
                                            labelKey="label"
                                            component={renderNoAsyncSelectField}
                                            label="Curso"
                                            options={this.state.cursos_list}/> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-sm-12 col-md-6">
                                        <label htmlFor="nombre">Ciclo</label>
                                        <Field name='ciclo'
                                                valueKey="pk"
                                                labelKey="nombre"
                                                component={renderSearchSelect}
                                                label="Ciclo"
                                                defaultOptions={ true }
                                                loadOptions={this.getCiclos}/>  
                                    </div>
                                    <div className=" form-group col-md-6 col-sm-12">
                                       <label htmlFor="cohorte">Cohorte</label>
                                        <Field 
                                            name="cohorte" 
                                            component={renderField}
                                            type="number" 
                                            className="form-control p-0 no-border" />

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-sm-12 col-md-6">
                                        <label htmlFor="nombre">Fecha</label>
                                        <Field
                                                name="fecha"
                                                className=" p-0"
                                                component={renderDatePicker}
                                                placeholder="10-19-2018"
                                                numberOfMonths={1}/>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12 mt-lg-5 ">
                                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <button type="button"  className="btn btn-secondary m-1" onClick={this.props.closeModal}>Cancelar</button>
                                            <button type="submit" className="btn btn-primary m-1">Añadir</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

      )
    }

}
const EquivalenciaForm = reduxForm({
    form: 'certificacion',
    validate: data => {
        return validate(data, {
            'curso': validators.exists()('Campo Requerido'),
            'ciclo': validators.exists()('Campo Requerido'),
            'cohorte': validators.exists()('Campo Requerido'),
            'fecha': validators.exists()('Campo Requerido'),
        })
    }
})(Form)

export default EquivalenciaForm
