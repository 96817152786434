import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField, renderSwitch} from 'Utils//renderField/renderField'
import { tituloOptions } from '../../../utility/constants';
import { email, phone, minLength } from '../../../../utility/validation';
import Titulo from "../../Utils/Titulo/Titulo";


class Form extends React.Component{

   
    constructor(props) {
      super(props);

      this.state = {
          cambiarPwd: false
      };

      this.onClick = this.onClick.bind(this)
  }

  
  onClick(e) {
    console.log(this.state.cambiarPwd)
      this.setState({ cambiarPwd: !this.state.cambiarPwd })
  }
 render(){
        const { handleSubmit, editar } = this.props
        return (
            <div  className="col-12 px-0">
                <Titulo editar={ editar } titulo='CATEDRÁTICO '/>
                <form onSubmit={handleSubmit} className="row d-flex justify-content-center ">
                    <div className="form-group grid-container col-lg-9 col-sm-12">
                        <div className="grid-titulo padding-15">
                            <div className="padding-15 p-sm-0 py-sm-1">
                                <div className="row">
                                        <div className="form-group col-sm-12 col-md-6">
                                            <label htmlFor="first_name">Nombre</label>
                                            <Field name="first_name" component={renderField} type="text" className="form-control" />
                                        </div>
                                        <div className="form-group col-sm-12 col-md-6">
                                            <label htmlFor="last_name">Apellido</label>
                                            <Field name="last_name" component={renderField} type="text" className="form-control" />
                                        </div>
                                </div>
                                <div className="row">

                                         <div className="form-group col-sm-12 col-md-6">
                                            <label htmlFor="titulo">Título</label>
                                            <Field name="titulo" component={renderField} type="text" className="form-control" />
                                           
                                        </div>
                                       <div className="form-group col-sm-12 col-md-6">
                                            <label htmlFor="nit">NIT</label>
                                            <Field name="nit" component={renderField} type="text" className="form-control" />
                                        </div>
                                        
                                </div>
                                <div className="row">
                                        <div className="form-group col-sm-12 col-md-6">
                                            <label htmlFor="telefono">Teléfono</label>
                                            <Field name="telefono" 
                                                   component={renderField} 
                                                   validate={phone}
                                                   type="text" 
                                                   className="form-control" />
                                        </div>
                                        <div className="form-group col-sm-12 col-md-6">
                                            <label htmlFor="email">Correo Electrónico</label>
                                            <Field  name="email" 
                                                    component={renderField} 
                                                    type="email"
                                                    validate={email}
                                                    className="form-control" />
                                        </div>
                                </div>
                                
                                <div className="row">
                                    <div className="form-group col-sm-12 col-md-6">
                                        <label htmlFor="codigo">Código (Nombre de Usuario)</label>
                                        <Field name="username" component={renderField} type="text" className="form-control" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 d-flex flex-row pl-0 ">
                                        {(!editar || this.state.cambiarPwd) && <div className="form-group col">
                                            <label htmlFor="password">Contraseña</label>
                                            <Field  name="password" 
                                                    component={renderField}
                                                    disabled ={editar && !this.state.cambiarPwd}
                                                    type="text" 
                                                    className="form-control" />
                                        </div>}
                                        {editar &&
                                            <div className="form-group col-sm-12 col-md-6 d-flex align-items-end">
                                        
                                                 <button type="button" 
                                                        className="btn btn-info"
                                                        onClick={this.onClick}>
                                                    Cambiar Contraseña
                                                </button>
                                            </div>
                                        }
                                    </div>  
                                    
                                </div>
                                <div className="row">
                                       
                                    <div className="form-group col-sm-12 col-md-6">
                                        <label htmlFor="direccion">Dirección</label>
                                        <Field name="direccion" component={renderField} type="text" className="form-control" />
                                    </div>
                                    <div className="form-group col-sm-12 col-md-6">
                                                <label htmlFor="email">Coordinador Académico</label>
                                                <Field 
                                                    name="coordinador_academico" 
                                                    component={renderSwitch} 
                                                    type="text" 
                                                    className="form-control p-0 no-border" />
        
                                     </div>
                                </div>
                                
                                   
                           

                                <div className="row my-auto">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <Link className="btn btn-secondary m-1" to="/docentes/">Cancelar</Link>
                                            <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
      )
    }

}



export const DocenteUpdateForm = reduxForm({
    form: 'docente',
    validate: data => {

        let password_validation = null;
        if (data.password) {
            password_validation = validators.regex(/^.{6,}$/)('Corto (el mínimo es 6 caracteres)');
        }
        return validate(data, {
            'username': validators.exists()('Campo Requerido'),
            'titulo': validators.exists()('Campo Requerido'),
            'telefono': validators.exists()('Campo Requerido'),
            'nit': validators.exists()('Campor Requerido'),
            'direccion': validators.exists()('Campo Requerido'),
            'first_name': validators.exists()('Campo Requerido'),
            'last_name': validators.exists()('Campo Requerido'),
            'email': validators.exists()('Campo Requerido'),
            'password': password_validation,
        })
    }
})(Form)

const DocenteForm = reduxForm({
    form: 'docente',
    validate: data => {

        let password_validation = validators.exists()('Campo Requerido');
        if (data.password) {
            password_validation = validators.regex(/^.{6,}$/)('Corto (el mínimo es 6 caracteres)');
        }
        return validate(data, {
            'username': validators.exists()('Campo Requerido'),
            'titulo': validators.exists()('Campo Requerido'),
            'telefono': validators.exists()('Campo Requerido'),
            'nit': validators.exists()('Campo Requerido'),
            'direccion': validators.exists()('Campo Requerido'),
            'first_name': validators.exists()('Campo Requerido'),
            'last_name': validators.exists()('Campo Requerido'),
            'email': validators.exists()('Campo Requerido'),
            'password': password_validation,
        })
    }
})(Form)

export default DocenteForm
