import React from 'react';
import PropTypes from 'prop-types';
import './VerErrores.css';

export default class verErrores extends React.Component {

  static propTypes = {
    verErrores: PropTypes.func.isRequired,
  }

  componentWillMount(){
    this.props.verErrores()
  }

  render () {
    const { errores } = this.props

    return (
      <div className="row d-flex justify-content-center">
      	<div  className="col-12 px-0">
            <div>
	         	<h5 className="mb-1 text-primary subtitulo font-weight-bold">
	                <strong>ERRORES DE IMPORTACIÓN</strong>
	            </h5>
	            <div className="py-3 background-secondary mb-4">
	            </div>
	        </div>
	        <div className="errores-container p-5">
	        	{ errores?errores.motivo:"" }
	        </div>
        </div>
      </div>
    )
  }
}
