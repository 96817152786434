import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { api } from "../../../../utility/api";
import _ from "lodash";
import { RenderSearchSelect, RenderNoAsyncSelectField } from './FilterFields'


class FechaAsignacionFilter extends Component {
    constructor(props) {
        super(props);
        this.state = { cohorte_options:[] };
    }
    componentWillMount() {
        this.getCiclos = this.getCiclos.bind(this);
        this.handleCicloChange = this.handleCicloChange.bind(this);
        this.handleCohorte = this.handleCohorte.bind(this);
    }
    componentDidMount() {
        const year = (new Date()).getFullYear() -5;
        const years = []
        for (let i = 0; i < 10; i++) { 
            years.push({value: year+i, label: year+i });
        }
         this.setState({
                  cohorte_options: years
        })
    }

	//PARA POBLAR EL SELECT DE CURSOS
    getCiclos(search=''){
        return api.get(`ciclos/`, {search:search})
        .then(data=>{
            if(data && data.results){
                return data.results;
            }
            else
                return  []
        })
        .catch(e=>{
            return []
        })
    }
    handleCicloChange(e){
        if(e == '')
            this.props.filtroCiclo({pk: '', nombre: ''});
        else
            this.props.filtroCiclo(e);
    }

    handleCohorte(e){
         if(e == '')
            this.props.filtroCohorte({value: '', label: ''});
        else
            this.props.filtroCohorte(e);
    }

    render() {
        return (
            <div className="col-12 row">
                <div  className="col-lg-12 col-md-12 row">
                   <div className="form-group col-sm-12 col-lg-6">
                        <label>Ciclo</label>
                        <RenderSearchSelect
                            name='ciclo'
                            valueKey="pk"
                            labelKey="nombre"
                            label="Ciclo"
                            defaultOptions={ true }
                            onChange={this.handleCicloChange}
                            valor={this.props.fecha_ciclo}
                            loadOptions={this.getCiclos}/>       
                   </div> 
                   <div className=" form-group col-lg-6 col-sm-12">
                        <label>Cohorte</label>
                        <RenderNoAsyncSelectField
                            name='cohorte'
                            valueKey="value"
                            labelKey="label"
                            onChange = { this.handleCohorte }
                            label="Cohorte"
                            defaultOptions={ true }
                            valor={this.props.fecha_cohorte}
                            options={this.state.cohorte_options}/>       
                    </div>
                </div>
            </div>

      )
    }

}



export default FechaAsignacionFilter;