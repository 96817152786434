import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { Link } from 'react-router-dom'
import { PrintContainer, actions as printActions } from "Utils/Print";
import ListadoAsistencia from "Utils/Impresiones/ListadoAsistencia";
import ActaContraloria from "Utils/Impresiones/ActaContraloria";
import ActaComprobante from "Utils/Impresiones/ActaComprobante";
import numeros from "Utils/Impresiones/numeros";
import Titulo from "Utils/Titulo/TituloBotones";
import { BreakLine } from 'Utils/tableOptions';
import { RenderDateTime } from 'Utils/renderField/renderReadField';
import writtenNumber from  'written-number';
import Table from 'Utils/Grid'

import './ActaVer.css';


function indexN(cell, row, enumObject, index) {
    return (<div>{index+1}</div>) 
}

function Color(cell){
    return {color: '#1687a7'}
}

function NotaFormat(cell, row) {
  //return cell.nombre
  return parseInt(cell) 
}

function EstadoFormat(cell, row) {
  //return cell.nombre
  return cell!= 3? writtenNumber(parseInt(row.nota), { lang: numeros}):'AUSENTE' 
}

export default class ActasVer extends React.Component {
    
    componentWillMount() {
        this.props.findNotasActa(this.props.match.params.id);
        this.props.getInscritos(this.props.match.params.id);
        this.props.getActa(this.props.match.params.id);
        this.props.getPieActa(this.props.match.params.id);
        

    }

    render() {
        const { loading, page, data_acta, notas, data_encabezado, pie_acta, data_inscritos} = this.props;
        const { findNotasActa } = this.props;


        return (
            <div className="row  d-flex justify-content-center">
               <img  className="oculto" src={require("../../../../../assets/img//membrete_logo.png")}/>
               <div className="col-sm-12 px-0 pt-2">
                    <div className="grid-container">
                    <Titulo  titulo='ACTA'
                             boton1="Imprimir Comprobante"
                             boton2="Imprimir Acta"
                             boton3="Imprimir Asistencia"
                             funcBoton1={(e) => {
                                      e.preventDefault();
                                      printActions.print('COMPROBANTE')
                                   }}
                             funcBoton2={(e) => {
                                      e.preventDefault();
                                      printActions.print('ACTA')
                                   }}
                             funcBoton3={(e) => {
                                      e.preventDefault();
                                      printActions.print('asistencia')
                                   }}/>
                       <div className="grid-title">

                        <div className="row col-sm-12 px-0 pt-2 mx-0 px-2">    
                           <div  className="col-lg-3 col-md-4 text-md-left text-sm-center pl-md-5 col-sm-12 subtitulo font-weight-bold">
                              <div>
                                <h5 className="text-info">
                                  <strong>{  data_encabezado.curso?data_encabezado.curso: ''}</strong>
                                </h5>
                              </div>
                              <div className="text-primary">
                                <span className="pr-1">
                                  {  data_encabezado.ciclo?data_encabezado.ciclo: ''}
                                </span>
                                <span>
                                  {  data_encabezado.cohorte?data_encabezado.cohorte: ''}
                                </span>
                              </div>                           
                           </div>
                           <div  className="col-lg-7 col-md-8 col-sm-12 font-weight-bold ">
                                    <div>
                                      <span className="mr-2">
                                        Sección:
                                      </span>
                                      <span className="mr-4 text-info subtitulo font-weight-bold">
                                        {  data_encabezado.seccion?data_encabezado.seccion: ''}
                                      </span>
                                      <span className="mr-2">
                                        Catedrático: 
                                     </span>
                                      <span className="mr-4 text-info subtitulo font-weight-bold">
                                        { pie_acta.docente }
                                      </span>
                                    </div>
                                    <div>
                                      <span className="mr-2">
                                        Carrera: 
                                     </span>
                                      <span className="mr-4 text-info subtitulo font-weight-bold">
                                        {  data_encabezado.carrera?data_encabezado.carrera: ''}
                                      </span>
                                    </div>

                           </div> 
                            <div className="col-lg-2 col-md-12 text-center text-md-right pt-md-3 pr-md-5">
                                  <Link className="btn btn-primary" 
                                        to={`/curso_docente/crear/${this.props.match.params.id}`}>
                                        Editar Notas
                                  </Link>
                            </div>

                        </div>
                        <div className="row mt-2 mx-0 px-md-5 mt-2 font-italic">    
                            <div className="row col-sm-12 px-0 p-2 mx-0 border-secondary">
                             <div  className="col-md-2 col-sm-6 text-md-left text-sm-center font-weight-bold pl-md-2">
                                <div><span className="mb-1">Fecha inicio:</span></div>
                                <div>{RenderDateTime({value: data_encabezado.inicio, className: 'text-secondary letra-pequenia'})}</div>
                             </div>
                             <div  className="col-md-2 col-sm-6 text-md-left text-sm-center font-weight-bold">
                                <div><span className="mb-1">Fecha final:</span></div>
                                <div>{RenderDateTime({value: data_encabezado.fin, className: 'text-secondary letra-pequenia'})}</div>
                             </div>
                             <div  className="col-md-2 col-sm-6 text-md-left text-sm-center font-weight-bold">
                                <div><span className="mb-1">Fecha acta:</span></div>
                                <div>{RenderDateTime({value: data_encabezado.fecha_acta, className: 'text-secondary letra-pequenia'})}</div>
                             </div>
                             <div  className="col-md-3 col-sm-12 text-md-left text-sm-center font-weight-bold d-flex flex-column">
                                  { !data_encabezado.abierto && 
                                     <div className="text-info my-auto">
                                       ACTA CERRADA POR CATEDRÁTICO
                                      </div>
                                  }
                                  { data_encabezado.cerrado_fecha && 
                                     <div className="text-info my-auto">
                                       ACTA CERRADA POR FECHA FINAL
                                      </div>
                                  }
                             </div>
                             <div  className="col-md-3 col-sm-12 text-md-left text-sm-center font-weight-bold text-md-right text-center pr-md-2 my-auto">
                                  { !data_encabezado.abierto && 
                                    <button className="btn btn-info"
                                            onClick={e=>{
                                              this.props.reabrir(this.props.match.params.id);
                                            }}>Reabrir Acta</button>
                                  }

                             </div>
                            </div>
                        </div>
                        <div className="col-sm-12 px-0 pt-2 px-md-5 mt-4"> 

                           <Table  onPageChange={findNotasActa} 
                                  data={ { results: notas } } 
                                  loading={loading} 
                                  page={page} 
                                  noPagination={ true }>
                            <TableHeaderColumn
                              tdStyle={BreakLine}
                              thStyle={BreakLine}
                              className="titulo-dias text-info td-impresion"
                              columnClassName=" td-impresion col-size"
                              dataField="pk" isKey dataAlign="center"
                              dataFormat={indexN}>NO.</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                className="titulo-dias text-info td-impresion col-size"
                                columnClassName=" td-impresion col-size pr-2"
                                dataField="carnet" dataSort>Carnet</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                className="titulo-dias text-info col-large td-impresion "
                                columnClassName="col-large td-impresion"
                                dataField="alumno" dataSort>Alumno</TableHeaderColumn>
                             <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                className="titulo-dias text-info text-uppercase"
                                columnClassName="text-uppercase"
                                tdStyle={Color}
                                dataFormat={NotaFormat}
                                dataField="nota" dataSort>Nota</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                columnClassName="text-uppercase"
                                className="titulo-dias text-info"
                                dataFormat={EstadoFormat}
                                dataField="estado_nota" dataSort/>
                        </Table>

                        </div>

                        <div className="col-sm-12 px-0 pt-2"> 
                            <ActaContraloria 
                                notas={ notas }
                                pie_acta={ pie_acta }
                                data_encabezado={ data_encabezado }/>
                            <ActaComprobante notas={ notas }
                                             data_encabezado={ data_encabezado }/>
                            <ListadoAsistencia  data_acta={ data_encabezado }
                                                data_inscritos={ data_inscritos }/>
                        </div>


                       
                       </div>
                </div>
                <div >
                </div>
            </div>
            </div>
        )
    }
}
