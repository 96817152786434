import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm, FieldArray, FormSection} from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { connect } from 'react-redux'
import { renderField, renderSelectField, renderSwitch, renderSearchSelect, renderNoAsyncSelectField, renderTimeField } from 'Utils//renderField/renderField'
import { api } from "../../../../utility/api";
import _ from "lodash";
import { required } from '../../../../utility/validation';
import renderDatePicker from 'Utils//renderField/renderDatePicker'
import Titulo from "../../Utils/Titulo/TituloBigForm";



//FROM PARA AÑADIR CARRERAS
const renderAlumno = ({ fields, getAlumnos, meta: { error, submitFailed } }) => (
    <div className="row px-5 mt-2">
        <div className="col-sm-12">
             <table className="table table-sm table-hover">
                <thead>
                <tr className="array-header">
                    <th style={{width: "90%"}} className="text-primary subtitulo font-weight-bold py-2 titulo-dias pl-3">Alumno</th>
                    <th style={{width: "10%"}} className="text-primary subtitulo font-weight-bold py-2 titulo-dias"/>
                </tr>
                </thead>
            </table>
               
            <div className="">
            <table className="table table-sm table-hover">
                <tbody>
                {fields.map((alumno, index) => (
                    <tr key={index} className="my-2">
                        <td style={{width: "90%"}}>
                            <Field
                                name={`${alumno}`}
                                valueKey="pk"
                                labelKey="label"
                                component={renderSearchSelect}
                                label="Carrera"
                                loadOptions={getAlumnos}/>
                        </td>
                        <td style={{width: "10%"}} className="text-center">
                             <img   onClick={() => fields.remove(index)}
                                     className="action-img" 
                                     title="Eliminar" 
                                     src={require("../../../../../assets/img/icons/delete.png")} alt="Delete" />
                    
                        </td>
                    </tr>
                ))}
                 </tbody>

                
               
            </table>
             {  submitFailed &&
                error &&
                <div className="invalid-feedback-array">
                        {error}
                    </div>
            }
            </div>
             <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center mt-5">
                <button type="button" className="btn btn-info  my-sm-auto flex-nowrap flex-sm-wrap" onClick={() => fields.push({})}>
                    Agregar Alumno
                </button>
            </div>
        </div>
    </div>
)
//FIN FORM CARRERAS



//FORM PRINCINPAL DE PENSUMS
class ActasEspecialesForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          ciclopensum:[],
          ciclo_sel: '',
          cursos:[]          
        };
    }
    componentWillMount() {
        this.getCicloPensum = this.getCicloPensum.bind(this);
        this.handleCicloPensumChange = this.handleCicloPensumChange.bind(this);
        this.getDocentes = this.getDocentes.bind(this);
        this.getAlumnos = this.getAlumnos.bind(this);
    }
    componentDidMount() {
        this.getCicloPensum();
    }

     componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.editar && this.props.data !== prevProps.data) {
        const acta = this.props.data
        this.setState({ ciclo_sel: acta.ciclo_pensum.nombre});
        this.handleCicloPensumChange(acta.ciclo_pensum);
      }
    }


  //PARA POBLAR EL SELECT DE CICLO PENSUM
    getCicloPensum(search){
        return api.get(`cursos/ciclopensum/${this.props.idPensum}/`)
        .then(data=>{
            if(data){
                this.setState({ciclopensum: data})
            }
            else
                this.setState({ciclopensum: []})
        })
        .catch(e=>{
          console.log(e)
            this.setState({ciclopensum: []})
        })
    }


    /*inscrito__extension
    inscrito__pensum
    inscrito__cohorte
    inscrito__estado*/


    getAlumnos(search=''){
      const inscrito__extension = this.props.coordinacion__extension.value;
      const inscrito__pensum = this.props.pensum.pk;
      const inscrito__cohorte = this.props.cohorte.value;
      const params = {search, inscrito__extension, inscrito__pensum, inscrito__cohorte}
      return api.get(`alumnos/inscripcion/`, params)
        .then(data=>{
            if(data && data.results){
                return data.results;
            }
            else
                return [];
        })
        .catch(e=>{
           return [];
        })
    }



    //POBLAR EL SELECT DE DOCENTES
    getDocentes(search=''){
        return api.get(`docentes/select`, {value: search})
        .then(data=>{
            if(data){
                return data;
            }
            else
                return [];
        })
        .catch(e=>{
            return [];
        })
    };


    //CUANDO CAMBIA EL VALOR SELECCIONADO EN EL SELECT DE CICLOS
    handleCicloPensumChange(e){
      if(e.nombre != this.state.ciclo_sel){
        this.setState({ ciclo_sel: e.nombre });
        this.setState({ cursos: e.cursos && e.cursos.length?e.cursos: [] });
      }
    }
    
    render() {
        const { handleSubmit, data, editar } = this.props
        const { coordinacion__extension, coordinacion, pensum, cohorte } = this.props
        
        return (
            <form onSubmit={handleSubmit} noValidate>
                <div className="form-group grid-container">
                    <div className="grid-titulo padding-15">
                        <div className="padding-15 p-sm-0 py-sm-1">
                            <Titulo  editar={editar} titulo='ACTA ESPECIAL'/>
                            <div className=" subtitulo col-12 pl-5 mx-0">
                               <div className="subtitulo text-info"> 
                                    <h5><strong>{coordinacion.label}</strong></h5>
                               </div>
                               <div >
                                    <span>EXTENSIÓN {coordinacion__extension.label}</span>
                               </div>
                               <div >
                                     <span>PENSUM {pensum.codigo}</span>
                               </div>
                               <div >
                                     <span> {cohorte.label}</span>
                               </div>
                            </div>
                            <div className="py-3 background-secondary my-4">
                            </div>
                            <div className="row d-flex justify-content-center px-5">
                              <div className="form-group col-lg-4 col-sm-12 ">
                                    <label htmlFor="ciclo">Ciclo</label>
                                    <Field
                                          name='ciclo_pensum'
                                          valueKey="nombre"
                                          labelKey="nombre"
                                          component={renderNoAsyncSelectField}
                                          label="Ciclo"
                                          defaultOptions={ true }
                                          onChange={this.handleCicloPensumChange}
                                          options={ this.state.ciclopensum } /> 
                              </div>
                              <div className="form-group col-lg-4 col-sm-12 ">
                                    <label htmlFor="ciclo">Sección</label>
                                    <Field name="seccion" 
                                           component={renderField} 
                                           type="text" 
                                           className="form-control" />
                              </div>
                              <div className="form-group col-lg-4 col-sm-12 ">
                                    <label htmlFor="curso">Nombre Acta</label>
                                    <Field name="nombre_especial" 
                                           component={renderField} 
                                           type="text" 
                                           className="form-control" />
                              </div>
                            </div>
                            <FormSection name="detalleActas">
                              <div className="row d-flex justify-content-center px-5">
                                <div className="form-group col-lg-4 col-sm-12 ">
                                      <label htmlFor="ciclo">Fecha inicio</label>
                                      <Field
                                              name='inicio'
                                              className=" p-0"
                                              component={renderDatePicker}
                                              placeholder="10-19-2018"
                                              numberOfMonths={1}/>
                                </div>
                                <div className="form-group col-lg-4 col-sm-12 ">
                                      <label htmlFor="curso">Fecha Fin</label>
                                      <Field
                                            name='fin'
                                            className=" p-0"
                                            component={renderDatePicker}
                                            placeholder="10-19-2018"
                                            numberOfMonths={1}/>
                                </div>

                                <div className="form-group col-lg-4 col-sm-12">
                                    <label htmlFor="nombre">Fecha Acta</label>
                                    <Field
                                            name='fecha_acta'
                                            className=" p-0"
                                            component={renderDatePicker}
                                            placeholder="10-19-2018"
                                            numberOfMonths={1}/>
                                    
                                </div>
                              </div>
                              <div className="row d-flex justify-content-start px-5">
                                <div className="form-group col-lg-4 col-sm-12 ">
                                      <label htmlFor="curso">Curso</label>
                                      <Field
                                            name='curso'
                                            valueKey="pk"
                                            labelKey="label"
                                            component={renderNoAsyncSelectField}
                                            label="Curso"
                                            defaultOptions={ true }
                                            options={ this.state.cursos } /> 
                                </div>

                                <div className="form-group col-lg-4 col-sm-12">
                                    <label htmlFor="nombre">Catedrático</label>
                                    <Field  name='docente'
                                            valueKey="value"
                                            labelKey="label"
                                            component={renderSearchSelect}
                                            label="Catedrático"
                                            placeholder="Catedrático"
                                            defaultOptions={ true }
                                            loadOptions={ this.getDocentes }/>
                                    
                                </div>  
                              </div>
                            </FormSection>
                            <FieldArray name="alumnos" component={renderAlumno} getAlumnos={this.getAlumnos}/>


                             <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center mt-3">
                                        <Link className="btn btn-secondary m-1" to="/actas/">Cancelar</Link>
                                        <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                    </div>
                                </div>
                            </div>
               
                        </div>
                    </div>
                </div>
            </form>
      )
    }

}

ActasEspecialesForm = reduxForm({
    form: 'acta_especial',
     validate: values => {
        const errors = {};
        const detalleActas ={};
        if(!values.ciclo_pensum)
          errors.ciclo_pensum = "Campo Requerido"
        if(!values.detalleActas || !values.detalleActas.curso){
          detalleActas.curso = "Campo Requerido"
          errors.detalleActas = detalleActas;
        }
        if(!values.detalleActas || !values.detalleActas.docente){
          detalleActas.docente = "Campo Requerido"
          errors.detalleActas = detalleActas;
        }
        if(!values.seccion)
          errors.seccion = "Campo Requerido"
        if(!values.nombre_especial)
          errors.nombre_especial = "Campo Requerido"
        if(!values.alumnos || !values.alumnos.length)
        {
            errors.alumnos ={ _error: 'Debe agregar al menos un alumno' }
        }
        return errors;
    }
})(ActasEspecialesForm)

export default ActasEspecialesForm