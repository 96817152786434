import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { BootstrapTable } from 'react-bootstrap-table';
import { BreakLine } from '../../Utils/tableOptions';
import { PrintContainer, actions as printActions } from "Utils/Print";
import ListadoAsistencia from "Utils/Impresiones/ListadoAsistencia";
import { Link } from 'react-router-dom';

function CursoFormat(cell, row) {
  if(cell  && cell.label){
    return cell.label;
    }
    return ''
}

function dateFormatter(cell) {
    if (!cell) {
          return "---------";
    }
    try
    {

        let date = new Date(cell)
        return date.toLocaleDateString()
    }
    catch(e){
        return cell;
    }
    
}



export function ButtonFormatter() {
    return (cell, row) => {
        return (
          <Link className="btn btn-info" to={`/actas/ver/${cell}`}>Ver Acta</Link>
        )
    };
}




const expandComponent=({row, EstadoFormat})=> {

    return (
    
    <div className="px-lg-5 mx-lg-5">
        <div className="p-2 subtitulo background-secondary text-white font-weight-bold">
            <span className="mr-2">{ row.ciclo_pensum }</span>
            <span>Sección { row.seccion }</span>
        </div>
        <BootstrapTable
            data={ row && row.detalleActas ? row.detalleActas:[] }>
            <TableHeaderColumn
                tdStyle={BreakLine}
                thStyle={BreakLine}
                dataFormat={ CursoFormat }
                className="titulo-dias text-info"
                dataField="curso" dataSort>
                CURSO</TableHeaderColumn>
            <TableHeaderColumn
                thStyle={BreakLine}
                dataFormat={dateFormatter}
                className="titulo-dias text-info"
                dataField="inicio" dataSort>
                Fecha Apertura</TableHeaderColumn>
            <TableHeaderColumn
                thStyle={BreakLine}
                dataFormat={dateFormatter}
                className="titulo-dias text-info"
                dataField="fin" dataSort>
                Fecha Final</TableHeaderColumn>
            <TableHeaderColumn
                thStyle={BreakLine}
                dataFormat={dateFormatter}
                className="titulo-dias text-info"
                dataField="fecha_acta" dataSort>
                Fecha de Acta</TableHeaderColumn>
            <TableHeaderColumn
                thStyle={BreakLine}
                tdStyle={BreakLine}
                dataFormat={EstadoFormat}
                className="titulo-dias text-info"
                dataField="abierto" dataSort>
                Cerrado</TableHeaderColumn>
            
            <TableHeaderColumn
                dataFormat={ButtonFormatter()}
                className="titulo-dias text-info"
                dataField="pk" isKey dataAlign="center"/>


        </BootstrapTable>
       
    </div>
    );
  }

export default expandComponent;