import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter }  from 'Utils/Acciones/Acciones'
import { Link } from 'react-router-dom'
import { BreakLine } from '../../Utils/tableOptions';
import Table from 'Utils/Grid'
import Filtros from '../../Utils/Toolbar/FilterBitacora';
import Titulo from '../../Utils/Titulo/TituloGrid';
import {fechalHoraFormat} from '../../Utils/renderField/renderTableField';
      


export default class BitacoraGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        accion: PropTypes.object,
        fecha_inicial: PropTypes.any.isRequired,
        fecha_final: PropTypes.any.isRequired,
    };

     componentWillMount() {
        this.props.getBitacoras();
    }

    render() {
        const { loading, data, fecha_inicial, fecha_final, usuario, accion} = this.props;

        const { getBitacoras, page, setUsuario,setAccion, setFecha} = this.props;

        return (

            <div className="row  d-flex justify-content-center">
               <div className="col-sm-12 px-0 pt-2">
                    <div className="grid-container">
                       <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                           
                            <div className="row col-12 d-flex justify-content-between my-3">
                                <div className="col-md-auto col-sm-12 d-flex flex-column my-auto">
                                    <Titulo titulo='BITÁCORA' noDivisor={ true }/>
                                </div>
                                <div className="col-md-10 col-sm-12">
                                    <Filtros  filtroUsuario={ setUsuario }
                                                filtroFecha={ setFecha }
                                                filtroAccion={ setAccion }
                                                accion={ accion }
                                                fecha_inicial={ fecha_inicial }
                                                fecha_final={ fecha_final }
                                                usuario={usuario}/>
                                </div>
                            </div>


                        </div>
                         <div className="">
                            <Table onPageChange={getBitacoras} data={data} loading={loading} page={page}>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row)=>{
                                        if(cell.first_name)
                                            return cell.first_name + " "+ cell.last_name
                                    }}
                                    dataField="usuario" dataSort>Usuario</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="accion" dataSort>Acción</TableHeaderColumn>
                                <TableHeaderColumn
                                    thStyle={BreakLine}
                                    dataFormat={fechalHoraFormat}
                                    dataAlign="center"
                                    dataField="creado" dataSort>Fecha</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="id" isKey dataAlign="center"
                                    dataFormat={activeFormatter({ ver_icono: '/bitacora/ver'})}
                                />
                            </Table>
                            </div>
                       

                    </div>

                </div>

            </div>
        )
    }
}
