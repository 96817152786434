import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import './accordion.css';
import './menu.css';
import { icons } from "icons";


const Movil = (props)=>{
  
        const {
        ver_usuarios,
        ver_cursos,
        ver_docente,
        ver_carreras,
        ver_extensiones,
        ver_pensums,
        ver_ciclos,
        ver_horarios,
        url,
        ver_vistas_alumno ,
        ver_vistas_docente,
        me
      } = props;
  return (
    <div style={{ position: "relative" }}>
      <div className="v-menu v-menu-front">
       <Accordion>
         <AccordionItem>
           <AccordionItemTitle className={`profile-item ${url.includes("/perfil")  ? "activo " : ""}`}>
                  <div className="menu-item mb-0 sidebar-item mt-5 ">
                      <em className="px-2 fa fa-user pr-5" />
                       {me?`${me.first_name?me.first_name: me.username} ${me.first_name?me.last_name:''}`:'Perfil'}
                    </div>
                </AccordionItemTitle>
                <div className="list-items">
                  {( me.is_superuser || me.rol == 0) &&
                    <AccordionItemBody>
                            <Link to='/usuarios/perfil/' onClick={props.toggleMenu}>
                                   <em className="fa fa-user" />
                                    Perfil
                          </Link>
                    </AccordionItemBody>
                  }
                  <AccordionItemBody>
                          <a   href="/#/login" onClick={props.logOut}>
                                <em className="fa fa-sign-out" />Cerrar Sesión
                          </a>
                  </AccordionItemBody>
                  
                </div>
         </AccordionItem>
        {  ver_vistas_alumno &&
            <Link to='/asignacion/inscripcion' className={`sidebar-item menu-item ${url.includes("/asignacion") ? "activo" : ""}`} onClick={props.toggleMenu}>
                     <img   className="icon-menu img-inactivo" src={icons.asignaciones1 }  alt="Asignaciones" />
                      <img   className="icon-menu img-activo" src={icons.asignaciones2 }  alt="Asignaciones" />    
                     <div className="pl-3">Asignaciones</div>
            </Link>
          }
          {  ver_vistas_alumno &&
            <Link to='/notas' className={`sidebar-item menu-item ${url.includes("/notas") ? "activo" : ""}`} onClick={props.toggleMenu}>
                     <img   className="icon-menu img-inactivo" src={icons.alumnos1 }  alt="Notas" />
                      <img   className="icon-menu img-activo" src={icons.alumnos2 }  alt="Notas" />    
                     <div className="pl-3">Notas</div>
            </Link>
          }
          {  ver_vistas_docente &&
            <Link to='/curso_docente' className={`sidebar-item menu-item ${url.includes("/curso_docente") ? "activo" : ""}`} onClick={props.toggleMenu}>
                     <img   className="icon-menu img-inactivo" src={icons.cursoscatedraticos1 }  alt="Cursos" />
                      <img   className="icon-menu img-activo" src={icons.cursoscatedraticos2 }  alt="Cursos" />    
                     <div className="pl-3">Cursos</div>
            </Link>
          }

          {( me.is_superuser || me.rol == 0) &&
            <AccordionItem>
                <AccordionItemTitle className={` ${url.includes("/carreras") || url.includes("/pensums") || url.includes("/cursos") || url.includes("/ciclos") || url.includes("/usuarios") && !url.includes("/perfil")  ? "activo" : ""}`}>
                  <div className="menu-item mb-0 sidebar-item mt-5">
                        <img   className="icon-menu img-inactivo" src={icons.admin1}  alt="Alumnos" />
                        <img   className="icon-menu img-activo" src={icons.admin2}  alt="Alumnos" />    
                        <div className="pl-3 letra-pequenia"> Contro Académico</div>
                      </div>
                </AccordionItemTitle>
                <div className="list-items">
                <AccordionItemBody>
                       { ver_carreras &&
                        <Link to='/carreras/' onClick={props.toggleMenu}>
                                Carreras
                        </Link>
                      }
                </AccordionItemBody>
                <AccordionItemBody>
                    { ver_cursos &&
                      <Link to='/cursos/'>
                        Cursos
                      </Link>
                    }
                </AccordionItemBody>
                <AccordionItemBody>
                       { ver_pensums &&
                          <Link to='/pensums/' onClick={props.toggleMenu}>
                            Pensums
                          </Link>
                        }
                </AccordionItemBody>
                <AccordionItemBody>
                    { ver_extensiones &&
                      <Link to='/extensiones/' onClick={props.toggleMenu}>
                        Extensiones
                      </Link>
                    }
                </AccordionItemBody>
                <AccordionItemBody>
                    { ver_ciclos &&
                      <Link to='/ciclos/' onClick={props.toggleMenu}>
                        Ciclos
                      </Link>
                    }
                </AccordionItemBody>
                <AccordionItemBody>
                    { ver_usuarios &&
                      <Link to='/usuarios/' onClick={props.toggleMenu} className="letra-pequenia">
                        Usuarios Control Académico
                      </Link>
                    }
                </AccordionItemBody>
                
                 <AccordionItemBody>
                    { ver_ciclos &&
                      <Link to='/configuraciones/' onClick={props.toggleMenu} className="letra-pequenia">
                       Configuraciones Universidad
                      </Link>
                    }
                </AccordionItemBody>
                <AccordionItemBody>
                    { ver_ciclos &&
                      <Link to='/bitacora/' onClick={props.toggleMenu}>
                       Bitácora
                      </Link>
                    }
                </AccordionItemBody>
                </div>
            </AccordionItem>
          }
        </Accordion>

       {  ver_docente &&
        <Link to='/alumnos/' className={`sidebar-item menu-item ${url.includes("/alumnos") ? "activo" : ""}`} onClick={props.toggleMenu}>
                <img   className="icon-menu img-inactivo" src={ icons.alumnos1 }  alt="Alumnos" />
                <img   className="icon-menu img-activo" src={ icons.alumnos2 }  alt="Alumnos" />    
                 <div className="pl-3"> Alumnos</div>
               </Link>
        }
        {  ver_docente &&
        <Link to='/docentes/' className={`sidebar-item menu-item ${url.includes("/docentes") ? "activo" : ""}`} onClick={props.toggleMenu}>
                 <img   className="icon-menu img-inactivo" src={icons.catedraticos1 }  alt="Catedráticos" />
                  <img   className="icon-menu img-activo" src={icons.catedraticos2 }  alt="Catedr" />    
                 <div className="pl-3"> Docentes</div>
               </Link>
        }
        {  ver_horarios &&
        <Link to='/actas/' className={`sidebar-item menu-item ${url.includes("/actas") ? "activo" : ""}`} onClick={props.toggleMenu}>
                 <img   className="icon-menu img-inactivo" src={icons.actas1 }  alt="Actas" />
                  <img   className="icon-menu img-activo" src={icons.actas2 }  alt="Actas" />    
                 <div className="pl-3"> Actas</div>
               </Link>
        }
        {  ver_horarios &&
        <Link to='/horarios/' className={`sidebar-item menu-item ${url.includes("/horarios") ? "activo" : ""}`} onClick={props.toggleMenu}>
                 <img   className="icon-menu img-inactivo" src={icons.horarios1 }  alt="Horarios" />
                  <img   className="icon-menu img-activo" src={icons.horarios2 }  alt="Horarios" />    
                 <div className="pl-3"> Horarios</div>
               </Link>
        }
        {  ver_horarios &&
        <Link to='/fecha_asignacion/' className={`sidebar-item menu-item ${url.includes("/fecha_asignacion") ? "activo" : ""}`} onClick={props.toggleMenu}>
                 <img   className="icon-menu img-inactivo" src={icons.asignaciones1 }  alt="Asignaciones" />
                  <img   className="icon-menu img-activo" src={icons.asignaciones2 }  alt="Asignaciones" />    
                 <div className="pl-3">Asignaciones</div>
               </Link>
        }
        {  ver_horarios &&
        <Link to='/certificaciones/' className={`sidebar-item menu-item ${url.includes("/certificaciones") ? "activo" : ""}`} onClick={props.toggleMenu}>
                 <img   className="icon-menu img-inactivo" src={icons.certificaciones1 }  alt="Certificaciones" />
                  <img   className="icon-menu img-activo" src={icons.certificaciones2 }  alt="Certificaciones" />    
                 <div className="pl-3">Certificaciones</div>
               </Link>
        }
        
      </div>
    </div>
    )
}



export default Movil