import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form'
import { renderField, renderSelectField } from 'Utils//renderField/renderField'
import { SEXO } from '../../../utility/constants';
import Titulo from "../../Utils/Titulo/TituloGrid";
const Form = props => {
    const { handleSubmit, editar } = props
    return (

        <div  className="col-12 px-0">
            <Titulo titulo='Configuraciones Universidad'/>
            <form onSubmit={handleSubmit} className="row d-flex justify-content-center mt-lg-5 ">
                <div className="form-group grid-container col-lg-9 col-sm-12">
                    <div className="grid-titulo padding-15">
                        <div className="padding-15 p-sm-0 py-sm-1">
                            <div className="row">
                                
                                    <div className="form-group col-sm-12 col-md-6">
                                        <label htmlFor="director">Director</label>
                                        <Field name="director" component={renderField} type="text" className="form-control" />
                                    </div>
                                    
                                    <div className="form-group col-sm-12 col-md-6">
                                       <label htmlFor="sexo_director">Sexo</label>
                                            <Field 
                                                name="sexo_director" 
                                                component={renderSelectField} 
                                                type="text" 
                                                className="form-control p-0 no-border" 
                                                labelKey="label" valueKey="value" options={SEXO}/>
                                    </div>
                            </div>
                             <div className="row">                               
                                    <div className="form-group col-sm-12 col-md-6">
                                        <label htmlFor="control_academico">Control Académico</label>
                                        <Field name="control_academico" component={renderField} type="text" className="form-control" />
                                    </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12 mt-lg-5 ">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Link className="btn btn-secondary m-1" to="/carreras/">Cancelar</Link>
                                        <button type="submit" className="btn btn-primary m-1">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
  )
}



const CarreraForm = reduxForm({
    form: 'configuracion',
    validate: value => {
        const errors = {};
        if(!value.director){
              errors.director = 'Campo Requerido';
        }
        if(value.sexo_director== null){
              errors.sexo_director = 'Campo Requerido';
        }
         if(!value.control_academico){
              errors.control_academico = 'Campo Requerido';
        } 
        return errors;
    }
})(Form)

export default CarreraForm
